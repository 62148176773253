@import "../globals";

.placeholder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0px;

  .placeholder-button {
    margin-top: 20px;
  }

  .placeholder-title {
    color: $placeholder-gray;
    text-align: center;
    font-weight: 550;
  }

  .placeholder-icon-wrapper {
    margin: 30px 0px;
    .placeholder-icon {
      font-size: 140px;
      color: $placeholder-gray;
    }
  }

  .placeholder-subtext-wrapper {
    .placeholder-subtext {
      color: $placeholder-gray;
      text-align: center;
      font-size: 13px;
      margin-bottom: 0px;
    }
  }
}
