@import "../../assets/styles/globals";

.family-link {
  color: #f68a22;
  cursor: pointer;

  &:hover {
    color: #2b3843;
  }
}

.family-button {
  position: absolute;
  z-index: 9999;
}

.family-header {
  height: 200px;
}

.families-title {
  color: $primary-color;
  padding: 20px 15px;
  font-weight: 550;
}

.families-search-bar {
  margin: -10px auto 15px;
  background-color: #fff;
  border-radius: 8px;
}