@import "../../../assets/styles/globals";

#PlaceholderPreviewCard {
  background: #f4f4f5;
  color: $primary-orange;
  // text-align: center;
  height: 65px;
  border-radius: 8px;
  margin: 9px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    text-align: center;
  }
  .placeholder-card-action {
    margin-top: 5px;
    color: #34ace0;
    align-self: center;
    &:hover {
      color: #0e7cac;
      cursor: pointer;
    }
  }
}
