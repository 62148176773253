@import "../../globals";

.comment {
  &:nth-of-type(1) {
    margin-top: -4px;
  }
  border-radius: 5px;
  background-color: transparent;
  margin-top: 5px;
  .commentUserPicture {
    padding-right: 0px;
  }
  .commentUserPicture img {
    display: block;
    width: 100%;
    max-width: 55px;
    margin: 0 auto;
    padding-top: 0px;
    border-radius: 50%;
  }

  .commentUserPictureWeb img {
    max-width: 75px;
  }
  .commentContent {
    background-color: lighten($light-gray, 10%);
    border-radius: 8px;

    .commentUserName {
      padding-top: 5px;
      font-size: 16px;
      color: $secondary-color;
      margin-bottom: 0px;
    }
    .commentBody {
      font-size: 14px;
      color: $dark-font-color;
      text-align: left;
      margin-bottom: 0px;
    }
  }
}

#comment-web-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0px !important;
}

.comment-time {
  font-size: 10px;
  color: lightgrey;
  position: absolute;
  left: 15px;
  bottom: -15px;
}
