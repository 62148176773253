#SmallMilestoneIcon {
  .hexagon {
    position: relative;
    border-radius: 3px;
    width: 54.4px;
    height: 31.75px;
    top: -23px;
    p {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: -3px;
      z-index: 3;
      font-size: 24px;
    }
  }

  .hexagon-before,
  .hexagon-after {
    position: relative;
    border-radius: 4px;
    z-index: 1;
    width: 38.89px;
    height: 38.89px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    left: 8px;
  }

  .hexagon-before {
    top: -3px;
  }

  .hexagon-after {
    top: -43px;
  }
}

#StickySmallMilestoneIcon {
  .hexagon {
    position: relative;
    width: 55.75px;
    height: 31.75px;
    top: -47px;
    p {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 3;
      font-size: 20px;
    }
  }

  .hexagon-before,
  .hexagon-after {
    position: relative;
    z-index: 1;
    width: 38.89px;
    height: 38.89px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    left: 8.5px;
  }

  .hexagon-before {
    top: -28px;
  }

  .hexagon-after {
    top: -66px;
  }
}

#AvatarSmallMilestoneIcon {
  .hexagon {
    position: relative;
    width: 20.9px;
    height: 12px;
    top: -15.25px;
    left: -23.6px;
    p {
      position: absolute;
      left: 0;
      right: 0;
      top: -3.5px;
      margin-left: auto;
      margin-right: auto;
      z-index: 3;
      font-size: 12px;
    }
  }

  .hexagon-before,
  .hexagon-after {
    position: relative;
    z-index: 1;
    width: 14.65px;
    height: 14.65px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    left: -20.5px;
  }

  .hexagon-before {
    top: -8px;
  }

  .hexagon-after {
    top: -22.5px;
  }
}
