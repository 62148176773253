@import "../../assets/styles/globals";

.onboarding-row {
  border-bottom: 1px solid $light-font-color;
  padding-bottom: 25px;
}

.onboarding-icon {
  color: $light-font-color;
  margin-bottom: 25px;
}

.complete {
  color: $secondary-color !important;
}

.onboarding-title {
  text-transform: uppercase;
  color: $primary-color;
  padding-top: 40px;
  font-weight: 550;
}

.onboarding-subtitle {
  color: $light-font-color;
  text-transform: uppercase;
  padding-bottom: 25px;
}

.activity-text {
  font-size: 25px;
}

.app-icon {
  width: 180px;
  padding-right: 10px;
}

.modal-gif {
  width: 500px;
}

.modal-gif img {
  display: block;
  width: 100%;
  height: auto;
}

.modal-gif-mobile img {
  display: block;
  width: 30%;
  height: auto;
  margin: 0 auto;
}

.collapse-button {
  width: 60%;
  margin: 0 auto;
}

.activity-gif-mobile img {
  display: block;
  max-width: 300px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.onboarding-button-complete {
  transition: none !important;

  &:hover {
    transform: none !important;
    cursor: default !important;
  }
}
