@import "../../../../assets/styles/globals";

#RegistrationCompletedMaterials {
  @media (min-width: 1200px) {
    padding-top: 8%;
  }

  .max-width-xl-85 {
    @media (min-width: 1200px) {
      max-width: 85%;
    }
  }

  .final-step-header {
    width: 300px;
    font-size: 1.5em;
    line-height: 1.6em;
    margin-bottom: 0;
    color: $primary-orange;
    font-weight: bold;

    @media (min-width: 768px) {
      width: inherit;
      font-size: 2em;
    }
  }

  .get-material-paragraph {
    max-width: 300px;
    font-weight: 700;

    @media (min-width: 768px) {
      max-width: inherit;
    }
  }

  .material-description {
    max-width: 300px;

    @media (min-width: 768px) {
      max-width: inherit;
    }
  }

  .material-photo {
    width: 230px;
    border-radius: 8px;

    @media (min-width: 768px) {
      width: 250px;
    }
  }
}
