@import "../../assets/styles/globals";

#LeadsPage {
  margin-bottom: 35px;
  .question-button {
    .fa-question {
      font-size: 1.5rem;
    }
  }
  .card {
    padding: 15px;
    margin-top: 10px;
    .card-title-container {
      display: flex;
      justify-content: space-between;
    }
    .card-title {
      font-weight: 550;
      margin: 0;
    }
    .card-subtitle {
      margin: 0;
      font-size: 12px;
      color: #777;
    }
    .fake-input {
      color: transparent !important;
      text-shadow: 0 0 0 #2b3843;
      border: none !important;
      border-bottom: 2px solid #2b3843 !important;
      padding-left: 5px;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 450;
      // &:hover {
      //   cursor: default;
      // }
    }
    #StateSwitcher {
      margin-bottom: 0;
    }
    .copy-to-clipboard {
      margin-right: 10px;
      background: #60707d;
      border: none;
      border-radius: 8px;
      box-shadow: 0 4px 2px -2px #777;
      &:hover {
        position: relative;
        bottom: -2px;
        box-shadow: 0 2px 2px -2px #222;
      }
    }
    .go-to-landing-page-button {
      background: $primary-orange;
      border: none;
      border-radius: 500px;
      box-shadow: 0 4px 2px -2px #777;
      &:hover {
        position: relative;
        bottom: -2px;
        box-shadow: 0 2px 2px -2px #222;
      }
    }
  }
  .first-card {
    margin-top: 0;
  }
  .chart-area {
    display: flex;
    justify-content: space-between;
    .chart-card {
      width: 32%;
    }
    .admin-chart-card {
      width: 24%;
      .card-desc {
        font-size: 13px;
        color: #999;
      }
      .stat-title {
        font-size: 16px;
      }
      .stat1 {
        border-radius: 500px;
        padding: 5px 30px;
        font-weight: 550;
      }
      .view-stat {
        background: #cceaf7;
        color: #34ace0;
        border: 2px solid #34ace0;
      }
      .comm-lead-stat {
        background: #d9ddf7;
        color: #6677de;
        border: 2px solid #6677de;
      }
      .admin-lead-stat {
        background: #ffccd3;
        color: #ff5c72;
        border: 2px solid #ff5c72;
      }
      .total-student-stat {
        background: #ccf5ec;
        color: #33d9b2;
        border: 2px solid #33d9b2;
      }
    }
  }
  .grey-out {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(43, 56, 67, 0.7);
    z-index: 5;
  }
  table {
    width: 100%;
    border-collapse: collapes;
    margin-top: 20px;
    .data-rows {
      &:hover {
        cursor: pointer;
        background: #f4f4f5;
      }
    }
    th {
      border-bottom: 2px solid #afb7bd;
      vertical-align: bottom;
    }
    td {
      padding: 10px 0;
      border-bottom: 2px solid #d7dbde;
      font-size: 0.9rem;
      .status-pill {
        border-radius: 500px;
        color: #fff;
        margin: 0 10px;
        text-align: center;
      }

      .fa-ellipsis-v {
        padding: 0 5px;
        font-size: 24px;
        margin: 0 5px 0 15px;
      }
      .fa-times {
        font-size: 24px;
        &:hover {
          color: red;
          cursor: pointer;
        }
      }
      .fa-file-o {
        font-size: 24px;
        padding: 0 10px;
      }
    }

    .td-delete {
      text-align: center;
    }
  }

  .note-cell {
    max-width: 550px;
    word-wrap: break-word;
    @media screen and (max-width: 1550px) {
      max-width: 350px;
    }
  }

  .fa-info-circle {
    font-size: 120px;
    color: #d7dbde;
  }
  .no-lead-placeholder-text {
    color: #d7dbde;
  }
}
