@import "../../assets/styles/globals";

#FieldError {
  padding-top: 5px;
  color: $input-error-color;

  i {
    padding-top: 3px;
    font-size: 16px;
  }

  p {
    margin: 0;
    font-size: 14px;
    padding-left: 3px;
  }

  .general-error {
    text-align: center;
    padding: 10px;
    margin-top: 4px;
    margin-bottom: 18px;
    background-color: #FFBFBF;
    border: 2px solid $dark-red;
    border-radius: 8px;

    p {
      color: $dark-red;
      font-size: 16px;
    }

    i {
      color: $dark-red;
      font-size: 18px;
    }
  }

  .larger-margins {
    margin-top: 20px;
    margin-bottom: 36px;
  }
}
