@import "../../../../assets/styles/globals";

#Schedule {
  width: 100%;
  margin: auto;
  padding: 24px;

  @media (min-width: 768px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: $dark-navy;
    margin-bottom: 8px;
  }

  .sessions {
    border-bottom: 1px solid $placeholder-gray;
  }

  .add-session-button {
    background-color: $primary-orange;
    // position: relative;
    box-shadow: none !important;
    border: none;

    &:focus, &:active {
      background-color: $primary-orange !important;
      outline: none;
      border: none;
    }

    &:disabled:hover {
      background-color: $primary-orange !important;
    }
  }


  .delete-all-sessions-button {
    background-color: #D63939;
    position: relative;
    box-shadow: none !important;
    border: none;

    &:focus, &:active {
      background-color: #D63939 !important;
      outline: none;
      border: none;
    }

    &:hover {
      background-color: $light-red !important;
    }
  }

  .session-builder-card {
    &:hover {
      background-color: $off-white;
    }

    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    border: 1px solid $placeholder-gray;
    border-radius: 24px;

    #roundedPlusIcon {
      margin-bottom: 16px;
      width: 80px;
      height: 80px;

      path {
        fill: $primary-orange;
      }
    }

    #resetIcon {
      margin-bottom: 16px;
      height: 80px;
    }

    #packageIcon {
      margin-bottom: 16px;
      width: 80px;
      height: 80px;

      path {
        fill: $primary-orange;
      }
    }

    .session-card-title {
      text-align: center;
      color: $dark-navy;
      font-size: 24px;
      font-weight: 700;
      padding-bottom: 16px;
    }

    .session-card-description {
      text-align: center;
      color: $light-navy;
      font-size: 18px;
      font-weight: 400;
    }
  }
}