@import "../../../assets/styles/globals";

#ResourceSectionCard {
  .card {
    overflow: hidden;

    .resource-section-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90px;
      overflow: hidden;
      background: $light-gray;

      img {
        width: 100%;
      }

      .image-gradient {
        height: 90px;
      }
    }

    .resource-section-info-wrapper {
      padding: 15px;

      h4 {
        font-size: 20px;
        color: $primary-color;
        font-weight: 550;
      }

      p {
        margin: 0px;
        color: $light-font-color;
        font-size: 14px;
        display: block;
        display: -webkit-box;
        max-width: 400px;
        height: 75px;
        margin: 0 auto;
        line-height: 1.4;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
