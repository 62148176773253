@import "../../../assets/styles/globals.scss";

.input-container-wrapper {
  width: 100%;

  .input-container {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    border: 1px solid $placeholder-gray;
    border-radius: $card-corner-radius;
    padding: 5px 10px;

    &.textarea {
      height: 72px;
      align-items: flex-start;
    }

    .symbol {
      font-size: 1rem;
      cursor: pointer;
      padding-right: 10px;
      margin-bottom: 0;
    }

    .input {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      display: flex;
      border: none !important;
      border-radius: 0;
      padding: 0;
      font-weight: normal;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (min-width: 768px) {
        font-size: 0.9rem;
      }

      &.textarea {
        resize: none;
        overflow-y: auto;
        color: $dark-navy !important;
      }

      &:disabled {
        color: $light-gray !important;
        cursor: not-allowed;
      }

      &:focus {
        border-bottom: none;
      }

      &::placeholder {
        color: $placeholder-gray !important;
      }

      &:autofill, &:autofill:focus {
        box-shadow: 0 0 0 100px white inset;
      }

      &:-webkit-autofill, &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 100px white inset;
      }

      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    .color-wrapper-label {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;

      .color-input {
        border: none !important;
        padding: 0;
        margin-right: 10px;
        flex-basis: 40px;
        flex-shrink: 0;
      }

      .color-name {
        font-weight: normal;
        flex-grow: 1;

        &.placeholder {
          color: $placeholder-gray;
        }
      }
    }
  }

  .error-input {
    border-color: $input-error-color;
  }
}
