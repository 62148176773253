@import "../../../assets/styles/globals";

.photo-wall-display-area {
  background-color: $light-navy;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  // height: 100vh;
  // width: 100vw;
  height: 100%;
  width: 100%;
  grid-area: main-zone;
}

//////////////////////////////////////////////////
////////// Placeholder screen (no photos) //////////
//////////////////////////////////////////////////
.photo-wall-no-photo-placeholder {
  // padding: $primary-padding;
  padding: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
  text-align: center;
  color: white;
}

.photo-wall-no-photo-placeholder > h1 {
  // font-size: 2.5vw; // 2.5% of 1920px width
  font-size: calc($heading-0-size / 1px / $ideal-academy-display-width * 100vw);
  font-weight: $bold-font-weight;
  // margin-bottom: $secondary-padding;
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);

  // to help center display-area (icons have bottom padding of 36px)
  // padding-top: $primary-padding;
  padding-top: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
}

.photo-wall-no-photo-placeholder > h2 {
  // font-size: 1.875vw; // 1.875% of 1920px width
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
}

.photo-wall-placeholder-icon {
  // margin: $primary-padding;
  margin: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
  width: 15vw;
  height: auto;
}

//////////////////////////////////////////////////
////////// Photos within photo wall //////////
//////////////////////////////////////////////////
.photo-wall-tag-icon {
  width: 10%;
  height: auto;
  margin: 1vmin 0 0 1vmin;
}

.photo-wall-photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $card-corner-radius;
}
