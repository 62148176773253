@import "../../assets/styles/globals";

#Pagination {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0px;

  .paginated-arrow-step {
    margin: 0px 10px;

    button {
      background-color: transparent;
      border: 1px solid $light-font-color;
      border-radius: 50%;
      color: $light-font-color;
      height: 30px;
      width: 30px;
    }
  }

  .paginated-step {
    button {
      background-color: transparent;
      border: none;
      color: $light-font-color;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .active-step {
    button {
      background-color: $primary-color;
      color: $white-font-color;
      border-radius: 50%;
    }
  }
}
