@import "../../../../assets/styles/globals";

.header-image-modal-content {
  height: min-content !important;
  max-width: 700px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $dark-navy;
  margin-bottom: 4px;
}

.sub-title {
  color: $dark-navy !important;
  font-size: 16px;
  font-weight: 700;
}

.uploaded-header-image-container {
  cursor: pointer;
  position: relative;

  .uploaded-header-image-preview {
    border-radius: 10px;
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
}

.selected-image {
  outline: 3px solid $secondary-color !important;
}

.selected-image-icon {
  position: absolute;
  right: 6px;
  bottom: 6px;
}

.library {
  margin: 0 -8px !important;

  .library-image-container {
    padding: 8px;

    .library-image {
      cursor: pointer;
      max-width: 100%;
      outline: 1px solid $light-navy;
      border-radius: 10px;
    }
  }
}

.error-input {
  border-color: $input-error-color !important;
}