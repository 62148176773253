@import "../../../assets/styles/globals";

#StripeConnection {
  width: 100%;
  background-color: $off-white;
  padding: 23px 21px;

  p {
    margin-bottom: 0;
  }

  .stripe-icons {
    flex-shrink: 0;
    margin-right: 16px;
  }

  .lato-question-icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .primary-stripe-text {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .secondary-stripe-text {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .stripe-connect-button {
    background-color: $primary-orange;
    border-radius: 25px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 260px;
    padding: 8px 0;
    color: $off-white;
    font-size: 16px;
  }

  .stripe-disconnect-button {
    background-color: #36434e;
    margin-left: 21px;
    border-radius: 25px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 260px;
    padding: 8px 0;
    color: $off-white;
    font-size: 16px;
  }
}

@media (max-width: 900px) {
  #StripeConnection {
    flex-wrap: wrap;

    .stripe-buttons {
      margin-left: 0 !important;

      .stripe-disconnect-button {
        margin-left: 21px !important;
      }
    }

    .stripe-connect-button {
      margin-top: 21px;
      margin-left: 0 !important;
    }

    .stripe-disconnect-button {
      margin-top: 21px;
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 586px) {
  #StripeConnection {
    flex-wrap: wrap;

    .stripe-buttons {
      margin-left: 0 !important;
      flex-wrap: wrap;

      .stripe-disconnect-button {
        margin-left: 0 !important;
      }
    }

    .stripe-connect-button {
      flex-basis: 100%;
      margin-top: 21px;
      margin-left: 0 !important;
    }

    .stripe-disconnect-button {
      flex-basis: 100%;
      margin-top: 21px;
      margin-left: 0 !important;
    }
  }
}
