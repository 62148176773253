@import "../../../../assets/styles/globals";

.exit-program-builder-modal-content {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.title {
  color: $primary-orange;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
  color: $primary-orange;
  font-size: 28px;
  font-weight: 700;
}

.message {
  text-align: center;
  word-break: break-word;
  margin-bottom: 6px;
}
