@import '../../assets/styles/globals.scss';

.mapSidebar {
  height: 100%;

  @media screen and (min-width: 830px) {
    height: calc(100vh - 60px);
  }

  .topSearchArea {
    width: 100%;
    padding: 24px;
    background: #fff;
    box-shadow: 2px 2px 2px -2px #777;

    .searchTitle {
      font-weight: 550;
      font-size: 20px;
      margin-bottom: 10px;
    }

    i {
      align-self: center;
      color: #2b3843;
    }

    .searchContainer {
      .searchIcon {
        width: 24px;
        flex-shrink: 0;

        > path {
          fill: $light-navy;
        }
      }

      .searchInput {
        font-size: 16px;
        width: 100%;
        border: none !important;
        padding: 0 !important;

        &::placeholder {
          color: $light-navy !important;
          font-weight: 400 !important;
          font-size: 16px !important;
        }
      }

      .searchDropdownContainer {
        position: absolute;
        background-color: white;
        z-index: 1;
        margin-top: 4px;
        overflow: hidden;
        border: solid 1px $placeholder-gray;
        border-radius: $card-corner-radius;
        box-shadow: 0 2px 5px $light-gray;

        .suggestionItem {
          padding: 4px 8px;

          &.activeSuggestionItem {
            cursor: pointer;
            background-color: $off-white;
          }
        }
      }
    }

    .filterCheckboxContainer {

      .checkboxWrapper {
        width: fit-content;

        .filterBadge {
          height: 45px;
          flex-basis: 42px;
          flex-shrink: 0;
          margin: 0 16px;
        }

        .filterInformation {
          .filterTitle {
            font-size: 16px;
            font-weight: 700 !important;
            margin-bottom: 0 !important;
          }

          .filterDescription {
            font-size: 12.5px;
          }
        }
      }
    }
  }

  .communityContentArea {
    height: calc(100% - 136px);
    overflow-y: auto;
    border-bottom: 1px solid #2b3843;

    padding: 0 5px;
    width: 100%;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
      margin-left: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1d2933;
      border-radius: 10px;
    }

    .searchSpinner {
      color: $placeholder-gray;
    }

    .invalidLocation {
      text-align: center;
      color: #ccc;

      i {
        font-size: 60px;
        margin-top: 40px;
      }

      p {
        font-size: 14px;
      }
    }

    @media screen and (min-width: 830px) {
      padding: 8px;
      border-bottom: none;
    }
  }
}
