@import "../../../../assets/styles/globals";

.single-session-builder-modal-content {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

#SingleSessionModal {
  .title {
    font-size: 24px;
    font-weight: 700;
    color: $dark-navy;
    margin-bottom: 16px;;
  }

  label {
    color: $dark-navy;
    font-size: 16px;
    font-weight: 700;
  }

  .startDateInput {
    width: 50%;
  }

  .endDateInput {
    width: 50%;
  }

  .error-input {
    border-color: $input-error-color;
  }
}
