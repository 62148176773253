@import "../../assets/styles/globals";

#ChallengesPage {
  .challenge-submit-button {
    border-radius: 500px;
    padding: 5px 30px;
    margin-top: 15px;
    box-shadow: 3px 3px 3px #ccc;
    &:hover {
      position: relative;
      bottom: -1px;
      right: -1px;
      box-shadow: 2px 2px 2px #ccc;
    }
  }
  .return-button-container {
    display: flex;
    justify-content: flex-end;
    .return-challenge-button {
      background: #1d2933;
      color: $primary-orange;
      border-radius: 500px;
    }
  }
}
