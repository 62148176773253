@import "../../../../assets/styles/globals";

#EducationSidebar {
  background-color: $background-navy;
  color: white;
  height: 100vh;
  overflow-y: scroll;
  padding-top: 25px;

  .education-sidebar-header {
    margin-bottom: 25px;

    h3 {
      width: 90%;
      margin-left: 25px;
      font-weight: 550;
      font-size: 25px;
      padding-bottom: 20px;
    }

    p {
      margin-left: 25px;
      font-weight: 550;
    }

    .progress {
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 15px;

      .progress-bar-text {
        margin: 0px;
        font-weight: 400;
      }
    }
  }
}