@import "../../../assets/styles/globals";

.sideCard {
  width: 100%;
  height: 100%;
  bottom: 0;
  position: absolute;
  background: linear-gradient(to top, rgba(29, 41, 51, 1) 25%, rgba(0, 0, 0, 0) 100%);

  @media (min-width: 768px) {
    width: 40%;
    height: 100%;
    top: 0;
    position: relative;
    background: none;
    text-align: center;
    display: initial;
    background-color: $dark-navy;
    box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
  }

  .summary {
    width: 100%;
    height: 100%;
    padding: 8px;
    overflow-y: auto;

    .actionPanel {
      width: 100%;
      height: 100%;
      max-width: 320px;
      color: white;
      text-align: center;
      justify-content: safe end;

      @media (min-width: 768px) {
        justify-content: safe center;
      }

      .titleText {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 32px;
        text-align: left;
        white-space: pre-wrap;
      }

      .subtitleText {
        font-size: 18px;
        font-weight: 700;
        text-align: left;
      }

      .actionLink {
        max-width: 100%;
        width: 320px !important;
        margin-bottom: 10vh;

        @media (min-width: 768px) {
          margin-bottom: 0vh;
        }
      }
    }
  }
}
