// Cross browser border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}
// USAGE
.button {
  @include border-radius(5px);
}


// Single side border-radius
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}
// USAGE
.submit-button {
  @include border-top-radius(10px);
  @include border-right-radius(8px);
  @include border-bottom-radius(10px);
  @include border-left-radius (6px);
}


// Cross browser support for transitions
@mixin transition($duration) {
    -webkit-transition: $duration;
    -moz-transition: $duration;
    -ms-transition: $duration;
    -o-transition: $duration;
    transition: $duration;
}
@mixin transition-property($property...) {
-moz-transition-property:    $property;
-o-transition-property:      $property;
-webkit-transition-property: $property;
transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
// Usage
.class {
  @include transition(.2s);
  @include transition-property (.2s);
  @include transition-duration (.2s);
  @include transition-timing-function (linear);
  @include transition-delay (.2s);
}


// Default Border
@mixin bordered($color, $width: 1px) {
  border: $width solid $color;
}
