@import "../../assets/styles/globals";

#CommunityDetailPage {
  margin: 0 -15px !important;
  background-color: #fff;

  .switcher-wrapper {
    border-radius: 100px;
    background-color: #fff;
    margin-top: 20px;

    button {
      padding: 0 15px;
    }
  }

  .community-content {
    padding: 20px 35px;
  }
}
