@import "../../../assets/styles/globals";

.categoryName {
  font-weight: 800;
  font-size: 1.3rem;
  color: $dark-navy;

  .categoryNameButton {
    padding: 0;
    background: transparent;
    border: none;
    color: inherit;
    font-weight: inherit;
  }

  .icon {
    margin-left: 5px;
  }

  .iconCollapsed {
    transform: rotate(180deg);
  }
}

.headerImage {
  height: fit-content;
  width: 191px;
  border-radius: 8px;
}

.description {
  font-weight: 700;
  font-size: 0.9rem;
}
