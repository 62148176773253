#StudentPickerRow {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #5f6f7c;
  overflow: hidden;
  p {
    margin: 0;
  }
  i {
    color: green;
    margin-right: 18px;
  }
  .fa-times-circle {
    color: red;
    display: none;
  }
  .avatar {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 45px;
    flex: 0 0 45px;
    border-radius: 50%;
    margin-right: 18px;
  }
  .name-area {
    flex: 1 1;
    overflow: hidden;
    .name {
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .login {
      font-size: 14px;
    }
  }
  .cover {
    padding: 4px 8px;
  }
  .hover-red {
    position: relative;
    .remove-inciator {
      align-self: stretch;
      margin: -4px -8px -4px 0;
      color: #fff;
      font-weight: bold;
      padding: 8px;
      background: red;
      position: relative;
      bottom: -53px;
      transition: bottom 0.6s;
    }
    &:hover {
      background: #ffc7ce;
      cursor: pointer;
      .remove-inciator {
        bottom: 0;
      }
      .hide-hover {
        display: none;
      }
      .show-hover {
        display: inline-block;
      }
    }
  }
}
