@import "../../../assets/styles/globals";

.container {
  margin-bottom: 12px !important;
}

.control {
  border: none !important;
  background-color: $primary-navy !important;
  border-radius: 10px !important;

  &:focus-within {
    box-shadow: none !important;
  }
}

.singleValue {
  font-size: 1rem;
  color: white !important;

  @media (min-width: 768px) {
    font-size: 0.9rem;
  }
}
