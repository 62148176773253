@import "../../assets/styles/globals";

#NotificationsContainer {
  height: 250px;
  width: 220px;
  overflow-y: scroll;

  .notifications-loader {
    text-align: center;
    margin-top: 15px;
  }

  .notifications-wrapper {
    padding: 15px;

    .notifications-button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding-bottom: 5px;
      border-bottom: 1px solid $light-font-color;

      .notifications-refresh-btn {
        margin-left: auto;
        font-size: 14px;
      }

      .notifications-clear-btn {
        color: $error-font-color;
        font-size: 12px;
        border-radius: 500vh !important;

        &:hover {
          color: darken($error-font-color, 15%);
        }
      }
    }
  }
}
.notifications-empty-text {
  padding-top: 15px;
  margin: 0px;
  color: $light-font-color;
  text-align: center;
  white-space: wrap;
}
