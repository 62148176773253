@import "../../../assets/styles/globals";

.golfersInfo {

  .golfersCount {
    color: $dark-gray;
    font-weight: 700;
    font-size: 14px;
    width: 10rem;

    .count {
      font-size: 18px;
    }
  }

  .progressBackground {
    border-radius: 1.25rem;
    width: 10rem;
    height: 0.75rem;
    background-color: $primary-navy;
  }
}

.progressBar {
  background-color: $dark-teal;
  border-radius: 1.25rem;
}
