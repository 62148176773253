@import "../../assets/styles/globals";
$cardColor: #ff6633;
$activeCardColor: #2b3843;

.modal-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.log-action-goal-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .log-action-goal-card {
    margin: 10px;

    .log-action-goal-icon {
      color: white;
      font-size: 80px;
      margin: 0 auto;
      padding: 15px;
    }
  }
}

.log-action-card {
  height: 70%;
  margin-top: 25%;
  background-color: $activeCardColor !important;
  border-color: $activeCardColor !important;

  &:hover {
    background-color: lighten($activeCardColor, 15%) !important;
    border-color: lighten($activeCardColor, 15%) !important;
  }

  .log-action-image {
    width: 36%;
    margin: 0 auto;
  }

  .log-action-title {
    margin: 0 auto;
    color: white;
    font-size: 28px;
    text-transform: uppercase;
  }

  .log-action-subtitle {
    margin: 0 auto;
    color: white;
    margin-bottom: 25px;
    font-size: 16px;
  }
}

.log-action-card-active {
  background-color: $cardColor !important;
  border-color: $cardColor !important;

  &:hover {
    background-color: $cardColor !important;
    border-color: $cardColor !important;
  }
}

.goal-loading {
  margin-top: -125px !important;
}

.train-title {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  .train-duration-pill {
    color: white;
    background-color: $cardColor;
    border-radius: 15px;
    padding: 2px 15px;
  }
}

.refresh-sliders {
  color: $cardColor;
  margin-top: 25px;
  width: 135px;

  &:hover {
    cursor: pointer;
  }
}

.refresh-icon {
  color: $cardColor;
}

.goal-objective-border {
  height: 1px;
  background-color: lightgrey;
}

.goal-objective-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 55px;

  .goal-objective-add-icon {
    font-size: 25px;
    color: $cardColor;
    margin-top: 15px;
  }

  .goal-objective-minus-icon {
    font-size: 25px;
    color: $activeCardColor;
    margin-top: 15px;
    margin-right: 15px;
  }
}

.upload-aside-text {
  font-size: 14px !important;
  color: lightgrey;
  margin-left: 20px;
}

.uploading-images-container {
  margin-top: 25px;

  .uploading-images-icon {
    color: $activeCardColor;
  }

  .uploading-images-text {
    color: $activeCardColor;
  }
}

.op36-curriculum-image-card-container {
  margin: 0 auto;

  .op36-curriculum-image-card {
    width: 70px;
    height: 70px;
    margin: 20px;
  }
}

.info-card {
  border: 2px solid $cardColor;
  border-radius: 8px;
  padding: 18px;

  .info-text {
    display: flex;
    color: $cardColor;
  }
}

.logged-drill-sessions {
  background-color: $placeholder-gray;
  margin-top: 20px;
  border-radius: 8px;
  padding: 18px;
}

/* xs < 768 */
@media screen and (max-width: 767px) {
  .modal-title {
    font-size: 18px !important;
  }

  .log-actions-button {
    font-size: 13px !important;
  }
}

/* sm */
@media screen and (max-width: 768px) {
  .modal-title {
    font-size: 18px !important;
  }

  .log-actions-button {
    font-size: 13px !important;
  }
}
