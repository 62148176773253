@import "../../../assets/styles/globals";

#ChallengeCard {
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 2px 2px 2px #777;
  flex-basis: 300px;
  flex-shrink: 0;
  flex-grow: 0;
  &:hover {
    box-shadow: 2px 2px $primary-orange;
    cursor: pointer;
  }
  .challenge-image-container {
    height: 250px;
    width: 100%;
    background-size: cover;
    background-position: center;
    .challenge-delete-container {
      display: flex;
      justify-content: flex-end;
      opacity: 0.9;
      animation: fadein 1s;
      .fa-trash-o {
        margin-top: 5px;
        margin-right: 5px;
        // padding: 5px;
        height: 30px;
        flex-basis: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 50%;
        background: #fff;
        color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        transition: font-size 0.3s;
        opacity: 0.9;
        &:hover {
          background: #f4f4f5;
          font-size: 1.3rem;
        }
      }
    }
  }
  .challenge-card-footer {
    display: flex;
    align-items: center;
    .challenge-card-icon {
      margin: 5px;
      height: 45px;
      flex-basis: 45px;
      flex-grow: 0;
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid #33d9b2;
      background: #ccc;
      background-position: center;
      background-size: cover;
    }
    .challenge-name-container {
      p {
        margin: 0;
      }
      .challenge-dates {
        color: #999;
        font-size: 0.8rem;
      }
    }
    .challenge-points {
      margin-left: auto;
      margin-right: 5px;
      align-self: flex-start;
      color: #6677de;
      font-weight: 550;
      font-size: 0.9rem;
    }
    .challenge-points-container {
      margin-left: auto;
      margin-right: 5px;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      p {
        margin: 0;
        color: #6677de;
        font-weight: 550;
        font-size: 0.9rem;
      }
      i {
        margin-bottom: 5px;
        color: $primary-orange;
      }
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.9;
    }
  }
}
