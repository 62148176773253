@import "../../../../assets/styles/globals";

#LessonPlanSelect {
  .lesson-plan-option {
    cursor: pointer;

    &:hover {
      background-color: darken(white, 10%) !important;
    }
  }

  .lesson-plan-value {
    cursor: pointer;

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .name {
    color: $dark-navy;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
  }

  .sessions, .dash {
    color: $dark-navy;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 700;
    white-space: nowrap;
  }

  .notes {
    color: $light-navy;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 400;
  }

  .selected-lesson-plan {
    height: 45px;

    p {
      margin-bottom: 0;
    }
  }
}