@import "../../assets/styles/globals";

.categoryName {
  font-weight: 800;
  font-size: 1.3rem;
  color: $dark-navy;

  .categoryNameButton {
    padding: 0;
    background: transparent;
    border: none;
    color: inherit;
    font-weight: inherit;

    &:hover {
      text-decoration: underline;
    }

    .angle {
      font-size: inherit;
      font-weight: inherit;
    }
  }
}

.headerImage {
  height: fit-content;
  width: 191px;
  border-radius: 8px;
}

.description {
  font-weight: 700;
  font-size: 0.9rem;
}
