@import "../../assets/styles/globals";

#AdminEducationSection {
  border: 1px solid $light-gray !important;
  border-radius: 8px;
  margin-bottom: 15px;
  padding-bottom: 30px;

  .delete-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none !important;
    background-color: $light-red;
    z-index: 9999;

    i {
      font-size: 12px;
    }
  }

  .form-group {
    margin: 10px;

    label {
      font-size: 12px;
    }
  }

  .section-lession-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 20px;

    p {
      color: $primary-color;
      font-size: 12px;
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }

  .add-lesson-btn-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

    .add-lesson-btn {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      background-color: transparent;
      color: $secondary-color;
      border-radius: 500vh;

      i {
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }

  .education-lesson-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding-right: 10px;

    h1 {
      text-align: center;
      color: $secondary-color;
      font-weight: 550;
    }
  }
}
