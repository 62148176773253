@import "../../../../assets/styles/globals";

.modal {
  height: fit-content;
}

.completedIcon {
  circle {
    fill: $primary-orange;
  }

  width: 82px;
  height: 82px;
}

.title {
  color: $primary-orange;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.subTitle {
  text-align: center;
}

.coachMessageInputContainer {
  height: 150px;
}
