@import "../../../assets/styles/globals";
#DrillLegalModal {
  .reader-box {
    padding: 9px 16px;
    max-height: 350px;
    overflow-y: scroll;
    background: #f4f4f5;
    border: 1px solid black;
    border-radius: 5px;
    .bold {
      font-weight: bold;
    }
  }
  .checkbox-container {
    display: flex;
    margin-top: 10px;
    .checkbox {
      height: 20px;
      // width: 20px;
      background-color: white;
      flex: 20px 0 0;
      border: 2px solid $primary-orange;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      .fa-check {
        color: #fff;
      }
    }
    .filled {
      background-color: $primary-orange;
    }
  }
}
