@import "../../../assets/styles/globals";

.division-records-display-area {
  // padding: $primary-padding;
  // padding-bottom: $secondary-padding;
  padding: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
  padding-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
  background-color: $off-white;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  align-self: stretch;
  grid-area: main-zone;
}

.division-records-title {
  // font-size: $heading-1-size;
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  font-weight: $bold-font-weight;
  // margin-bottom: $secondary-padding;
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
  color: $dark-navy;
  line-height: 1;
}

.division-records-subtitle {
  // font-size: $heading-2-size;
  font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
  // margin-bottom: $primary-padding;
  margin-bottom: calc($primary-padding / 1px / $ideal-academy-display-height * 100vh);
  color: $dark-navy;
  line-height: 1;
}

#division-records-card-5 {
  margin-bottom: 0;
}
