@import "../../assets/styles/globals";

#DrillsDetail {
  display: flex;
  flex-wrap: wrap;
  .modal-content-2button {
    // this is from the TwoButtonModal - it just moves it down a little
    top: 135px !important;
  }
  .favorite-action-button {
    margin-left: 18px;
    padding: 0 9px;
    display: flex;
    border: 2px solid #1d2933;
    border-radius: 5px;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .fav-icon {
      font-size: 18px;
      color: #1d2933;
      margin-right: 6px;
    }
    p {
      margin: 0;
      color: #1d2933;
    }
  }
  .edit-action {
    // border-color: $primary-orange;
    border-color: #33d9b2;
    .fav-icon {
      // color: $primary-orange;
      color: #33d9b2;
    }
    p {
      // color: $primary-orange;
      color: #33d9b2;
    }
  }
  .delete-action {
    // border-color: #ec8391;
    border-color: red;
    .fav-icon {
      // color: #ec8391;
      color: red;
    }
    p {
      // color: #ec8391;
      color: red;
    }
  }
  .pinned {
    background-color: #1d2933;
    .fav-icon {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
  .page-column {
    flex: 0 0 50%;
    padding: 18px;
    min-width: 450px;
    color: #1d2933;
    p {
      margin: 0;
    }
    .featured-image-box {
      background: #f4f4f5;
      border-radius: 8px;
      text-align: center;
      margin-bottom: 10px;
      height: 250px;
      padding: 5px 0;
      overflow: hidden;
      .featured-image {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #f4f4f5;
        border-radius: 8px;
      }
      .orange-link {
        color: $primary-orange;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      i {
        // margin-top: 10px;
        font-size: 65px;
        // color: #fc6d81;
      }
    }
    .image-preview-box {
      // background: greenyellow;
      border-radius: 8px;
      text-align: center;
      height: 60px;
      display: flex;
      justify-content: center;
      .preview-image {
        flex: 0 0 12%;
        height: 55px;
        border-radius: 5px;
        background-size: cover;
        background-position: center;
        margin: 0 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        &:hover {
          cursor: pointer;
        }
      }
      .video-preview {
        border: 1px solid #1d2933;
        background-color: #f4f4f5;
      }
      .video-error {
        border: 1px solid #fc6d81;
        color: #fc6d81;
        background-color: #ffd8dd;
      }
      .nav-box {
        font-size: 50px;
        color: #5f6f7c;
        transition: font-size 0.3s;
        &:hover {
          font-size: 55px;
        }
      }
    }
    .drill-title {
      font-size: 36px;
      font-weight: bold;
      line-height: 1;
      margin: 9px 0;
      .type-indicator-pill {
        display: inline-block;
        background: #34ace0;
        line-height: 1.5;
        color: #fff;
        border-radius: 500px;
        padding: 0 6px;
        font-size: 14px;
        vertical-align: middle;
        // font-weight: normal;
      }
      .orange-text {
        color: $primary-orange;
        font-weight: normal;
        font-size: 15px;
        margin-top: 5px;
        float: right;
        padding: 1px 4px;
        border-radius: 500px;
        &:hover {
          cursor: pointer;
          background: #f4f4f5;
        }
      }
    }
    .author-pill {
      max-width: 100%;
      background: #1d2933;
      border-radius: 500px;
      padding: 9px;
      color: #fff;
      display: inline-flex;
      padding-right: 30px;
      flex-wrap: nowrap;
      margin-right: auto;
      .author-avatar {
        height: 24px;
        flex: 0 0 24px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        border: 2px solid #fff;
        margin-right: 6px;
      }
      .author-name {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .drill-description {
      margin-bottom: 5px;
    }
    .visibility-text {
      color: #5f6f7c;
    }
    .visibility-icon {
      height: 20px;
      flex: 0 0 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.6;
    }
    .icon-container {
      width: 70px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .skill-icon {
        width: 50px;
        flex: 0 0 50px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        // background-color: #5f6f7c;
        // filter: grayscale(75%);
      }
      p {
        font-size: 0.8rem;
        text-align: center;
      }
    }
    .skills-display,
    .tag-category-container,
    .tag-display-container {
      display: flex;
      flex-wrap: wrap;
      .shown-tag {
        background: #5f6f7c;
        color: #fff;
        border-radius: 500px;
        font-size: 14px;
        margin: 4px 9px;
        text-align: center;
        padding: 0 10px 1px 10px;
      }
    }
    .subsection-label {
      flex: 0 0 100%;
      font-size: 18px;
      font-weight: bold;
      .orange-text {
        color: $primary-orange;
        font-weight: normal;
        font-size: 15px;
        float: right;
        padding: 1px 4px;
        border-radius: 500px;
        &:hover {
          cursor: pointer;
          background: #f4f4f5;
        }
      }
    }
    .custom-tag-container {
      padding-bottom: 18px;
    }
    .drop-bar {
      padding: 4px 9px;
      background: #5f6f7c;
      border-radius: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      margin-bottom: 9px;
      &:hover {
        cursor: pointer;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        background: $primary-orange;
        color: #fff;
        margin: 0;
        line-height: 1;
        font-size: 13px;
        padding: 4px 6px;
        border-radius: 500px;
      }
      .fa-chevron-up {
        transform: rotate(0deg);
        transition: transform 0.3s;
      }
      .flip {
        transform: rotate(180deg);
      }
    }
    .collapsable {
      padding-bottom: 9px;
      .list-row {
        display: flex;
        align-items: center;
        p {
          line-height: 1.2;
        }
        .check-icon {
          height: 12px;
          flex: 0 0 16px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 3px;
        }
        .step-counter {
          background: #5f6f7c;
          color: #fff;
          height: 30px;
          flex: 0 0 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 4px;
          p {
            line-height: 1;
          }
        }
      }
      .setup-row {
        margin-bottom: 6px;
      }
    }
    .drill-nav-btn {
      background: $primary-orange;
      border: none;
      border-radius: 500px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 10px;
      &:hover {
        color: #f4f4f5;
      }
    }
  }
}
