@import "../../assets/styles/globals";

.dark-navy-border {
  @include bordered($dark-navy, 2px);
}

.requestLocationModal {
  z-index: 1000009;
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  .modalContent {
    .title {
      text-align: center;
      font-weight: 700;
      margin: 0;
    }

    .successTitle {
      text-align: center;
      font-weight: 700;
      color: #12A482;
      margin: 0;
    }

    .subtitle {
      text-align: center;
      font-size: 14px;
    }

    .successSubtitle {
      text-align: center;
      line-height: 1.5;
    }

    label {
      font-size: 16px;
      font-weight: 700;
      margin-top: 16px;
    }

    .submitButton {
      width: 320px !important;
    }

    .checkCircleWhiteIcon {
      width: 60px !important;
      height: 60px !important;
    }
  }
}
