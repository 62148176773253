@import "../../../../assets/styles/globals";

.education-content-container {
  background-color: $white-font-color;
  height: 100vh;
  overflow-y: scroll;

  .education-content-view {
    height: 100vh;
    padding-top: 60px;

    .education-video-footer {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      button {
        margin: 10px;

        i {
          color: $white-font-color;
          margin-right: 5px;
        }
      }
    }

    .education-video-footer-no-bg {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-left: 20px;

        i {
          color: $white-font-color;
          margin-right: 5px;
        }
      }
    }

    .education-info-header {
      padding-top: 20px;
      padding-bottom: 15px;
      margin-bottom: 20px;
      border-bottom: 1px solid $light-gray;

      p {
        margin: 0px;
      }
    }

    .education-lesson-description {
      background-color: $white-font-color;
      text-align: center;
    }
  }

  .education-completed-view {
    height: 100%;
    padding-top: 100px;
  }
}

.education-video-header {
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: 9999;

  h5 {
    font-size: 20px;
    color: $white-font-color;
    padding-left: 15px;
  }


  .education-close-button-wrapper {
    button {
      color: black;
      padding-right: 25px;

      span {
        color: black;
      }
    }
  }
}

.education-video-header-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 25px 15px;

  h5 {
    font-size: 24px;
    font-weight: bold;
  }
}

.education-video-content-container {
  height: 480px;
  width: 100%;
  margin: 0 !important;
}

.education-info-button {
  position: absolute;
  top: 50%;
  right: 25px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}