@import "../../../assets/styles/globals";

#PackagesTable {
  margin: 0px 21px 36px 21px;

  th {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: $off-white;
    border-top: 1px solid $placeholder-gray;
    border-bottom: 1px solid $placeholder-gray;
    color: $dark-navy !important;
  }

  .packages-table-row {
    border-bottom: 1px solid $placeholder-gray;

    td{
      border-top: 1px solid $placeholder-gray;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      color: $light-navy;
    }

    .table-row-name {
      width: 35%;
      padding-left: 36px;
      color: $dark-navy;
      font-size: 18px;
      font-weight: 700;
      max-width: 200px;
    }

    .table-row-price {
      width: 5%;
      color: $secondary-color;
      font-size: 16px;
      font-weight: 700;
    }

    .table-row-description {
      width: 45%;
      max-width: 200px;
      font-size: 14px;
    }

    .table-row-actions {
      padding-right: 27px;


      .table-ellipsis {
        cursor: pointer;
        color: $light-navy;
      }
    }
  }

  .no-packages {
    background-color: $off-white;
    border-top: 2px solid $placeholder-gray;
    padding: 36px 0px 36px 0px;
    color: $light-navy;
    font-size: 18px;
  }
}
