@import "../../../assets/styles/globals";

.navigation-container {
  background-color: $off-white;
  border-bottom: 1px solid $border-darker-gray;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  .step {
    cursor: pointer;
    padding: 0px 8px;
    margin: 0px 8px;
    color: $dark-navy;

    .content {
      padding: 16px;
      white-space: nowrap;

      &:hover {
        .icon circle {
          fill: $primary-orange;
        }

        .error-icon circle {
          fill: $red;
        }
      }

      .name {
        font-size: 20px;
        font-weight: 800;
        margin-bottom: 0px;
      }

      .icon, .error-icon {
        @media screen and (min-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
    }
    .bar {
      display: relative !important;
      margin-top: 10px;
      bottom: 0;
      height: 6px;
      border-radius: 10px;
    }

    .active-bar {
      background-color: $primary-orange;
    }

    .error-bar {
      background-color: $red;
    }

    .active {
      circle {
        fill: $primary-orange !important;
      }
    }
  }
}
