@import "../../../../assets/styles/globals";

.enrollmentsTab {
  height: 100%;

  .placeholder {
    width: 250px;
    margin-top: 80px;

    .icon {
      height: 64px;
      width: 64px;
      fill: $light-navy;
    }

    .title {
      font-size: 1.2rem;
      font-weight: 800;
    }

    .text {
      text-align: center;
      color: $light-navy;
    }
  }

  .userCount {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .icon {
    height: 20px;
    width: 20px;

    path {
      fill: $light-navy;
    }
  }
}
