@import "../../../assets/styles/globals.scss";

.managePairings {
  height: calc(100vh - 70px - 60px);
  margin-top: 70px;
  margin-bottom: 60px;

  .eventStudents {
    background-color: #fff;
    height: 100%;
    min-width: 370px;

    .studentsList {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .messageIcon {
      cursor: pointer;
      fill: $light-gray;

      &:hover {
        fill: $slate-gray;
      }
    }

    .studentsPlaceholder {
      margin-top: 50%;
      padding: 18px;

      .clapIcon {
        stroke: $dark-navy;
        margin-bottom: 1rem;
      }

      .placeholderTitle {
        font-weight: 800;
        font-size: 20px;
      }

      .placeholderMessage {
        color: $light-navy;
        padding-inline: 25px;
      }
    }
  }

  .eventPairs {
    height: 100%;
    min-width: 470px;

    .pairsList {
      height: calc(100% - 64px);
      overflow-y: scroll;
      overflow-x: scroll;
    }

    .pairWrapper {
      background-color: #fff;
      border-radius: 8px;
      width: fit-content;
      min-width: 98%;
      max-width: 1150px;

      span {
        font-weight: 700;
      }

      input {
        border-radius: 8px !important;
        font-weight: normal;
        font-size: 16px;
        height: 35px !important;
      }

      .pairTime {
        width: 90px;

        .pairTimeContainer {
          height: 35px;
        }
      }

      .timeIcon {
        fill: $primary-navy;
      }

      .golferWrapper {
        background-color: $off-white;
        border: 1px solid $light-gray-accent;
        border-radius: 8px;
        width: 260px;
        height: 82px;
        overflow-y: hidden;
      }

      .isOver {
        border-width: 2px;
        box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.6);
      }

      .removePairButton {
        width: 20px;
        height: 20px;
        padding: 5px;
        margin-right: 10px;
        cursor: pointer;
        fill: #fff;
        border-radius: 10px;
        background-color: $primary-orange;
        display: none;
      }

      &:hover {
        .removePairButton {
          display: block;
        }
      }

      .groupInfo {
        z-index: 4;

        .selectMenuList {
          max-height: 140px;
        }
      }
    }

    .headerMessage {
      font-weight: 700;
      margin: 20px 0;
      p {
        margin-bottom: 0;
      }
    }

    @media (max-width: 1570px) {
      .pairWrapper {
        max-width: 950px;

        .golferWrapper {
          width: 208px;
          height: 66px;
        }
      }
    }
  }

  .tabList {
    width: 100%;
    height: 40px;
    background-color: #fff !important;
    padding-bottom: 1px;
    border-bottom: 1px solid $placeholder-gray;

    .tab {
      background-color: #fff !important;
      margin-top: 0px;
      text-transform: none;
      font-size: 14px;
      font-weight: bold;
      color: black;
    }

    .activeTab {
      border-bottom: 4px solid $secondary-color !important;
      height: 40px;
      color: $secondary-color !important;
      background: transparent !important;
    }
  }

  .tabPanel {
    margin-top: 15px;
  }

  .activeTabPanel {
    height: calc(100% - 70px);
  }

  .warningIcon {
    fill: #fff;
    width: 100px;
    height: 100px;
  }
}

.headerButtonActions {
  padding-bottom: 10px;
  padding-top: 5px;

  p, a {
    color: $light-navy;
    cursor: pointer;
    font-size: 15px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: $secondary-color;
    }
  }
}
