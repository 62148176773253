@import "../../../../assets/styles/globals";

.copy-schedule-modal-content {
  margin: auto !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.copy-schedule-header {
  padding: 20px 0px;
  background-color: $dark-navy;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .header-back {
    cursor: pointer;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 0px;
}

.modal-X-icon {
  cursor: pointer;
  margin-right: 16px;
  flex-shrink: 0;

  @media (min-width: 768px) {
    margin-right: 48px;
  }

  rect {
    fill: $dark-navy;
  }

  path {
    fill: white;
  }
}

.copy-schedule-programs-container {
  width: 100%;
  margin: auto;
  padding: 24px;

  @media (min-width: 768px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }

  .programs-list {
    .program {
      cursor: pointer;

      &:hover {
        background-color: $off-white;
      }

      .program-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 6px;
        word-break: break-word;
      }

      .program-schedule {
        color: $light-navy;
        margin-bottom: 0px;
      }

      .program-sessions {
        color: $secondary-color;
        margin-bottom: 0px;
        font-weight: 700;
        margin-left: 16px;
        text-align: right;

        @media (min-width: 576px) {
          white-space: nowrap;
        }
      }
    }
  }

  .line {
    background: $placeholder-gray;
    width: 100%;
    height: 1px;
  }
}

.copy-schedule-selected-program-container {
  width: 100%;
  margin: auto;
  padding: 24px;
  margin-bottom: 100px;

  @media (min-width: 768px) {
    width: 80%;
    margin-bottom: 70px;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }

  .program-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .program-schedule {
    color: $light-navy;
    margin-bottom: 0px;
  }

  .selected-program-sessions {
    border-bottom: 1px solid $placeholder-gray;
    margin-top: 16px;
  }
}

.copy-schedule-footer {
  height: 100px;
  z-index: 0;
  background: white;
  border-top: 1px solid #AFB7BD;

  @media (min-width: 768px) {
    height: 70px;
  }

  .go-back-button {
    background-color: $primary-navy !important;
    width: 200px;

    @media (min-width: 576px) {
      width: 300px;
    }
  }

  .copy-schedule-button {
    width: 200px;

    @media (min-width: 576px) {
      width: 300px;
    }
  }
}