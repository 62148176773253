@import "../../../assets/styles/globals";

.panel {
  padding: 1rem 1.5rem 0;

  .title {
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  &.bordered {
    @include bordered($border-darker-gray);
  }

  .selector {
    min-height: 0;

    .searchBar {
      flex-shrink: 0;
      background-color: $off-white;
    }

    .hr {
      margin-left: 0;
      margin-right: -2.5rem;
      margin-bottom: 0;
      border-color: $light-gray;
    }
  }

  .listWrapper {
    min-height: 0;
    overflow: auto;
    margin-right: -2.5rem;
    padding-right: 2.5rem;
  }

  &.right {
    overflow: auto;
    background-color: $off-white;

    &.withoutSwitcher {
      margin-top: 40px;
    }

    .itemWrapper {
      border: 1px solid $light-gray;
      border-radius: 10px;
      background-color: #fff;
    }
  }
}

.activeTabPanel {
  height: calc(100% - 40px);
  display: block !important;
}

.tabList {
  margin: 0;
  padding: 0;
  height: 40px;
  display: flex;
  background-color: transparent;

  &::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }

  .tab {
    height: 100%;
    flex-grow: 1;
    text-wrap: nowrap;
    margin: 0;
    padding: 0 0.75rem;
    font-size: 0.9rem;
    font-weight: bold;
    list-style: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid transparent;

    &:hover {
      color: $primary-orange;
    }
  }

  .activeTab {
    border-bottom-color: $primary-orange;
    color: $secondary-color;
  }
}

.placeholder {
  margin: 100px 0;

  .title {
    font-weight: 800;
    font-size: 1.2rem;
  }

  .text {
    color: $light-navy;
    text-align: center;
  }
}
