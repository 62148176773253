@import '../../../../assets/styles/globals.scss';


.modalWrapper {
  max-height: 600px !important;
}

.modalContent {
  position: relative;
  padding: 8px 10px;
  gap: 10px;

  .stepsWrapper {
    position: absolute;
    height: 450px;
    overflow: auto;
    width: 100%;
  }

  .stepsContainer {
    gap: 18px;
  }

  .roundedCheckbox,
  .roundedCheckbox:checked,
  .roundedCheckbox:checked:hover {
    width: 22px !important;
    height: 22px !important;
    cursor: default;
  }

  .roundedCheckboxComplete:checked,
  .roundedCheckboxComplete:checked:hover {
    &[readonly] {
      background-color: $dark-teal !important;
    }
  }

  .checkBoxCheckMark {
    left: 5.5px;
    top: -3px;
  }

  .title {
    font-weight: 800;
    font-size: 16px;
  }

  .item {
    gap: 8px;
  }

  .step {
    gap: 10px;
  }

  .statusText {
    font-size: 14px;
    font-weight: 700;
  }

  .totalCount {
    font-size: 12px;
    font-weight: 700;
  }

  .completedCount {
    font-size: 16px;
  }

  .stepTitle {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }

  .itemTitle {
    margin: 0;
    font-size: 14px;
  }
}