@import "../../../../assets/styles/globals.scss";
@import "../../../../../node_modules/loaders.css/src/animations/ball-pulse-sync.scss";

// Try using CSS variables here
:root {
  --regFlowBody-max-width: 768px;
  --regFlowBody-padding: #{$primary-padding};

  --regFlowHeader-display: block;
  --regFlowHeader-padding: 0;
  --regFlowHeader-logo-height: 48px;

  --regFlowCard-box-shadow: #{$non-pressable-elem-drop-shadow};
  --regFlowCard-border-radius: #{$card-corner-radius};
  --regFlowCard-padding: #{$primary-padding} 136px;

  --regFlowModal-padding: #{$primary-padding};
}

.CoachRegistrationFlow {
  background-color: $primary-orange;
  color: white;

  // width: 100vw;
  width: 100%; // To avoid horizontal scroll
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: var(--regFlowBody-padding);

  .CoachRegistrationFlowLoader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 999;

    // Same as ReactModal__Overlay
    background-color: $dark-transparent-overlay;

    .loader-hidden {
      display: none;
    }
    .loader-active {
      display: block;
    }

    h5 {
      font-size: $heading-3-size;
      margin: $secondary-padding;
    }
  }

  .CoachRegistrationFlowHeader {
    text-align: center;
    max-width: var(--regFlowBody-max-width);
    display: var(--regFlowHeader-display);
    padding: var(--regFlowHeader-padding);

    svg { // logo
      height: var(--regFlowHeader-logo-height);
      width: auto;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: $bold-font-weight;
    }
  }

  .CoachRegistrationFlowCard {
    background-color: white;
    color: $dark-navy;
    border-radius: var(--regFlowCard-border-radius);
    box-shadow: var(--regFlowCard-box-shadow);

    display: flex;
    flex-flow: column;
    align-items: center;
    flex: auto;
    text-align: center;
    padding: var(--regFlowCard-padding);

    width: var(--regFlowBody-max-width);
  }
}

.CoachRegistrationFlowModal {
  background-color: white;
  padding: var(--regFlowModal-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $card-corner-radius;
  box-shadow: $modal-drop-shadow;
  width: 400px;

  img {
    width: 100%;
    height: auto;
    margin-bottom: $secondary-padding;
  }

  p {
    text-align: center;
  }
}

.CoachRegistrationFlow.CoachOnboarding {
  background-color: $light-navy;
  background-image: url('../../../../assets/images/registration-coach/free-coach-dashboard-1440x900.png');
  background-blend-mode: multiply;
  background-size: cover;
  background-attachment: fixed;

  padding: 0;
  transition: all .5s ease-in-out;

  .CoachRegistrationFlowHeader {
    display: none;
  }

  .CoachRegistrationFlowCard {
    transition: all .2s ease-in-out;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }

  .CoachRegistrationFlowCard.CoachAccountCreated {
    padding: 0px;
  }
}

@media (max-width: 768px) { // tablet
  :root {
    --regFlowBody-max-width: 100vw;
    --regFlowBody-padding: 0;

    --regFlowHeader-padding:
      #{$primary-padding} #{$primary-padding} 0px #{$primary-padding};

    --regFlowCard-box-shadow: 0;
    --regFlowCard-border-radius: 0;
    // --regFlowCard-padding: #{$primary-padding} 15vw;
    --regFlowCard-padding:
      #{$primary-padding} calc(18px + (136 - 18) * (100vw - 426px) / (768 - 426));
  }
}

@media (max-width: 425px) { // phone (large)
  :root {
    --regFlowHeader-display: none; // remove header
    --regFlowHeader-logo-height: 36px; // for AccountCreatedStep logo

    --regFlowCard-padding: #{$secondary-padding} 4vw;

    --regFlowModal-padding: #{$secondary-padding};
  }
}
