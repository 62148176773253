@import "../../assets/styles/globals.scss";

@media (min-height: 680px) {
  .modal-content-container {
    max-height: fit-content !important;
  }
}

#MaterialInfoModal {
  .material-modal-logo {
    height: 103px;
    width: 103px;
  }

  .material-modal-title {
    font-weight: 700;
    font-size: 24px;
    margin: 15px 0;
  }

  .material-modal-info {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    line-height: 17px;
  }
}
