@import "../../../../assets/styles/globals";

.preview-program-modal-content {
  margin: auto !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  z-index: -999;
}

.preview-header {
  padding: 0px 16px;
  height: 100px;
  background-color: $dark-navy;

  .header-title {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-orange;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
  }
}

.preview-footer {
  background: white;
  height: 100px;
  padding: 0px 16px;
  border-top: 1px solid $border-darker-gray;

  @media (min-width: 576px) {
    height: 70px;
  }

  .action-button {
    height: 50%;

    @media (min-width: 576px) {
      height: 100%;
    }

    .back-button {
      background-color: $primary-navy !important;
      width: 250px;
      padding: 6px 20px;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: $primary-navy !important;
      }
    }

    .save-button {
      background-color: $primary-orange !important;
      width: 250px;
      padding: 6px 20px;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: $primary-orange !important;
      }
    }
  }
}
