@import "../../../assets/styles/globals";

#DrillFailedValidationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-weight: bold;
  }
  .subtitle {
    font-size: 18px;
  }
  .orange-text {
    color: $primary-orange;
  }
  .full-length-btn {
    width: 100%;
    background: $primary-orange;
    border: none;
    border-radius: 500px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      position: relative;
      bottom: -2px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
  .two-button-container {
    display: flex;
    width: 100%;
    .cancel-button {
      flex: 0 0 50%;
      background: #fff;
      border: none;
      color: $primary-orange;
      border-radius: 500px;
      &:hover {
        background: #f4f4f5;
      }
    }
    .navigate-button {
      flex: 0 0 50%;
      background: $primary-orange;
      border: none;
      border-radius: 500px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #fff;
      &:hover {
        position: relative;
        bottom: -2px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
