#ChallengeIndex {
  .new-challenge-button-container {
    display: flex;
    justify-content: flex-end;
    .new-challenge-button {
      background: #33d9b2;
      border: 1px solid #1d2933;
      border-radius: 500px;
      padding: 5px 20px;
      &:hover {
        background: linear-gradient(#9eaaf6, #86eed5, #79d1f7);
        color: #1d2933;
      }
    }
  }
  .challenge-index-content-area {
    display: flex;
    flex-wrap: wrap;
  }
}
