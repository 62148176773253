@import "../../../../assets/styles/globals";

#Pricing {
  width: 60%;
  margin: auto;
  padding: 24px;

  @media (max-width: 1199px) {
    width: 80%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: $dark-navy;
    margin-bottom: 8px;
  }

  label {
    color: $dark-navy !important;
    font-size: 16px;
    font-weight: 700;
  }
}