@import "../../../assets/styles/globals";

#ProgramRegistrationFooter {
  height: 120px;
  flex: 0 0 auto;
  position: sticky;
  background: $off-white;
  bottom: 0;

  @media (min-width: 576px) {
    height: 70px;
  }

  .go-back {
    padding-top: 5px;
  }

  .submit-register-button {
    padding-bottom: 5px;
  }

  .go-back, .submit-register-button {
    @media (min-width: 576px) {
      padding: 0 7%;
    }
  }
}
