.image-uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
  border: 1px solid lightgrey;

  .image-uploader-reset {
    background-color: transparent;
    color: #ff6633;
    border: none;
    margin-top: 5px;
    font-size: 13px;
  }

  .image-uploader-icon {
    font-size: 15px;
    margin-right: 8px;
    color: white;
  }

  .image-uploader-text {
    color: #2b3843;
    font-size: 13px;
    margin-bottom: 3px;
  }

  .image-uploader-error {
    color: #d9534f;
    font-size: 12px;
  }

  .image-uploader-recommendation {
    font-size: 12px;
    color: #ff6633;
  }

  .image-uploader-button {
    display: none;
  }

  .image-uploader-input {
    input {
      margin-left: 50px !important;
    }
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .image-uploader-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    margin-bottom: 0px;
  }

  .image-uploader-input + label {
    font-size: 14px;
    color: white;
    background-color: #2b3843;
    display: inline-block;
    cursor: pointer;
  }

  input[type=file] {
    // display: none !important;
  	width: 0.1px;
  	height: 0.1px;
  	opacity: 0;
  	overflow: hidden;
  	position: absolute;
  	z-index: -1;
  }
}
