@import "../../../assets/styles/globals";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  height: 70px;
  border-radius: 10px;
  border-left: 3px solid #f63;
  gap: 10px;

  .inputContainer {
    display: flex;
    flex: 1;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 7px;
    border: 2px solid $grey-slate;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
    color: $light-navy;

    .textField {
      flex: 1;
      margin-right: 1rem;
    }
  }
}
