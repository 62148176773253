@import "../../assets/styles/globals";

#CurrencySelect {
  .currency-selector {
    height: fit-content;
  }

  .currency-card {
    cursor: pointer;
    padding: 4px 0 5px 12px;

    p {
      padding-left: 8px !important;
      font-size: 15px;
      margin: 0;
    }
  }

  .currency-option {
    &:hover {
      background-color: #dfecfe;
    }
  }
}
