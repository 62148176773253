@import "../../../assets/styles/globals.scss";

.modal {
  height: fit-content;
  padding: 20px !important;
  display: flex;
  flex-direction: column;

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .submitButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .button {
      width: 150px;
    }
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 25px;

    .profileWrapper {
      display: flex;
      align-items: center;

      .profileInfo {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        .coachName {
          font-size: 16px;
          font-weight: 800;
        }

        .labelText {
          color: #f63;
        }
      }
    }

    .profileImg {
      border-radius: 50%;
      height: 50px;
      width: 50px;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
    }

    .announcementIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: solid 3px #f63;
      height: 40px;
      width: 40px;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
      margin-right: 20px;
    }
  }

  .bodyContainer {
    display: flex;
    flex-direction: column;
    gap: 35px;

    .textArea {
      border-radius: 8px !important;
      border: 1px solid #afb7bd !important;
      height: 160px !important;
      color: black !important;
    }

    .textArea::placeholder {
      color: #0bf;
    }

    .addProgramsButton {
      display: flex;
      align-items: center;
      justify-content: start;
      border-radius: 8px;
      height: 50px;
      width: 100%;
      background-color: #f4f4f5;
      color: black;
      font-weight: 700;
      padding: 10px;
      border: none;
      gap: 10px;
      font-size: 14px;
    }

    .addProgramsButton:active {
      background-color: #f4f4f5;
      border: none;
    }

    .toggleSwitch {
      display: flex;
      align-items: center;
      gap: 10px;

      .toggleText {
        font-size: 16px;
        font-weight: 800;
      }
    }
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .addMediaWrapper {
      display: flex;
      align-items: flex-end;
      gap: 20px;

      .addMediaContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        background-color: white;
        border: none;
      }

      .addMediaContainerSecondary {
        align-items: start;
        flex-direction: column;
        gap: 5px;
      }

      .addMediaText {
        font-size: 14px;
        color: #36434e;
      }

      .imageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23.5px;
        height: 18px;
        flex-shrink: 0;
        border: 1.5px solid #5f6f7c;
      }

      .imagesContainer {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      .addMediaContainer:active {
        background-color: white;
        border: none;
      }

      .selectedImageContainer {
        position: relative;
        display: inline-block;

        .selectedImage {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background-color: $slate-gray;
        }

        .playVideo {
          width: 30px;
          height: 30px;
        }

        .removeImageButton {
          position: absolute;
          top: -12px;
          right: -12px;
          border: none;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          color: white;
          font-size: 14px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: #f63;
        }
      }
    }

    .submitButton {
      display: flex;
      gap: 10px;
      align-items: center;
      border: none;
      background: transparent;
      color: #f63;
      font-weight: 600;

      .vectorContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        background-color: #f63;
      }

      .vectorContainerDisabled {
        background-color: #36434e !important;
        cursor: not-allowed;
      }
    }

    .submitButton:disabled {
      color: #36434e;
    }
    .submitButton:active {
      background-color: white;
      border: none;
    }
  }
}

.modalPrograms {
  width: 80% !important;
  max-width: 1400px !important;
  height: 85% !important;
  max-height: 1000px !important;

  .placeholder {
    max-width: 300px;
    margin: 100px auto;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .icon {
      width: 64px;
      height: 64px;
      fill: $light-navy;
    }

    .title {
      font-weight: 800;
      font-size: 1.2rem;
    }

    .description {
      color: $light-navy;
      text-align: center;
    }
  }
}

.modalContentWrapper {
  height: calc(100% - $modal-header-height);

  .dualPanelSelector {
    flex: 1;
    min-height: 0;
  }

  .button {
    width: 200px !important;
  }
}
