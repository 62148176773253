@import "../../assets/styles/globals";

#ActionLeadModal {
  position: fixed;
  width: 900px;
  height: 85vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 85px;
  background: #fff;
  z-index: 6;
  border-radius: 20px;
  box-shadow: 4px 4px 4px #000;
  color: #2b3843;
  overflow-y: auto;

  @media screen and (max-width: 576px), (max-height: 576px) {
    width: 100%;
    height: calc(100vh - 70px);
    top: 70px;
  }

  .lead-info-section {
    position: relative;
    box-shadow: 0 6px 3px -3px #777;
    padding: 15px 15px 0 15px;
    height: 200px;
    // z-index: 9;

    @media screen and (max-width: 576px) {
      overflow-x: auto;
      flex-wrap: nowrap;
    }
    .header-left {
      display: flex;
      width: 50%;
      flex-direction: column;

      @media screen and (max-width: 576px) {
        width: fit-content;
      }
      .name-container {
        display: flex;
        align-items: center;
        .lead-name {
          margin: 0 10px 0 0;
          width: 350px;
          padding: 0;
          padding-left: 5px;
          border: 1px solid transparent !important;
          font-weight: 550;
          color: #2b3843 !important;
          font-size: 1.5rem;
          border-radius: 8px !important;
          line-height: 1.25;

          &:hover {
            border: 1px solid #ddd !important;
          }
          &:focus {
            border: 1px solid #ddd !important;
          }
        }
        i {
          font-size: 20px;
          margin-right: 5px;
          color: #5f6f7c;
        }
        .num-golfers {
          margin: 0;
          color: #5f6f7c;
        }
        .divider {
          margin-left: 4px;
          margin-right: 1px;
          height: 100%;
          width: 1px;
          background: #f0f0f0;
        }
      }
      .status-container {
        display: flex;
        align-items: center;
        text-align-last: center;
        margin-top: 2px;
        .subtitle {
          font-size: 14px;
          color: #5f6f7c;
          margin: 0;
          margin-right: 5px;
        }
        .select-wrapper {
          border-radius: 500px;
          &:hover {
            cursor: pointer;
          }
          select {
            text-align: center;
            width: 100%;
            font-weight: 400;
            color: #fff;
            // padding-right: 5px;
            &:focus {
              border-bottom: none !important;
            }
            &:hover {
              cursor: pointer;
              color: #f4f4f5;
            }
          }
        }
      }
      .email-container {
        margin-top: 3px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
        .lead-email {
          margin: 0;
          width: 250px;
          padding: 0;
          padding-left: 5px;
          border: 1px solid transparent !important;
          font-weight: 400;
          color: #2b3843 !important;
          font-size: 1rem;
          border-radius: 8px !important;
          &:hover {
            border: 1px solid #ddd !important;
          }
          &:focus {
            border: 1px solid #ddd !important;
          }
        }
      }
      .phone-container {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
        .phone-number {
          margin: 0;
          width: 250px;
          padding: 0;
          padding-left: 5px;
          border: 1px solid transparent !important;
          font-weight: 400;
          color: #2b3843 !important;
          font-size: 1rem;
          border-radius: 8px !important;
          &:hover {
            border: 1px solid #ddd !important;
          }
          &:focus {
            border: 1px solid #ddd !important;
          }
        }
      }
    }
    .header-right {
      display: flex;
      width: 50%;
      flex-direction: column;
      // align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 576px) {
        width: fit-content;
      }

      .lead-action-button-container {
        display: flex;
        justify-content: flex-end;
        .lead-action-button {
          color: #2b3843;
          background: transparent;
          border: 2px solid #2b3843;
          border-radius: 5px;
          font-size: 14px;
          &:hover {
            background: #2b3843;
            color: #fff;
          }
        }
        .inverted {
          color: #fff;
          background: #2b3843;
          &:hover {
            color: #2b3843;
            background: transparent;
          }
        }
        .archive-button {
          margin-right: 5px;
        }
        .send-email-button {
          margin-right: 27px;
        }
      }
      .interest-skill-container {
        display: flex;
        p {
          margin: 0;
        }
        .interest-container {
          margin-right: 55px;
        }
        .info-title {
          font-weight: 550;
        }
        .info-list {
          line-height: 1;
          margin-bottom: 15px;
        }
      }
    }
    .header-bottom {
      width: 100%;

      @media screen and (max-width: 576px) {
        width: fit-content;
      }

      .original-message-title {
        font-weight: 550;
        margin: 0;
      }
      .original-message {
        line-height: 1;
        margin-bottom: 8px;
        max-width: 870px;
        word-wrap: break-word;
        font-size: 15px;
      }
    }
  }
  .notes-area {
    height: calc(100% - 260px);
    background: #f4f4f5;
    padding: 10px;
    overflow-y: auto;
    .lead-notes-container {
      height: 100%;
      .notes-loader-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          text-align: center;
        }
      }
      .note-card {
        padding: 0 10px;
        .author-icon {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 2px solid #2b3843;
          background-size: cover;
          background-position: center;
          margin-right: 5px;
        }
        .author-name {
          font-size: 15px;
          margin: 0;
        }
        .note-date {
          margin: 0;
          font-size: 12px;
          color: #777;
        }
        .note-content {
          margin: 0 15px 10px 15px;
          font-size: 14px;
          border-radius: 8px;
          background: #2488f7;
          color: #fff;
          // font-weight: 550;
          padding: 5px 10px;
          display: inline-block;
          max-width: 100%;
        }
        .green {
          background: #6eeb51;
        }
        .dark {
          background: #2b3843;
        }
        .orange {
          background: $primary-orange;
        }
      }
    }
  }
  .bottom-input-area {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -6px 3px -3px #777;
    height: 60px;
    display: flex;
    padding: 0 15px;
    align-items: center;

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    .bottom-form {
      width: 100%;

      .lead-note-input {
        flex-basis: 700px;
        margin-right: 36px;
        border-radius: 8px !important;
        background-color: #f4f4f5 !important;
        font-size: 14px;
        font-weight: 400;
        color: #2b3843 !important;
        border: 1px solid #2b3843 !important;
        &:focus {
          border-bottom: 1px solid #2b3843 !important;
        }

        @media screen and (max-width: 576px) {
          flex-basis: 60%;
        }
      }
      .lead-note-post-button {
        width: 115px;
        background: $primary-orange;
        border-radius: 500px;
        box-shadow: 0 4px 2px -2px #777;
        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0 2px 2px -2px #777;
        }
      }
    }
  }
  .exit-button {
    position: absolute;
    right: 15px;
    top: 14px;
    font-size: 22px;
    color: #2b3843;
    z-index: 9;
    &:hover {
      // color: #eee;
      cursor: pointer;
    }
  }
  .grey-grey-out {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(43, 56, 67, 0.7);
    z-index: 10;
    border-radius: 19px;
  }
  .submodal {
    position: absolute;
    height: 250px;
    width: 400px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    background: #fff;
    z-index: 11;
    box-shadow: 4px 4px 4px #000;
    border-radius: 8px;
    padding: 15px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }
    .submodal-exit {
      z-index: 12;
    }
    .submodal-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .fa-check-circle {
        color: green;
        font-size: 90px;
      }
      .success-title {
        color: green;
        font-size: 24px;
      }
      p {
        text-align: center;
        margin: 0;
      }
      .name-confirm {
        font-size: 24px;
        font-weight: 550;
      }
      .email-confirm {
        font-size: 15px;
      }
      .actual-email {
        color: #34ace0;
      }
      .caution-note {
        display: block;
        color: #ec8291;
      }
      .submodal-button-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .submodal-button {
          width: 165px;
          height: 45px;
          border: 2px solid $primary-orange;
          border-radius: 500px;
          padding: 5px;
          background: $primary-orange;
          white-space: nowrap;
          font-size: 1rem;
          transition: color 1s;
          &:hover {
            color: #000;
          }
        }
        .fail {
          background: #ec8291;
        }
        .cancel {
          background: transparent;
          color: $primary-orange;
        }
      }
    }
  }
}
