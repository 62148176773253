@import "../../globals";

.table-row-actions {
  padding-right: 27px;


  .table-ellipsis {
    cursor: pointer;
    color: $light-navy;
  }
}


.popover-style {
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25) !important;
}

.communities-actions {
  padding-bottom: 10px;
  padding-top: 5px;

  .community-action {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    padding: 5px 10px 0px 10px;
    margin: 0;
    color: $dark-navy;

    &:hover {
      color: #ff6633;
    }
  }

  a.disabled {
    pointer-events: none;
  }
}