@import "../../assets/styles/globals";

.programRegistrationPrompt {
  width: 100vw;
  height: 100vh;
  background-color: $dark-navy;
  color: white;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 48px;
  }

  .promptContent {
    width: 100%;
    max-width: 600px;

    .titleText {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: center;
    }

    .subtitleText {
      color: white;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 4rem;
    }

    .actionLink {
      max-width: 100% !important;
      width: 320px !important;
    }
  }
}