@import "../../../assets/styles/globals";

#ResourceSectionDetailPage {
  .resource-section-container {
    padding: 30px;
    .resource-section-info-wrapper {
      h3 {
        font-weight: 550;
        color: $primary-color;
      }
    }
  }

  .resource-search-bar {
    margin: -10px auto 15px;
    background-color: #fff;
    border-radius: 8px;
  }
}
