@import "../../globals";

#FamilyPage {
  .select-box {
    display: inline-block;
    margin-left: 8px;
  }

  table.table {
    tbody {
      a {
        display: block;
      }
    }
    td {
      img {
        border-radius: 50%;
      }
    }
  }
}

.coaches-search-bar {
  width: 98%;
  margin: -10px auto 15px;
  background-color: #fff;
  border-radius: 8px;
}

.family-row,
.FamilyMemberRow {
  color: darken($disable-color, 65%);
  list-style: none;
  font-size: 14px;
  padding: 20px 10px;
  text-align: left;
  @include transition(0.5s);
  &:hover,
  &:active,
  &:visited {
    cursor: pointer;
    background: $action-button-color;
    color: $white-font-color;
    text-decoration: none;
  }
}
