@import "../../assets/styles/globals";

#CompletedEducation {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-weight: 550;
    text-align: center;
  }

  p {
    text-align: center;
    color: $light-font-color;
  }
}
