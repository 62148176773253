@import '../../assets/styles/globals.scss';

.noNearbyCommunities {
  max-width: 340px;
  padding: 32px;

  .op36LocationPinIcon {
    height: 108px;
    flex-shrink: 0;
  }

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  .subtitle {
    text-align: center;
    color: $light-navy;
    font-size: 14px;
  }
}
