@import "../../../assets/styles/globals";

.coachProgramsPage {
  .tabList {
    height: 40px;
    margin: 0 -15px 10px;
    padding: 0;
    background-color: #fff;
    border-bottom: 2px solid $light-gray !important;

    .tab {
      margin-top: 0px;
      text-transform: none;
      background-color: transparent;
      padding-left: 70px;
      padding-right: 70px;
      font-size: 14px;
      height: 40px;
      font-weight: bold;
      color: black;
    }

    .activeTab {
      border-bottom: 4px solid $secondary-color;
      height: 40px;
      color: $secondary-color;
      background: transparent !important;
    }
  }

  .tabPanel {
    margin-top: 10px;
  }

  .coachProgramsList {
    height: calc(100vh - $page-header-height - 124px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
  }
}

@media all and (max-width: 991px) {
  .coachProgramsPage {
    .tabList {
      .tab {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .coachProgramsPage {
    .tabList {
      .tab {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}

@media all and (max-width: 575px) {
  .coachProgramsPage {
    .tabList {
      .tab {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .coachProgramsList {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

@media all and (max-width: 390px) {
  .coachProgramsPage {
    .tabList {
      .tab {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13px;
      }
    }

    .coachProgramsList {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
