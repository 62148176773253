@import "../../../assets/styles/globals";
$title-font-size: 30px;
$icon-size: $title-font-size;
$top-padding: calc(($page-header-height - $title-font-size) / 2);

.page-header-v2 {
  margin-left: -15px;
  margin-right: -15px;
  background-color: $dark-navy;
  height: $page-header-height;
  border-bottom: 1px solid $placeholder-gray;

  .headerIcon {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
  }

  img {
    float: right;
    max-width: 80px;
    width: 100%;
  }
  h1 {
    float: left;
    color: $light-font-color;
    padding-left: 10px;
    font-size: 18px;

    a {
      color: white;

      &:hover {
        color: white;
      }
    }
  }
  h3 {
    color: $primary-color;
  }
  button {
    margin-top: 0;
  }
  .header-button-container {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    .question-button {
      font-size: 22px;
    }
  }
  .back-button{
    margin-left: 15px;
    color: #fff;
  }

  .header-title {
    margin: 0px;
    color: #fff !important;
    font-size: 24px;
    font-weight: 600;
  }

  .header-icon {
    position: relative;
    bottom: 3px;
    left: -20px;
    width: 100%;
    padding: 20px;
  }

  .fa-header-icon {
    font-size: 25px !important;
    color: $primary-color !important;
  }

  .btn-family {
    background-color: white;
    border-color: white !important;
    color: #f68a22 !important;

    &:focus {
      background-color: white;
    }

    &:hover {
      color: #f68a22 !important;
      background-color: #f1f1f1;
      border-color: #e9e9e9 !important;
    }
  }

  .view-community-link {
    padding: 0 15px;

    #outsideLinkIcon {
      flex-shrink: 0;
    }

    .view-community-label {
      padding-left: 10px;
      color: $primary-orange;
      font-weight: 700;
    }
  }

  @media (max-width: 761px) {
    .page-header {
      button {
        margin-top: 0;
        font-size: 12px;
        float: right;
      }
    }
  }

  @media (max-width: 991px) {
    .page-header {
      .header-title {
        top: 10px;
      }

      button {
        margin-top: 0;
        font-size: 12px;
        float: right;
      }
    }
  }
}

@media (max-width: 1199px) {
  .page-header-v2-padding {
    margin-left: 75px !important;
  }
}

@media all and (max-width: 575px) {
  .view-community-link {
    .view-community-label {
      display: none;
    }
  }
}
