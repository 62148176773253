@import "../../../assets/styles/globals";

.inputContainerWrapper {
  margin-bottom: 12px;
}

.inputContainer {
  border: none !important;
  background-color: $primary-navy !important;
  border-radius: 10px !important;
}

.input {
  color: white !important;

  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
   -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
  }
}

.telInput {
  :global(.flag-dropdown) {
    background-color: transparent !important;
    border: none !important;
  }

  :global(.selected-flag) {
    margin-top: 4px;
  }
}

.symbol {
  color: $border-gray !important;
}
