@import "../../../../assets/styles/globals.scss";

.container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  gap: 10px;

  button {
    all: unset;
    flex: 1;
    text-align: center;
    cursor: pointer;
    font-weight: 600;

    &:active {
      background-color: transparent;
    }
  }

  .selected {
    color: #f63;
    border-bottom: 4px solid #f63;
  }
}
