@import "../../assets/styles/globals";
#LessonPlanPage {
  width: calc(100% + 30px);
  margin: 0 -15px 0 -15px;

  .tabs {
    height: calc(100% - 70px);
    .tabList {
      height: 40px;
      margin-left: -40px;
      background-color: transparent;
      border-bottom: 2px solid $light-gray !important;
      margin-bottom: 0rem !important;

      .tab{
        margin-top: 0px;
        text-transform: none;
        background-color: transparent;
        padding-left: 100px;
        padding-right: 100px;
        font-size: 14px;
        height: 40px;
        font-weight: bold;
        color: black;
      }

      .activeTab{
        border-bottom: 4px solid $secondary-color;
        height: 40px;
        color: $secondary-color;
        background: transparent !important;
      }
    }
  }

  .title-dropdown {
    margin-left: 10px;
    cursor: pointer;

    path {
      stroke: white;
    }
  }


  .lesson-plans-search-bar {
    width: 90%;
    margin: -10px auto 15px;
    background-color: #fff;
    border-radius: 8px;
  }

  .pinned-bar {
    width: 100%;
    background: #5f6f7c;
    padding: 10px;
    color: #fff;
    h5 {
      margin: 0;
      position: relative;
      .pin-count {
        line-height: 1;
        padding: 2px 5px 3px 4px;
        border-radius: 500px;
        background: $primary-orange;
        color: #fff;
        font-size: 14px;
        position: absolute;
        top: -7px;
        right: -17px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .pinned-area {
      .pinned-card-container {
        display: flex;
        flex-wrap: wrap;
      }
      .no-pad {
        * {
          padding-top: 0 !important;
        }
      }
    }
    .pinned-toggler {
      font-size: 1.5rem;
      margin-right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .lesson-content-container {
    padding: 10px;
    .no-pad {
      * {
        padding-top: 0 !important;
      }
    }
    .lesson-plan-card-container {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .add-button {
    position: fixed;
    bottom: 35px;
    right: 35px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: $primary-orange;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 98;
    &:hover {
      cursor: pointer;
    }
    i {
      font-size: 2.5rem;
    }
  }
  .add-button-1 {
    // position: fixed;
    // bottom: 35px;
    // right: 35px;
    height: 40px;
    // width: 80px;
    padding: 0 15px;
    border-radius: 500px;
    background: $primary-orange;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 98;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
    }
    // i {
    //   font-size: 2.5rem;
    // }
  }
}

.title-button {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  cursor: pointer !important;
  outline: inherit !important;
  font-size: 18px !important;
  padding: 5px 10px !important;
  color: $dark-navy !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.popover-container {
  width: 250px;
  padding: 10px;
}