@import "../../../assets/styles/globals";

.op36-toggle {
  &.react-toggle {
    .react-toggle-track {
      background-color: $placeholder-gray;
      height: 30px;
      width: 56px;
    }

    .react-toggle-thumb {
      border-color: $placeholder-gray;
      height: 28px;
      width: 28px;

      &:active {
        box-shadow: none;
      }
    }

    &:active {
      .react-toggle-thumb {
        box-shadow: none;
      }
    }

    &:hover {
      .react-toggle-track {
        background-color: $dark-navy;
      }
    }
  }

  &.react-toggle--checked {
    .react-toggle-track {
      background-color: $primary-orange;
    }

    .react-toggle-thumb {
      border-color: $primary-orange;
    }

    &:hover {
      .react-toggle-track {
        background-color: $secondary-color-alt;
      }
    }
  }

  &.react-toggle--focus {
    .react-toggle-thumb {
      box-shadow: none;
    }
  }

  // Note: Medium size is not explicit
  // TODO: Add rules for large size

  &.small {
    .react-toggle-track {
      height: 26px;
      width: 46px;
    }

    .react-toggle-thumb {
      height: 24px;
      width: 24px;
    }

    &.react-toggle--checked {
      .react-toggle-thumb {
        left: 21px;
      }
    }
  }
}