@import "../../../../assets/styles/globals";

#RegistrationActions {
  .approve-button {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background: $primary-orange;
    padding: 7px 10px;
    max-width: fit-content;
    white-space: nowrap;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    margin-bottom: 0;
  }

  .decline-button {
    cursor: pointer;
    color: $primary-orange;
    margin-right: 11px;
    font-weight: 700;
    margin-bottom: 0;
    padding: 0;
  }

  .disabled {
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
  }

  .ellipsis-icon {
    cursor: pointer;
  }
}

.popover-style {
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25) !important;
}

.enrollment-actions {
  padding-bottom: 10px;
  padding-top: 5px;

  p, a {
    color: $dark-navy;
    cursor: pointer;
    font-size: 16px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: #ff6633;
    }
  }
}
