@import "../../../assets/styles/globals";

#FacilityImpactReport {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  .impact-top-bar {
    background: $dark-navy;
    margin-left: -15px;
    margin-right: -15px;
    padding: 9px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    p {
      margin: 0;
    }
    i {
      font-size: 36px;
      margin-right: 9px;
    }
    .fa-arrow-left:hover {
      cursor: pointer;
      color: $primary-orange;
    }
    .top-bar-title-container {
      flex: 1 1;
    }
    .impact-video-trigger-container {
      color: $primary-orange;
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      i {
        font-size: 18px;
        margin-right: 4px;
      }
      &:hover {
        cursor: pointer;
        p {
          text-decoration: underline;
        }
      }
    }
  }
  .impact-report-main-data {
    flex: 1 1;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    background: #fff;
    // align-items: stretch;
    .input-area {
      overflow-y: auto;
      flex: 0 0 470x;
      max-width: 470px;
      // margin-left: -15px;
      padding: 18px 36px;
      // border-right: 2px solid $light-navy;
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      p {
        margin: 0;
      }
      hr {
        border-top: 2px solid #afb7bd;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
      }
      .video-container {
        height: 200px;
        border: 2px solid $light-navy;
        border-radius: 8px;
      }
      .back-container {
        display: flex;
        flex-wrap: nowrap;
        color: $primary-orange;
        align-items: center;
        p,
        i {
          margin: 0;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        p {
          padding-left: 6px;
        }
      }
      .orange-text {
        color: $primary-orange;
      }
      label,
      .label {
        font-size: 16px;
        font-weight: bold;
      }
      .label {
        margin-bottom: 0;
      }
      input {
        padding: 5px 0 0 0;
        font-size: 16px;
        border: none !important;
        border-bottom: 2px solid $primary-orange !important;
      }
      .date-range-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          max-width: 163px;
          text-align: center;
        }
      }
      .input-section-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 18px;
          font-weight: 700;
        }
        i {
          color: $primary-orange;
          font-size: 19px;
        }
      }
      .all-tooltips {
        .interior-tip {
          max-width: 300px;
          padding: 9px 0;
          p {
            margin-bottom: 0.5rem;
          }
          .bold {
            font-weight: bold;
          }
          .orange {
            color: $primary-orange;
            font-size: 1rem;
          }
        }
      }
      .industry-standards {
        color: $light-navy;
        font-size: 12px;
      }
      .inline-dashed-input-container {
        display: flex;
        align-items: center;
        position: relative;

        p {
          color: $light-navy;
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
        }
        .dash-box {
          height: 0;
          border-top: 2px dashed #afb7bd;
          flex: 1 1;
          margin: 0 9px;
        }

        .currency-input {
          display: flex;
          width: 85px;
          color: $primary-orange;
          border-bottom: 2px solid $primary-orange !important;
          input {
            padding-left: 5px;
            border-bottom: none !important;
            width: 100%
          }
          span {
            height: 18px;
            margin-top: 3px;
          }
        }

        .percent-input {
          display: flex;
          width: 85px;
          color: $primary-orange;
          border-bottom: 2px solid $primary-orange !important;

          input {
            padding-right: 5px;
            border-bottom: none !important;
            width: 100%;
          }
          span {
            height: 18px;
            margin-top: 3px;
          }
        }

        input {
          max-width: 85px;
          font-size: 14px;
        }

        .padding-left {
          padding-left: 9px;
        }
        .padding-right {
          padding-right: 16px;
        }
        span {
          color: $primary-orange;
        }
        .lng {
          right: 78px;
        }
      }
      .inline-full-input-container {
        display: flex;
        align-items: center;
        label {
          white-space: nowrap;
          margin: 0 4px 0 0;
        }
        input {
          flex: 1 1;
        }
      }
    }
  }
  .impact-report-bottom-bar {
    background: $light-navy;
    margin-right: -15px;
    margin-left: -15px;
    padding: 18px 25% 9px 25%;
  }
  .student-section {
    flex: 1 1;
    // align-self: stretch;
    padding: 18px 36px;
    background: #f4f4f5;
    .student-placeholder-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: $light-navy;
      p {
        margin-bottom: 4px;
        text-align: center;
        max-width: 350px;
      }
      .bold {
        font-weight: bold;
      }
      .placeholder-icon {
        flex: 0 0 70px;
        width: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .student-display-section {
    flex: 1 1;
    height: 100%;
    padding: 18px 36px 9px 36px;
    display: flex;
    flex-direction: column;
    // background: #fff;
    // justify-content: space-between;
    h4 {
      font-size: 18px;
    }
    .edit-button {
      padding: 0 8px;
      margin-bottom: 4px;
      color: $primary-orange;
      &:hover {
        cursor: pointer;
        border-radius: 8px;
        background: #ddd;
      }
      p {
        margin: 0;
      }
    }
    .student-row-box {
      flex: 1 1;
      border: 2px solid #5f6f7c;
      max-height: 500px;
      max-width: 400px;
      overflow-y: auto;
      background: #fff;
    }
  }
  .error-text {
    color: red;
    border: 1px solid red;
    border-radius: 4px;
    margin: 4px 0 !important;
    text-align: center;
    background-color: pink;
  }
}

#add-student-to-report-modal {
  height: 100vh;
  width: 100vw;
  background: #fff;
  overflow-y: auto;
  padding: 15px;
  .bold {
    font-weight: bold;
  }
  .student-modal-content {
    display: flex;
    width: 100%;
    height: calc(100vh - 135px);
  }
  .selector-section {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    .completed-range {
      font-weight: 400;
      font-size: 13px;
      color: $light-navy;
    }
    .top-selector-bar {
      background: $dark-navy;
      color: #fff;
      display: flex;
      flex: 0 0 66px;
      position: relative;
      border-radius: 4px;
      .selection-indicator {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 25%;
        height: 8px;
        border-radius: 500px;
        background: $primary-orange;
        transition: left 1s;
      }
      .selection-container {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          p {
            text-decoration: underline;
          }
        }
        p {
          text-align: center;
          margin: 0;
        }
        .isActive {
          color: $primary-orange;
        }
      }
    }
    .picker-selection-display {
      flex: 1 1;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 9px;
      border: 2px solid $light-gray;
      border-radius: 4px;
      .fa-spinner {
        font-size: 36px;
      }
      .clickable:hover {
        cursor: pointer;
        background: #ddd;
      }
      .student-row {
        display: flex;
        align-items: center;
        padding: 9px;
        border-bottom: 1px solid $light-gray;
        overflow: hidden;
        .avatar {
          height: 45px;
          flex: 0 0 45px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        .name-area {
          flex: 1 1;
          padding: 0 9px;
          p {
            margin: 0;
          }
          .name {
            font-weight: bold;
            font-size: 16px;
            white-space: nowrap;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .login {
            font-size: 14px;
          }
        }
        i {
          margin-right: 9px;
          font-size: 24px;
        }
        .fa-check-circle {
          color: green;
        }
        .fa-plus {
          color: $primary-orange;
        }
      }
      .family-row1 {
        display: flex;
        align-items: center;
        padding: 9px;
        border-bottom: 1px solid $light-gray;
        // &:hover {
        //   cursor: pointer;
        //   background: #ddd;
        // }
        .fa-users {
          font-size: 36px;
        }
        .name-area {
          flex: 1 1;
          padding: 0 9px;
          p {
            margin: 0;
          }
          .name {
            font-weight: bold;
            font-size: 16px;
            white-space: nowrap;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .student-count {
            font-size: 14px;
            color: $primary-orange;
          }
        }
        .fa-plus {
          margin-right: 9px;
          font-size: 24px;
          color: $primary-orange;
        }
        .fa-check-circle {
          margin-right: 9px;
          font-size: 24px;
          color: green;
        }
      }
      .class-row {
        display: flex;
        align-items: center;
        padding: 9px;
        border-bottom: 1px solid $light-gray;
        // &:hover {
        //   background: #ddd;
        //   cursor: pointer;
        // }
        .class-icon {
          height: 45px;
          flex: 0 0 45px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          // background-color: #f4f4f5;
          border-radius: 8px;
        }
        .name-area {
          flex: 1 1;
          padding: 0 9px;
          p {
            margin: 0;
          }
          .name {
            font-weight: bold;
            font-size: 16px;
            // white-space: nowrap;

            text-overflow: ellipsis;
          }
          .meeting-times {
            font-size: 14px;
            .orange {
              color: $primary-orange;
              font-weight: bold;
            }
          }
        }
        .fa-plus {
          margin-right: 9px;
          font-size: 24px;
          color: $primary-orange;
        }
        .fa-check-circle {
          margin-right: 9px;
          font-size: 24px;
          color: green;
        }
      }
    }
  }
  .icon-search-bar {
    i {
      color: $light-gray;
      font-size: 18px;
      margin-right: 6px;
    }
    input {
      border: none !important;
      border-bottom: 2px solid $light-gray !important;
    }
  }
  .placeholder-container {
    flex: 1 1;
    color: $light-navy;
    p {
      margin-bottom: 4px;
      text-align: center;
      max-width: 350px;
    }
    .placeholder-icon {
      flex: 0 0 70px;
      width: 70px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .student-display-section {
    flex: 1 1;
    padding: 75px 9px 0px 9px;
    .inner-display {
      border: 2px solid $light-navy;
      border-radius: 4px;
      max-height: 98%;
      overflow-y: auto;
      .section-title {
        padding-left: 18px;
      }
    }
  }
}

.StudentReport {
  min-height: 748px;
}

#community-impact-report,
.StudentReport {
  // height: 100%;
  width: 100%;
  width: 1000px;
  min-height: 748px;
  background: #fff;
  // overflow-y: auto;
  margin-right: auto;
  margin-left: auto;
  .report-spacer {
    height: 18px;
    background: #f4f4f5;
  }
  .student-report-container {
    padding: 18px;
    .page-count {
      color: $light-navy;
      font-size: 14px;
    }
    table {
      width: 100%;
      margin-top: 10px;
      thead {
        tr {
          background: #f4f4f5;
          border-top: 2px solid $light-navy;
          border-bottom: 2px solid $light-navy;
          th {
            padding: 9px 6px;
          }
          .center {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 2px solid $light-navy;
          height: 37px;
          .center {
            text-align: center;
          }
          .student-name {
            font-weight: 700;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
          .orange-text {
            font-size: 16px;
            font-weight: 900;
          }
          .green-text {
            font-size: 16px;
            font-weight: 900;
            color: $primary-teal;
          }
          .blue-text {
            font-size: 16px;
            font-weight: 900;
            color: $primary-blue;
          }
        }
      }
    }
  }

  p {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
  .modal-header {
    padding: 0.5rem !important;
  }
  .report-top-bar {
    color: #fff;
    background: $dark-navy;
    // margin-left: -15px;
    // margin-right: -15px;
    padding-left: 18px !important;
    padding-right: 18px !important;
    .sub-text {
      color: #fff;
    }
  }
  .orange-text {
    color: $primary-orange;
  }
  .community-photo {
    height: 60px;
    flex: 0 0 60px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    margin-right: 6px;
  }
  .report-id-info {
    flex: 1 1;
    p {
      margin: 0;
    }
    .large-bold {
      font-size: 18px;
      font-weight: 700;
    }
    .small-bold {
      font-size: 14px;
      font-weight: 700;
    }
    .sub-text {
      font-size: 14px;
    }
  }
  .report-timeline-container {
    p {
      text-align: right;
      margin: 0;
      font-size: 14px;
    }
    .bold {
      font-weight: 700;
    }
    .logo {
      height: 24px;
      width: 166px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .inline-icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 6px;
    margin-top: 9px;
    // vertical-align: middle;
  }
  .facility-impact-revenue {
    border-right: 2px solid $light-gray;
    margin: 9px 0;
    padding: 0 9px;
    .large-est-revenue-text {
      font-size: 72px;
      font-weight: 900;
    }
    .lg-est-visit-text {
      font-size: 24px;
      font-weight: 700;
    }
    .small-est-revenue-text {
      font-size: 24px;
      font-weight: 900;
      margin-right: 4px;
    }
    .sm-est-visit-text {
      font-size: 14px;
      font-weight: 700;
    }
    .impact-chart {
      width: 50%;
      padding: 0 9px 9px 9px;
      p {
        color: $light-navy;
      }
      .section-title1 {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        margin-bottom: 6px;
      }
      .total-line {
        margin-top: 2px;
        border-top: 1px solid $light-navy;
      }
      .section-key {
        font-size: 12px;
      }
      .total-section-key {
        font-size: 14px;
        font-weight: 700;
      }
      .section-val {
        font-size: 12px;
        font-weight: 900;
        color: $primary-orange;
      }
      .total-section-val {
        font-size: 14px;
        font-weight: 700;
        color: $primary-orange;
      }
    }
    .single-chart {
      margin-right: auto;
      margin-left: auto;
    }
    .left-chart {
      border-right: 2px solid #ddd;
    }
    .sub-text {
      margin-top: 6px;
    }
    .estimated-visit-container {
      flex-direction: column;
      align-items: center;
      border-right: 2px solid $light-gray;
      padding: 9px;
      min-width: 200px;
      margin-bottom: 9px;
      p {
        text-align: center;
      }
    }
    .dollar-calc-container {
      padding: 9px;
      display: flex;
      flex-wrap: wrap;
      .dollar-calc {
        flex: 0 0 50%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .orange-text {
          font-size: 18px;
          font-weight: 700;
        }
        .category {
          font-size: 12px;
          color: $light-navy;
        }
      }
    }
    .bold {
      font-weight: 700;
    }
    .sub-text {
      font-size: 12px;
      color: $light-navy;
    }
  }
  .program-demographics {
    padding: 9px;
    // canvas.chartjs-render-monitor {
    //   height: 82px !important;
    //   flex: 0 0 82px;
    // }
    .percent-bar-container {
      height: 12px;
      border-radius: 500px;
      overflow: hidden;
      background: blue;
      display: flex;
      margin-bottom: 9px;
      .junior-bar {
        background: $primary-blue;
        height: 12px;
      }
      .leftover-bar {
        flex: 1 1;
        height: 12px;
        background: #afb7bd;
      }
      .adult-bar {
        background: $primary-teal;
        height: 12px;
      }
    }
    .demographic-title {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
    .jnr-demo-title {
      color: $primary-blue;
    }
    .adt-demo-title {
      color: $primary-teal;
    }
  }
  .large-num {
    font-size: 36px;
    font-weight: 700;
  }
  .med-num {
    font-size: 24px;
    font-weight: 700;
  }
  .main-title {
    font-size: 18px;
    font-weight: 700;
  }
  .sub-text {
    font-size: 12px;
    color: $light-navy;
  }
  .stubd {
    max-width: 97px;
    line-height: 1;
    text-align: left;
    color: $light-navy;
  }
  .inline-d-val {
    font-size: 18px;
    margin-right: 6px;
    font-weight: 700;
  }
  .dark-background {
    background: $light-navy;
    padding: 18px;
    color: #fff;
    // margin-left: -15px;
    // margin-right: -15px;
    .inline-icon {
      margin-top: 0;
    }
    .facility-impact-improvement {
      padding: 9px;
      border-right: 2px solid #fff;
      .white-text {
        margin-top: 5px;
        font-size: 12px;
      }
      .tri-line-container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 10px;
        .improvement-box {
          flex: 0 0 32%;
          background: #fff;
          color: $dark-navy;
          border-radius: 8px;
          border: 2px solid $primary-orange;
          padding: 9px;
          .improvement-title {
            text-align: center;
            color: $primary-orange;
            font-size: 12px;
            line-height: 14px;
          }
          .improvement-icon {
            height: 24px;
            flex: 0 0 24px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .improvement-number {
            font-size: 24px;
            color: $dark-navy;
            font-weight: 700;
            margin-left: 4px;
          }
        }
      }
      .large-improvement-box {
        background: #fff;
        border-radius: 8px;
        border: 2px solid $primary-orange;
        padding: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .inner-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .improvement-number {
            font-size: 24px;
            color: $dark-navy;
            font-weight: 700;
            margin-left: 4px;
          }
          .improvement-title {
            text-align: center;
            color: $primary-orange;
            font-size: 12px;
            line-height: 14px;
          }
        }
        .star-icon {
          height: 24px;
          flex: 0 0 24px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin: 0 10px;
        }
      }
    }
    .facility-impact-engagement {
      padding: 9px;
      .white-text {
        margin-top: 5px;
        font-size: 12px;
      }
      .white-box {
        background: #fff;
        border: 2px solid $primary-orange;
        border-radius: 8px;
        padding: 9px;
        margin-top: 10px;
      }
      .half-flex {
        flex: 0 0 50%;
      }
      .total-golfers {
        text-align: center;
        .total-golfers-num {
          color: $primary-orange;
          font-size: 24px;
          font-weight: 700;
        }
        .total-golfers-title {
          color: $dark-navy;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .ir-icon {
        height: 20px;
        flex: 0 0 20px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 4px;
      }
      .bold-num {
        font-size: 18px;
        font-weight: 700;
      }
      .orange-text {
        font-size: 14px;
      }
      .tri-flex {
        flex: 0 0 32%;
        margin-top: 18px;
        p {
          text-align: center;
        }
        .engagement-title {
          font-size: 14px;
        }
        .engagement-sublist {
          font-size: 12px;
        }
      }
      .rounds-container {
        color: $primary-teal;
      }
      .sessions-container {
        color: $primary-blue;
      }
      .social-container {
        color: $primary-purple;
      }
    }
  }

  .chart-box {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-top: 9px;
    canvas {
      //margin: 0 -40px;
      z-index: 9999;
    }
    #PieChart {
      margin-right: 10px;
    }
    .demo-breakdown {
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      margin-left: -25%;
      p {
        margin-bottom: 4px;
      }
    }
    .jnr-female {
      color: $dark-blue;
    }
    .jnr-male {
      color: $primary-blue;
    }
    .jnr-other {
      color: $light-blue;
    }
    .adt-female {
      color: $dark-teal;
    }
    .adt-male {
      color: $primary-teal;
    }
    .adt-other {
      color: $light-teal;
    }
  }
  .junior-container {
    border-right: 2px solid $light-gray;
    .jnr-percentage {
      color: $primary-blue;
      font-size: 12px;
      text-align: center;
    }
  }
  .adult-container {
    .adt-percentage {
      color: $primary-teal;
      font-size: 12px;
      text-align: center;
    }
  }
}

#impact-report-loading-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  i {
    font-size: 72px;
    color: $primary-orange;
  }
  .main-text {
    margin-top: 18px;
    font-size: 18px;
    font-weight: bold;
  }
}

#impact-report-error-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .oh-snap {
    height: 160px;
    width: 320px;
    background-position: center;
    background-size: cover;
  }
  .main-text {
    font-size: 18px;
    font-weight: bold;
  }
}

.report-display-container {
  margin-bottom: 63px;
  position: relative;
  .back-to-previous {
    display: inline-block;
    color: $primary-orange;
    // margin: 0;
    // font-weight: 700;
    i {
      margin-right: 9px;
    }
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .redownload-bar {
    position: fixed;
    bottom: 0;
    background: $light-navy;
    // left: 0;
    right: 0;
    width: calc(100% - 200px);
    height: 63px;
    display: flex;
    align-items: center;
    border-top: 1px solid $dark-navy;
    z-index: 10;
  }
  @media screen and (max-width: 1200px) {
    .redownload-bar {
      width: 100%;
    }
  }
}
