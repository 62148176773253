@import '../../assets/styles/globals';
$bg-color: rgba(227, 113, 0, 0.24);
$grey-font-color: #666;

#announcement-form {
  * .announcement-form-mobile{
    display: none;
  }
  label {
    font-size: 1em;
    margin-top: 1em;

    &.first-label {
      margin-top: 0;
    }
  }

  #select-all-courses {
    float: right;
    text-decoration: underline;
    cursor: pointer;
  }

  .course-select-input {
    z-index: 999999;
  }

  .Select--multi .Select-value {
    color: $grey-font-color;
    border-color: $secondary-color-alt;
    background-color: $bg-color;
  }

  .Select-option {
    &.is-focused {
      color: $grey-font-color;
      background-color: $bg-color;
    }
  }

  .text-input input {
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-toggle {
    float: right;
  }

  .orange-bg.react-toggle--checked .react-toggle-track {
    background-color: $secondary-color;
  }

  #email-toggle-label, .react-toggle {
    margin-top: 1.5em;
    display: none;
  }
}

.message-mobile {
  max-height: 3em;
  overflow: hidden;
}

.DraftEditor-editorContainer {
  min-height: 200px;
}

.announcement-row {
  height: 50px !important;

  .announcement-row-type {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: none !important;
  }

  .announcement-row-email-icon {
    color: $secondary-color;
  }

  .announcement-row-push-icon {
    color: $primary-color;
  }

  .announcement-row-preview {
    cursor: pointer;
  }

  .announcement-row-message {
    width: 850px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



#AnnouncementsPage {
  @media (max-width: 991px) {
    .modal-header {
      padding-top:10px;
      padding-bottom: 10px;

      * .modal-title.display-4{
        font-size: 15px;
      }
    }

    .modal-body {
      padding: 0px;
    }

    button.close {
      font-size: 30px !important;
      margin: 0px;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
        top: 5px !important;
        left: 5px !important;
        right: 5px !important;
        bottom: 5px !important;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 10px !important;

        position: absolute !important;
        top: 10px !important;
        left: 10px !important;
        right: 10px !important;
        bottom: 10px !important;
        border: 1px solid #cccccc;
        background: white;
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 10px !important;
        width: calc(100% - 20px) !important;
        transform: none !important;
    }

    .EditorToolbar__root___3_Aqz {
      display: none;
    }

    .public-DraftEditor-content {
      min-height: 20vh;
    }
  }
}
