@import "../../../assets/styles/globals";

#AdminUserForm {
  h4 {
    margin-bottom: 25px;
    color: $light-font-color;
  }

  .reset-password-container {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $light-gray;

    p {
      color: $light-red;
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 0px;
    }
  }
}
