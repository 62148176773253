@import "../../../../assets/styles/globals";

#VisibilitySelect {
  .visibility-option {
    cursor: pointer;

    &:hover {
      background-color: darken(white, 10%) !important;
    }
  }

  .visibility-value {
    cursor: pointer;

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .name {
    color: $dark-navy;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 700;
  }

  .description {
    color: $light-navy;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
  }

  .selected-visibility {
    height: 45px;

    p {
      margin-bottom: 0;
    }
  }
}