@import "../../../../assets/styles/globals.scss";

:root {
  --coachAccountCreatedH1: #{$heading-1-size};
  --coachAccountCreatedH2: #{$heading-2-size};
  --coachAccountCreatedH3: #{$heading-3-size};

  --coachAccountCreated-header-img-height: 250px;
  --coachAccountCreated-avatar-margin: calc(#{$primary-padding} + 56px);
}

#AccountCreatedStep {
  width: 100%;

  .account-created-header {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    background-image: url('../../../../assets/images/registration-coach/account-created-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 400px / 0px 0px 200px 200px;
    height: var(--coachAccountCreated-header-img-height);
  }

  svg { // logo
    height: var(--regFlowHeader-logo-height);
    width: auto;
    margin-bottom: var(--coachAccountCreated-avatar-margin);
  }

  h2 {
    color: $primary-orange;
    font-size: var(--coachAccountCreatedH1);
    font-weight: $bold-font-weight;
    margin: $secondary-padding 0px;
  }

  h3 {
    color: $dark-navy;
    font-size: var(--coachAccountCreatedH2);
    font-weight: $bold-font-weight;
  }

  h4 {
    color: $light-navy;
    font-size: var(--coachAccountCreatedH3);
    margin-bottom: $primary-padding;
  }

  .account-created-body {
    margin-top: calc(-18px + -56px);
    padding: var(--regFlowCard-padding);
    padding-top: 0px;
  }
}

#CoachRegistrationPhotoUploader {
  // margin: $primary-padding 0px;
  margin-bottom: $primary-padding;

  .account-created-step-img-uploader {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    margin: $secondary-padding auto;
    margin-bottom: 10px;
    transition: all .2s ease-in-out;

    img {
      border: 4px solid $primary-navy;
      height: 100px;
      width: 100px;
      border-radius: 100%;
      object-fit: cover;
    }

    &:hover {
      transform: scale(0.9);
      transition: all .2s ease-in-out;
      cursor: pointer;
    }
  }

  .account-created-step-img-uploader-button {
    color: $primary-orange;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0px;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(0.9);
      transition: all .2s ease-in-out;
      cursor: pointer;
    }
  }
}

@media (max-width: 425px) { // phone (large)
  :root {
    --coachAccountCreatedH1: #{$heading-2-size};
    --coachAccountCreatedH2: #{$heading-3-size};
    --coachAccountCreatedH3: #{$heading-4-size};

    --coachAccountCreated-header-img-height: 150px;
    --coachAccountCreated-avatar-margin: calc(#{$secondary-padding} + 56px);
  }
}
