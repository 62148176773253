@import "../../../assets/styles/globals";

#TwoButtonModal {
  .grey-out {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: #37434e;
    opacity: 0.8;
    z-index: 99;
  }
  .modal-content-2button {
    position: fixed;
    height: 250px;
    width: 500px;
    background: #fff;
    border-radius: 8px;
    top: 100px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 25px 35px 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    z-index: 100;
    .close-button-container {
      display: flex;
      justify-content: flex-end;
      i {
        padding: 5px;
        font-size: 24px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .modal-content-box {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .modal-title {
        font-size: 36px;
        text-align: center;
      }
      .modal-subtitle {
        font-size: 20px;
      }
      .modal-button-box {
        display: flex;
        .modal-cancel-button {
          flex: 1 1;
          background: #fff;
          border: none;
          color: $primary-orange;
          border-radius: 500px;
          &:hover {
            background: #f4f4f5;
          }
        }
        .modal-confirm-button {
          flex: 1 1;
          background: $primary-orange;
          border: none;
          border-radius: 500px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          color: #fff;
          &:hover {
            position: relative;
            bottom: -2px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
