@import '../../globals';

.globalHeader{
  background-size:cover;
}

.profileHeader {
  overflow: hidden;
  header {
    &.bar-nav {
      position: relative;
      height: auto;
    }
    .bg-overlay {
      width: 100vw;
      height: 100vh;
      background-color: #000;
      opacity: .4;
      position: absolute;
      z-index: -1;
    }
    .top-links {
      overflow: hidden;

      &.invisible {
        visibility: hidden;
      }

      * .fa-bell{
        transform: rotate(-25deg);
      }

    }
  }

  .user-profile-img {
    max-height: 100px;
    max-width: 100px;
  }
}

#notifications-badge {
  //background-color: $error-font-color;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: inline-block;
  position: relative;
  left: -15px;
  top: -9px;
  opacity: .8;  
}  


.user-info {
  // margin-top: 20px;
  padding-bottom: 10px;
  // white-space: nowrap;

  * h1{
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;        
  }

  .title {
    
  }
}

.info {
  color: #fff;
  text-align: center;
  padding-bottom: 15px;

  #points {
    font-weight: 100;
    opacity: .6;
    background-color: $primary-color;
    // display: inline-block;
    border-radius: 25px;
    padding: 5px;

    span.pt-num {
      font-size: 30px;
    }

  }
}
  
