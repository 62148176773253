@import "../../assets/styles/globals.scss";

.imageContainer {
  .image {
    width: 100%;
    display: block;
    object-fit: contain;
  }

  .eventsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $light-gray;
    padding-left: 8rem;
    padding-right: 12rem;
    overflow-y: auto;
    background-color: $off-white;

    .title {
      font-size: 20px;
      padding-top: 5rem;
      padding-bottom: 5rem;
      font-weight: bold;
      color: $dark-navy;
    }
  }

  .placeHolder {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: $light-gray;

    i {
      font-size: 100px;
      color: $light-font-color;
    }
  }

  .overlay {
    height: 2.5rem;
    background-color: $light-navy;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;

    .overlay_text {
      color: white;
      font-weight: bold;
      margin-left: 20px;
    }

    .viewMore {
      font-weight: 800;
      background-color: transparent !important;
      border: none !important;
      color: white;
    }

    .viewMore:hover {
      text-decoration: underline;
    }
    .viewMore:active {
      text-decoration: underline;
      background-color: transparent !important;
    }
  }
}
