@import "../../../assets/styles/globals";

#CommunityStageForm {
  h5 {
    font-weight: 550;
  }

  input {
    background-color: $white-font-color !important;

    &:disabled {
      color: $white-font-color !important;
    }
  }
}
