@import "../../../../assets/styles/globals";

#DrillTagsAndVisibility {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  p {
    margin: 0;
  }
  h4 {
    font-weight: bold;
  }
  .orange-text {
    color: $primary-orange;
  }
  .content-container {
    width: 90vw;
    max-width: 800px;
    padding: 18px;
    // display: flex;
    // justify-content: center;
    .selected-tag-container {
      display: flex;
      flex-wrap: wrap;
      .shown-tag {
        background: #5f6f7c;
        color: #fff;
        border-radius: 500px;
        // box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
        margin: 4px 9px;
        // width: 154px;
        text-align: center;
        padding: 0 10px 1px 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .type-picker-container {
      display: flex;
      border-bottom: 2px solid rgba(95, 111, 124, 0.5);
      .type-container {
        flex: 1 1;
        p {
          font-weight: bold;
          font-size: 16px;
          text-align: center;
          line-height: 3;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            color: $primary-orange;
          }
        }
        .bottom-bar {
          height: 6px;
          background-color: transparent;
          position: relative;
          bottom: -2px;
          border-radius: 500px;
          transition: background-color 0.3s;
        }
        .active {
          background-color: $primary-orange;
        }
      }
    }
    .tag-picker-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: 36px;
      .tag-pill {
        background: #5f6f7c;
        color: #fff;
        border-radius: 500px;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
        margin: 4px 9px;
        width: 154px;
        text-align: center;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
      .dull {
        opacity: 0.5;
        box-shadow: none;
        &:hover {
          transform: scale(1);
        }
      }
    }
    .visibility-container {
      background: #f4f4f5;
      border-radius: 8px;
      display: flex;
      padding: 24px 45px;
      justify-content: space-between;
      .visibility-card {
        flex: 0 0 48%;
        padding: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        background: #fff;
        border: 3px solid #fff;
        // p,
        // h4 {
        color: rgb(29, 41, 25);
        // }
        transition: all 0.5s;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        &:hover {
          // background: rgba(95, 111, 124, 0.2);
          // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          cursor: pointer;
        }
        .visibility-icon {
          width: 100%;
          min-width: 96px;
          flex: 0 0 60px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin-bottom: 10px;
          filter: none;
          transition: all 0.5s;
        }
      }
      .selected1 {
        // background: $primary-orange;
        border: 3px solid $primary-orange;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // color: #fff;
        // .visibility-icon {
        //   filter: brightness(0) invert(1);
        // }
      }
    }
    .custom-tag-flow-container {
      width: 100%;
      .tag-input-container {
        display: flex;
        border-bottom: 2px solid rgba(95, 111, 124, 0.5);
        align-items: center;
        i {
          font-size: 24px;
          margin: 0 6px;
          color: rgba(95, 111, 124, 0.5);
        }
        input {
          border: none !important;
          color: inherit !important;
          font-size: 16px;
          font-weight: normal;
          flex: 1 1;
        }
      }
    }
  }
}
