@import "../../../../assets/styles/globals";

#LessonReviewPublish {
  height: 100%;
  width: 100%;
  display: flex;
  .lesson-review-sidebar-container {
    flex: 0 0 300px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    .lesson-review-photo {
      flex: 0 0 165px;
      background-color: #5f6f7c;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .lesson-review-photo-placeholder {
      flex: 0 0 165px;
      background: $primary-orange;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      .lesson-plan-default-icon {
        height: 108px;
        flex: 0 0 108px;
        background-position: center;
        background-size: cover;
      }
    }
    .lesson-review-title-container {
      background: #fff;
      padding: 10px 15px 15px 25px;
      p {
        margin: 0;
      }
      .lesson-review-title {
        display: flex;
        justify-content: space-between;
        .lesson-reivew-title-text {
          font-size: 24px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .lesson-info-button {
          border: 2px solid #212529;
          height: 24px;
          flex: 0 0 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            cursor: pointer;
            color: #6f7a85;
            border-color: #6f7a85;
          }
        }
      }
      .lesson-review-session-count-container {
        color: $primary-orange;
        display: flex;
        align-items: center;
        .calendar-icon {
          height: 24px;
          flex: 0 0 24px;
          background-size: cover;
          background-position: center;
          margin-right: 6px;
        }
        .lesson-review-session-count {
          font-size: 24px;
          font-weight: bold;
          margin-right: 6px;
        }
        .video-indicator {
          margin-left: auto;
          color: #0e7cac;
          font-size: 24px;
          &:hover {
            cursor: pointer;
            color: #34ace0;
          }
        }
      }
      .resource-button-link {
        // flex: 0 0 80%;
        margin-top: 3px;
        width: 100%;
        background: $primary-orange;
        border: none;
        border-radius: 500px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }
    .lesson-review-sidebar {
      flex: 1 1;
      background: #36434e;
      color: #fff;
      overflow-y: auto;
      .lesson-review-sidebar-session {
        padding: 18px 16px 18px 26px;
        &:hover {
          cursor: pointer;
          background: #5f6f7c;
        }
        .lesson-review-sidebar-session-name {
          margin: 0;
          font-size: 24px;
          font-weight: bold;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .lesson-review-active-session {
        background: #5f6f7c;
      }
    }
    .lesson-review-sidebar-edit-container {
      flex: 0 0;
      background: #36434e;
      padding: 10px 26px 18px 26px;
      .jump-to-edit-button {
        background: $primary-orange;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 145px;
        height: 38px;
        border-radius: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .lesson-review-content-container {
    // flex: 1 1;
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    background: #c4c4c4;
    // background: #5f6f7c;
    .lesson-review-content {
      height: 100%;
      width: 90%;
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
      background: #fff;
      display: flex;
      .lesson-review-block-section {
        flex: 0 0 66.6%;
        max-width: 66.6%;
        display: flex;
        flex-direction: column;
        .lesson-review-session-title-detail {
          flex: 0 0;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          padding: 35px 36px 18px 36px;
          max-width: 100%;
          p {
            margin: 0;
            line-height: 1;
          }
          .detail-session-title {
            font-size: 24px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 18px;
            max-width: 100%;
          }
          .session-detail-box {
            display: flex;
            align-items: center;
            color: $primary-orange;
            .clock-icon,
            .block-icon {
              height: 24px;
              flex: 0 0 24px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              margin-right: 6px;
            }
            .review-session-detail-text {
              margin-right: 10px;
            }
            .session-notes-trigger {
              margin-left: auto;
              font-size: 28px;
              color: #0e7cac;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        .review-session-block-view {
          flex: 1 1;
          width: 100%;
          padding: 18px 36px;
          overflow-y: auto;
          max-width: 100%;
        }
      }
      .grow {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .lesson-review-notes-section {
        flex: 0 0 33.3%;
        background: #f4f4f5;
        padding: 36px;
        min-width: 200px;
        overflow-y: auto;
        .session-notes-title {
          font-size: 18px;
          font-weight: bold;
        }
        .review-session-notes-placeholder {
          color: #c4c4c4;
          p {
            text-align: center;
            span {
              display: inline-block;
              height: 1rem;
              width: 1rem;
              background-size: cover;
              background-position: center;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  .grey-out {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: #37434e;
    opacity: 0.8;
    z-index: 99;
  }
  .review-info-modal {
    position: fixed;
    // height: 250px;
    width: 500px;
    max-height: 75vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 8px;
    top: 10vh;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 25px 35px 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    // display: flex;
    // flex-direction: column;
    z-index: 100;
    .exit-icon-container {
      display: flex;
      justify-content: flex-end;
      i {
        padding: 5px;
        font-size: 24px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .review-info-modal-title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .review-info-modal-subtitle {
      font-size: 17px;
      font-weight: bold;
      margin: 0;
    }

    .lp-info-placeholder-text {
      color: #c4c4c4;
    }

    .reivew-info-modal-tag-container {
      display: flex;
      flex-wrap: wrap;
      .tag-pill {
        background: #5f6f7c;
        border-radius: 500px;
        padding: 0 10px;
        margin-right: 8px;
        color: #fff;
        margin-bottom: 8px;
      }
    }
    .edit-basic-detail-button-container {
      display: flex;
      justify-content: center;
      .edit-basic-detail-button {
        background: $primary-orange;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 200px;
        height: 38px;
        border-radius: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
