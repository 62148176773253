@import "../../../../assets/styles/globals";

#ColumnPaymentStatusSelect {
  height: 44px;

  .payment-status-select {

    .payment-status-selected-value {
      height: 45px;
      overflow: hidden;

      p {
        margin-bottom: 0;
      }

      #stripeIcon {
        circle {
          fill: $primary-purple;
        }
        width: 21px;
        height: 21px;
        margin-top: -2px;
      }
    }
  }
}

#ColumnPaymentStatusSelect.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.payment-status-option {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: darken(white, 10%) !important;
  }
}