@import '../../../assets/styles/globals.scss';

.exitModal {
  margin: auto !important;
  width: 550px !important;
  height: 435px !important;
  padding: 24px !important;
  border-radius: 8px !important;
  background: white !important;

  .modalContainer {
    .modalContent {
      width: 90%;
      text-align: center;
      p {
        margin-bottom: 0;
        font-weight: 600;
        white-space: pre-wrap;
        justify-content: start;
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        color: $primary-orange;
        margin-left: 15px;
        padding-block: 10px;
      }
    }
  }
}