@import "../../assets/styles/globals";

#DeleteConfirmationModal {
  position: fixed;
  height: 250px;
  width: 350px;
  top: 150px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 10;
  background: silver;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  background: #fff;
  .top-icon {
    font-size: 90px;
  }
  p {
    text-align: center;
  }
  .close-modal {
    background: transparent;
    border-radius: 500px;
    width: 110px;
    color: $primary-orange;
    border: 2px solid $primary-orange;
    &:hover {
      background: $primary-orange;
      color: #fff;
    }
  }
  .button-area {
    button {
      background: transparent;
      border-radius: 500px;
      width: 110px;
    }
    .never-mind {
      color: #2488f7;
      border: 2px solid #2488f7;
      margin-right: 10px;
      &:hover {
        background: #2488f7;
        color: #fff;
      }
    }
    .kill {
      color: #dc3446;
      border: 2px solid #dc3446;
      &:hover {
        background: #dc3446;
        color: #fff;
      }
    }
  }
}
