@import "../../assets/styles/globals.scss";

.container {
  padding: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-between;

  .announcementIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 3px #f63;
    height: 40px;
    width: 40px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
  }

  .coachAvatarContainer {
    display: flex;
    gap: 20px;
  }

  .profileInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .coachTitle {
      color: $secondary-color;
      font-size: 12px;
    }

    .coachNameContainer {
      display: flex;
      align-items: center;

      .coachName {
        margin-bottom: 0px;
      }

      .coachPill {
        display: flex;
        align-items: center;
        margin-left: 10px;
        background-color: $secondary-color;
        border-radius: 500vh;
        padding: 2px 10px;
        color: $white-font-color;
        font-size: 12px;
      }
    }
  }
}
