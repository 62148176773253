@import "../../../assets/styles/globals";

.mainCard {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  background-color: $dark-navy;
  box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);

  @media (min-width: 768px) {
    height: 80%;
    width: 70%;
    border-radius: 8px;
  }

  @media (min-width: 1200px) {
    height: 80%;
    width: 50%;
    border-radius: 8px;
  }
}
