@import "../../../assets/styles/globals";

.pageFooterContainer {
  width: 100%;
  height: 60px;
  bottom: 0px;
  z-index: 999;
  background-color: #fff;
  border-top: 1px solid $light-gray;

  .footerButtonsContainer {
    padding-right: 75px;

    p {
      margin-bottom: 0;
      color: $dark-gray;
    }

    .savedScores {
      color: $dark-teal;
    }
  }

  .btn {
    min-width: 150px;
    background-color: $primary-orange;
    border-radius: 500px !important;

    transition: all 0.2s ease-in-out;

    &:focus {
      outline:none;
      box-shadow: none;
    }

    &:hover {
      transform: scale(0.9);
    }
  }

  .btnDisabled {
    cursor: default;
    background-color: $dark-gray;
    transition: none;

    &:hover {
      transform: none !important;
    }
  }

  .infoTooltip {
    background-color: white !important;
    opacity: 1 !important;
    border-color: $icon-gray !important;
    border-radius: 10px !important;
    color: $light-navy;
    width: 400px;
    font-size: 16px;

    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
}
