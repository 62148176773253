@import "../../../../assets/styles/globals";

.package-card {
  font-size: 0.9rem;
  border: 1px solid $border-darker-gray;
  border-radius: 8px;
  padding: 18px;
  line-height: 1.2;

  @media (min-width: 576px) {
    font-size: 1rem;
  }

  .name {
    font-size: 1.3em;
    font-weight: 800;
    word-break: break-word;
  }

  .price {
    font-size: 1.3em;
    font-weight: 800;
    color: $primary-orange;
  }

  .description {
    word-break: break-word;
  }

  &.no-border {
    padding: 0;
    border: 0;
    margin-left: 18px;

    .name, .price {
      font-size: 1em;
    }

    .description {
      font-size: 0.85em;
    }
  }
}
