#DrillReviewAndPublish {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  .page-content-container {
    max-width: 1000px;
    display: flex;
    padding: 18px;
  }
}
