@import "../../assets/styles/globals";

#UserRow {
  padding: 15px 0px;
  margin: 0px;
  background-color: $off-white;
  color: $dark-navy;
  border: 0;
  border-bottom: 1px solid $border-darker-gray;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  p {
    margin: 0px;
  }

  .smaller-round-image-wrapper {
    margin-right: 15px;
  }

  &:hover {
    background-color: darken($off-white, 10%);
  }
}
