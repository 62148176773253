@import "../../globals";

.email-template-row {
  padding-bottom: 100px;
}

.message-template-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  margin-top: 50px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: $secondary-color;
  }

  .message-template-icon {
    font-size: 80px;
    margin-bottom: 15px;
    color: $primary-color;
  }

  .message-template-title {
    font-size: 35px;
    color: $primary-color;
  }

  .message-template-desc {
    color: $primary-color;
    font-size: 17px;
  }
}

.active-template-card {
  background-color: $primary-color !important;
  border-color: $primary-color !important;

  .message-template-icon {
    color: white;
  }

  .message-template-title {
    color: white;
  }

  .message-template-desc {
    color: white;
  }
}

.preview-community-logo {
  height: 70px;
  width: 70px;
  border: 2px solid $secondary-color;
  border-radius: 50%;
  background-size: contain;
}

.message-page-header {
  z-index: 999999 !important;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);

  h1 {
    font-size: 55px;
  }

  h4 {
    color: $primary-color;
  }
}

.recipient-delete-icon {
  color: #d9534f;
}

.add-recipient-button {
  background-color: transparent !important;
  border: none !important;

  .add-recipient-icon {
    color: $secondary-color;
    font-size: 20px;
  }
}

.recipient-container {
  margin-top: 36px;
}

.recipient-list-wrapper {
  height: 430px;
  overflow: scroll;
}

.react-tabs__tab-panel--selected {
  display: block
}

.search-result-container {
  .toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;

    p {
      margin-bottom: 0px;
      margin-left: 10px;
    }

    i {
      font-size: 23px;
    }
  }

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .header-title {
      margin-left: 30px;
      font-size: 38px;
    }

    .search-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        font-size: 20px;
      }

      .search-icon {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .switchButton {
    width: 30%;
    height: fit-content;
    border-radius: 100px;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    font-size: 0.9rem;
    padding: 0;
    background-color: transparent;

    .switchOption {
      width: 50%;
      border: none;
      padding: 0.25em 0;
      border-radius: 30px;
      user-select: none;

      &.switchSelected {
        color: white;
        background-color: $primary-orange;
      }
    }
  }

  .campaign-result-container {
    height: 340px;
    overflow: scroll;
  }
}

// #### Responsive Breakpoints ####
// Mobile-First

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #CampaignPage {
    * .fixed-bottom {
      // Sidebar offset
      width: calc(100vw - $sidebar-width);
      // width: calc(100vw - $sidebar-width - 100px); // Why "-100px"?
      margin-left: $sidebar-width;
      box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.2);
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
