#MilestoneModal {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 500px;
  width: 400px;
  background-color: #fff;
  z-index: 999999;
  padding: 15px;
  border-radius: 10px;

  i:hover {
    cursor: pointer;
  }

  .move-left,
  .move-right {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 150px;
    color: #ccc;
    font-size: 100px;
  }

  .move-left {
    left: -55px;
  }

  .unclickable:hover {
    cursor: not-allowed;
  }

  .first-row-buttons {
    margin-top: 20px;
    position: relative;
    top: 8px;
  }

  .milestone-animated {
    animation: largeIcon 2s 1;

    .hexagon {
      animation-delay: 2s;
      animation: hexColor 4s 1, hexShadow 2s infinite;
      // animation: hexShadow 2s infinite;
    }

    .hexagon-before {
      animation-delay: 2s;
      animation: hexBeforeColor 4s 1, hexBeforeShadow 2s infinite;
      // animation: hexBeforeShadow 2s infinite;
    }

    .hexagon-after {
      animation-delay: 2s;
      animation: hexAfterColor 4s 1, hexAfterShadow 2s infinite;
      // animation: hexAfterShadow 2s infinite;
    }

    .icon-container {
      img {
        // animation-delay: 2s;
        animation: iconGrow 2s 1;
      }
    }
  }

  .small-nav-button {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 2px solid #5f6f7c;
    background: #fff;
    margin: 3px 7px 7px 0;
    &:hover {
      cursor: pointer;
    }
  }

  .filled {
    background: #5f6f7c;
  }

  .move-right {
    right: -120px;
  }

  .badge-container {
    margin-left: 34%;
  }
  .progress-container {
    height: 5px;
  }
  .progress-bar-1,
  .progress-bar-6 {
    background-color: #ff6633;
  }
  .progress-bar-2,
  .progress-bar-7 {
    background-color: #fddb10;
  }
  .progress-bar-3,
  .progress-bar-8 {
    background-color: #d5de27;
  }
  .progress-bar-4,
  .progress-bar-9 {
    background-color: #2baae1;
  }
  .progress-bar-5,
  .progress-bar-10 {
    background-color: #b51e8c;
  }

  .small-milestones {
    padding: 0 65px;
    position: relative;
    top: -45px;
  }

  .circle-container {
    background: red;
  }

  .fifty,
  .fortyfive,
  .thirtynine,
  .thirtysix {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -12px;
  }

  .fifty {
    left: 50px;
  }
  .fortyfive {
    left: 130px;
  }
  .thirtynine {
    left: 210px;
  }
  .thirtysix {
    left: 290px;
  }

  .subtext {
    font-size: 0.9rem;
    margin-bottom: 3px;
  }

  .milestone-time-stamp {
    font-size: 0.8rem;
    margin-top: 5px;
    font-style: italic;
    color: #bbb;
  }

  .small-button-area {
    margin-top: 10px;
  }

  .is-done {
    position: relative;
    top: -22px;
  }

  @keyframes largeIcon {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes hexColor {
    0% {
      border-left: solid 9px #f4f4f5;
      border-right: solid 9px #f4f4f5;
    }
  }

  @keyframes hexShadow {
    0% {
      box-shadow: "";
    }
    50% {
      box-shadow: 9px 5px 9px #fffdaf, 9px -5px 9px #fffdaf,
        -9px 5px 9px #fffdaf, -9px -5px 9px #fffdaf;
    }
    100% {
      box-shadow: "";
    }
  }

  @keyframes hexBeforeShadow {
    0% {
      box-shadow: "";
    }
    50% {
      box-shadow: 10px -10px 10px #fffdaf;
    }
    100% {
      box-shadow: "";
    }
  }

  @keyframes hexAfterShadow {
    0% {
      box-shadow: "";
    }
    50% {
      box-shadow: -10px 10px 10px #fffdaf;
    }
    100% {
      box-shadow: "";
    }
  }

  @keyframes hexBeforeColor {
    0% {
      border-top: solid 12.7279px #f4f4f5;
      border-right: solid 12.7279px #f4f4f5;
    }
  }

  @keyframes hexAfterColor {
    0% {
      border-bottom: solid 12.7279px #f4f4f5;
      border-left: solid 12.7279px #f4f4f5;
    }
  }

  @keyframes iconGrow {
    0% {
      height: 0;
      width: 0;
    }
  }
}
