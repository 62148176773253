@import "../../assets/styles/globals";

#ErrorPage {
  .header {
    background-color: $dark-navy;

    .op36-logo {
      width: 250px;
      padding: 18px;
    }
  }

  .main-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100vh;
    margin: auto;
  }

  .error-icon {
    color: $primary-orange;
    font-size: 200px;
  }

  .error-title {
    margin-top: 1rem;
    color: $primary-color;
    font-weight: 700;
    font-size: 48px;
  }

  .error-text {
    font-size: 32px;
    text-align: center;
  }

  .error-message-box {
    color: $primary-color;
    margin-top: 10px;
    padding: 30px;
    background-color: $off-white;
    border-radius: 20px;
    text-align: center;
    width: 50%;

    .error-primary-text {
      font-size: 24px;
    }

    .error-secondary-text {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .main-button {
    margin-top: 25px;
    width: 25%;
  }
}
