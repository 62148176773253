@import "../../../assets/styles/globals";

.accountNavigation {
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 30px;

    a {
      font-weight: 800;

      &:not(.active) {
        color: $dark-navy;
      }

      &:not(.active):hover {
        color: $light-navy;
      }
    }
  }
}