@import "../../../../assets/styles/globals";

.program-actions-card {
  overflow-y: auto;
  background-color: white;
  color: $dark-navy;

  @media (min-width: 992px) {
    border-radius: 10px;
    border: 1px solid $border-gray;
  }

  .video-wrapper {
    flex-shrink: 0;

    iframe {
      width: 100%;
      height: 250px;
      overflow-y: hidden;
      border-radius: 10px;
    }
  }

  .light-navy-border {
    @include bordered($light-navy)
  }

  .program-community-name {
    color: $dark-navy;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    fill: $dark-navy;
    flex-shrink: 0;
  }

  .program-spots-left {
    margin: 0;
    border-bottom: 1px solid $border-darker-gray;
    font-weight: bold;
    font-size: 1.5rem;

    @media (min-width: 992px) {
      border: none;
    }

    .open-spots-number {
      font-size: 2rem;
    }
  }

  .program-contact-share-actions {
    border-bottom: 1px solid $border-darker-gray;

    @media (min-width: 992px) {
      border: none;
    }

    .program-action {
      padding: 0;
      background-color: transparent;
      border: 0;
      color: inherit;
      font-weight: bold;
    }
  }
}
