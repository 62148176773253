// @import "node_modules/react-image-gallery/styles/scss/image-gallery.scss"; // TODO: Only css imported, no js
@import "../../../assets/styles/globals";

.uploading-images-text {
  text-transform: uppercase;
}

.goal-progressbar {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 35px;

  .progress-bar {
    background-color: #ff6633;
  }
}

.card-bg {
  background-image: none !important;
  background-color: #2b3843;
}

.dropdown-rank-toggle {
  margin: 0px !important;
  width: 100% !important;
  font-size: 22px !important;
  height: 60px;
  margin-top: 0px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #2b3843 !important;

  .goal-rank-icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .goal-rank-progress {
      margin-right: 20px;
      width: 300px;
    }
  }
}

.dropdown-rank-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.goal-objective-container {
  background-color: white !important;
  padding: 15px;
  border-bottom: 1px solid lightgrey;

  .goal-objective-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .goal-objective-name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .progress {
        margin-bottom: 5px;
        margin-left: 40px;
        width: 340px;
      }

      .progress-bar {
      }

      .goal-objective-subject-wrapper {
        margin-left: 15px;
        background-color: #2b3843;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        border-radius: 50vh;
        margin-top: -10px;

        .goal-objective-subject {
          font-size: 13px;
          color: white;
          margin-bottom: 0px;
        }
      }
    }

    .goal-objective-description-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .goal-objective-desc {
      max-width: 860px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .goal-objective-btn-wrapper {
      padding-right: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .goal-objective-btn {
        margin-top: 0px;
        margin-left: 25px;
        background-color: #33d9b2;
        border: none;
        padding-top: 0px 0px;
        height: 40px;
      }

      .goal-objective-next-btn {
        margin-top: 8px;
        margin-left: 25px;
        color: #2b3843;
        font-size: 30px;
        background-color: transparent;
        border: none;
      }
    }

    .goal-objective-nav-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .goal-objective-btn-wrapper {
        padding-right: 30px;
        display: flex;
        flex-direction: row;

        .goal-objective-btn {
          margin-top: 0px;
          margin-left: 25px;
          margin-bottom: 5px;
          background-color: #33d9b2;
        }
      }

      .goal-objective-view-wrapper {
        background-color: transparent !important;
        font-size: 30px;

        .goal-objective-view {
          color: #2b3843;
          background-color: transparent !important;
          border: none !important;
          margin-left: 30px;
        }

        .goal-objective-completed {
          margin-top: 0px !important;
        }
      }
    }
  }

  .goal-objective-image-container {
    height: 70px !important;
    width: 70px !important;
    border-radius: 50%;
    margin-right: 25px;

    .goal-objective-image {
      height: 100%;
      padding: 10px;
    }

    .goal-objective-icon {
      padding: 10px;
      font-size: 45px;
      color: white;
    }
  }
}

.goal-objective-private-image-container {
  min-height: 70px !important;
  min-width: 70px !important;
  border-radius: 50%;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .goal-objective-icon {
    padding: 10px;
    font-size: 45px;
    color: white;
  }
}

.goal-objective-active-icon {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  border-radius: 50vh;

  .goal-objective-active-text {
    color: #2b3843;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    margin: 0;
    font-weight: 525;
  }
}

.op36-curriculum-image-container {
  height: 75px !important;
  width: 75px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  margin: 0 auto !important;
  margin-top: 20px !important;

  .op36-curriculum-image {
    width: 45px;
    height: auto;
    padding: 0px !important;
  }
}

.objective-level-up-button {
  width: 120px;
  margin-top: 0px;
}

.objective-view-header-wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .objective-view-name {
    margin-bottom: 0px;
  }
}

.objective-view-points {
  color: #ff6633;
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: 30px;
}

.objective-view-points-icon {
  color: #ff6633;
  margin-left: 50px;
  font-size: 25px;
}

.objective-view-desc-wrapper {
  margin-top: 30px;
  margin-bottom: 50px;

  .objective-view-desc-title {
    text-transform: uppercase;
  }

  .objective-btn-link {
    margin-right: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.back-curriculms-btn {
  background-color: transparent !important;
  border: none !important;

  .back-curriculms-icon {
    color: #ff6633;
    font-size: 25px;
  }
}

.op36-no-goal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -25px;

  .op36-no-goal-image {
    width: 47%;
    height: auto;
    margin: 0 auto;
  }

  .op36-no-goal-text {
    text-align: center;
    text-transform: uppercase;
    color: #cacaca;
    margin-top: -15px;
  }

  .op36-no-goal-btn {
    width: 40%;
    margin: 0 auto;
    margin-top: 17px;
  }
}

.goal-task-container {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;

  .goal-task-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .goal-task-input {
      padding: 8px;
    }

    .goal-task-index {
      margin-top: 8px;
      margin-right: 10px;
    }
  }

  .goal-completion-text {
    text-align: right;
    margin-top: -10px;
    color: lightgrey;
    font-size: 14px;
  }
}

.goal-card-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-goal-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.custom-goal-edit-btn {
  background-color: transparent !important;
  border: none !important;
  position: absolute;
  right: 5px;
  top: -10px;
  margin-top: 20px !important;

  .custom-goal-edit-icon {
    color: white;
  }
}
.custom-goal-text-row {
  margin-top: 10px;
  width: 100% !important;
  margin-right: 0px !important;
  display: flex;
  flex-direction: row;
}

.custom-goal-text {
  color: white;
  margin-bottom: 8px;
  font-size: 12px;
}

.custom-goal-text-aside {
  color: white;
  margin-bottom: 8px;
  font-size: 12px;
}

.custom-goal-description {
  color: white !important;
}

.custom-goal-text-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;

  .custom-goal-text {
    font-weight: bold;
    margin-right: 10px;
  }
}

.custom-progress-bar-container {
  height: 280px;
}

.custom-goal-title {
  text-transform: uppercase;
  font-weight: 550;
  font-size: 22px;
}

.custom-goal-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-goal-day {
  color: white;
  margin-bottom: 0px;
  font-size: 25px;
}

.custom-goal-review-container {
  .review-wrapper {
    border-bottom: 1px solid lightgrey;
    padding: 15px;

    .review-desc-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .review-image-wrapper {
        height: 50px;
        width: 50px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          border: 1px solid grey;
        }

        .review-complete-icon {
          font-size: 55px;
          margin: 0 auto;
          color: #33d9b2;
        }

        .review-objective-icon {
          font-size: 55px;
          margin: 0 auto;
          color: #ff6633;
        }
      }
    }

    .review-note-wrapper {
      margin-left: 25px;
      margin-top: 15px;

      .review-note {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .review-description-wrapper {
      display: flex;
      flex-direction: row;
      margin-left: 25px;
      margin-top: 15px;

      .review-description-complete-icon {
        color: #ff6633;
        margin-top: 5px;
      }

      .review-objective {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 7px;
      }
    }

    .review-title {
      font-weight: 550;
      margin-bottom: 0px;
    }

    .review-view-icon {
      position: absolute;
      color: #ff6633;
      right: 60px;
    }

    .review-trash-icon {
      position: absolute;
      color: #ff6633;
      right: 30px;
    }

    .review-image-icon {
      position: absolute;
      color: lightgrey;
      right: 90px;
    }

    .review-date {
      margin-bottom: 0px;
    }
  }
}

.custom-goal-button-wrapper {
  display: flex;
  flex-direction: row;
  border-top: 1px solid lightgrey;

  .custom-goal-btn {
    margin: 10px !important;
    margin-top: 25px !important;
  }
}

.goal-tasks-container {
  display: block;
  .goal-task-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    margin-top: 5px;

    .goal-task-check {
      color: lightgrey;
      margin-right: 10px;
      margin-top: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .goal-task-check-active {
      color: #ff6633;
      margin-right: 10px;
      margin-top: 5px;

      &:hover {
        cursor: not-allowed;
      }
    }

    .goal-task-text {
      margin-bottom: 0px;
    }

    .goal-task-input {
      margin-right: 10px;
    }
  }
}

.review-modal-title {
  font-size: 30px;
  font-weight: 550;
}

.review-modal-objective-wrapper {
  display: flex;
  flex-direction: row;

  .review-modal-objective {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .review-modal-icon {
    color: #ff6633;
    font-size: 18px;
    margin-right: 8px;
    margin-top: 5px;
  }
}

.review-modal-no-objective {
  margin-bottom: 0px;
  font-size: 18px;
  color: lightgrey;
  text-transform: lowercase;
  margin-left: 25px;
}

.goal-objective-percentage-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .goal-percentage-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .goal-percentage-desc {
      color: lightgrey;
      text-transform: lowercase;
      font-size: 14px;
      margin-bottom: 0px;
      margin-right: 15px;
    }

    .goal-percentage-tracker {
      background-color: #2b3843;
      border-radius: 50vh;
      padding: 2px 15px;
      color: white;
    }
  }
}

.curriculum-header {
  margin-bottom: -33px !important;
}

.custom-goal-container {
  margin-top: -40px;
}

.custom-goal-header {
  width: 100%;
  margin: 0 auto;
  background-color: #67747e !important;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 8px;

  .custom-goal-header-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .custom-goal-header-info {
      width: 40%;
      margin-top: 0px;

      .progress {
        margin-top: 35px;
      }
    }
  }

  .custom-goal-header-wrapper {
    margin-left: 30px;
    margin-top: 15px;
    padding-bottom: 25px;
    width: 50%;
    display: flex;
    flex-direction: column;

    .custom-goal-header-title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 0;
      padding-bottom: 0;
    }

    .custom-goal-header-title {
      color: white;
      font-weight: 550;
      font-size: 30px;
      margin-right: 15px;
      margin-top: 15px;
      text-transform: uppercase;
    }

    .custom-goal-header-icon {
      color: #33d9b2;
      font-size: 45px;
    }

    .custom-goal-header-desc {
      margin-bottom: 0px;
      color: white;
    }
  }
}

.goal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 250px;
  margin-bottom: 20px;

  .goal-image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }

  #inner-box {
    background: black;
    height: 250px;
    width: 95%;
    margin: 0 auto;
    opacity: 0;
    top: 0;
    position: absolute;
    padding: 0;
    transition: opacity 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: white;
      margin: 0 auto;
      font-size: 45px;
    }
  }

  &:hover {
    cursor: pointer;

    #inner-box {
      opacity: 0.7;
      transition: opacity 0.5s;
    }
  }
}

.goal-overview {
  font-size: 20px;
  font-weight: 550;
}

.op36-curriculum-title {
  text-transform: uppercase;
}

.current-rank-row {
  .current-rank {
    color: #ff6633;
    font-size: 22px;
    margin-left: 17px;
    text-transform: uppercase;
  }
}

.private-goal-toggle {
  cursor: default !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #67747e !important;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  .goal-switch-button-wrapper {
    .goal-switch-button {
      margin-top: 0px;

      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .active-goal-button {
      background-color: #ff6633;
      border-color: #ff6633;
    }
  }
}

.custom-goal-switcher {
  position: absolute;
  right: 20px;
  top: 140px;
}

.coachClassCard {
  padding: 0px !important;
  margin-top: 20px;
  border-radius: 8px !important;
  overflow: hidden;

  h1 {
    color: $white-font-color;
    text-align: center;
    font-weight: 550;
  }

  h2 {
    color: $white-font-color;
    text-align: center;
  }
}

#GoalImage {
  margin-bottom: 15px;

  .goals-image {
    height: 169px;
    width: 300px;
    margin-top: -15px;
  }
}

.goal-modal-image {
  width: 100%;
}

.fileContainer {
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid lightgrey;
}

.image-gallery {
  margin-bottom: 25px;
}

.image-gallery-fullscreen-button {
  position: absolute !important;
  top: -200px !important;
  right: 0px !important;
}

.image-gallery-left-nav,
.image-gallery-right-nav,
.image-gallery-fullscreen-button {
  &:focus {
    outline: none !important;
    background-color: transparent !important;
  }

  &:before {
    color: white !important;
  }
}

.image-gallery-swipe {
  background-color: transparent !important;
}

.fullscreen-modal {
  width: 100% !important;
  height: 100vh !important;
  z-index: 9999999 !important;
  background-color: rgba(255, 0, 0, 0.3) !important;

  .image-gallery-slide {
    background-color: transparent !important;
  }

  .image-gallery-image {
    margin: 0 auto !important;
    width: 30% !important;
    height: auto !important;
  }
}

.image-gallery {
  max-height: 400px;

  img {
    max-height: 400px;
    object-fit: contain;
  }

  .video-wrapper {
    position: relative !important;
    padding-bottom: 56.25% !important; /* 16:9 */
    padding-top: 25px !important;
    height: 0 !important;
    margin-top: 25px;
  }
}

.modal-goal-image-gallery {
  max-height: 800px;

  .video-wrapper {
    position: relative !important;
    padding-bottom: 56.25% !important; /* 16:9 */
    padding-top: 25px !important;
    height: 0 !important;
    margin-bottom: 50px;
  }

  img {
    max-height: 800px;
    object-fit: contain;
    margin-bottom: 50px;
  }
}

.image-gallery-modal-content {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.curriculum-container {
  margin-top: -40px;

  .curriculum-video-detail-container {
    position: relative;
    width: 100%;
    height: 320px;
    margin-top: -10px;

    .detail-video-wrapper {
      width: 560px;
      height: 320px;
      position: absolute;
      left: 50%;
      top: -30%;
      transform: translateX(-50%);

      .video-close-button {
        cursor: pointer;
        position: absolute;
        top: -10%;
        right: 0;
      }
    }
  }

  .curriculum-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .curriculum-header-wrapper {
      width: 100%;
    }
  }

  .curriculum-title-wrapper {
    width: 100% !important;
  }
}

.goal-delete-wrapper {
  margin-top: -40px;

  .goal-delete-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-color: #d9534f;

    .goal-delete-icon {
      color: #d9534f;
      margin-right: 10px;
    }

    .goal-delete-text {
      color: #d9534f;
      margin-bottom: 0px;
    }
  }
}

.curriculums-loading {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  top: -110px !important;
}

/*****
  x-small
  *****/
@media (max-width: 640px) {
  .custom-goal-header-wrapper {
    width: 70%;
    margin-left: 0px !important;
  }

  .private-goal-mobile {
    width: 100% !important;
    padding: 0 !important;
  }

  .goal-switch-button-wrapper {
    position: absolute !important;
    top: -50px;
    left: 16px;
  }

  .goal-objective-nav-wrapper {
    display: none;
  }

  .goal-objective-view-wrapper {
    .goal-objective-view {
      margin-left: 5px !important;
    }
  }

  .custom-goal-header-wrapper {
    width: 100% !important;

    .custom-goal-header-title {
      font-size: 20px !important;
    }

    .custom-goal-description {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .review-view-icon {
    margin-top: 25px;
  }

  .review-trash-icon {
    margin-top: 25px;
  }

  .review-image-icon {
    margin-top: 25px;
  }

  .review-title {
    font-size: 15px;
  }
  .goal-task-text {
    overflow-x: auto;
  }

  .custom-mobile-container {
    margin-top: 40px;
    margin-bottom: 100px;
  }

  .mobile-goal-switcher {
    margin-top: 105px;
  }

  .mobile-private-goals-container {
    margin-top: 70px;
  }
}

/*****
  small
  *****/
@media (max-width: 768px) {
  .custom-goal-header-wrapper {
    width: 70%;
  }

  .private-goal-mobile {
    width: 100% !important;
    padding: 0 !important;
  }

  .goal-switch-button-wrapper {
    position: absolute !important;
    top: -50px;
    left: 16px;
  }

  .goal-objective-nav-wrapper {
    display: none;
  }

  .goal-objective-view-wrapper {
    .goal-objective-view {
      margin-left: 5px !important;
    }
  }

  .custom-goal-header-wrapper {
    width: 100% !important;

    .custom-goal-header-title {
      font-size: 20px !important;
    }

    .custom-goal-description {
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .review-view-icon {
    margin-top: 25px;
  }

  .review-trash-icon {
    margin-top: 25px;
  }

  .review-image-icon {
    margin-top: 25px;
  }

  .review-title {
    font-size: 15px;
  }
  .goal-task-text {
    overflow-x: auto;
  }
}

/*****
  medium
  *****/
@media (max-width: 1024px) {
}

/*****
  large
  *****/
@media (max-width: 1200px) {
}
