.hover-bar {
  margin: auto;
  height: 5px;
  display: none;
}

.react-tabs__tab {
  padding: 6px 12px 0 12px;
  border: 0;

  &::after {
    display: none;
  }
}

.react-tabs__tab--selected {
  .hover-bar {
    display: block;
  }
}
