@import "../../../assets/styles/globals";

#ProgramDetailPage {
  .program-icon-wrapper {
    height: 150px;
    width: 150px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-gray;
    background-size: cover;
    background-position: center;
    i {
      font-size: 70px;
      color: $light-font-color;
    }

    // img {
    //   width: 100%;
    // }
  }
  .lp-icon-wrapper {
    background-color: $primary-orange;
    .lp-icon {
      background-size: cover;
      background-position: center;
      height: 80%;
      flex: 0 0 80%;
    }
  }

  .resource-container {
    margin-top: 25px;
    h5 {
      font-weight: 550;
      font-size: 24px;
    }
    h6 {
      font-weight: 550;
      font-size: 20px;
    }

    p {
      margin: 0px;
      font-size: 14px;
      color: $light-font-color;
    }
  }

  .program-info-container {
    .program-title-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .program-title-wrapper {
        h5 {
          margin: 0px;
          font-weight: 550;
          font-size: 25px;
        }

        .program-organization-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-start;

          i {
            font-size: 16px;
            color: $light-font-color;
            margin-right: 5px;
          }

          a {
            font-size: 16px;
            color: $light-font-color !important;
            margin: 0px;

            &:hover {
              color: $secondary-color !important;
            }
          }

          p {
            font-size: 16px;
            color: $light-font-color !important;
            margin: 0px;
          }
        }
      }
    }

    .program-button-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;

      button {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    .program-description {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
