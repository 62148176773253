@import "../globals";

.confirmation-loader-container {
  margin-top: 80px;

  .confirmation-loader-icon {
    color: #2b3843;
  }

  .confirmation-loader-text {
    color: #2b3843;
    text-transform: uppercase;
  }
}

.confirmation-modal-close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  margin-top: 0px;
  right: 0;

  &:focus {
    outline: none !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  .confirmation-modal-close-icon {
    color: black;
  }
}

.confirmation-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;

  .confirmation-title-container {
    display: flex;
    flex-direction: column;

    .confirmation-modal-icon {
      font-size: 75px;
      font-weight: 550;
      margin-left: 0px;
      text-align: center;
    }

    .confirmation-modal-title {
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 550;
    }
  }

  .confirmation-modal-message {
    word-break: break-word;
  }

  .confirmation-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 170px;

    .confirmation-proceed-button {
      width: 80px;
      background-color: #ff6633;
      border-color: #ff6633;
    }
    .confirmation-denied-button {
      width: 80px;
      background-color: transparent;
      border: none;
      color: #ff6633;
    }
  }
}

.confirmation-spinner {
  color: $white-font-color;
  font-size: 16px;
  margin-right: 5px;
}
