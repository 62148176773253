@import "../../assets/styles/globals";

#StudentCardContainer {
  border: 1px solid $light-gray !important;
  width: 48%;
  margin: auto;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $light-gray;
    cursor: pointer;
    transform: scale(0.95);
  }
  .student-card-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-left: 10px !important;
      text-transform: capitalize;
      color: $primary-color;
    }
  }
}

.selected {
  background-color: $secondary-color !important;
  border: 1px solid $secondary-color !important;
  p {
    color: white !important;
  }
}
