@import "../../../assets/styles/globals";

#CommunityStatCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  .stat-wrapper {
    background-color: $primary-color;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
    -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
    box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);

    .stat-number {
      font-size: 45px;
      color: $white-font-color !important;
      margin: 0px;
      font-weight: 550;
      margin-bottom: 15px;
    }

    .stat-name {
      text-align: center;
      font-size: 12px;
      color: $white-font-color !important;
      margin: 0px;
    }

    .icon-wrapper {
      position: absolute;
      top: 10px;
      right: 10px;

      i {
        font-size: 20px;
        color: $secondary-color;
      }
    }
  }
}
