@import "../../../assets/styles/globals";

#PackageForm {
  .form-title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .form-label {
    color: $dark-navy !important;
    font-size: 16px;
    font-weight: 700;
  }

  .character-counter {
    color: $light-navy;
    font-size: 14px;
  }
}
