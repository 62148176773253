@import "../../../assets/styles/globals";

.comm-leaderboard-display-area {
  display: flex;
  // width: 100vw;
  width: 100%;
  height: 100%;
  background-color: $off-white;
  grid-area: main-zone;
}

.comm-leaderboard-top-3 {
  display: flex;
  flex-flow: column;
  align-items: center;
  // width: 50vw;
  width: 50%;
  background-color: $light-navy;
  // padding: $primary-padding $primary-padding 0 $primary-padding;
  padding: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
  padding-bottom: 0px;
}

.comm-leaderboard-heading {
  color: white;
  text-align: center;
  font-weight: $bold-font-weight;
  // font-size: $heading-0-size;
  // margin-bottom: $primary-padding;
  font-size: calc($heading-0-size / 1px / $ideal-academy-display-width * 100vw);
  margin-bottom: calc($primary-padding / 1px / $ideal-academy-display-height * 100vh);
  line-height: 1;
}

.comm-leaderboard-desc {
  text-align: center;
  color: white;
  // font-size: $heading-2-size;
  // margin-bottom: 72px;
  font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
  margin-bottom: calc(72px / 1px / $ideal-academy-display-height * 100vh);
}

.comm-leaderboard-top-3-card-wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;

  // Separate the cards
  margin: 0 -9px;
}

.comm-leaderboard-top-3-card {
  background-color: white;
  // padding: $secondary-padding;
  padding: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1;
  border-radius: 1.875vw 1.875vw 0 0;

  // Separate the cards
  margin: 0 9px;

  p {
    color: $primary-orange;
  }
}

#comm-leaderboard-top-3-card-1 {
  margin-top: 0px;
}

#comm-leaderboard-top-3-card-2 {
  margin-top: 10vh;
}

#comm-leaderboard-top-3-card-3 {
  margin-top: 20vh;
}

.comm-leaderboard-top-3-card-pic {
  // width: 7.813vw; // 7.813% of 1920px width
  // height: 7.813vw; // 7.813% of 1920px width
  width: calc(150px / 1px / $ideal-academy-display-width * 100vw);
  height: calc(150px / 1px / $ideal-academy-display-width * 100vw);
  border-radius: 50%;
  border: 6px solid $primary-orange;
  object-fit: cover;
  margin-bottom: $secondary-padding;
}

.comm-leaderboard-top-3-card-name {
  font-weight: $bold-font-weight;
  margin-bottom: 0;
  // font-size: 1.875vw; // 1.875% of 1920px width
  // margin-bottom: $secondary-padding;
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
  // line-height: 1;
  line-height: 1.2;

  // Need these in case name is too long
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 9.792vw; // 188px/1920px width
}

.comm-leaderboard-top-3-card-points {
  // font-size: 1.875vw; // 1.875% of 1920px width
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  line-height: 1;
  // margin-bottom: $secondary-padding;
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
}

.comm-leaderboard-top-3-card-ordinal {
  background-color: $primary-orange;
  color: white;
  border-radius: 50%;
  // width: 3.646vw; // 3.646% of 1920px width
  // height: 3.646vw; // 3.646% of 1920px width
  width: calc(70px / 1px / $ideal-academy-display-width * 100vw);
  height: calc(70px / 1px / $ideal-academy-display-width * 100vw);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    margin: 0;
    font-weight: $bold-font-weight;
    // font-size: 1.875vw; // 1.875% of 1920px width
    font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  }
}

p.comm-leaderboard-no-data {
  font-weight: $bold-font-weight;
  color: $light-navy;
  // font-size: 1.25vw; // 1.25% of 1920vw
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
}

.comm-leaderboard-call-to-action {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // padding: $primary-padding;
  margin: auto 0;
}

.comm-leaderboard-call-to-action-text {
  color: $light-navy;
  // font-size: 1.25vw; // 1.25% of 1920vw
  font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
  line-height: 1;
  // margin-bottom: $secondary-padding;
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
  text-align: center;
}

.comm-leaderboard-call-to-action-icon {
  // margin: $secondary-padding;
  // width: 5vw; // 5% of 1920vw
  // height: 5vw; // 5% of 1920vw
  margin: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  width: calc(96px / 1px / $ideal-academy-display-width * 100vw);
  height: calc(96px / 1px / $ideal-academy-display-width * 100vw);
}

.comm-leaderboard-4-to-10 {
  // width: 50vw;
  width: 50%;
  // padding: $primary-padding;
  padding: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
}

#comm-leaderboard-10 {
  margin-bottom: 0px;
}
