@import "../../../../assets/styles/globals";

.recurring-sessions-builder-modal-content {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}


.modal-X-icon {
  cursor: pointer;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $dark-navy;
  margin-bottom: 16px;;
}

.label {
  color: $dark-navy !important;
  font-size: 16px;
  font-weight: 700;
}

.start-date-input {
  width: 50%;
}

.end-date-input {
  width: 50%;
}

.week-day {
  height: 35px;
  width: 35px;
  background-color: white;
  color: $dark-navy;
  font-weight: 700;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid $placeholder-gray;
  border-radius: 50%;

  &:hover, &:active {
    border: 1px solid $dark-navy;
    background-color: $dark-navy;
    color: white;
  }

  &.selected {
    border: 1px solid $dark-navy;
    background-color: $dark-navy;
    color: white;
  }
}

.error-input {
  border-color: $input-error-color !important;
}