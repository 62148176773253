@import "../../assets/styles/globals";

#CommunityForm {
  .error-input {
    border: 2px solid $input-error-color !important;
  }

  .textarea {
    resize: none;
    color: $dark-navy !important;
  }

  #photoInputArea {
    height: 160px;
    width: 180px;
    cursor: pointer;
    border: 1px solid $light-gray;

    .fa {
      font-size: 80px;
    }

    .header-photo {
      width: 80px;
      height: 80px;
      background-position: center center;
      background-size: cover;
      margin-top: 10px;
    }
  }

  .react-tel-input {
    width: 100%;

    .flag-dropdown {
      background-color: transparent;
      border: none;
    }

    input {
      height: 38px;
      padding-left: 50px;
      box-shadow: none !important;
    }

    button {
      border: none;
      background-color: transparent !important;
    }
  }

  .community-select {
    .Select-control {
      border-radius: 0px !important;

      .Select-input {
        input {
          border: none !important;
        }
      }
    }
  }

  .application-fee-type-field {
    label {
      cursor: pointer;
      font-size: 16px;
      margin-left: 8px;
      margin-bottom: 0;
    }
  }

  .application-fee-field-percent {
    display: inline-block;
    padding-right: 10px !important;
    height: 38px !important;
    color: $light-navy !important;
    font-size: 16px;
    border: 1px solid $light-gray;
    white-space: nowrap;

    &:focus-within {
      border-bottom: 2px solid $primary-color !important;
    }

    input {
      border: none !important;
      width: 45px;
      height: 38px;
      font-weight: 400;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .application-fee-field-currency {
    display: inline-block;
    padding-left: 10px !important;
    height: 38px !important;
    color: $light-navy !important;
    font-size: 16px;
    border: 1px solid $light-gray;
    white-space: nowrap;

    &:focus-within {
      border-bottom: 2px solid $primary-color !important;
    }

    input {
      border: none !important;
      width: 93px;
      height: 38px;
      font-weight: 400;
      font-size: 14px;
      padding-left: 10px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .timezone-select-input {
    z-index: 20;
  }
}
