.links-table {
  .links-labels-row {
    margin-top: 10px;
    label {
      padding: 0;
      padding-left: 3px;
      margin-bottom: 0;
    }
  }
  .link-row {
    margin-top: 0.25rem;
  }
  .label-input-container {
    padding-right: 0;
    padding-left: 15px;
  }
  .url-input-container {
    padding-right: 0;
    padding-left: 0;
  }
  button.remove-link-btn {
    margin-top: 0;
    height: 46px;
    width: 46px;
  }
  button.add-link-btn {
    width: 100%;
    margin-top: 0.5rem;
  }
}