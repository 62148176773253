@import "../../../assets/styles/globals";

.droppable {
  box-sizing: content-box;
  border: 10px solid $border-lighter-gray;
  margin: 0 -10px;
  padding: 1px 1px 0 3px;
  background: $border-darker-gray;
  background-clip: padding-box;

  &.drag-disabled {
    border-color: transparent;
  }

  .category {
    background: white;
    height: 80px;
    margin-bottom: 1px;
    padding: 0.4rem 3rem;
    position: relative;

    .left-corner {
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      display: flex;

      .grip-dots-vertical {
        fill: $icon-gray;
        margin-right: 0.4rem;
      }

      .color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: 0.1rem;
        margin-left: 0.25rem;
      }
    }

    .details-wrapper {
      padding-right: 0.5rem;

      .title {
        font-weight: 800;
        margin-bottom: 0.1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .number {
          color: $light-navy;
        }
      }

      .description {
        font-size: 0.9rem;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .edit-button-wrapper {
      position: absolute;
      right: 1.75rem;
      top: 0.5rem;

      .edit-button {
        border: none;
        background: transparent;
        padding: 0;
        color: $primary-orange;
        font-size: 1.2rem;
      }
    }
  }
}
