@import "../../../assets/styles/globals";

#SimpleResourceCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
    background-color: lighten($light-gray, 10%);
  }

  .resource-image-wrapper {
    height: 35px;
    width: 35px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      border-radius: 8px;
      margin-right: 5px;
    }
  }

  p {
    margin: 0px;
    font-size: 14px;
  }
}
