@import "../../../assets/styles/globals";

.dashboardManagerPage {
  height: 100vh;
  margin: 0 -15px;

  .navbar {
    height: 40px;
    width: 100% !important;
    padding: 0;

    .nav {
      height: 100%;
      width: 100%;
      background-color: white;
      overflow-x: auto;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        height: 0;
        background: transparent;
      }

      .navItem {
        flex-basis: fit-content;
        border-bottom: none;

        .navLink {
          height: 100%;
          font-size: 0.9rem;
          font-weight: bold;
          color: $dark-navy;
          padding: 0 60px;
          display: flex;
          align-items: center;
          border-bottom: 4px solid transparent;
          text-wrap: nowrap;

          &:hover {
            color: $primary-orange;
          }

          &.active {
            color: $primary-orange;
            border-bottom-color: $primary-orange;
          }

          .dot {
            background-color: $secondary-color;
            border-radius: 50%;
            height: 7px;
            width: 7px;
            margin-bottom: 1rem;
            margin-left: 2px;
            margin-right: -9px;
          }
        }
      }
    }
  }

  .main {
    display: block;
    overflow-y: auto;
    flex-grow: 1;
    flex-basis: 0;
  }
}

.tabPanelContentWidth {
  width: 80%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
