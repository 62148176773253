@import "../../globals";
@import "../../globals";

// #appWebBody{
// 	// background-color:  desaturate( lighten($primary-color, 20%), 10% )!important;
// 	background-color: lighten($primary-color, 60%)
// }

#CoachDashboardContainer {
  margin-top: 25px;
}

.activity-feed-divider {
  height: 30px;
  width: 3px;
  margin-left: 65px;
}

.dashboard-active-graph {
  z-index: 9999;

  canvas {
    border-radius: 0 0 8px 8px;
  }
}

.dashboard-welcome-container {
  padding: 15px;
  background-image: linear-gradient(
    to right,
    $light-navy,
    $primary-color
  ) !important;

  .dashboard-welcome-info-wrapper {
    border-bottom: 1px solid white;
    padding-bottom: 20px;

    .dashboard-welcome-avatar {
      border: 2px solid white !important;
    }
  }

  .dashboard-welcome-icon {
    color: white !important;
  }

  .dashboard-time-name {
    font-size: 30px;
    color: white;
  }

  .dashboard-time-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dashboard-time {
      font-size: 18px;
      color: $secondary-color;
      margin-left: 10px;
    }

    .dashboard-time-text {
      text-transform: uppercase;
      font-size: 18px;
      color: white;
    }
  }

  .dashboard-welcome-tip-wrapper {
    p {
      font-size: 14px;
      color: white;
      text-align: center;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
}

#OldDashboardPage {
  * #activityWrapper {
    background-color: $white-font-color;
    padding: 0px 40px;
    margin-top: 0px;
    position: relative;
    .p-4,
    .container,
    .row,
    .col-12 {
      padding-right: 2px;
      padding-left: 2px;
    }
    * .avatar {
      width: 50px;
      height: 50px;
    }
  }

  * #ActiveTodayWidget {
    .avatar {
      width: 30px;
      height: 30px;
    }

    .active-golfer-header-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .active-golfer-number-wrapper {
      background-color: $primary-color;
      border-radius: 50%;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-content: center;
      padding-right: 7px;
      padding-left: 7px;

      .active-golfer-number {
        font-size: 17px;
        font-weight: 550;
        color: $white-font-color;
        margin-bottom: 0px;
      }
    }
  }

  * .card-dashboard {
    border: none;
  }
  .statGroup {
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 5px;
    }
    h6 {
      font-size: 12px;
      letter-spacing: 3px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .dashboard-sidebar {
    // background-color: darken($primary-color, 60%);
    min-height: 100vh;
  }
  // .community-stats{
  // 	position: fixed;
  // 	float: right;
  // 	margin-left: 60%;
  // 	height: calc(100vh - 200px);
  // 	// top: 0;
  // 	overflow: scroll;
  // 	.card {

  // 	}
  // }
  #activityWrapper {
    background-color: $white-font-color;
    padding: 0px 40px;
  }
  .onboarding-banner {
    background: $primary-orange;
    display: flex;
    align-items: center;
    // justify-content: center;
    margin: -8px -15px 0 -15px;
    padding: 15px;
    color: #fff;
    p {
      margin: 0;
    }
    .icon-box {
      margin-right: 9px;
    }
    .box-title {
      font-size: 18px;
      span {
        color: $dark-navy;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .button-box {
      .btn {
        color: $primary-orange;
        background: #fff;
        font-size: 14px;
        border-radius: 4px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .btn.btn-outline {
        color: #fff;
        background: transparent;
        border: 1px solid #fff;
        margin-right: 9px;
        box-shadow: none;
      }
    }
  }
}

#appWebBody {
  padding: 0px;
}

.sticky-widgets {
  position: -webkit-sticky;
  position: sticky;
  top: 7px;
}

.activity-feed-section {
  // margin-bottom: 125px;
}

.active-programs-section {
  height: 100%;
  overflow-y: scroll;
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.active-golfers-section {
  height: 300px;
  overflow-y: scroll;

  .active-golfer-card {
    background-color: white;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
    padding: 5px;
  }
  .active-golfer-badge {
    width: 130px;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active-golfer-avatar {
    &:hover {
      cursor: pointer;
    }
  }
}

.engagement-graph-container {
  height: 360px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 45px;

  .engagement-graph-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    width: 100%;
    position: absolute;
    top: 215px;
    margin-bottom: 0;
    font-size: 45px;
    font-weight: 550;

    .engagement-graph-activities {
      margin-bottom: 0px;
    }

    .engagement-graph-text {
      margin-top: -10px;
      font-size: 23px;
      font-weight: 500;
    }
  }
}

.dashboard-tooltip {
  font-size: 16px !important;
  color: $primary-color;
}

.active-golfer-name {
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

// Laptop - Desktop devices
@media all and (max-width: 1600px) {
  .dashboard-time-name {
    font-size: 22px !important;
  }

  .dashboard-time-text {
    font-size: 16px !important;
  }
  .dashboard-time {
    font-size: 16px !important;
  }

  .dashboard-welcome-tip-wrapper {
    p {
      font-size: 12px;
    }
  }

  .active-golfer-name {
    padding-left: 10px !important;
  }

  .dashboard-active-program {
    font-size: 19px !important;
  }
  .dashboard-active-program-time {
    font-size: 12px !important;
  }
}

// Landscape Tablet
@media all and (max-width: 1199px) {
  .dashboard-time-name {
    font-size: 18px !important;
  }

  .dashboard-time-text {
    font-size: 13px !important;
  }
  .dashboard-time {
    font-size: 13px !important;
  }

  .dashboard-welcome-tip-wrapper {
    p {
      font-size: 12px;
    }
  }
}

// Large devices
@media all and (max-width: 991px) {
}

// Medium devices && Protrait Tablet
@media all and (max-width: 768px) {
  #CoachDashboardContainer {
    margin-top: 25px;
  }
}

// Small devices
@media all and (max-width: 575px) {
  #CoachDashboardContainer {
    margin-top: 25px;
  }

  .active-golfer-widget {
    // margin-top: -120px;
  }
}
