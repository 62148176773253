@import "../../assets/styles/globals";

#InvitationCard {
  .invitation-name {
    color: $primary-color;
    font-size: 20px;
    margin: 0px;
  }

  .invitation-email {
    color: $light-font-color;
    margin-bottom: 10px;
  }

  .invitation-button {
    text-align: left !important;
    padding: 0px !important;
  }

  .invitation-delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $error-font-color;

    &:hover {
      color: darken($error-font-color, 15%);
    }
  }
}
