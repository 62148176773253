@import '../../globals';

.sideBarItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;

  .icon {
    fill: $icon-gray;
  }

  &:hover {
    border-left: 3px solid $off-white;

    .icon {
      fill: $off-white;
    }
  }
}

.selectedSideBarItem {
  border-left: 3px solid $off-white;

  .icon {
    fill: $off-white;
  }
}

.navTooltip {
  background-color: $dark-navy !important;
  left: 75px !important;
  opacity: 1 !important;
  border-color: $icon-gray !important;
  border-radius: 10px !important;

  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }
}

.singleLink {
  font-size: 16px;
  padding: 10px 30px 10px 0px;
  a {
    color: white;
    &:hover {
      color: $icon-gray;
    }
  }
}

.section {
  padding: 20px;
  font-size: 16px;

  .sectionTitle {
    color: white;
    border-bottom: 1px solid white;
    margin-bottom: 10px;
  }

  .sectionLink {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $icon-gray;
    border-left: 3px solid transparent;

    &:hover {
      border-left: 3px solid $off-white;
      color: $off-white;
    }
  }

  .selectedSectionLink {
    border-left: 3px solid $off-white !important;
    color: $off-white !important;
  }
}
