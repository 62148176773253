@import "../../../assets/styles/globals";

#SessionPopover {
  height: 100%;
  width: 100%;
  background: #fff !important;
  padding: 2.5px 10px;
  // &:hover {
  //   background: #fff;
  // }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  p {
    font-size: 14px !important;
    line-height: 1;
    color: #1d2933;
    padding: 7.5px 0px !important;
    &:hover {
      text-decoration: underline;
    }
  }
  .delete-session {
    color: $primary-orange;
  }
}
