@import "../../../assets/styles/globals";

#PlayerJourneyReportContainer {
  flex-direction: column;
  // height: calc(100vh - 70px);
  justify-content: space-between;
  margin-bottom: $primary-padding;

  .PlayerJourneyReportView {
    width: 100%;
    width: 1000px;
    height: 770px;
    background: white;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin-bottom: $primary-padding;

    .profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $secondary-padding;
      position: absolute;
      width: 350px;
      height: 752px;
      top: 0px;
      left: $secondary-padding;
      background: $dark-navy;
      box-shadow: $pressable-elem-drop-shadow;
      border-radius: 0px 0px $card-corner-radius $card-corner-radius;
      z-index: 2;

      // .avatar-container {
      //   text-align: center;
      //   position: relative;
      //   border-radius: 50%;
      //   border: 2px solid #1d2933;
      //   overflow: hidden;
      //   height: 30px;
      //   // width: 78px;
      //   background-position: center;
      //   background-size: cover;
      //   display: flex;
      //   justify-content: center;
      //   margin-top: 2px;
      //   align-items: center;
      //   .avatar {
      //     height: 100%;
      //     width: 100%;
      //   }
      // }

      .golfer-info {
        color: $white-font-color;
        text-align: center;
        width: 100%;

        .name {
          font-weight: $bold-font-weight;
          font-size: $heading-2-size;
          margin: 0px;
          overflow-x: hidden;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }

        .division {
          width: 100%;
          margin: 0px;
          font-size: $heading-3-size;
        }

        .age-member-since {
          font-size: 14px;
          margin-bottom: $secondary-padding;
        }
      }

      .division-line {
        width: 100%;
        border: 1px solid $placeholder-gray;
      }

      .all-time-records {
        display: flex;
        width: 100%;
        gap: 10px;
        padding-top: $secondary-padding;

        .column {
          display: flex;
          flex-flow: column;
          flex: 1;

          .icon-and-stat-number {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .ir-icon {
            height: 24px;
            width: auto;
            margin-right: 10px;
          }

          // Shrink this icon a little since it's a bit wider
          #ir-icon-social {
            width: 24px;
            height: auto;
          }

          .all-time-stat-qty {
            font-weight: $bold-font-weight;
            font-size: $heading-2-size;
            color: $white-font-color;
            margin: 0px;
          }

          .all-time-stat-substat {
            display: flex;
            flex-flow: column;
            margin-top: 10px;
            align-self: center;

            .substat-qty {
              font-weight: $bold-font-weight;
            }
          }

          .round-of-golf, .training-sessions, .social-actions, .milestones-reached-label, .objectives-completed-label, .strokes-lost-label {
            text-align: center;
            margin: 0px;
          }

          .round-of-golf {
            color: $light-teal;
          }

          .round-of-formal-round, .round-of-casual-round {
            width: 100%;
            font-size: 14px;
            color: $light-teal;
            margin: 0px;
          }

          .training-sessions {
            color: $light-blue;
          }

          .round-of-total-training-duration {
            width: 100%;
            font-size: 14px;
            color: $light-blue;
          }

          .social-actions {
            color: $light-purple;
          }

          .milestones-reached-label, .objectives-completed-label, .strokes-lost-label {
            color: $primary-orange;
          }
        }
      }

      .profile-footer-badge {
        flex: auto;
        height: 100px;
        width: auto;
        -webkit-filter: drop-shadow($pressable-elem-drop-shadow);
        filter: drop-shadow($pressable-elem-drop-shadow);
        margin: $secondary-padding 0px;
      }

      .profile-footer-logo {
        flex: none; // don't want this to shrink if player name is too long
        height: 36px;
        width: auto;
      }

      .profile-footer-report-name {
        width: 100%;
        text-align: center;
        color: $white-font-color;
        margin: 0px;
        margin-top: 10px;
      }
    }

    .section-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $secondary-padding;

      .title-icon {
        height: 24px;
        width: auto;
        margin-right: 10px;
      }

      .title {
        font-weight: $bold-font-weight;
        font-size: $heading-3-size;
        color: $dark-navy;
        margin: 0px;
      }

      .subtitle {
        font-size: 14px;
        color: $light-navy;
        margin: 0px;
      }
    }

    .progress-container-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: 596px;
      right: $secondary-padding;

      .progress-panel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $secondary-padding;
        width: 100%;
        color: $white-font-color;
        margin-top: $secondary-padding;

        .progress-subpanel {
          flex: 33.3%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .current-division-progress {
          }

          .round-milestones-reached {
            font-weight: $bold-font-weight;
            color: $light-navy;
            margin: 10px 0px;
            font-size: 14px;
          }

          .round-logged-body-title {
            border-radius: 36px;
            background-color: $placeholder-gray;
            color: $white-font-color;
            text-align: center;
            padding: 4px 10px;
            font-size: 14px;
            margin: 0px;
          }

          .current-division-progress-stats {
            display: flex;
            align-items: center;
            gap: $secondary-padding;
            width: 100%;
            margin-top: $secondary-padding;

            .stat-wrapper {
              display: flex;
              flex-flow: column;
              flex: 25%;
              align-items: center;

              .stat-qty {
                font-weight: $bold-font-weight;
                font-size: $heading-2-size;
                color: $light-navy;
                margin: 0px;
              }

              .stat-label {
                font-weight: $bold-font-weight;
                font-size: 12px;
                text-align: center;
                color: $light-navy;
                margin: 0px;
              }
            }

            .arrow-icon {
              height: 18px;
              width: auto;
            }
          }

          .progress-bar {
            display: flex;
            width: 100%;
            align-items: center;
            background-color: transparent;
            width: 100%;
            font-weight: $bold-font-weight;

            .progress-bar-level-title {
              font-size: $heading-3-size;
              margin: 0px;
            }

            .progress-bar-level-subtitle {
              margin: 0px;
              margin-bottom: 10px;
            }
          }

          .ethic-objective {
            width: 100%;

            .ethic-objective-title {
              width: 100%;
              font-weight: $bold-font-weight;
              color: $light-navy;
              text-align: center;
              margin-bottom: 10px;
            }

            .ethic-objective-stats {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              width: 100%;

              .tri-flex {
                display: flex;
                flex: 33.3%;
                flex-direction: column;
                align-items: center;

                .check-icon {
                  margin-left: 4px;
                  height: 10px;
                  width: auto;
                }

                .ethic-objective-icon {
                  height: 18px;
                  width: auto;
                }

                .ethic-objective-stats-title {
                  font-size: 14px;
                  font-weight: $bold-font-weight;
                  margin: 0px;
                }

                .play-color {
                  color: $primary-teal;
                }

                .train-color {
                  color: $primary-blue;
                }

                .ethic-objective-stats-subtitle {
                  font-size: 12px;
                  margin: 0px;
                  color: $light-navy;
                }

                .ethic-objective-stats-box {
                  display: flex;
                  font-size: 12px;
                  border-radius: 4px;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  padding: 2px;

                  .ethic-objective-stats-box-text {
                    margin: 0px;
                  }
                }

                .border-play, .border-train {
                  width: 100%;
                }

                .border-play {
                  border: 1px solid $primary-teal;
                  color: $primary-teal;
                }

                .border-train {
                  border: 1px solid $primary-blue;
                  color: $primary-blue;
                }

                .border-play-done, .border-train-done {
                  color: $white-font-color;
                }

                .border-play-done {
                  border: 1px solid $primary-teal;
                  background-color: $primary-teal;
                }

                .border-train-done {
                  border: 1px solid $primary-blue;
                  background-color: $primary-blue;
                }
              }
            }
          }
        }
      }

      .division-line-vertical {
        width: 0px;
        align-self: stretch;
        border: 1px solid $placeholder-gray;
      }
    }

    .golf-course-whole-panel {
      position: absolute;
      background-color: $off-white;
      width: 1000px;
      height: 424px;
      bottom: 0px;
      z-index: 1;
      border-top: 2px solid $placeholder-gray;

      .golf-course-whole-panel-backgroundImage {
        position: absolute;
        top: 60px;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .report-levels-progress {
        // position: absolute;
        // height: 100px;
        // width: 470px;
        // top: 98px;
        // left: 510px;
      }

      .golf-course-container {
        position: absolute;
        width: 596px;
        right: $secondary-padding;
        height: 100%;
        padding: $secondary-padding 0px;
        display: flex;
        flex-flow: column;

        #section-title-division-journey, #section-title-notes-from-coach {
          margin-bottom: 10px;
        }

        .note-from-coach-container {
          flex: 100%;
          padding: $secondary-padding;
          box-sizing: border-box;
          width: 100%;
          background-color: white;
          position: relative;
          z-index: 4;
          border: 2px solid $placeholder-gray;
          border-radius: $card-corner-radius;

          .note-from-coach-body {
            margin: 0px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
