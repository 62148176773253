@import "../../assets/styles/globals";

.seriesCard {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid;
  border-color: $light-gray;
  height: 90px;
  border-radius: 8px 8px 0 0;
  margin-top: 15px;

  .draftLabel {
    height: 25px;
    width: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-navy;
    border-radius: 500vh;
    padding: 0 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    .editIcon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .seriesButton {
    background-color: transparent;
    border: none;
  }

  .seriesInfo {
    font-size: 20px;
    font-weight: 800;
  }

  .rosterInfo {
    font-size: 18px;
    font-weight: 800;

    .golfersCountIcon {
      margin-top: -15px;
    }

    .golfersCount {
      font-size: 20px;
    }

    .golfersCountLabel {
      font-size: 16px;
      height: 24px;
    }
  }

  .actionsCol {
    margin-block: auto;
    text-align: center;
    max-width: 60px;
  }

  .vl {
    border-left: 2px solid $placeholder-gray;
    height: 60px;
    margin: auto 0;
    margin-left: 18px;
    padding-left: 18px;
  }

}

.first-child {
  margin-top: 0;
}
