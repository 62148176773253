@import "../../../assets/styles/globals";

.container {
  padding: 2px 40px;

  label {
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    color: $dark-navy;
  }
}

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  color: $primary-navy;
  margin-bottom: 76px;
}


.accountInputContainer,
.accountInputContainerPhone {
  padding: 5px 15px !important;

  input {
    color: $light-navy !important;
  }
}

.accountInputContainerPhone {
  div>div>button {
    margin-top: 3px;
    z-index: 1 !important;
  }
}

.genderInputWrapper {
  color: $light-navy !important;
}

label.inputIcon {
  position: absolute;
  right: 25px;
  color: $light-navy;
}

@media (min-width: 1400px) {
  .container {
    padding: 2px 80px;
  }
}

.familySwitchWrapper {
  .familySwitchButton {
    width: 100%;
    max-width: 250px;
    height: 48px;
    border-radius: 100px;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    font-size: 0.9rem;
    padding: 0;
    background-color: white;
    margin-bottom: 8px;

    @media (min-width: 254px) {
      height: 32px
    }

    .switchOption {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      padding: 0 4px;
      border-radius: 30px;
      user-select: none;

      &.switchSelected {
        color: white;
        background-color: $primary-orange;
      }
    }
  }

  .familySwitchText {
    font-size: 14px;
    margin-bottom: 0;
  }
}
