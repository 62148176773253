@import "../../../assets/styles/globals";

.logInPage {
  color: white;
  position: fixed;
  background-image: url("../../../assets/images/auth/sign-up-background-desktop.jpg");
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  .mainCard {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
    background-color: $dark-navy;
    overflow-y: auto;
    padding: 16px;

    @media (min-width: 768px) {
      background-image: none;
      height: 80%;
      width: 70%;
      border-radius: 8px;
      padding: 48px;
    }

    @media (min-width: 1200px) {
      height: 80%;
      width: 50%;
      border-radius: 8px;
    }

    .circleLogo {
      width: 105px !important;
      height: auto;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        width: 64px !important;
        height: auto;
      }
    }

    .titleText {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 16px;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }

    .inputLabel {
      font-size: 16px;
      text-align: left;
      color: white;
      font-weight: 700;
    }

    .inputSymbol {
      color: white;
    }

    .logInButton {
      margin-top: 36px !important;
    }

    .footer {
      width: 100%;
      margin-top: 16px;
      padding: 0 16px;
      text-align: center;
      font-size: 14px;

      .forgotSection {
        margin-top: 24px;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $dark-gray;

        .forgotLink {
          margin: 0 4px;
          font-weight: 700;
          margin-left: 4px;
        }
      }

      .signUpLink {
        font-weight: 700;
        margin-left: 4px;
      }
    }
  }
}
