@import "../../../assets/styles/globals";

.communityLeaderboardExport {
  height: 100%;
  padding: 0 10%;

  .exportTitle {
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
  }

  .exportText {
    color: $dark-gray;
  }

  .downloadButton, .tryAgain, .refresh {
    padding: 0.5rem;
    background: $primary-orange;
    color: white;
    border-radius: 40px;
    border: none;
    font-size: 16px;
    margin-right: 30px;

    &:active {
      background: $primary-orange;
    }
  }

  .loaderContainer {
    margin-top: 100px;
    width: 100%;
  }

  .warningIcon {
    path {
      fill: $light-navy;
    }
  }

  .refresh {
    background: $dark-navy;

    &:active {
      background: $dark-navy;
    }
  }

  .errorButtons {
    width: 60%;
    margin-top: 25px;
    display: flex;
  }
}
