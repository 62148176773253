@import "../../../assets/styles/globals.scss";

.tel-input {
  height: 28px;
  width: 100% !important;

  .flag-dropdown {
    background-color: white;
    border-width: 0 1px 0 0;
    border-color: $placeholder-gray;

    .selected-flag {
      bottom: 4px;
      height: 28px;
      border: none;
      padding: 0 8px 0 4px;
      background-color: transparent !important;
    }

    .country-list {
      margin-top: 16px;
      margin-left: -10px;
      width: 200px !important;
      height: 180px !important;
      font-size: 1rem;
      border-radius: $card-corner-radius;

      @media (min-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }

  .form-control {
    padding: 0 0 0 50px !important;
    border: none !important;
    box-shadow: none !important;
    height: 100%;
    width: 100%;
    font-size: 1rem;

    @media (min-width: 768px) {
      font-size: 0.9rem;
    }

    &:autofill, &:autofill:focus {
      box-shadow: 0 0 0 100px white inset !important;
    }

    &:-webkit-autofill, &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 100px white inset !important;
    }
  }
}
