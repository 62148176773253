@import "../../assets/styles/globals";
@import "~loaders.css/src/animations/line-scale.scss";

.overlay-5 {
  background-color: transparent !important;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

.btn-primary {
  border-radius: 0px !important;
}

.loading-content {
  height: 350px;
}

.icon-from-header {
  #SmallMilestoneIcon:hover {
    cursor: pointer;
  }
}

.stats-loading-content {
  margin-top: -60px !important;
}

.loading-card {
  height: 598px !important;
}

.header-bg,
.bg-overlay {
  // background-image: url('../common/page-header/page-header-bg.png');
  //  background-position: center;
  //  background-size: cover;
  background-image: linear-gradient(to right,
      $light-navy,
      $primary-color ) !important;
  height: 170px;
  margin-bottom: 65px;
  border-bottom: 36px solid rgba(0, 0, 0, 0.1);
}

.metric-btn {
  margin-top: -1px;
}

.metric-container {
  display: flex;
  flex-direction: row;
}

.last-action {
  color: white;
  margin-right: 10px;
}

.action-light {
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-top: 3px;
}

.engagement-light {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-top: 10px;
}

.grn-light {
  color: #33d9b2;
  box-shadow: 0px 0px 12px #33d9b2;
  background-color: #33d9b2;
}

.ylw-light {
  color: #ffff00;
  box-shadow: 0px 0px 12px #ffff00;
  background-color: #ffff00;
}

.red-light {
  color: #d9534f;
  box-shadow: 0px 0px 12px #d9534f;
  background-color: #d9534f;
}

.blk-light {
  color: black;
  box-shadow: 0px 0px 12px black;
  background-color: black;
}

.action-row {
  display: flex;
  justify-content: flex-end;
}

// .btn-row {
//   display: flex;
//   justify-content: flex-end;
//   margin-top: 50px;
// }

#ProfileHeader {
  .btn-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
}

.sticky-btn-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn {
    margin-top: 9px;
  }
}

.mobile-btn-row {
  position: absolute;
  top: -62px;
  right: 10px;
}

.edit-btn {
  margin-right: 10px;

  &.active {
    background-color: $secondary-color !important;
  }
}

.family-btn {
  margin-right: 10px;
}

.back-btn {
  z-index: 999999;

  &:hover {
    background-color: #ff6633 !important;
    color: white !important;
  }
}

.image-container {
  position: absolute !important;
  top: 52px;
  height: 95px !important;
  width: 95px !important;
  border: 3px solid #f8f8f8;
  border-radius: 50%;
  overflow: hidden;
  background-color: #2b3843;

  &:hover {
    cursor: pointer;
  }
}

.image-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 500px;

  &:hover {
    cursor: pointer;
  }
}

.profile-sticky-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sticky-image-container {
  position: absolute !important;
  text-align: center;
  top: 20px;
  height: 40px !important;
  width: 40px !important;
  // box-shadow: 1px 1px 10px #222;

  &:hover {
    cursor: pointer;
  }
}

.sticky-user-profile-img-web {
  height: 40px !important;
  width: 40px !important;
  position: relative;
  border: 1px solid white;
  border-radius: 50%;
  object-fit: cover;
}

.profile-data {
  position: absolute !important;
  top: 58px;
  left: 600px;
  display: flex;
}

.data-section-1 {
  margin-right: 10px;
  max-width: 460px;
}

.sticky-division-icon-container {
  // position: absolute !important;
  // top: 0;
  // left: 485px;
  transform: scale(0.7);
}

.sticky-profile-data {
  position: absolute !important;
  top: 12px;
  left: 650px;
  display: flex;
  align-items: flex-start;
}

.profile-stats {
  margin-top: -5px;
  margin-left: 2px;
}

.dashboard-stats {
  position: -webkit-sticky;
  position: sticky;
  top: 67px;
}

.player-stat-card-container {
  min-height: 600px;
}

.division-dropdown-menu-active {
  display: block !important;
  // position: static !important;
  // left: 25% !important;
}

.division-select-container {
  .division-dropdown-button {
    border-radius: 10px !important;
    background: #60707d;
    color: #fff !important;
    width: 165px;
    font-size: 1.2rem;
  }
}

.milestone-badge-container {
  display: flex;
  height: 115px;
  justify-content: center;
  transform: scale(0.75);
}

.train-stats-row {
  display: flex;
  flex-wrap: none;
  flex-direction: row;
  align-items: center;
  margin: 0 auto !important;
}

.dropdown-item {
  font-size: 0.9rem;

  .small-yardage {
    color: #bbb;
    font-size: 0.7rem;
    vertical-align: middle;
  }
}

.player-profile-stats-topbar {
  position: relative;
  top: -25px;
  display: flex;
  padding: 0 10px 10px 10px;
  align-items: flex-end;
  justify-content: space-between;
  box-shadow: 0 5px 5px -5px #999;
  flex-wrap: wrap;

  .player-profile-stat-topbar-section-1,
  .player-profile-stat-topbar-section-2 {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: nowrap;
  }

  .player-profile-stat-topbar-section-1 {
    align-items: flex-start;
  }

  #StateSwitcher {
    margin-bottom: 0;
  }

  .no-milestone-title-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.player-profile-milestone-progress-container {
  width: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  position: relative;

  // top: -28px;
  .progress-container {
    height: 5px;
    width: 100%;
    position: absolute;
    top: 51px;
  }

  .circle-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }

  .progress-bar-1,
  .progress-bar-6 {
    background-color: #ff6633;
  }

  .progress-bar-2,
  .progress-bar-7 {
    background-color: #fddb10;
  }

  .progress-bar-3,
  .progress-bar-8 {
    background-color: #d5de27;
  }

  .progress-bar-4,
  .progress-bar-9 {
    background-color: #2baae1;
  }

  .progress-bar-5,
  .progress-bar-10 {
    background-color: #b51e8c;
  }

  .fifty,
  .fortyfive,
  .thirtynine,
  .thirtysix {
    position: relative;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    font-size: 0.9rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    // top: -14px;
  }

  .division-yardage-title {
    // left: 20px;
    // position: absolute;
    // bottom: -35px;
    position: relative;
    bottom: -5px;
  }

  // .division-name-title {
  //   left: 80px;
  //   position: absolute;
  //   bottom: 35px;
  // }
}

// .stat-group {
//   position: relative;
//   top: -60px;
// }

.stat-icon {
  float: left;
  width: 35px;
  height: 35px;
  background-color: #36434e;
  margin-right: 1em;

  &.first-icon {
    margin-top: 0;
  }

  img {
    width: 29px;
    margin: 3px;
  }
}

.stats-icon {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: invert(100%);
  -webkit-filter: invert(100%);
  width: 30px;
  height: auto;
}

.stats-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.percentage-count {
  color: #ff6633;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 5px;
}

.md-percentage-count {
  color: #ff6633;
  font-size: 72px;
  font-weight: 600;
}

.lg-percentage-count {
  color: #ff6633;
  font-size: 80px;
  font-weight: 600;
}

.count-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  /* center horizontally */
  top: 55%;
  /* center vertically */
  transform: translate(-50%, -50%);
}

.count-container-no-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  /* center horizontally */
  top: 60%;
  /* center vertically */
  transform: translate(-50%, -50%);
}

.count-container-no-icon-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  /* center horizontally */
  top: 63%;
  /* center vertically */
  transform: translate(-50%, -50%);
}

.enrolled-circle {
  width: 100px;
  height: auto;
}

.enrolled-circle-off {
  width: 100px;
  height: auto;
  position: relative;
  top: 5px;
}

.empty-circle {
  margin-top: 7px;
  width: 100px;
  height: 100px;
}

.lg-empty-circle {
  width: 220px;
  height: 120px;
}

.stat-title {
  font-size: 15px;
}

.md-stat-title {
  font-size: 17px;
}

.lg-stat-title {
  font-size: 20px;
}

/*****
Division Tabs Styles
*****/

.tab-container {
  padding-bottom: 40px;

  .tabs {
    margin: 0 auto;
  }

  .tab {
    margin-right: 10px;
    padding: 5px 24px;
    border-radius: 500px;
    background-image: linear-gradient(to right,
        $light-navy,
        $primary-color ) !important;
    border: none;
    transition: all 0.2s ease-in-out;

    border-radius: 500px !important;
    -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);

    &:hover {
      transform: scale(0.9);
    }
  }

  .tab[aria-selected="true"] {
    margin-right: 10px;
    padding: 5px 24px;
    border-radius: 500px;
    background-image: linear-gradient(to right,
        $primary-orange,
        $secondary-color ) !important;
  }

  .tab-list {
    display: flex;
    justify-content: center;
  }

  p {
    margin: 0;
    width: 100%;
  }

  .div-desc {
    color: #2b3842;
  }
}

.chart-row {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

/*****
Top Dashboard Tabs Styles
*****/

.dashboard-tab-container {
  .dashboard-tabs {
    margin: 0 auto;
  }

  .dashboard-tab {
    margin-top: 0 !important;
    margin-right: 40px;
    padding: 5px 24px;
    border-radius: 0px;
    background-color: transparent;
    padding-bottom: 6px;
    border: none;
    font-size: 17px;
    display: flex !important;
    align-items: flex-end !important;

    p {
      margin-bottom: 0px !important;
    }

    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(0.9);
    }

    &:focus,
    :after {
      outline: none !important;
    }
  }

  .dashboard-tab[aria-selected="true"] {
    color: white;
    font-weight: 550;
    background-image: linear-gradient(to right,
        $primary-orange,
        $secondary-color ) !important;
    border-radius: 500px;

    border-radius: 500px !important;
    -webkit-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 8px 10px -10px rgba(0, 0, 0, 0.4);
  }

  .dashboard-tab-list {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 99999;
    top: 134px;
    left: 300px;
  }
}

.round-pill {
  border-radius: 15px;
  text-align: center;
  width: 50px;
  color: white;
}

.page-header-btn-wrapper {
  margin-top: 35px;
  z-index: 99999;
}

.golfer-stats-loading-content {
  margin-top: -270px !important;
}

/*****
Sticky Header Styles
*****/

.profile-sticky-header-container {
  width: 100%;
  height: 60px;
  background-color: #5e6971;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 0px !important;
  z-index: 99999 !important;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
}

/* xs < 768 */
@media screen and (max-width: 767px) {
  .goal-row-tab {
    margin-top: 50px !important;
  }

  .dashboard-tab-container {
    .dashboard-tabs {
      margin: 0 auto;
    }

    .dashboard-tab {
      margin-top: 0 !important;
      margin-right: 40px;
      padding: 5px 24px;
      border-radius: 0px;
      background-color: transparent;
      padding-bottom: 6px;
      border: none;
      font-size: 17px;
      display: flex !important;
      align-items: flex-end !important;
      color: #2b3842;

      p {
        margin-bottom: 0px !important;
      }

      &:hover {
        color: #ff6633;
      }

      &:focus,
      :after {
        outline: none !important;
      }
    }

    .dashboard-tab[aria-selected="true"] {
      color: #2b3842;
      font-weight: 550;
      border-bottom: 3px solid #ff6633;
    }

    .dashboard-tab-list {
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: 99999;
      top: 175px;
      left: 50px;
    }
  }

  .section-title {
    font-size: 0.9em;
  }

  .image-container {
    left: 45px;
  }

  .sticky-image-container {
    top: 10px;
    left: 30px;
  }

  .profile-data {
    left: 160px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-stats {
    font-size: 15px;
  }

  .sticky-profile-data {
    left: 80px;
  }

  .sticky-profile-name {
    font-size: 32px;
  }
}

.empty-data-text {
  background-color: #2b3842;
  color: white;
  padding: 15px 10px;
  border-radius: 6px;
}

/* sm */
@media screen and (min-width: 768px) {
  .section-title {
    font-size: 0.9em;
  }

  .image-container {
    left: 120px;
  }

  .sticky-image-container {
    top: 10px;
    left: 30px;
  }

  .profile-data {
    left: 230px;
  }

  .profile-name {
    font-size: 27px;
  }

  .profile-stats {
    font-size: 16px;
  }

  .sticky-profile-data {
    left: 80px;
  }

  .sticky-profile-name {
    font-size: 32px;
  }
}

/* md */
@media screen and (min-width: 992px) {
  .section-title {
    font-size: 1em;
  }

  .image-container {
    top: 52px;
    left: 135px;
  }

  .sticky-image-container {
    top: 10px;
    left: 250px;
  }

  .profile-data {
    left: 250px;
  }

  .profile-name {
    font-size: 30px;
  }

  .profile-stats {
    font-size: 16px;
  }

  .sticky-profile-data {
    left: 300px;
  }

  .sticky-profile-name {
    font-size: 32px;
  }

  .sticky-profile-stats {
    font-size: 16px;
  }

  .stat-title {
    font-size: 13px;
  }

  .md-stat-title {
    font-size: 15px;
  }

  .lg-stat-title {
    font-size: 18px;
  }

  .md-percentage-count {
    font-size: 60px;
  }
}

@media screen and (min-width: 1050px) {
  .section-title {
    font-size: 1em;
  }

  .image-container {
    top: 52px;
    left: 135px;
  }

  .sticky-image-container {
    top: 10px;
    left: 250px;
  }

  .profile-data {
    left: 250px;
  }

  .profile-name {
    font-size: 30px;
  }

  .profile-stats {
    font-size: 16px;
  }

  .sticky-profile-data {
    left: 300px;
  }

  .sticky-profile-name {
    font-size: 32px;
  }

  .sticky-profile-stats {
    font-size: 16px;
  }
}

/* lg */
@media screen and (min-width: 1200px) {
  .section-title {
    font-size: 1.3em;
  }

  .image-container {
    top: 52px;
    left: 135px;
  }

  .sticky-image-container {
    top: 10px;
    left: 250px;
  }

  .profile-data {
    left: 250px;
  }

  .profile-name {
    font-size: 32px;
  }

  .profile-stats {
    font-size: 17px;
  }

  .sticky-profile-data {
    left: 300px;
  }

  .sticky-profile-name {
    font-size: 32px;
  }
}