@import "../../../assets/styles/globals";

.createPairingsModalContent {
  height: min-content !important;
  max-width: 600px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $dark-navy;
  margin-bottom: 16px;;
}

label {
  color: $dark-navy;
  font-size: 16px;
  font-weight: 700;
}

.errorInput {
  border-color: $input-error-color;
}

.hl {
  position: static;
  display: inline-block;
  background: $border-lighter-gray;
  width: 100%;
  height: 1px;
  margin-block: 16px;
}

.selectInput {
  width: 35%;
}

.teeTimeSlotsInputWrapper {
  width: 20%;
}

.holeInput {
  width: 50px;
  margin-right: 10px;
}

.holeInputWrapper{
  width: 39px;
  height: 39px;
}

.intervalInputWrapper {
  width: 20%;
}