@import "../../../../../assets/styles/globals";

.refund-enrollment-modal-content {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.second-modal-screen-content {
  @media (max-height: 910px) {
    height: auto !important;
  }
}

#RefundEnrollmentModal {
  #modalXIcon {
    cursor: pointer;
  }

  #warningIcon {
    margin-bottom: 8px;
  }

  .title {
    color: $primary-orange;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .sub-title {
    text-align: center;
  }

  .coachMessageWrapper {
    width: 95%;
  }

  .coachMessageInputContainer {
    height: 150px;
  }

  .transaction-details {
    font-size: 18px;
    color: $primary-navy;
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 16px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $border-lighter-gray;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .key {
    color: $light-navy;
    margin-bottom: 7px;
    margin-right: 5px;
  }

  .value {
    text-align: right;
    margin-bottom: 7px;
  }
}
