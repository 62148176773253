@import '../../../assets/styles/globals.scss';

.pairingsPage {
  margin-top: -15px;
  height: calc(100vh - 170px);
  padding-top: 30px;
  overflow-x: scroll;

  .pairingGroups {
    margin-left: 10%;
    margin-right: 10%;
  }

  .draftPairingsMessage {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    height: 80px;
    border: 1px solid $light-gray-accent;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;

    i {
      font-size: 39px;
      color: $primary-orange;
    }
  }

  @media (max-width: 1570px) {
    .pairingGroups {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}