@import "../../../../assets/styles/globals";

#SimpleEducationSection {
  width: 95%;
  margin: auto;
  margin-bottom: 15px;
  color: white;

  h5 {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 550;
    margin-left: 10px;
  }

  p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 14px;
  }

  .section-container {
    overflow: hidden;
    background-color: $slate-gray;
    border: 1px solid $border-slate !important;

    .section-button {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: transparent;
      border: none;
      text-align: left;

      .section-completed-count-wrapper {
        display: flex;
        flex-direction: row;
        margin: 0px;
        padding: 0 10px !important;
      }

      .completed-section {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}