@import "../../../../assets/styles/globals";

.program-presentation-header {
  flex: 0 0 fit-content;
  overflow-y: auto;
  color: white;

  @media (min-width: 992px) {
    flex-basis: 42%;
  }

  .back-arrow-button {
    width: fit-content;
    position: sticky;
    top: 0;
  }

  .program-header-details-wrapper {
    @media (min-width: 992px) {
      width: 60%;
    }

    .program-title {
      font-weight: 800;
      font-size: 1.3rem;
      margin-bottom: 0.5em;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (min-width: 992px) {
        font-size: 2.5rem;
      }
    }

    .program-subtitle {
      font-weight: 700;
      font-size: 1rem;
      margin: 0;

      @media (min-width: 992px) {
        font-size: 1.5rem;
      }

      &.program-meeting-times {
        display: -webkit-box;
        width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .program-search-tag {
      font-weight: 700;
      background: $light-navy;
      padding: 6px 10px;
      border-radius: 20px;
      margin: 0.25rem 0.5rem 0.25rem 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
