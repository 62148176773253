@import "../../assets/styles/globals";

#DrillsPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: calc(100% + 30px);
  margin: 0 -15px 0 -15px;

  .tabs {
    height: calc(100% - 70px);

    .tabList {
      height: 40px;
      margin-left: -40px;
      background-color: transparent;
      border-bottom: 2px solid $light-gray !important;
      margin-bottom: 0rem !important;

      .tab {
        margin-top: 0px;
        text-transform: none;
        background-color: transparent;
        padding-left: 70px;
        padding-right: 70px;
        font-size: 14px;
        height: 40px;
        font-weight: bold;
        color: black;
      }

      .activeTab {
        border-bottom: 4px solid $secondary-color;
        height: 40px;
        color: $secondary-color;
        background: transparent !important;
      }
    }

    .react-tabs__tab-panel--selected {
      display: flex;
      height: calc(100% - 110px);
    }
  }

  .active-pg {
    color: $primary-orange;
  }

  .drills-search-bar {
    width: 90%;
    margin: -10px auto 15px;
    background-color: #fff;
    border-radius: 8px;

    .filter-button {
      position: absolute;
      top: 130px;
      right: 15px;
      height: 24px;
      width: auto;
      cursor: pointer;
      margin-right: $secondary-padding;

      &:hover {
        opacity: 0.85;
      }
    }
    .filter-count {
      position: absolute;
      top: 120px;
      right: 15px;
      background: $primary-teal;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-weight: bold;
        line-height: 1;
        margin: 0;
      }
    }
  }

  .drills-activities-cards-container,
  .drills-activities-cards-container-public {
    display: flex;
    height: 100%;
    width: 100%;
    h3 {
      font-size: $heading-3-size;
      font-weight: $bold-font-weight;
      margin: 0;
      margin-bottom: $secondary-padding;
      line-height: 1;
    }

    .drills-activities-cards-pinned-sidebar-closed {
      width: 5% !important;
    }

    .drills-activities-cards-pinned-sidebar {
      color: white;
      background-color: $light-navy;
      padding: $secondary-padding;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 30%;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.5);
        background-color: rgba(104, 104, 104, 0.5);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      }

      .pinned-toggler {
        display: flex;
        align-items: center;
        margin-bottom: $secondary-padding;
        i {
          transform: rotate(0deg);
          transition: transform 0.3s;
        }
        .flip {
          transform: rotate(180deg);
        }
        h3 {
          margin: 0;
          margin-left: 10px;
        }

        &:hover {
          cursor: pointer;
          color: darken(white, 15%);
        }
      }

      .empty-sidebar-msg {
        // margin: auto;
        display: flex;
        flex-flow: column;
        // justify-content: flex-start;
        align-items: center;
        opacity: 0.5;
        padding-top: 150px;
        img {
          width: 72px;
          height: 72px;
          filter: brightness(0) invert(1);
          margin-bottom: 10px;
        }

        p {
          text-align: center;
        }
      }
    }

    .drills-all-main-area {
      width: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px !important;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.5);
        // background-color: rgba(104, 104, 104, 0.5);
        background-color: #f4f4f5;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // background-color: rgba(255, 255, 255, 0.8);
        background-color: rgba(104, 104, 104, 0.5);
        -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
      }
    }

    .drills-activities-cards-main-area {
      padding: $secondary-padding;
    }

    .empty-main-area-msg {
      padding-top: 150px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      margin: auto;

      img {
        width: 72px;
        height: 72px;
        margin: 10px;
        margin-top: 0px;
      }

      p {
        margin: 0px;
        color: $light-navy;
      }
    }
  }

  .drills-activities-cards-container-public {
    .drills-activities-cards-leaderboard {
      color: white;
      background-color: $primary-navy;
      // padding: $primary-padding;
      padding: $secondary-padding;
      padding-bottom: 6px;

      h3 {
        text-align: center;
      }

      p {
        text-align: center;
        margin: 0;
        line-height: 1;
      }

      .drills-activities-cards-leaders {
        display: flex;
        // justify-content: space-between;

        .drills-activities-cards-leaderboard-spot {
          display: flex;
          flex-flow: column;
          align-items: center;
          width: 20%;
          position: relative;
          .contributor-leaderboard-placement {
            position: absolute;
            top: 0;
            left: calc(50% - 36px);
            background: $primary-orange;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $primary-navy;
            p {
              font-size: 16px;
              margin: 0;
              font-weight: bold;
            }
          }
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 2px solid white;
            margin-bottom: 10px;
          }

          p {
            line-height: 1;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .drills-activities-public-sort {
    flex: 0 0 100%;
    p {
      color: $dark-navy;
      font-size: $heading-3-size;
      margin: 0;
      line-height: 1;
    }
  }
  hr.section-divider {
    margin: $secondary-padding 0;
    border: 0;
    background-color: $light-navy;
    height: 2px;
    border-radius: 2px;
  }

  @media screen and (max-width: 700px) {
    .drills-activities-cards-pinned-sidebar {
      display: none !important;
    }
  }
}
