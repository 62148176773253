@import '../../assets/styles/globals.scss';

#CommunityPlaceholder {
  display: none;
  height: 100%;
  color: #2b3843;

  img {
    height: 100px;
  }

  i {
    font-size: 180px;
  }

  p {
    color: $light-navy;
    font-size: 14px;
    text-align: center;
  }

  @media screen and (min-width: 830px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
