@import "../../../assets/styles/globals";

#ResourceDetailPage {
  .resource-detail-container {
    padding: 30px;

    .resource-detail-sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;

      h5 {
        font-size: 18px;
        font-weight: 550;
        margin-bottom: 15px;
      }

      .resource-detail-image-wrapper {
        height: 150px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 8px;
        background-color: $light-gray;

        i {
          font-size: 100px;
          color: $light-font-color;
        }

        img {
          width: 100%;
        }
      }

      .related-resources-wrapper,
      .resource-sections-wrapper {
        padding-top: 30px;
        width: 100%;
      }

      .resource-sections-wrapper {
        display: flex;
        flex-direction: column;
        align-content: flex-start;

        a {
          padding: 5px 0px;
          color: $secondary-color;

          &:hover {
            color: darken($secondary-color, 15%);
          }
        }
      }
    }

    .resources-not-available {
      color: $light-font-color;
    }

    .resource-detail-section {
      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
      .resource-detail-header {
        padding-bottom: 25px;

        p {
          font-size: 14px;
          color: $primary-color;
        }

        h4 {
          font-weight: 550;
          color: $primary-color;
          margin-bottom: 5px;
          font-size: 35px;
        }

        .resource-orgranization-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $light-font-color;

          p {
            margin: 0px;
          }

          i {
            margin-right: 5px;
          }
        }
      }

      .resource-description {
        padding-top: 50px;
      }
    }
  }
}
