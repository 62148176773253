@import "../../assets/styles/globals";

.Tooltip {
  .custom-tooltip {
    min-width: 280px;
    max-width: 400px;
    min-width: min-content;
    word-break: break-word;
    font-size: 12px;
    border: 2px solid $placeholder-gray !important;
    border-radius: 8px;
    text-align: center;
    opacity: 1 !important;

    @media screen and (max-width: 767px) {
      width: 250px;
    }
  }
}