@import "../../../../assets/styles/globals";

.seriesDetails {
  padding-bottom: 50px;
  background-color: white;

  .title {
    font-size: 20px;
    font-weight: 800;
    color: $dark-navy;
  }

  .characterCounter {
    margin-top: 5px;
    font-size: 12px;
    color: $light-navy;
  }

  .label {
    font-size: 16px;
    font-weight: 800;
  }
}