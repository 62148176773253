@import "../../assets/styles/globals";

#EducationProgressBar {
  width: 100%;
  margin-top: 15px;

  p {
    margin-bottom: 20px;
    text-align: left;
    color: $dark-gray;
    font-weight: 800;
    font-size: 14px !important;
  }

  .progress {
    background-color: $light-navy;
    margin-bottom: 20px;
  }

  .progress-bar {
    overflow: initial;
  }

  .progress-bar-text {
    color: white;
    padding: 0px 4px;
    margin: 0px;
    font-size: 12px;
  }

  .education-section-count {
    margin-top: 10px;
  }

  .completed-lessons {
    font-size: 16px;
  }
}