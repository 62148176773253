@import "../../assets/styles/globals";

.customPlaceholder {
  color: $light-navy;
  width: 35%;
  margin: 100px auto;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .placeholderTitle {
    margin-top: 1rem;
    font-weight: 800;
    font-size: 20px;
    color: $dark-navy;
  }

  .subtext {
    white-space: pre-wrap;
    justify-content: start;
    text-align: center;
    margin-bottom: 1rem;
  }

  .learnMoreText {
    white-space: pre-wrap;
    justify-content: start;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 600;
    color: $primary-orange;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
}
