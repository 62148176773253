@import "../../../assets/styles/globals";

#CoachStats {
  padding: 10px;

  .lead-coach-button {
    font-size: 0.9rem;
    color: $primary-orange;
    cursor: pointer;
  }

  input {
    font-size: 0.8rem;
    max-width: 100%;
  }

  form {
    overflow-x: auto;

    .input-label {
      font-size: 0.8rem;
    }
  }

  #form-save {
    margin-top: 10px;
    text-align: center;
    color: $secondary-color;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .coach-contact-container {
    color: $primary-color;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: 15px;
    margin-bottom: 15px;

    h4 {
      font-weight: 550;
      font-size: 18px;
      margin: 0px;
    }

    #contact-info-edit {
      color: $secondary-color;
      float: right;
      font-size: 1rem;

      &:hover {
        color: #c36508;
        cursor: pointer;
      }
    }

    p {
      color: $light-font-color;
      margin: 0px;

      span {
        color: $primary-color;
      }
    }
  }

  .coach-onboarding-details {
    border-bottom: 1px solid $light-gray;
    padding: 12px 15px 15px;
    margin-bottom: 15px;
    color: $slate-gray;
    width: 100%;

    .onboarding-section-title {
      font-weight: 700;
      font-size: 18px;
      margin: 0;
    }

    .onboarding-details-button {
      display: flex;
      align-items: center;
      gap: 4px;
      border: none;
      color: $secondary-color;
      font-size: 14px;
      font-weight: 700;
      width: initial;
      padding: 0;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }

      & > div {
        padding: 0 !important;
      }
    }

    .onboarding-status-text {
      font-size: 12px;
      font-weight: 700;
    }

    .total-count {
      font-size: 12px;
      font-weight: 700;
    }

    .completed-count {
      font-size: 16px;
    }

    .onboarding-action-button {
      background-color: $secondary-color;
      color: white;
      justify-content: center;
      padding: 8px;
      font-weight: 700
    }
  }


  .row {

    .col-6,
    .col-12 {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-weight: 550;
        font-size: 40px;
      }

      p {
        text-align: center;
        font-size: 12px;
        color: $light-font-color;
      }
    }
  }
}