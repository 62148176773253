@import "../../../../assets/styles/globals";

#PackageSelect {
  .truncated-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .package-counter {
    p {
      color: $primary-orange;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
    }
  }

  .option {
    cursor: pointer;
    padding-bottom: 10px;
  }

  .package-card {
    .left-package-icon {
      text-align: center;
      padding: 10px;
    }

    .package-info {
      overflow: hidden;

      .title {
        font-weight: 700;
        word-break: break-word;
        margin-bottom: 0;
      }

      .price {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .separating-dot {
        font-size: 14px;
        color: $light-navy;
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 0;
      }

      .description {
        font-size: 14px;
        color: $light-navy;
        word-break: break-word;
      }
    }

    .right-delete-icon {
      cursor: pointer;
      padding: 10px;
      font-size: 20px;
      text-align: center;
      color: $light-navy;
    }
  }

  .create-package {
    cursor: pointer;

    p {
      font-size: 15px;
      color: $primary-orange;
      font-weight: 700;
      padding: 10px 10px 0px 10px;
    }
  }

  .line {
    background: $placeholder-gray;
    width: 100%;
    height: 1px;
    margin: 10px 0px;
  }

  .info-modal-message {
    padding-top: 50px;
    text-align: center;
    color: $light-navy;

    p {
      margin-bottom: 0;
    }
  }
}