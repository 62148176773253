@import "../../../assets/styles/globals";

#ResourceCategoryCard {
  h4 {
    font-weight: 550;
    border-bottom: 3px solid $light-font-color;
    padding-bottom: 20px;
    color: $primary-color;
  }
}
