@import '../../../assets/styles/globals.scss';

#PostScores {
  background-color: #fff;
  padding-top: 15px;

  .form-container{
    label {
      font-weight: 800;
      font-size: 16px;
      color: $dark-navy;
    }
  }

  input {
    border: 0 !important;
  }

  .description-input {
    height: 110px;
  }
}

#SimplePhotoUploader {
  height: 200px;
}

