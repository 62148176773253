@import "../../assets/styles/globals";

.landingHeader {
  height: 60px;
  background: #1d2933;
  display: flex;
  align-items: center;
  padding: 0 18px;
  position: relative;
  z-index: 100004;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .op36Icon {
    width: 42px;
    margin-left: 16px;
    height: auto;

    & > path {
      fill: white;
    }
  }

  .avatarWrapper {
    cursor: pointer;
    width: 46px !important;
    height: 46px !important;
    margin-right: 16px;
    border: 1px solid white;
  }

  .tooltipWrapper {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 700;
    background-color: $dark-navy !important;
    border-color: $dark-gray !important;
    border-radius: 8px !important;
    opacity: 1 !important;

    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }

  .userTooltip {
    width: 70px;

    a {
      padding: 3px 0;
      color: white;

      &:hover {
        color: $icon-gray;
      }
    }

    .logOut {
      padding: 3px 0;
      color: $primary-orange;

      &:hover {
        color: $red;
      }
    }
  }
}
