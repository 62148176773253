@import "../../assets/styles/globals";

.accountCard {
  position: relative !important;
  max-width: 100%;
  width: 320px;
  padding: 12px;
  border-radius: 8px;
  color: white;
  background-color: $primary-navy;
  margin-bottom: 32px;
  overflow: visible;

  .accountVisuals {
    position: relative;

    .divisionWrapper {
      position: absolute;
      top: 1px;
      right: -29px;
    }
  }

  .accountInfo {
    .fullName {
      text-align: left;
      margin-bottom: 0;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .familyAdmin {
      color: $primary-orange;
      font-size: 15px;
      text-align: left;
      font-weight: 700;
    }
  }
}
