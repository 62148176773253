@import "../../assets/styles/globals";

.schedule-session-card {
  display: flex;
  background-color: #fff;
  color: $light-navy;
  margin-top: -2px;
  padding: 18px;
  border-top: 1px solid $placeholder-gray;
  border-bottom: 1px solid $placeholder-gray;
  justify-content: center;

  // p {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  //   word-break: break-word;
  //   margin-bottom: 0;

  //   @supports (-webkit-line-clamp: 2) {
  //     white-space: initial;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 2;
  //     -webkit-box-orient: vertical;
  //   }
  // }

  p {
    margin-bottom: 0;
  }

  i {
    color: $placeholder-gray;
    font-size: 20px;
  }

  .icon-wrapper{
    min-width: 18px;
    margin-right: 7px;
    text-align: center;
  }

  .title {
    max-width: 95%;
    font-weight: 700;
    font-size: 18px;
    color: $dark-navy;
  }

  .form-title{
    color: $dark-navy;
  }

  .vl {
    border-left: 2px solid $placeholder-gray;
    height: 100%;
    min-height: 100px;
    margin-left: 18px;
    padding-left: 18px;
  }

  .hl {
    position: static;
    display: inline-block;
    background: $placeholder-gray;
    width: 100%;
    height: 2px;
  }

  .date-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px;
    .day-number {
      font-weight: 700;
      font-size: 36px;
      color: $dark-navy;
    }

    .month-name {
      font-weight: 700;
      color: $dark-navy;
    }
  }

  .time-range {
    color: $primary-orange;
    font-weight: 700;
  }

  .edit-icon {
    color: $primary-orange;
    font-size: 24px;
    margin-right: -18px;
    cursor: pointer;

    &:hover {
      color: darken($primary-orange, 15%);
    }
  }

  .delete-button {
    color: $primary-orange;

    i {
      color: $primary-orange;
    }

    &:hover {
      color: darken($primary-orange, 15%) !important;

      i {
        color: darken($primary-orange, 15%);
      }
    }
  }

  .description {
    white-space: pre-wrap;
  }

  .error-container {
    i {
      color: $input-error-color;
    }
  }

  @media all and (max-width: 768px) {
    .title {
      font-size: 16px;
    }

    .edit-icon {
      font-size: 18px;
    }

    .vl {
      margin-left: 10px;
      padding-left: 10px;
    }

    p, span {
      font-size: 14px;
    }
  }
}
