@import "../../assets/styles/globals";

$objectPlaceholder: url("../../assets/images/activity_posted_overlay_bg.png");

#CurriculumsPage {
  .class-promo-widget {
    background-color: $primary-color;
    // background: $objectPlaceholder;
    // background-size: cover;
    min-height: 20vh;
    * .badge {
      font-weight: 500;
      font-size: 16px;
    }
    .overlay-5 {
      padding: 10vh 15px;
    }
  }
  * .card {
    border: none;
    * .card-title {
      min-height: 100px;
    }
  }
}

.sub-title {
  margin: 0 auto;
}

.btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-btn {
  margin: 0 auto;
  width: 200px;
}

.join-program-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2em;
}

.join-program-listitem {
  width: 20%;
  margin: 1em 0;
  input {
    margin-right: 0.5em;
  }
}

// Inside a modal
.enrolledFamilyMember,
.pendingFamilyMember {
  opacity: 0.5;
}

// React Modal Override.
// Consider adding this to a global stylesheet.
// .ReactModal__Content{
// 	background-color: transparent !important;
// 	border-color: transparent !important;
// }
button.close {
  font-size: 70px;
}
.ReactModal__Overlay {
  background-color: rgba(255, 255, 255, 0.9);
}

.curriculums-header {
  // background-color: $tertiary-color;

  h4 {
    color: $primary-color;
    padding: 20px 15px;
    font-weight: 550;
  }
}

.curriculum-card-container {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    text-transform: uppercase;
    color: $primary-color;
    font-size: 25px;
    margin-top: 15px;
  }

  p {
    text-align: center;
    margin-bottom: 15px;
    color: $light-font-color;
  }

  .curriculum-card-image-wrapper {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
