@import "../../../assets/styles/globals";

:root {
  --consentFlow-modal-width: 600px;
  --consentFlow-modal-padding: #{$primary-padding};
  --consentFlow-modal-title-size: #{$heading-2-size};
}

#ConsentContainer {
  padding: var(--consentFlow-modal-padding);
  width: var(--consentFlow-modal-width);
  border-radius: $card-corner-radius;
  background-color: white;
  box-shadow: $modal-drop-shadow;
  max-height: 90vh;
  overflow-y: auto;
  .orange {
    color: $primary-orange;
  }
  h2 {
    font-size: var(--consentFlow-modal-title-size);
    font-weight: $bold-font-weight;
    text-align: center;
  }

  .subtext {
    color: $light-navy;
    font-size: 12px;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  ul {
    color: $primary-orange;
  }

  .checkbox-container {
    display: flex;
    margin-top: $secondary-padding;

    .checkbox {
      height: 24px;
      // width: 24px;
      flex: 24px 0 0;
      background-color: white;
      border: 2px solid $primary-orange;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-top: 3px;
      .fa-check {
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(85%);
      }
    }

    .filled {
      background-color: $primary-orange;
    }
  }
  .signout {
    margin: 0;
    font-size: 0.8rem;
    cursor: pointer;
    color: $light-navy;
  }
}

@media (max-width: 768px) {
  // tablet
  :root {
    --consentFlow-modal-width: 95vw;
    --consentFlow-modal-padding: #{$secondary-padding};
  }
}

@media (max-width: 425px) {
  // phone (large)
  :root {
    --consentFlow-modal-title-size: #{$heading-3-size};
  }
}
