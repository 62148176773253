@import '../../../../assets/styles/globals.scss';

.programsTab {
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media (min-width: 1200px) {
    padding: 36px;
  }
}
