@import "../globals";

.refreshLoadingSpinner {
  color: $primary-orange !important;
}

.App {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  #appBody {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    // overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    * .scrollContainer {
      // Test
      // margin-top: 90px;
      // height: calc(100% - 166px);
      // border: solid 2px black;

      // // margin-top: 60px;
      // // height: calc(100% - 123px) ;
      // -webkit-overflow-scrolling: touch;
      // width: 100%;
      // position: absolute;
      // overflow-y: scroll;
      // overflow-x: hidden;
    }
  }
  #appWebBody {
    margin: 0px 0 0 0px;
    overflow-x: hidden;
    // overflow-y: hidden;
    position: fixed;
    left: 90px;
    right: 0;
    height: 100%;
    background-color: $off-white;
  }

  #appWebBody.no-sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    left: 0px;
    right: 0;
    height: 100%;
    top: 70px;
    .container-fluid {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  #appWebBody.no-nav {
    margin: 0px 0 0 0px;
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    left: 0px;
    right: 0;
    height: 100%;
    top: 0px;
    .container-fluid {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media (max-width: 1199px) {
  .App {
    #appWebBody {
      left: 0px;
    }
    navbar {
    }
  }
}
