@import "../../assets/styles/globals";

.print-window {
  margin-top: 50px;
}

.print-container {
  margin: 15px;
}

.print-top-header {
  justify-content: space-between;

  .print-date-text {
    font-size: 25px;
    margin-bottom: 50px;
  }
}

.print-user-info-wrapper {
  margin-left: 15px;

  .print-name-text {
    text-align: left;
  }

  .print-birthdate-text {
    margin-top: 5px;
    font-size: 20px;
  }
}

.print-division-course {
  width: 100%;
  margin-bottom: 150px;
}

.print-report-header {
  font-weight: 550;
  font-size: 12px;
}

.print-bottom-footer {
  display: flex;
  justify-content: space-between;

  .print-community-name {
    font-size: 14px;
  }

  .print-op36-footer-wrapper {
    display: flex;
    align-items: center;
  }

  .print-footer-text-wrapper {
    margin-left: 5px;
    .print-op36-text {
      color: $secondary-color;
      font-size: 12px;
      text-transform: uppercase;
    }
    .print-op36-copyright {
      font-size: 8px;
    }
  }
}

.print-passed-icon {
  font-size: 15px;
  margin-bottom: 0px;
  display: none;
}

.print-passed-days {
  font-size: 15px;
  font-weight: 550;
  margin-bottom: 0px;
  color: black;
}

.print-passed-text {
  margin-bottom: 0px;
  margin-top: 5px;
  text-transform: uppercase;
  font-size: 13px;
}

.print-passed-date {
  margin-bottom: 0px;
  font-size: 10px;
  color: black;
}

.print-division-row-text {
  font-weight: 550;
  font-size: 19px;
}

.print-division-yardage {
  font-size: 14px;
  font-weight: 550;
}

.border-division-6 {
  border: 8px solid #ff6633;
}

.border-division-7 {
  border: 8px solid #fddb10;
}

.border-division-8 {
  border: 8px solid #d5de27;
}

.border-division-9 {
  border: 8px solid #2baae1;
}

.border-division-10 {
  border: 8px solid #b51e8c;
}
