@import "../../assets/styles/globals";

#SimplePhotoUploader {
  width: 100%;
  height: fit-content;

  .photo-uploader-input {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 200px;

    .uploaded-image-container {
      width: 100%;
      height: 100%;

      .uploaded-image-wrapper {
        margin-top: 10px;
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        padding: 0;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .clear-image-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $light-font-color;
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: flex-end;
        border: none;
        background: none;
        padding: 0;

        i {
          font-size: 14px;
        }

        p {
          font-size: 14px;
          margin: 0px;
          margin-left: 5px;
        }

        &:hover {
          cursor: pointer;
          color: darken($light-font-color, 20%);
        }
      }
    }

    .empty-uploader {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: $light-font-color;
      border: none;
      background: none;
      padding: 0;

      i {
        font-size: 45px;
      }

      p {
        margin: 0px;
        font-size: 14px;
      }

      &:hover {
        color: darken($light-font-color, 20%);
        cursor: pointer;
      }
    }
  }

  .error-input {
    border-color: $input-error-color;
  }
}
