@import "../../../../assets/styles/globals";

.eventHeader {
  background-color: $dark-navy;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  .eventTitle {
    font-size: 20px;
    font-weight: 800;
  }

  span {
    margin-right: 20px;
    i {
      margin-right: 10px;
    }
  }
}

.headerButtonActions {
  padding-bottom: 10px;
  padding-top: 5px;

  p, a {
    color: $light-navy;
    cursor: pointer;
    font-size: 15px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: $secondary-color;
    }
  }
}

@media (max-width: 1199px) {
  .eventHeader {
    margin-left: 75px !important;
  }
}