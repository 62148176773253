@import "../../../assets/styles/globals";

#DrillsBuilder {
  .lesson-builder-topnav {
    width: 100%;
    background: #1d2933;
    color: #fff;
    padding: 10px;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .question-icon-container {
      i {
        font-size: 2rem;
      }
    }
  }
  .builder-content-area {
    height: calc(100vh - 120px);
    background: #fff;
  }
  .lesson-builder-footer-container {
    // padding: 10px 20px;
    position: absolute;
    background: #1d2933;
    bottom: 0;
    width: 100vw;
    box-shadow: 0 -5px 5px -5px #333;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lesson-plan-builder-action-btn {
      // flex: 0 0 140px;
      width: 160px;
      height: 38px;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .good-button-box {
      flex: 0 0 338px;
      display: flex;
      justify-content: space-between;
    }
    .lp-btn-secondary {
      background: #fff;
      color: $primary-orange;
      border: 1px solid $primary-orange;
      &:disabled {
        background: #ccc;
      }
    }
    .lp-btn-primary {
      background: $primary-orange;
      border: 1px solid $primary-orange;
      &:disabled {
        color: #ccc;
      }
    }
    .next-btn-drill {
      margin-right: 50px;
    }
    button:disabled {
      cursor: not-allowed;
    }
  }
}
