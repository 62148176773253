@import "../../assets/styles/globals";

#AnimatedCompletedEducationBadge {
  padding: 40px 0px;

  .education-badge-image-wrapper {
    height: 225px;
    width: 225px;

    img {
      width: 100%;
    }
  }

  .empty-education-badge-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 225px;
    width: 225px;
    background-color: $light-gray;
    border-radius: 50%;

    i {
      font-size: 150px;
      color: $light-font-color;
    }
  }

  .animated-badge-wrapper {
    background-color: lighten($secondary-color, 25%);

    i {
      color: $secondary-color;
    }
  }
}
