@import "../../../../assets/styles/globals";

#SinglePhotoUploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .uploaded-image-container {
    display: flex;
    flex-direction: row;

    .uploaded-image-wrapper {
      height: 90px;
      width: 160px;
      display: flex;
      background-color: $light-navy;

      .uploaded-image-thumbnail {
        width: 100%;
        object-fit: contain;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .uploaded-file-info-wrapper {
      margin-left: 10px;

      .uploaded-file-name {
        font-size: 14px;
        color: $light-navy;
        opacity: 0.5;
        margin-bottom: 10px;
      }

      .clear-image-wrapper {
        display: flex;
        flex-direction: row;
        color: $primary-orange;
        font-weight: $bold-font-weight;
        justify-content: flex-start;

        i {
          font-size: 14px;
          height: 14px;
        }

        p {
          font-size: 14px;
          margin: 0px;
          margin-left: 5px;
        }

        &:hover {
          color: darken($primary-orange, 20%);
          cursor: pointer;
        }
      }
    }
  }

  .empty-uploader {
    background-color: rgba(0, 0, 0, 0);
    color: $primary-orange;
    border: none;
    font-weight: $bold-font-weight;
    // align-self: flex-start;
    padding: 0;

    i {
      font-size: 45px;
    }

    p {
      margin: 0px;
      font-size: 14px;
    }

    &:hover {
      color: darken($primary-orange, 20%);
      cursor: pointer;
    }
  }
}
