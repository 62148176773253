@import "../../../assets/styles/globals";

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  color: $primary-navy;
  margin-bottom: 48px;
}

.accountSettings {
  padding: 2px 40px !important;


  .deleteSubtitle {
    color: $primary-orange;
    border-bottom: 1px solid $light-gray;
  }

  .deleteMessage {
    font-size: 14px;
  }

  .deleteButton {
    width: fit-content !important;
  }
}

@media (min-width: 1400px) {
  .accountSettings {
    padding: 2px 80px !important;
  }
}