@import '../../../assets/styles/globals';

.eventDetail {
  position: absolute;
  width: 100%;
  height: calc(100% - $page-footer-height);
  margin-bottom: $page-footer-height;
  margin-left: -15px;
  margin-right: -15px;

  .guestList {
    margin-top: -15px;
    padding-bottom:  $page-footer-height;
    .table thead th {
      vertical-align: top;
    }
  }

  .tabList {
    height: 40px;
    background-color: #fff !important;

    .tab {
      background-color: #fff !important;
      margin-top: 0px;
      text-transform: none;
      padding-left: 100px;
      padding-right: 100px;
      font-size: 14px;
      height: 40px;
      font-weight: bold;
      color: black;
    }

    .activeTab {
      border-bottom: 4px solid $secondary-color !important;
      height: 40px;
      color: $secondary-color !important;
      background: transparent !important;
    }
  }

  .tabPanel {
    margin-top: 15px;
  }

  .leaderboardPage {
    height: calc(100vh - $page-header-height - $page-footer-height - 60px);
    padding-top: 30px;
  }

  .leaderboardPageQuickLog {
    height: calc(100vh - $page-header-height - $page-footer-height);
    padding-top: 30px;
  }
}

.editIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  width: 100px;
  height: 100px;
  background-color: $primary-orange;
}