@import '../../../../../assets/styles/globals.scss';

.facilities {
  width: 100%;

  .addressHeader {
    background-color: white;
    border-radius: $card-corner-radius;
    padding: 8px 16px;

    .pinIcon {
      flex-shrink: 0;
    }

    .addressText {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .addressChangeButton {
      box-shadow: none !important;
      background-color: $primary-orange !important;
      border: none;

      &:focus, &:active {
        background-color: $primary-orange !important;
        outline: none;
        border: none;
      }

      &:disabled {
        cursor: not-allowed;

        &:hover {
          background-color: $primary-orange !important;
        }
      }
    }
  }

  .facilitiesList {
    .title {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .exploreLocations {
    .mapPreviewImage {
      height: 210px;
      width: 173;

      // @media (min-width: 1200px) {
      //   height: 360px;
      //   width: 440px;
      // }
    }

    .exploreLocationsTitle {
      font-size: 18px;
      font-weight: 700;
    }

    .exploreLocationsSubtitle {
      font-size: 14px;
    }

    .arrowIcon {
      flex-shrink: 0;
      fill: $placeholder-gray;
      // height: 20px;
      // width: 13px;
    }
  }

  .requestLocation {
    .requestLocationTitle {
      font-size: 18px;
      font-weight: 700;
    }

    .requestLocationSubtitle {
      font-size: 14px;
    }
  }
}
