@import '../../globals';

#ReportsPage{
  .refresh-button {
    background-color: $light-navy;
    border: none;
  }

  .coach-report-container {
    background-color: $off-white;
    padding: 36px;
  }
}