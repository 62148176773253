@import "../../../../assets/styles/globals";

.newMemberLogInData {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 48px;
    }
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
      padding: 48px;
    }

    .titleText {
      color: white;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
      text-align: left;
    }

    .inputSection {
      color: white;
      font-size: 18px;
      font-weight: 700;
      margin: 16px 0;
      text-align: left;
    }

    .inputLabel {
      font-size: 16px;
      color: white;
      font-weight: 700;
    }

    .inputSymbol {
      color: white;
    }

    .avatarWrapper {
      margin-top: 24px;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        margin-top: 31px;
        margin-bottom: 0;
      }
    }
  }
}
