@import "../../../assets/styles/globals.scss";

#SalesLeadDetailModal {
  display: flex;
  line-height: 1.15;
  .EditorToolbar__root___3_Aqz {
    display: none;
  }
  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: 70px;
    min-height: 70px;
  }
  p {
    margin-bottom: 5px;
  }
  i {
    font-size: 18px;
  }
  input[type="text"] {
    border: 1px solid #fff !important;
    font-weight: 400;
    border-radius: 4px !important;
    padding-left: 5px;
  }
  select {
    padding-left: 5px;
    border-radius: 4px !important;
    border: 1px solid #fff;
    font-weight: 400;
  }
  select:disabled {
    border: none !important;
    color: #777 !important;
    cursor: not-allowed !important;
  }
  .button-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #bbb;
    border: none !important;
    width: min-content;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .change-btn {
    text-align: center;
    font-size: 0.8rem;
    color: $primary-orange;
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
  .col-fill {
    flex: 1 1;
  }
  .icon-lg {
    font-size: 28px;
  }
  .aff-select {
    max-width: 122px;
  }
  .int-select {
    max-width: 110px;
  }
  .inline-select {
    margin-left: 2px;
  }
  .title-select {
    width: calc(100% - 38px);
  }
  .hours-select {
    width: calc(100% - 94px);
  }
  .hover-border {
    &:focus {
      border: 1px solid #fff !important;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid #ccc !important;
    }
  }
  .keep-focus {
    &:focus {
      border: 1px solid #ccc !important;
    }
    &:hover {
      cursor: text;
    }
  }
  .navy-pill {
    color: #fff;
    background: #5f6f7c;
    border-radius: 500px;
    margin: 0 2px;
    padding: 3px 8px;
  }
  .modal-main-content {
    flex: 0 0 70%;
    .avatar-container {
      flex: 0 0 75px;
      .avatar {
        border-radius: 50%;
        background-size: cover;
        // background-repeat: no-repeat;
        background-position: center;
      }
    }
    .personal-info {
      flex: 1 1;
      .sales-lead-name {
        font-weight: bold;
        font-size: 36px;
      }
    }
    .checkbox-container {
      flex: 0 0 50%;
      label {
        margin-left: 6px;
      }
    }
    .other-title {
      font-weight: bold;
    }
    .other-community-type {
      color: #36434e;
    }
  }
  .modal-side-content {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    .rep-change-box {
      margin: 4px 18px 0 0;
      padding: 9px;
      border: 1px solid #1d2933;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      .fa-times {
        align-self: flex-end;
        cursor: pointer;
      }
      .box-title {
        align-self: center;
        text-align: center;
        font-weight: bold;
      }
      .inactive {
        color: #c0c0c0;
        cursor: not-allowed;
      }
      .selectable {
        &:hover {
          background: #ddd;
          cursor: pointer;
        }
      }
      .selected {
        background: $primary-orange;
      }
      .button-box {
        display: flex;
        justify-content: space-around;
        margin-top: 18px;
        .cancel-button {
          background: #dc3546;
          border: none;
          padding: 3px 8px;
        }
        .confirm-button {
          background: #007bff;
          border: none;
          padding: 3px 8px;
        }
      }
    }
    .upgrade-container {
      color: #1d2933;
      border: 2px solid #1d2933;
      display: flex;
      flex-wrap: nowrap;
      padding: 5px;
      border-radius: 4px;
      // width: min-content;
      align-self: flex-end;
      &:hover {
        cursor: pointer;
        background: #f4f4f5;
      }
      p {
        margin: 0 0 0 6px;
      }
    }
  }
  .class-row {
    display: flex;
    .class-pic {
      flex: 0 0 60px;
      height: 60px;
      border-radius: 5px;
      background: grey;
      margin-right: 6px;
      background-size: cover;
      background-position: center;
    }
    .class-detail-box {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      .class-progress-outer {
        width: calc(100% - 90px);
        height: 6px;
        border-radius: 500px;
        background: lightgrey;
        overflow: hidden;
        .class-progress-inner {
          background: #44c217;
          height: 100%;
        }
      }
      .percent-text {
        font-size: 13px;
        width: max-content;
        margin: 0;
      }
    }
  }
  .meta-box {
    .status-dot {
      flex: 0 0 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
    }
    p {
      white-space: nowrap;
      margin: 0;
    }
  }
}

#SalesLeadUpgradeModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-box {
    border: 3px solid $primary-orange;
    border-radius: 8px;
    padding: 18px;
    background: #f4f4f5;
    margin-bottom: 18px;
    ul {
      margin: 0;
    }
    p {
      font-weight: bold;
    }
    .bold {
      font-weight: bold;
    }
  }
  form {
    width: 70%;
    min-width: 300px;
    display: flex;
  }
  input {
    padding-left: 5px;
    color: inherit !important;
    font-weight: normal;
    border: 1px solid #ddd !important;
    flex: 1 1;
  }
  .sub-btn {
    flex: 0 0 65px;
    background: $primary-orange !important;
    color: white !important;
  }
  table {
    tbody {
      tr {
        &:hover {
          background: #ccc;
          cursor: pointer;
        }
      }
    }
  }
}

.upgrade-conf-modal {
  .confirmation-button-box {
    display: flex;
    padding: 0 18px;
    margin-bottom: 18px;
    select {
      padding-left: 5px;
      flex: 1 1;
      border: 1px solid $primary-orange;
      border-radius: 4px !important;
      margin-right: 6px;
    }
    button:disabled {
      background: #ccc;
      border: 2px solid black;
      cursor: not-allowed;
    }
  }
  .cancel-button {
    width: 100%;
    background: $light-red;
    border-radius: 500px;
  }
}

.upgrading {
  i {
    font-size: 68px;
  }
}
