@import "../../assets/styles/globals";

.family-admin-container {
  background-color: $primary-color !important;

  .family-admin-header {
    color: $light-navy;
  }

  .family-admin-detail {
    color: $white-font-color;
    font-size: 25px;
  }
}
