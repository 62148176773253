@import "../../../assets/styles/globals";

.mainCard {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  background-color: $dark-navy;
  box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);

  @media (min-width: 768px) {
    height: 80%;
    width: 70%;
    border-radius: 8px;
  }

  @media (min-width: 1200px) {
    height: 80%;
    width: 50%;
    border-radius: 8px;
  }

  .emailVerificationRequired {
    padding-top: 72px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .mainContent {
      flex-grow: 1;
      width: 100%;
      padding: 16px;

      @media (min-width: 768px) {
        padding: 48px;
      }

      .header {
        .titleText {
          color: white;
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 16px;
          text-align: left;
        }

        .subtitleText {
          color: white;
          font-size: 24px;
          font-weight: 700;
        }

        .orangeCheckMarkRoundIcon {
          height: auto;
          flex-shrink: 0;
        }
      }

      .details {
        .mainText {
          color: white;
          font-size: 20px;
          font-weight: 700;
        }

        .emailText {
          color: $primary-orange;
          text-decoration: underline;
        }

        .smallText {
          color: white;
          font-weight: 700;
        }

        .resendEmail {
          color: $primary-orange !important;
          cursor: pointer;

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
          }

          p {
            text-decoration: underline;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
