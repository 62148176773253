@import "../../../assets/styles/globals";

.drill-activity-card {
  box-shadow: $pressable-elem-drop-shadow;
  // width: 33%;
  // max-width: 416px; // Too wide b/c we still have main sidebar
  // max-width: 350px;
  max-width: 380px;
  min-width: 300px !important;
  margin-bottom: $primary-padding;
  margin-right: 18px;
  .drill-activity-card-hover-overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(95, 111, 124, 0.5); // light navy at 50% opacity
    height: 100%;
    width: 100%;
    border-radius: $card-corner-radius;
    padding: 10px;

    img {
      height: 24px;
      width: 24px;
      filter: brightness(0) invert(1);
      margin-left: $secondary-padding;
    }
  }

  .drill-activity-card-author {
    display: flex;
    background-color: $primary-navy;
    padding: 10px;
    border-radius: $card-corner-radius $card-corner-radius 0 0;

    img {
      height: 24px;
      width: 24px;
      border: 2px solid white;
      border-radius: 50%;
    }

    p {
      color: white;
      font-weight: $bold-font-weight;
      margin: 0;
      margin-left: 10px;
    }
  }

  .drill-activity-card-details {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    img {
      height: 87px;
      width: 87px;
      object-fit: cover;
      border-radius: $card-corner-radius;
    }

    .drill-activity-card-details-text {
      padding: 10px;
      padding-right: 2px;
      height: 87px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      max-width: calc(100% - 138.16px);

      p {
        color: $dark-navy;
        font-weight: $bold-font-weight;
        margin: 0;
        text-overflow: ellipsis;
        line-height: 1;
      }

      .drill-card-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .drill-activity-tags-container {
        // margin: 10px 0px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .drill-card-tag {
          background-color: $light-navy;
          color: white;
          padding: 6px;
          margin-right: 6px;
          border-radius: 50px;
          line-height: 1;
          font-weight: 400;
          font-size: 14px;
          white-space: nowrap;
        }

        .drill-card-tag-more {
          color: $light-navy;
          text-decoration: underline;
          font-size: 14px;
        }

        .drill-card-no-tag-indicator {
          color: $light-navy;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .drill-activity-type-container {
        display: flex;
        align-items: center;

        .drill-activity-type-dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }

    i {
      color: $light-navy;
      font-size: $heading-0-size;
      margin: auto;
      margin-right: 10px;
    }
  }

  // All hover states here
  &:hover {
    .drill-activity-card-hover-overlay {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      &:hover {
        img {
          &:hover {
            filter: none;
          }
        }
      }
    }
  }
}

.drill-activity-card-50 {
  width: calc(50% - 18px);
}

.drill-activity-card-100 {
  width: 100%;
}
