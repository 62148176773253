@import '../../../assets/styles/globals.scss';

.CoachDashboardProgramsOverview {
  border-radius: 10px;
  width: 470px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 10px;
  padding-bottom: 10px;

  .title {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
  }

  .programsOverviewContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .programsOverviewItem {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-left: 20px;

      .info {
        margin-left: 10px;

        .text{
          font-size: 14px;
        }

        .data {
          font-size: 20px;
          font-weight: 700;
        }

        .textSpots {
          font-size: 12px;
          font-weight: 700;
          
          .spotsTaken {
            font-size: 20px;
          }
        }
      }
      .iconWrapper {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $primary-orange;
  
        .icon {
          fill: white;
        }
      }
    }
  }

  .programsOverviewAction {
    margin: 30px 20px 30px 20px;
    padding: 10px;
    border: 1px solid $placeholder-gray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .textContainer {
      display: flex;

      .actionIcon {
        width: 20%;
      }

      .actionText {
        width: 80%;
        font-size: 12px;
      }
    }

    .buttonContainer {
      margin-top: 15px;

      .buttonIcon {
        fill: $primary-orange;
        margin-right: 10px;
      }

      .actionButton {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: $primary-orange;
        font-size: 14px;
        text-decoration: underline;
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px){
  .CoachDashboardProgramsOverview {
    width: 400px;

    .programsOverviewContainer {
      .programsOverviewItem {
        .info {

          .data {
            font-size: 18px;
          }

          .textSpots {            
            .spotsTaken {
              font-size: 18px;
            }
          }
        }

        .iconWrapper {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}