@import "../../../assets/styles/globals";
#ConfigPage {
  margin: $secondary-padding;
  padding: $secondary-padding;

  #config-card-main-content {
    display: flex;
    margin-bottom: $secondary-padding;

    #config-card-part-1 {
      flex: 0 0 400px;
      padding-right: $secondary-padding;
      border-right: 1px solid rgba(95, 111, 124, 0.5); // light-navy, 50% opacity
      .pendo-trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        color: $primary-orange;
        font-size: 20px;
        p {
          margin: 0;
          margin-left: 6px;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    #config-card-part-2 {
      padding-left: $secondary-padding;
      border-left: 1px solid rgba(95, 111, 124, 0.5); // light-navy, 50% opacity
      min-width: 400px;
      flex: 1 1;
    }
  }

  .config-card-header {
    font-weight: $bold-font-weight;
  }

  .config-card-header.card-title {
    font-size: $heading-1-size;
    margin-bottom: $secondary-padding;
    text-align: center;
  }

  .card-heading-1 {
    font-size: $heading-2-size;
    margin-bottom: $secondary-padding;
  }

  .academy-display-mockup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    // width: auto;
    margin: 0 auto;
    margin-bottom: $secondary-padding;

    img {
      height: 150px;
      width: auto;
    }
  }

  #display-all-label {
    font-size: $heading-3-size;
    font-weight: $bold-font-weight;
    margin-left: $secondary-padding;
    margin-bottom: 0;
  }

  hr.section-divider {
    margin: $secondary-padding 0;
    border: 0;
    background-color: $light-navy;
    opacity: 0.5;
    height: 2px;
    border-radius: 2px;
  }

  .config-card-items {
    display: flex;
    flex-wrap: wrap;
  }

  .config-item-checkbox {
    // Adapated from CommunityContactForm.scss
    height: 20px;
    // width: 20px;
    background-color: white;
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    border: 2px solid $primary-orange;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .config-item-checkbox-checked {
    background-color: $primary-orange;

    i {
      color: white;
    }
  }

  .config-item-checkbox:hover,
  .config-item-checkbox-checked:hover {
    cursor: pointer;
    filter: brightness(85%);
  }

  #display-all-checkbox-item {
    display: flex;
  }

  #display-all-label {
    margin: 0;
    margin-left: $secondary-padding;
  }

  .display-input {
    margin-bottom: $secondary-padding;

    label {
      font-weight: $bold-font-weight;
      font-size: $heading-3-size;
      margin: 0px;
      margin-right: 10px;
    }

    input,
    textarea {
      padding: 0px;
      border: none !important;
      border-bottom: 2px solid $light-navy !important;
    }
  }

  #display-call-to-action-input {
    // display: flex;

    div {
      flex: 1;
    }

    input {
      width: 100%;
    }

    // textarea {
    //   width: 100%;
    //   resize: none;
    // }
  }

  #call-to-action-char-limit {
    color: $light-navy;
    margin-bottom: 0px;
  }

  #display-duration-input {
    input {
      width: 100px;
    }
  }

  @media screen and (max-width: 930px) {
    #config-card-main-content {
      flex-direction: column;

      #config-card-part-1, #config-card-part-2 {
        border: none;
      }
    }

    #config-card-part-1 {
      .pendo-trigger {
        margin-bottom: 60px;
      }
    }
  }
}
