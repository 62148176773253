@import "../../../../assets/styles/globals";

.forgotPasswordPage {
  color: white;
  position: fixed;
  background-image: url("../../../../assets/images/auth/sign-up-background-desktop.jpg");
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  .backArrowIcon {
    position: fixed;
    cursor: pointer;
    width: 24px !important;
    height: auto;
  }

  .mainCard {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
    background-color: $dark-navy;
    overflow-y: auto;
    padding: 16px;

    @media (min-width: 768px) {
      background-image: none;
      height: 80%;
      width: 70%;
      border-radius: 8px;
      padding: 48px;
    }

    @media (min-width: 1200px) {
      height: 80%;
      width: 50%;
      border-radius: 8px;
    }
  }
}
