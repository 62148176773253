@import "../../assets/styles/globals";

.programsScreen {
  background: $off-white;
  overflow-y: auto;

  @media (min-width: 992px) {
    width: 75%;
    height: 100%;
  }

  .bookingLink {
    font-weight: 800;
    color: inherit;
    margin-top: 0.5rem;
    margin-right: 2rem;

    &:hover {
      text-decoration: underline;
    }

    .angleRight {
      font-size: 1.25rem;
      font-weight: inherit;
    }
  }
}
