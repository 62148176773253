.avatar {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 70px;
  width: 70px;
  background-position: center;

  &.with-border {
    border: solid 2px white;
  }

  img.picture {
    width: 100%;
  }

  img.label {
    border-radius: 0;
    position: absolute;
    bottom: 4%;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 85%;
    min-width: 70%;
    font-size: 12px;
  }

  span.label {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    background: #ff6633;
    color: white;
    text-transform: uppercase;
    max-width: 80%;
    font-size: 12px;
  }
}

.avatar-web {
  text-align: center;
  position: relative;
  border-radius: 50%;
  min-height: 40px;
  min-width: 40px;
  background-position: center;

  img.picture {
    border-radius: 50%;
    width: 40% !important;

    &.with-border {
      border: solid 2px #ff6633;
    }
  }

  img.label {
    // display: none;
    border-radius: 0;
    position: absolute;
    bottom: -12%;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 33% !important;
    min-width: 25% !important;
    font-size: 12px;
  }

  span.label {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-bottom: 20px;
    background: #ff6633;
    color: white;
    text-transform: uppercase;
    max-width: 20%;
    font-size: 12px;
  }
}

.avatar-section {
  h6 {
    color: #303e4a;
    margin-top: 4px;
  }
}

/* xs < 768 */
@media screen and (max-width: 767px) {
  .avatar {
    height: 60px;
    width: 60px;

    &.is-header {
      height: 80px !important;
      width: 80px !important;
    }
  }
}

/* sm */
@media screen and (max-width: 768px) {
  .avatar {
    height: 60px;
    width: 60px;

    &.is-header {
      height: 80px !important;
      width: 80px !important;
    }
  }
}
