@import '../../../../../assets/styles/globals.scss';

.exploreLocationsCard {
  background: white;
  border-radius: $card-corner-radius;
  padding: 24px 32px;
  cursor: pointer;
  width: 100%;

  .mapPreviewImage {
    width: 170px;
    height: 140px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .subtitle {
    color: $light-navy;
  }

  .arrowIcon {
    flex-shrink: 0;
    fill: $light-navy;
  }
}
