@import '../../../assets/styles/globals.scss';

.dashboardHeaderNotifications {
  display: flex;
  flex-direction: row;

  .notificationButton {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 60px;

    .icon {
      height: 20px;
      width: 20px;
      fill: white
    }

    .label {
      color: white;
      font-weight: 500;
      padding-left: 10px;
      margin-bottom: 0px !important;
    }

    .badge {
      background-color: $secondary-color;
      border-radius: 50%;
      height: 7px;
      width: 7px;
      margin-bottom: 10px;
      margin-left: -4px;
    }
  }
}