@import "../../globals";

.modal-body {
  .react-datepicker__input-container {
    display: block;
  }
}

.issue-badge-btn {
  z-index: 99999 !important;
}

.StudentRow {
  width: 100% !important;

  .classDetailPage-avatar {
    &:hover {
      cursor: pointer;
    }
  }
}

.resource-card-container {
  padding-top: 110px !important;
}

.add-resource-btn {
  position: absolute;
  right: 25px;
}

#ResourceCard {
  height: 180px;
  margin-bottom: 25px !important;

  .resource-card-container {
    background-color: white;
    padding-top: 0px !important;
    border: 1px solid lightgrey;
  }

  .resource-image {
    height: 180px;
    width: 180px !important;
    padding: 0px;
  }

  .resource-title {
    margin-top: 5px;
    font-size: 22px;
    margin-left: 15px;
    color: #ff6633;
  }

  .resource-desc {
    margin-left: 15px;
    font-size: 13px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .resource-btn {
    position: absolute;
    bottom: 5px;
    margin-left: 15px;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .resource-edit-btn {
    position: absolute;
    bottom: 5px;
    right: 8px;
    color: #ff6633;
  }

  .description-container {
    padding: 15px !important;
    padding-left: 5px !important;
  }
}

.recommended-size-text {
  font-size: 14px;
  color: lightgrey;
  text-align: right;
}
