@import "../../assets/styles/globals";

#FamilyCardContainer {
  margin-bottom: 15px;

  .card {
    transition: all 0.2s ease-in-out;
    padding: 10px;

    &:hover {
      //   background-color: $light-gray;
      cursor: pointer;
      transform: scale(0.95);
    }

    .family-card-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .family-name {
        font-size: 18px;
        margin-bottom: 0px;
        color: $primary-color;
      }

      .family-card-divider {
        margin-top: 10px;
        height: 1px;
        width: 90%;
        background-color: lighten($light-gray, 5%);
      }

      .family-points {
        font-size: 25px;
        margin-bottom: 0px;
        font-weight: 550;
        color: $secondary-color;
      }

      .family-students {
        margin-bottom: 0px;
        color: $light-font-color;
      }
    }
  }
}
