@import "../../../assets/styles/globals";

.fullName {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  min-width: 12ch;
  max-width: 20ch;
}

.editAvatar {
  padding: 8px !important;
  line-height: 0 !important;
  z-index: 2;
  right: 0;
  top: 0;
}

.saveAvatarBtn {
  position: absolute;
  bottom: -30px;
}

.avatarWrapper {
  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 82px;
  width: 82px;
  background-position: center;
  flex-shrink: 0;

  .roundImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1400px) {
  .fullName {
    min-width: 8ch;
    max-width: 12ch;
  }
}