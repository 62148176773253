#DivisionPopover {
  padding: 30px 15px 0 15px;
  // .small-largeIcon {
  //   transform: scale(0.465);
  // }
  .progress-area {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .bottom-text {
    font-size: 0.8rem;
    position: relative;
    top: -35px;
  }

  .progress-tracker-area {
    padding: 0 50px;
    position: relative;
    top: -80px;
  }

  .circle-container {
    display: flex;
    justify-content: space-around;
    padding: 0 6px;
  }

  .progress-container {
    height: 5px;
    // width: 134px;
  }

  .progress-bar-1,
  .progress-bar-6 {
    background-color: #ff6633;
  }
  .progress-bar-2,
  .progress-bar-7 {
    background-color: #fddb10;
  }
  .progress-bar-3,
  .progress-bar-8 {
    background-color: #d5de27;
  }
  .progress-bar-4,
  .progress-bar-9 {
    background-color: #2baae1;
  }
  .progress-bar-5,
  .progress-bar-10 {
    background-color: #b51e8c;
  }

  .fifty,
  .fortyfive,
  .thirtynine,
  .thirtysix {
    position: relative;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    font-size: 0.7rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -14px;
  }

  .current-division-progress,
  .next-division-progress {
    z-index: 3;
  }
}
