@import "../../../../assets/styles/globals";

#SummaryPanel {
  @media (min-width: 992px) {
    height: 90%;
    width: 90%;
    padding-top: 5%;
    padding-bottom: 2em;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }

  @media (min-width: 1800px) {
    font-size: 1.3rem;
  }

  .summary-step-details {
    height: 60px;

    @media (min-width: 768px) {
      height: 100px;
    }
  }

  .registration-details-wrapper {
    flex-basis: 50%;
  }

  .registration-program-details {
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }

    @media (min-width: 992px) {
      padding: 1.5rem 1rem;
    }

    .program-title {
      font-weight: bold;
      margin-bottom: 0.5em;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (min-width: 768px) {
        margin-bottom: 1em;
        font-size: 1.5em;
      }

      @media (min-width: 1400px) {
        font-size: 1.8em;
        -webkit-line-clamp: 4;
      }
    }

    .program-dates, .program-meeting-times {
      margin-bottom: 0;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (min-width: 768px) {
        margin-bottom: 0.5em;
      }

      @media (min-width: 1400px) {
        font-size: 1.5em;
      }
    }
  }

  .registration-enrollment-details {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    @media (min-width: 992px) {
      padding: 1em 1em;
    }

    @media (min-width: 1400px) {
      padding: 1.5em 1em;
    }

    .selected-family-member {
      margin-bottom: 1em;

      .full-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .selected-package-name {
      margin-bottom: 0.25em;
    }

    .selected-package-price {
      color: $primary-orange;
      margin-bottom: 0;
    }
  }

  .white-border {
    @include bordered($white-font-color, 2px);
  }

  .community-name {
    font-size: 1em;
    color: $white-font-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (min-width: 1400px) {
      font-size: 1.3em;
    }
  }

  .registration-summary-separator {
    background-color: $border-lighter-gray;
    height: 1px;
    border-radius: 3px;
  }
}
