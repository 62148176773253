#PlayerProgress {
  // position: relative;
  .grey-out {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(43, 56, 67, 0.85);
    z-index: 9999;
  }

  .popup {
    position: fixed;
    top: 125px;
    left: 0;
    right: 0;
    bottom: 10px;
    overflow-y: scroll;
    z-index: 10000;
    h4 {
      text-align: center;
      width: 100%;
      font-weight: 800;
      color: #fff;
      position: absolute;
      //top: -35px;
    }
  }
  .info-modal {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    // height: 85vh;
    width: 550px;
    background: #fff;
    border-radius: 12px;
    border: 3px solid #fff;
    p {
      margin: 0;
    }
    .track-container {
      color: #fff;
      padding: 10px 0 10px 40px;
      .info-modal-section-title {
        font-size: 1.2rem;
        font-weight: 550;
        .orange-pill {
          font-size: 0.9rem;
          font-weight: 400;
          padding: 0 10px;
          float: right;
          background: #f68621;
          border-radius: 500px;
          margin-right: 5px;
        }
      }
      .info-modal-section-subtitle {
        font-weight: 550;
      }
      ul {
        margin: 0;
      }
    }
    .exploratory-track-container {
      background: #33d9b2;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .social-track-container {
      background: #6677de;
    }
    .recreational-track-container {
      background: #34ace0;
    }
    .competitor-track-container {
      background: #0e7cac;
    }
    .college-track-container {
      background: #36434e;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .card {
    padding: 10px;
  }
  #StateSwitcher {
    margin-bottom: 5px;
  }
  .age-override {
    height: 25px;
    font-size: 0.8rem;
    width: 160px;
    margin-left: auto;
    margin-bottom: 5px;
  }
  .data-area {
    height: 100%;
    width: 100%;
  }
  .data-row {
    height: 100%;
    width: 100%;
  }
  .no-prog-tracking-row {
    border: none;
  }

  .progress-header {
    position: relative;
    img {
      height: 85px;
      // width: 85px;
      flex: 0 0 85px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .info-container {
      display: inline-block;
      h2 {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
      }
      span {
        font-weight: bold;
      }
      .track-label {
        margin: 0 5px 0 0;
      }
      .current-track-text {
        font-size: 16px;
        font-weight: 550;
        color: #33d9b2;
      }
      .goal-label {
        display: block;
        border: 1px solid #fff !important;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // text-indent: 1px;
        // text-overflow: "";
        padding: 6px;
        // height: calc(6px + 1.25rem);
        border-radius: 5px !important;
        color: #33d9b2;
        &:focus {
          box-shadow: none;
          border: 1px solid #fff;
        }
        &:hover {
          border: 1px solid #ccc !important;
        }
      }
    }

    .info-card-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: #fff;
        display: inline-block;
        margin-left: auto;
        margin-bottom: 0;
        padding: 2px 10px;
        border-radius: 10px;
      }
      h4 {
        margin: 0;
        .fa-trademark {
          font-size: 0.8rem;
          vertical-align: super;
        }
        .question-button {
          font-size: 1.2rem;
          margin: 0 !important;
        }
      }
    }
  }

  .right-line {
    border-right: 1px dashed #bbb;
  }

  .graph {
    position: absolute;
    right: 23px;
    top: 10px;
    width: 74.1%;
    height: 89.2%;
    z-index: 1000;
  }

  .round-icon {
    height: 38px;
    width: 38px;
    border-radius: 50%;
  }
  .x-axis {
    width: 100%;
    p {
      font-size: 10px;
      margin-top: 3px;
      color: #2b3843;
      border: 1px solid #2b3843;
      background: #d8d8d8;
      border-radius: 15px;
      padding: 1px 5px;
      overflow: hidden;
    }
  }
  .y-axis {
    // display: flex;
    // align-items: center;
    p {
      color: #2b3843;
      font-size: 0.8rem;
      font-weight: bold;
    }
    .division-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.1rem;
    }
    .division-title-label {
      font-size: 0.5rem;
      margin-bottom: 0;
      font-weight: 350;
      margin-left: 2px;
    }
  }
  .icon-wrapper {
    height: 40px;
    position: relative;
    top: 2px;
    // height: auto;
    width: 40px;
    border-radius: 50%;
    background: #fff;
    padding: 2px;
    overflow: show;
  }

  .top-row {
    height: 45px;
    border-bottom: 1px dashed white;
  }

  .milestone-box {
    height: 100%;
    width: 100%;
    padding: 3px 2px;
  }
  .milestone-circle {
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #3b4650;
    background: #d8d8d8;
    color: #3b4650;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    p {
      margin: 0;
    }
    @media screen and (max-width: 1324px) {
      height: 28px;
      width: 28px;
    }
  }
}
