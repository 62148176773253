@import "../../assets/styles/globals";

.icon {
  fill: $light-navy;
  height: fit-content;
  width: 65px;
}

.title {
  font-size: 1.3rem;
  font-weight: 800;
}

.message {
  color: $light-navy;
  text-align: center;
  white-space: pre-wrap;
}

.viewFuturePrograms {
  color: $primary-orange;
  padding: 0;
  background: transparent !important;
  border: none;

  &:hover {
    text-decoration: underline;
  }
}
