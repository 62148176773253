@import "../../../../assets/styles/globals";

#ProgramRegistrationCompletedPage {
  color: $dark-navy;
  font-size: inherit;

  .summary-panel-wrapper {
    height: auto;
    position: sticky;
    top: 0;
    background-color: $dark-navy;
    color: white;
    z-index: 1;

    @media (min-width: 992px) {
      height: 100%;
      position: static;
    }
  }

  .post-registration-wrapper {
    padding: 1rem 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 575px), (max-height: 575px) {
      padding: 1rem 0.5rem;
    }
  }

  .post-registration-title {
    color: $primary-teal;
    font-weight: bold;

    @media (min-width: 992px) {
      color: $dark-teal;
    }

    .post-registration-check-icon {
      height: 1.5em;
    }
  }
}

.program-registration-spinner {
  color: $primary-orange;
}
