@import "../../../assets/styles/globals";

.drill-activity-card-hover-overlay-menu {
  background-color: white;
  position: absolute;
  top: 48px;
  border-radius: $card-corner-radius;
  box-shadow: $non-pressable-elem-drop-shadow;
  padding: 10px;
  padding-bottom: 0px;
  z-index: 10;

  p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 10px;
    color: #1d2933;
    &:hover {
      text-decoration: underline;
    }
  }

  .delete-drill-activity-btn {
    color: $primary-orange;
  }
}
