@import '../../../../assets/styles/globals.scss';

.event-detail-container {
  height: 100%;
  overflow: scroll;

  .event-image-description {
    width: 90%;
    position: relative;
    margin: auto;
    .event-detail-image-wrapper {
      height: 257px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: $light-gray;
      border: 1px solid $comments-form-border;
      border-bottom: 0;
      border-radius: 16px 16px 0 0;
      margin: auto;

      img {
        object-fit: cover;
        width: 100%;
        height: inherit;
      }

      i {
        font-size: 100px;
        color: $light-font-color;
      }

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    .event-data {
      background-color: #fff;
      border: 1px solid $comments-form-border;
      border-top: 0;
      border-radius: 0 0 16px 16px;
      padding: 15px;

      .event-name {
        font-weight: 800;
        padding-bottom: 15px;
      }

      .event-description {
        font-size: 14px;
        color: $light-navy;
      }
    }
  }

  .event-activity {
    width: 90%;
    margin: auto;

    h5 {
      font-weight: 550;
      color: $primary-color;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 0px;
    }

    button {
      font-size: 12px;
    }

    .event-social-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;

      p {
        margin: 0px;
        font-size: 16px;
        font-weight: 550;
        color: $secondary-color !important;
        margin-left: 5px;
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
          font-size: 20px;
          color: $secondary-color;
        }

        .event-clap-image-wrapper {
          border: none;
          padding: 0px;
          width: 22px;
          height: 22px;
          img {
            width: 100%;
          }
        }
      }
    }

    .event-detail-comment-container {
      background-color: #fff;
      min-height: 350px;
      overflow-x: hidden;
      border: 1px solid $light-gray;
      border-radius: 8px;
      margin-top: 25px;
      margin-bottom: 50px;

      .comment {
        width: 100%;
        margin: auto;
      }
    }
  }
}
