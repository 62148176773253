@import "../../../../assets/styles/globals";

.footer-zone {
  background-color: $primary-orange;
  // padding: $primary-padding;
  padding: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
  grid-area: footer-zone;
  overflow: hidden;
}

.footer-zone-text {
  color: white;
  // font-size: $heading-1-size;
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  margin: 0;
  // Can't set line-height of 1 or else bottom of descenders get cut off
  // E.g. g, p, y
  // line-height: 1;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
