@import "../../../assets/styles/globals";

.footer-container {
  height: 100px;
  z-index: 0 !important;
  background: white;
  border-top: 1px solid $border-darker-gray;

  @media (min-width: 576px) {
    height: 70px;
  }

  .content {
    width: 100%;
    height: 100%;
    margin: auto;

    @media (min-width: 768px) {
      width: 80%;
    }

    @media (min-width: 1200px) {
      width: 60%
    }

    .step-button {
      height: 50%;

      @media (min-width: 576px) {
        height: 100%;
      }

      .back-button {
        background-color: $primary-navy !important;
        padding: 6px 20px;

        &:hover, &:active, &:visited, &:focus, &:focus-visible {
          background-color: $primary-navy !important;
        }

        .back-icon {
          width: 24px;
          height: 24px;
          margin-right: 24px;
        }
      }

      .next-button {
        background-color: $primary-orange !important;
        padding: 6px 20px;

        &:hover, &:active, &:visited, &:focus, &:focus-visible {
          background-color: $primary-orange !important;
        }

        .next-icon {
          width: 24px;
          height: 24px;
          margin-left: 24px;
          transform: rotate(180deg);
        }
      }

      .save-button {
        background-color: $primary-orange !important;
        width: 170px;
        padding: 6px 20px;

        &:hover, &:active, &:visited, &:focus, &:focus-visible {
          background-color: $primary-orange !important;
        }
      }
    }
  }
}