@import "../../../../assets/styles/globals.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 30px;
  background-color: white;
  border-radius: 8px;
  border-left: 4px solid red;
  margin-bottom: 12px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .title {
      font-size: 20px;
      font-weight: 800;
    }

    .subtitle {
      color: $light-navy;
    }
  }

  .golferContainer {
    display: flex;
    gap: 5px;
    align-items: center;

    .students {
      color: black;
    }
  }
}
