// Widgets 

.onboarding-widget{
	color: $mid-font-color;
	background-color: lighten($primary-color, 70%);
	@include border-radius(10px);
}

.profileHeader{
	background-color:#222;
	// background: url('/images/mock/StockPhoto.png');
	background-size: cover;	
}


.trophy-rank{
	* h6{
		margin-top: 2px;
		font-size: 10px;
	}		
}

.border-box{
	border: solid 1px;
	border-color:$mid-font-color;
}

.trophy-rank{
	@include transition(1s);
	@include transition-timing-function (ease-in-out); 
	@include transition-delay (2s); 
	&-closed{
		height:0px;
		overflow: hidden;
		-webkit-scale: (0,0);
    	-ms-scale: (0,0);
    	scale: (0,0);
    }	
	
	&-open{
		height:auto;
	}
}


.titleLineToggle{
	* .fa{
		@include transition(.5s);
		margin-top: 0.1em;
		margin-left: 12px;
	}
	* .fa.fa-rotate-180{
		margin-top: 0.3em;
	}
}

.classDetailPage-avatar{
	width: 30px;
	height: 30px;
	@include border-radius(30px);
}

// 

.rankIcon{
	width: 38px;
	height: 38px;
	@include border-radius(38px);
	p{

	}	
	&-sm{
		width: 24px;
		height: 24px;		
		@include border-radius(24px);
		p{
			font-size: 15px;
		}
	}		
} 

.objectiveRankBadge{	
	$objectiveRankBadgeSize: 50px;
	border-radius: $objectiveRankBadgeSize;
	width: $objectiveRankBadgeSize;
	height: $objectiveRankBadgeSize;
	margin-left: auto;
    margin-right: auto;
	display: block;
	img{		
		padding: 1px;		
		width: 100%;
		display: block;
    	margin: auto;
	}
}



// Tables
tr.border-bottom{
	background: $white-font-color;
}




// Modals

.modal-content{
	border: none;
}

