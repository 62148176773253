@import "../../../assets/styles/globals";

#LessonPlanViewerPage {
  height: 100vh;
  width: 100vw;
  .lesson-plan-viewer-container {
    height: 100%;
    width: 100%;
    .lp-viewer-top-header {
      width: 100%;
      height: 70px;
      background: #1d2933;
      color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      align-items: center;
      // padding: 0 18px;
      i {
        margin: 0 18px;
        flex: 0 0;
        font-size: 36px;
        &:hover {
          cursor: pointer;
          color: $primary-orange;
        }
      }
      .fa-ellipsis-v {
        padding: 0 5px;
      }
      .lp-name {
        flex: 1 1;
        margin: 0;
        font-size: 36px;
        padding: 0 10px;
      }
      .view-delete-popover {
        position: absolute;
        background: #fff;
        border-radius: 5px;
        color: $primary-orange;
        right: 18px;
        top: 65px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        padding: 2px 15px 5px 15px;
        &:hover {
          background: #f4f4f5;
        }
        p {
          margin: 0;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  #LessonReviewPublish {
    height: calc(100% - 70px);
    #DrillPreviewCard:hover {
      cursor: pointer;
    }
    #CurriculumPreviewCard:hover {
      cursor: pointer;
    }
  }
}
