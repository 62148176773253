// Typography Assets go here
@import url("https://fonts.googleapis.com/css?family=Lato:200,200i,400,400i,600,600i,700,800,900,900i");

* {
  // font-family: Helvetica, sans-serif;
  font-family: "Lato", sans-serif;
}

body {
  * a {
    color: $secondary-color;
  }
}

* a {
  color: $secondary-color;
}

@each $weight in 200, 400, 600, 700, 800, 900 {
  .font-#{$weight} {
    font-weight: #{$weight};
  }
}

@each $size in 14 {
  .font-size-#{$size} {
    font-size: #{$size}px;
  }
}

.text-fade {
  opacity: 0.5;
}

.text {
  &-primary {
    color: $secondary-color !important;
  }
}

.bg {
  &-light {
    background-color: #eee;
  }
}

.pagetitle {
  font-size: 4em;
  font-weight: 200;
  color: $secondary-color;
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.font-200 {
  letter-spacing: 0.1em;
}

.h-overflow {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.p-overflow {
  &-2 {
    max-height: 2em;
    overflow: hidden;
    &:after {
      content: "...";
    }
  }
}

@media (max-width: 991px) {
  .page-header h1 {
    font-size: 20px;
  }
}
