@import "../../../assets/styles/globals";

#ProgramRow {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $light-font-color;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  .program-photo-wrapper {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    .lp-icon {
      background-size: cover;
      background-position: center;
      height: 80%;
      flex: 0 0 80%;
    }
  }
  .lp-icon-wrapper {
    background-color: $primary-orange;
  }

  .program-no-photo {
    background-color: $light-gray;

    i {
      font-size: 30px;
      color: $light-font-color;
    }
  }

  .program-info-wrapper {
    margin-left: 15px;

    h6 {
      margin: 0px;
      color: $primary-color;
      font-weight: 550;
    }

    .program-organization-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;

      i {
        font-size: 14px;
        color: $light-font-color;
        margin-right: 5px;
      }

      p {
        margin: 0px;
        font-size: 14px;
        color: darken($light-font-color, 15%);
      }
    }
  }

  &:hover {
    background-color: lighten($light-gray, 10%);
    cursor: pointer;
  }
}
