@import "../../../../assets/styles/globals";

#GolfersTable {
  background-color: white;

  .payment-status-column-header {
    min-width: 145px;
    white-space: nowrap;
  }

  .tuition-column-header, .points-column-header, .date-joined-column-header {
    white-space: nowrap;
    text-align: center;
  }

  .actions-column-header {
    min-width: 186px;
    width: 186px;
    text-align: center;
  }

  .table-header {
    text-transform: none;
    border-top: 0 !important;
    background-color: $off-white;
  }

  .cell {
    padding: 10px 0px;
  }

  .table-body {
    .tr {
      height: 67px;
      border-bottom: 1px solid $border-darker-gray;

      &.table-header {
        border-bottom-width: 2px;
      }

      .td {
        padding: 10px 0px;
      }

      .golfer-avatar-wrapper {
        overflow: hidden;
        flex-shrink: 0;
        border: 2px solid #1D2933;
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }

      .golfer-info {
        width: 100%;
        min-width: 125px;

        .golfer-name {
          color: $dark-navy;
          font-weight: 700;
          margin-bottom: 0;
        }

        .golfer-age-gender {
          font-size: 0.9rem;
          margin-bottom: 0;
        }
      }

      .package-column {
        min-width: 200px;
      }

      .tuition-column, .date-joined-column {
        text-align: center;
      }

      .payment-status-column {
        white-space: nowrap;
      }
    }
  }
}
