@import "../../../assets/styles/globals";

#ChallengeForm {
  // border: 2px solid #777;
  padding: 15px;
  // border-radius: 8px;
  // display: flex;
  p {
    margin: 0;
  }
  .required-field {
    color: red;
  }
  .challenge-inner-form {
    border: 2px solid #777;
    padding: 15px 30px;
    border-radius: 8px;
    max-width: 900px;
    background: #fff;
    // height: 600px;
    margin-right: auto;
    margin-left: auto;
    .challenge-form-top-container {
      display: flex;
      justify-content: space-between;
      .challenge-photo-area {
        flex-basis: 48%;
        flex-grow: 0;
        flex-shrink: 0;
        // #SimplePhotoUploader {
        background: #f4f4f5;
        border-radius: 5px;
        // }
        .subtext {
          font-size: 0.7rem;
          color: #999;
        }
      }
      .challenge-form-name-area {
        flex-basis: 48%;
        flex-grow: 0;
        flex-shrink: 0;
        // input {
        //   width: 100%;
        //   margin-bottom: 10px;
        //   &:focus {
        //     border-bottom: 1px solid #ddd !important;
        //   }
        // }
        .date-box-container {
          display: flex;
          justify-content: space-between;
          .date-box {
            flex-basis: 49%;
            flex-grow: 0;
            flex-shrink: 0;
          }
        }
      }
    }
    input {
      width: 100%;
      margin-bottom: 10px;
      &:focus {
        border-bottom: 1px solid #ddd !important;
      }
    }
    textarea {
      width: 100%;
      height: 150px;
    }

    .links-container {
      .single-link-card {
        width: 90%;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid #bbb;
        border-radius: 5px;
        padding: 15px;
        position: relative;
        .remove-link-card {
          position: absolute;
          height: 24px;
          width: 24px;
          top: 8px;
          right: 8px;
          border-radius: 50%;
          color: #fff;
          background: red;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: darkred;
            cursor: pointer;
          }
        }
      }
      .add-link-button {
        color: $primary-orange;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .challenge-form-bottom-container {
      display: flex;
      justify-content: space-between;
      .challenge-form-icon-loader-container {
        flex-basis: 30%;
        flex-grow: 0;
        flex-shrink: 0;
        background: #f4f4f5;
        border-radius: 8px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .challenge-form-detail-area {
        flex-basis: 67%;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
    .challenge-form-submit-container {
      display: flex;
      justify-content: center;
    }
    .failed-validation-box {
      width: 75%;
      border: 2px solid #ff2a49;
      color: #ff2a49;
      padding: 15px;
      border-radius: 5px;
      margin-right: auto;
      margin-left: auto;
      font-weight: 550;
      text-align: center;
    }
  }
  .failed-validation {
    background: #ffc0c9 !important;
  }
}
