@import "../../../../assets/styles/globals";

#Leaderboard {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  overflow: scroll;

  .event-leaderboard-container {
    .event-description-wrapper {
      border-bottom: 1px solid $light-gray;
      margin-bottom: 20px;

      p {
        font-size: 14px;
      }
    }
    .event-leaderboard-text {
      font-weight: 550;
      color: $primary-color;
      margin-bottom: 0;
    }

    .event-leaderboard-table-header {
      border-bottom: 1px solid $light-gray;
      padding-bottom: 10px;
      p {
        font-size: 12px;
        color: $light-font-color;
      }
    }

    .event-filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;

      button {
        font-size: 14px;
      }

      .active-filter-btn {
        color: $light-font-color;
        border-bottom: 2px solid $secondary-color;
      }
    }

    margin-top: -15px;
    .leaderboard-table {
      thead {
        height: 57px;
      }

      th {
        padding-bottom: 0.25rem;
        // text-align: center;
        color: $light-navy !important;
        font-size: 14px;
        font-weight: 700px;
      }

      td {
        // text-align: center;
        vertical-align: middle;
        align-items: center;
      }

      .division-1 {
        color: $division-01-color !important;
      }
      .division-2 {
        color: $division-02-color !important;
      }
      .division-3 {
        color: $division-03-color !important;
      }
      .division-4 {
        color: $division-04-color !important;
      }
      .division-5 {
        color: $division-05-color !important;
      }
      .division-6 {
        color: $division-06-color !important;
      }

      .students-table-row {
        .milestones-col {
          color: $dark-teal;
          font-size: 14px;
          font-weight: 800;
        }

        .score-col {
          font-weight: 700;
          color: $dark-teal;
        }

        .golfer-col {
          padding-left: 30px;
        }
      }

      .golfer-header {
        text-align: left;
        padding-left: 30px;
      }


    }

    th {
      border-top: 0;
    }

    td {
      border-bottom: 1px solid $border-lighter-gray;
    }

    .leaderboard-section {
      padding-top: 25px;
    }
  }
}
