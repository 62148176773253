@import "../../../assets/styles/globals";

#LessonPlanBuilderPage {
  // margin: 0 -15px;
  // width: calc(100% + 30px);
  // height: 100vh;
  overflow: hidden;
  .builder-content-area {
    height: calc(100vh - 120px);
    background: #fff;
  }
  .lesson-builder-topnav {
    width: 100%;
    background: #1d2933;
    color: #fff;
    padding: 10px;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .question-icon-container {
      i {
        font-size: 2rem;
      }
    }
  }
  .lesson-builder-footer-container {
    // padding: 10px 20px;
    position: absolute;
    background: #1d2933;
    bottom: 0;
    width: 100vw;
    box-shadow: 0 -5px 5px -5px #333;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999999;
    .lesson-plan-builder-action-btn {
      // flex: 0 0 140px;
      width: 160px;
      height: 38px;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .good-button-box {
      flex: 0 0 338px;
      display: flex;
      justify-content: space-between;
    }
    .lp-btn-secondary {
      background: #fff;
      color: $primary-orange;
      border: 1px solid $primary-orange;
    }
    .lp-btn-primary {
      background: $primary-orange;
      border: 1px solid $primary-orange;
    }
  }
  .sessions-failed-validation-modal-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 98;
    .sessions-failed-validation-modal {
      position: fixed;
      // height: 250px;
      max-height: 85vh;
      overflow-y: auto;
      width: 500px;
      background: #fff;
      border-radius: 8px;
      top: 7vh;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      padding: 36px 25px 36px 25px;
      display: flex;
      flex-direction: column;
      z-index: 100;
      align-items: center;
      justify-content: center;
      .validation-modal-title {
        font-size: 24px;
        font-weight: bold;
      }
      .failed-validation-text {
        font-size: 16px;
        text-align: center;
        .empty-sessions {
          color: $primary-orange;
          font-weight: bold;
        }
      }
      .session-duration-mismatch-bar {
        border-top: 1px solid rgba(95, 111, 124, 0.5);
        border-bottom: 1px solid rgba(95, 111, 124, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        p {
          margin: 0;
        }
        .session-name-mismatch {
          font-size: 18px;
          font-weight: bold;
        }
        .actual-mismatch {
          color: $primary-orange;
        }
      }
      .first-mismatch {
        border-top: 2px solid rgba(95, 111, 124, 0.5);
      }
      .last-mismatch {
        border-bottom: 2px solid rgba(95, 111, 124, 0.5);
      }
      .failed-validation-btn {
        margin-top: 10px;
        width: 90%;
        background: $primary-orange;
        border: none;
        border-radius: 500px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
      .validation-modal-button-group {
        display: flex;
        width: 100%;
        .validation-modal-cancel-btn {
          flex: 1 1;
          background: #fff;
          border: none;
          color: $primary-orange;
          border-radius: 500px;
          &:hover {
            background: #f4f4f5;
          }
        }
        .validation-modal-nav-btn {
          flex: 1 1;
          background: $primary-orange;
          border: none;
          border-radius: 500px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          color: #fff;
          &:hover {
            position: relative;
            bottom: -2px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
    .grey-out-validation {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: #37434e;
      opacity: 0.8;
      z-index: 99;
    }
  }
  .lp-save-modal-container {
    .hard-grey-out {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: #37434e;
      opacity: 0.8;
      z-index: 99;
    }
    .lp-save-modal {
      position: fixed;
      min-height: 250px;
      // height: 250px;
      width: 500px;
      background: #fff;
      border-radius: 8px;
      top: 7vh;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      padding: 36px;
      display: flex;
      flex-direction: column;
      z-index: 100;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: bold;
      i {
        font-size: 72px;
      }
      .fa-check-circle {
        color: #33d9b2;
      }
      .fa-spinner {
        color: $primary-orange;
      }
      .save-draft-text {
        font-size: 18px;
        font-weight: normal;
      }
      .return-to-homepage-btn {
        width: 100%;
        background: $primary-orange;
        border: none;
        border-radius: 500px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        font-size: 18px;

        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .failed-draft-validation-container {
    .grey-out {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: #37434e;
      opacity: 0.8;
      z-index: 99;
    }
    .failed-draft-validation {
      position: fixed;
      height: 250px;
      width: 500px;
      background: #fff;
      border-radius: 8px;
      top: 100px;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      padding: 36px;
      display: flex;
      flex-direction: column;
      z-index: 100;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      .failed-draft-validation-title {
        font-size: 24px;
        font-weight: bold;
      }
      .failed-draft-validation-text {
        text-align: center;
      }
      .ok-close-modal-button {
        width: 100%;
        background: $primary-orange;
        border: none;
        border-radius: 500px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .cond-show {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    .cond-show {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .cond-hid {
      display: none;
    }
  }
}
