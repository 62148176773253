@import "../../../assets/styles/globals";

#LessonPlanCardPopover {
  position: absolute;
  right: 18px;
  top: 48px;
  padding: 2.5px 10px;
  background: #fff;
  color: #1d2933;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 10;
  p {
    padding: 7.5px 0px;
    font-size: 14px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .delete-lp {
    margin: 0;
    color: $primary-orange;
  }
  .inactive {
    color: #bbb;
    &:hover {
      cursor: not-allowed;
      text-decoration: none;
    }
  }
}
