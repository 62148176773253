@import "../../assets/styles/globals";

#ResourceRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  border: 1px solid $light-gray !important;
  border-radius: 8px;
  margin-bottom: 15px;

  p {
    margin: 0px;
    width: 80%;
  }

  .resource-row-info-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .resource-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light-gray;
      height: 45px;
      width: 45px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
      }

      i {
        font-size: 30px;
        color: $light-font-color;
      }
    }
  }

  .resource-row-updated-text {
    color: $light-font-color;
    font-size: 12px;
    position: absolute;
    bottom: 3px;
    right: 5px;
  }

  &:hover {
    background-color: lighten($light-gray, 5%);
    cursor: pointer;
  }
}
