@import '../../assets/styles/globals';

.error-page {
  height: 100%;
  width: 100%;
  background: white;
  padding-left: 5%;
  padding-right: 5%;

  .error {
    text-align: center;

    @media (min-height: 767px) {
      position: relative;
      top: -80px;
    }

    .lock-image {
      color: $placeholder-gray;
      height: 4rem;
      width: 4rem;
    }

    .title {
      margin: 1rem 0 0.5rem;
      font-weight: 700;
    }

    .message {
      color: $light-navy;
    }
  }
}
