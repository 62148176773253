@import '../../../assets/styles/globals.scss';

.loaderText {
  font-size: 1.2rem;
  font-weight: 700;
}

.leaderboardRanking {
  padding: 0 10%;
  height: 100%;
  overflow-y: auto;

  .noActivities {
    width: min(450px, 100%);
    margin-top: 15%;
    margin-left: auto;
    margin-right: auto;

    .icon {
      width: 65px;
      height: 65px;
      fill: $light-navy;
    }

    .noActivitiesTitle {
      font-size: 1.2rem;
      font-weight: 800;
    }

    .noActivitiesText {
      color: $dark-gray;
    }

    .resetFiltersButton {
      padding: 0.5rem;
      background: $red-delete;
      color: white;
      border-radius: 40px;
      border: none;
      font-size: 16px;
      margin-right: 30px;

      &:active {
        background: $red-delete;
      }
    }
  }
}

.leaderboardStatsItem {
  display: flex;
  align-items: baseline;

  .data {
    font-size: 1.2rem;
    font-weight: 800;
  }

  .text {
    font-size: 0.9rem;
  }
}
