@import "../../../../assets/styles/globals";

#LessonCreateSchedule {
  width: 100%;
  height: 100%;
  // background: pink;
  display: flex;
  .builder-session-sidebar {
    flex: 0 0 250px;
    background: #36434e;
    color: #fff;
    padding-top: 18px;
    overflow-y: auto;
    h4 {
      padding-left: 26px;
      font-size: 36px;
    }
    .session-bar-content {
      max-height: calc(100% - 51px);
      // display: block;
      .session-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // height: 36px;
        position: relative;
        &:hover {
          cursor: pointer;
          background: #5f6f7c;
        }
        .fa-bars {
          position: absolute;
          left: 5px;
          &:hover {
            color: $primary-orange;
            cursor: grab;
          }
        }
      }

      p {
        flex: 1 1;
        padding-left: 26px;
        font-size: 24px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .fa-ellipsis-v {
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 5px;
        font-size: 24px;
        &:hover {
          color: $primary-orange;
        }
      }
      .session-popover-container {
        position: absolute;
        height: 60px;
        width: 127px;
        background: #fff;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        bottom: -60px;
        right: 5px;
        z-index: 5;
        padding: 0;
        overflow: hidden;
      }
      .active {
        background: #5f6f7c;
      }
    }
    .add-session-button {
      padding-left: 26px;
      margin-top: 10px;
      color: #5f6f7c;
      font-size: 18px;
      &:hover {
        text-decoration: underline;
        background: #36434e;
        cursor: pointer;
      }
    }
  }
  .builder-content {
    flex: 1 1;
    max-width: calc(100% - 250px);
    display: flex;
    .builder-block-view {
      flex: 1 1;
      max-width: 50%;
      // padding: 36px;
      // padding-top: 36px;
      // padding-bottom: 36px;
      // overflow-y: auto;
      .no-session-placeholder {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ccc;
        .fa-frown-o {
          font-size: 20rem;
          margin-bottom: 10px;
        }
        .session-placeholder-text {
          text-align: center;
        }
      }
    }
    .block-content-picker-section {
      flex: 1 1;
      border-left: 4px solid #5f6f7c;
      max-width: 50%;
    }
  }

  @media screen and (max-width: 900px) {
    .builder-session-sidebar {
      flex: 0 0 150px;
    }
    // .builder-block-view {
    //   max-width: calc(50% - 75px);
    // }
    // .block-content-picker-section {
    //   max-width: calc(50% - 75px);
    // }
    .builder-content {
      max-width: calc(100% - 150px);
    }
    .fa-frown-o {
      font-size: 10rem !important;
    }
  }
}
