@import '../../assets/styles/globals';

.search-input-container {
  display: flex;
  border-radius: 5px;
  height: 48px;
  align-items: center;
  .search-icon {
    margin-top: 0 !important;
    margin-inline: 10px;
    font-size: 20px !important;
    color: $light-navy;
  }

  .search-input {
    width: 90%;
    margin-bottom: 0 !important;
    color: $light-navy !important;
  }

  input {
    border: 0 !important;
    font-weight: 500;
    font-size: 16px;
  }

  ::placeholder {
    color: $light-navy !important;
  }
}
