#PageViewsGraph {
  // ripped from the lead stats graph - should probably make a single component
  canvas {
    position: relative;
    z-index: 2;
  }
  .today-lead-container {
    position: absolute;
    left: 10%;
    p {
      margin: 0;
      text-align: center;
    }
    .lead-count {
      font-size: 36px;
      color: rgb(14, 124, 172);
      line-height: 1;
      font-weight: 550;
    }
    .lead-label {
      font-size: 16px;
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .total-lead-container {
    position: absolute;
    bottom: 15%;
    right: 10%;
    z-index: 1;
    p {
      margin: 0;
      text-align: right;
    }
    .lead-count {
      font-size: 48px;
      color: rgb(14, 124, 172);
      line-height: 1;
      font-weight: 550;
      // text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333,
      //   1px 1px 0 #333;
    }
    .lead-label {
      font-size: 16px;
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}
