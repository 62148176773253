@import '../../../../../assets/styles/globals.scss';

.addressPicker {
  .addressWrapper {
    background: white;
    border-radius: $card-corner-radius;
    padding: 40px;
    width: 100%;

    @media (min-width: 1200px) {
      width: 80%;
      padding: 60px;
    }

    .searchIcon {
      width: 55px;
      flex-shrink: 0;
    }

    .programInfo {
      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
      }

      .statisticsIcon {
        width: 50px;
        height: auto;
        flex-shrink: 0;
      }

      .statsCounter {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0;
      }

      .statsText {
        font-size: 16px;
        margin-bottom: 0;
        white-space: nowrap;
      }

      .inputWrapper {
        label {
          font-size: 18px;
          font-weight: 700;
        }

        .addressInput {
          font-size: 16px;
          width: 100%;
          border: none !important;
          padding: 0 !important;

          &::placeholder {
            color: $light-navy !important;
            font-weight: 400 !important;
            font-size: 16px !important;
          }
        }

        .dropdownContainer {
          position: absolute;
          background-color: white;
          z-index: 1;
          margin-top: 4px;
          overflow: hidden;
          border: solid 1px $placeholder-gray;
          border-radius: $card-corner-radius;
          box-shadow: 0 2px 5px $light-gray;

          .suggestionItem {
            padding: 4px 8px;

            &.activeSuggestionItem {
              cursor: pointer;
              background-color: $off-white;
            }
          }
        }

        .submitButton {
          width: 100%;
          box-shadow: none !important;
          background-color: $primary-orange !important;
          border: none;

          &:focus, &:active {
            background-color: $primary-orange !important;
            outline: none;
            border: none;
          }

          &:disabled {
            cursor: not-allowed;

            &:hover {
              background-color: $primary-orange !important;
            }
          }
        }
      }
    }

    .mapPreviewImage {
      height: 270px;
      width: 330;

      @media (min-width: 1200px) {
        height: 360px;
        width: 440px;
      }
    }
  }
}
