@import "../../../../assets/styles/globals.scss";

:root {
  --questionStep-title-heading-size: #{$heading-2-size};
  --questionStep-input-font-size: #{$heading-1-size};
  --questionStep-mc-font-size: #{$heading-3-size};
  --questionStep-flag-dropdown-margin: #{$secondary-padding};
}

.QuestionStep {
  width: 100%;

  .question-step-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; // For absolute position of arrow
    height: 46px;

    .question-step-header-arrow {
      position: absolute;
      left: 0;
      color: $light-navy;
      font-size: $heading-2-size;
      transition: all .2s ease-in-out;

      &:hover {
        transform: scale(0.9);
        transition: all .2s ease-in-out;
        cursor: pointer;
      }
    }

    .question-step-header-bar {
      height: 10px;
      border-radius: 999px;
      background-color: $light-navy;
    }

    .question-step-header-bar-fill {
      height: 10px;
      border-radius: 999px;
      background-color: $primary-orange;
      transition: width .2s ease-in-out; // So the bar slides
    }
  }

  .question-step-question {
    color: $dark-navy;
    font-size: var(--questionStep-title-heading-size);
    font-weight: $bold-font-weight;
    margin-top: $secondary-padding;
  }

  .question-step-desc {
    color: $light-navy;
    font-size: 14px;
    margin-bottom: $secondary-padding;
  }

  // Have to override these with !important because !important
  // is used everywhere in the main app styles...
  .question-step-input-field {
    border: 0px !important;
    border-bottom: 2px solid $light-navy !important;
    outline: none;

    font-size: var(--questionStep-input-font-size);
    font-weight: 400;
    color: $dark-navy !important;
    opacity: 1;
    text-align: center;

    margin: $secondary-padding 0px;
    padding: 0px !important;

    // max-width: 100%; // To avoid overflow on mobile devices
    width: 100%;

    &::placeholder {
      color: $light-navy !important;
      opacity: .5;
    }
  }

  .question-step-phone-input-container {
    width: 100%;
    display: flex;
    align-items: center;

    .flag-dropdown {
      position: static;
      margin-right: var(--questionStep-flag-dropdown-margin);
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0);

      .selected-flag {
        background-color: white;
        border: 2px solid $primary-orange;
        border-radius: $card-corner-radius;
        width: 46px;

        &:hover {
          background-color: darken(white, 10%);
          border-color: darken($primary-orange, 10%);
        }
      }

      .question-step-phone-input-dropdown-item {
        text-align: left;
        padding: 10px;
        height: auto !important;

        &:hover {
          background-color: #afb7be; // $light-navy at 50%
        }
      }
    }

    #question-step-phone-input-field {
      text-align: left;
      flex: auto;
      box-shadow: none;
      margin: $secondary-padding 0px !important;
      height: auto;
    }
  }

  .question-step-input-field-error {
    border-color: $light-red !important;
  }

  .question-step-input-error-msg {
    color: $light-red;
    font-size: 14px;
    margin-top: -8px;
  }

  .question-step-multiple-choice {
    display: flex;
    text-align: left;
    background-color: white;
    color: $primary-orange;
    font-size: var(--questionStep-mc-font-size);

    border: 2px solid $primary-orange;
    border-radius: $card-corner-radius;
    padding: 10px;
    margin: $secondary-padding 0px;

    &:hover {
      background-color: $primary-orange;
      color: white;
      cursor: pointer;

      .question-step-multiple-choice-other-input {
        color: white !important;

        &::placeholder {
          color: white !important;
          opacity: .5;
        }
      }
    }

    .mcElement {
      margin-right: 10px;
    }

    .question-step-multiple-choice-other-input {
      color: $primary-orange;
      font-size: var(--questionStep-mc-font-size);
      font-weight: 400;
      border: 0px !important;
      padding: 0px !important;
      margin: 0px 10px;
      flex: auto;
      outline: none;

      &::placeholder {
        color: $primary-orange !important;
        opacity: .5;
      }
    }
  }

  .question-step-multiple-choice-special-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .question-step-multiple-choice-special {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      background-color: $primary-orange;
      color: white;
      font-size: var(--questionStep-mc-font-size);
      font-weight: $bold-font-weight;

      padding: 10px;
      // margin: $secondary-padding 0px;
      margin-top: $secondary-padding;

      // Can grow, can't shrink, must be at least 50% of width
      flex: 1 0 calc(50% - 9px);

      &:hover {
        cursor: pointer;
      }

      img {
        height: 72px;
        width: 72px;
        margin-bottom: 10px;
        filter: brightness(0) invert(1);
      }
    }

    .question-step-multiple-choice-special:first-of-type {
      margin-right: $secondary-padding;
    }
  }

  .question-step-button {
    margin: $secondary-padding 0px;
  }

  .question-step-button-group {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

@media (max-width: 425px) { // phone (large)
  :root {
    --questionStep-input-font-size: #{$heading-2-size};
    --questionStep-mc-font-size: #{$heading-4-size};
    --questionStep-flag-dropdown-margin: 10px;
  }
}

@media (max-width: 320px) { // phone (small)
  :root {
    --questionStep-title-heading-size: #{$heading-3-size};
  }
}
