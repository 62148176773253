@import "../../../assets/styles/globals";

.actionSubmittedModal{
  margin: auto !important;
  width: 550px !important;
  height: 500px !important;
  padding: 24px !important;
  border-radius: 8px !important;
  background: white !important;

  .modalContainer {
    .modalContent {
      width: 90%;
      text-align: center;
      padding: 20px 0;
      p {
        margin-bottom: 0;
        font-weight: 600;
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        color: $primary-orange;
        margin-left: 15px;
        padding-block: 10px;
        text-align: center;
      }
    }

    .iconWrapper {
      background-color: $primary-orange;
      border-radius: 100px;
      width: 136px;
      height: 131px;

      i {
        font-size: 56px;
        color: #fff;
      }
    }
  }

}