@import "../../../assets/styles/globals";

.studentRow {
  display: flex;
  align-items: center;
  padding: 5px 10px;

  .studentName {
    padding-left: 10px;
    color: black;
    font-size: 0.8rem;
  }

  .profileImg {
    border-radius: 50%;
    border: 1px solid $icon-gray;
    height: 35px;
    width: 35px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
    margin-left: -10px;
  }
}

.enrollmentsTooltip {
  background-color: white !important;
  border-color: $light-gray !important;
  border-radius: 10px !important;
  color: $dark-gray !important;
  opacity: 1 !important;
  width: 15%;
  padding: 0 !important;
  max-height: 30%;
  overflow-y: auto;

  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }

  .tooltipTitle {
    margin: 0;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .tooltipSection {
    padding: 10px 15px;
    border-bottom: 1px solid $light-gray;
  }
}
