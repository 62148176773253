@import "../../../../assets/styles/globals";

.image-bank-modal {
  // width: 1000px;
  width: 809px; // 133 px * 5 img + 18px * 4 gutters + 36px * 2 margins
  background: $off-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $card-corner-radius;
  box-shadow: $modal-drop-shadow;
}

.image-bank-modal-header,
.image-bank-modal-footer {
  background-color: white;
  width: 100%;
  display: flex;
  padding: $primary-padding;
}

.image-bank-modal-header {
  flex-flow: column;
  border-radius: $card-corner-radius $card-corner-radius 0 0;

  h1,
  h2 {
    text-align: center;
    line-height: 1;
    margin-bottom: $secondary-padding;
  }

  h1 {
    font-weight: $bold-font-weight;
    font-size: $heading-1-size;
  }

  h2 {
    font-size: $heading-2-size;
  }

  hr {
    width: 100%;
    border-top: 2px solid $light-gray;
    margin: 0;
    margin-bottom: $secondary-padding;
  }

  .image-bank-upload-button-wrapper {
    display: flex;
    height: 36px;
    align-items: center;

    p {
      color: $light-navy;
      margin: 0;
      margin-left: 10px;
    }

    button:disabled,
    button[disabled] {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
        transform: none;
        transition: none;
      }
    }
  }
}

.image-bank-modal-num-selected {
  color: $light-navy;
  margin: 0;
  margin-top: 36px;
}

.image-bank-modal-images {
  // display: flex;
  // justify-content: center;
  // align-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; // 5 cols
  grid-template-rows: 1fr 1fr; // 2 rows
  grid-column-gap: $secondary-padding;
  grid-row-gap: $secondary-padding;
  width: 100%;
  padding: $primary-padding;
  padding-top: $secondary-padding;
  height: 249px; // 79.5 px * 2 img + 36px * 2 margins + 18px * 1 gutter

  .image-bank-modal-empty-message {
    color: $light-navy;
    text-align: center;
    margin: auto 0;
    width: 100%;
  }

  .image-bank-modal-photo {
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    width: 133px;
    height: 79.5px;
    background-color: $light-navy;
    border-radius: $card-corner-radius;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    position: relative;
    .delete-spinner {
      position: absolute;
      color: #fff;
      font-size: 55px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      height: 48px;
      width: 48px;
    }

    .image-bank-modal-photo-thumbnail {
      width: 133px;
      height: 79.5px;
      object-fit: contain;
    }

    .image-bank-modal-photo-selected-overlay {
      position: absolute;

      .image-bank-delete-button {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        // width: 32px;
        // height: 32px;
        // Adjust padding so icon is easier to click
        padding: 4px 8px 8px 8px;
        // padding: 4px;
        text-align: center;

        i.fa-trash {
          font-size: $heading-3-size;
          color: white;
        }
      }
    }
  }

  .image-bank-modal-photo:hover,
  .image-bank-modal-photo-selected {
    border-radius: $card-corner-radius;
    cursor: pointer;
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;

    .image-bank-modal-photo-selected-overlay {
      position: absolute;
      border-radius: $card-corner-radius;
      width: 100%;
      height: 100%;
      padding: 4px;
      box-sizing: border-box;
      background-color: rgba(231, 146, 53, 0.75); // $primary-orange, 75%

      &:hover .image-bank-checkmark-circle-outline {
        width: 24px;
        height: 24px;
        border: 2px solid white;
        border-radius: 50%;

        &:hover {
          border: 2px solid darken(white, 20%);
        }
      }

      // Only show delete icon when img is hovered on
      &:hover .image-bank-delete-button {
        display: block;

        // And darken delete icon when icon is hovered on
        &:hover {
          i.fa-trash {
            color: darken(white, 20%);
          }
        }
      }
    }

    .image-bank-modal-photo-selected-check {
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $primary-orange;
        text-align: center;
      }

      &:hover {
        background-color: darken(white, 20%);
        i {
          color: darken($primary-orange, 20%);
        }
      }
    }
  }
}

.image-bank-modal-footer {
  border-radius: 0 0 $card-corner-radius $card-corner-radius;

  .btn-rectangular-main-negative {
    margin-right: $secondary-padding;
  }

  .btn-rectangular-main-positive {
    margin-left: $secondary-padding;
  }
}
