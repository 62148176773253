@import '../../assets/styles/globals';

.student-with-avatar {
  .student-level {
    font-size: 25px;
  }

  .student-title {
    color: $dark-navy;
    font-weight: 700;
    margin-left: -15px;
  }

  p {
    margin-bottom: 0 !important;
  }
}
