@import "../../../assets/styles/globals";

.avatarInput {
  .avatarWrapper {
    position: relative;

    .avatar {
      border: 1px solid white;
    }

    .removeAvatar {
      cursor: pointer;
      font-size: 12px;
      color: $primary-orange;
    }

    .removeAvatarButton {
      position: absolute;
      top: -4px;
      right: -4px;
      background-color: $primary-orange;
      border-radius: 50%;
      padding: 6px;

      .removeAvatarIcon {
        width: 10px;
        height: auto;
        flex-shrink: 0;
        display: block;

        path {
          fill: white;
        }
      }
    }
  }

  .avatarLabel {
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0;

    .roundedPlusIcon {
      width: 15px;
      height: auto;
      flex-shrink: 0;
      margin-left: 20px;
      margin-right: 8px;

      path {
        fill: white;
      }
    }

    .avatarText {
      font-size: 16px;
      color: white;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
