@import "../../assets/styles/globals";

.community-select {
  input {
    border: none !important;
  }
  // .Select-control {
  //   border-radius: 0px !important;

  //   .Select-input {
  //     input {
  //       border: none !important;
  //     }
  //   }
  // }
}

#NewCoachForm {
  .grey-out {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(43, 56, 67, 0.85);
    z-index: 99;
  }
  .accolade-confirmation-modal {
    position: fixed;
    height: 300px;
    width: 500px;
    top: 100px;
    left: 0;
    right: 0;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    background: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #2b3843;
    border-radius: 35px;
    p {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
    .datepicker-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .add-date-prompt {
        font-size: 14px;
      }
      input {
        text-align: center;
        border-radius: 8px !important;
        &:focus {
          border-bottom: 1px solid #ddd !important;
        }
        &:hover {
          cursor: pointer;
          background: #eee !important;
        }
      }
    }
    .button-box {
      button {
        width: 120px;
        border-radius: 500px;
        padding: 5px;
      }
      .nevermind {
        margin-right: 20px;
        border: 2px solid #79d1f7;
        background: #79d1f7;
        &:hover {
          color: #79d1f7;
          background: transparent;
        }
      }
      .remove-button {
        border: 2px solid #243497;
        background: #243497;
        &:hover {
          color: #243497;
          background: transparent;
        }
      }
      .add-accolade-button {
        border: 2px solid #33d9b2;
        background: #33d9b2;
        &:hover {
          color: #33d9b2;
          background: transparent;
        }
      }
    }
  }
  .accolade-button {
    padding: 5px 15px;
    background: $primary-orange;
    border: 2px solid $primary-orange;
    border-radius: 500px;
    font-size: 24px;
    transition: color 1s, background 1s;
    &:hover {
      background: transparent;
      color: $primary-orange;
    }
  }
  .accolade-tables-container {
    display: flex;
    .accolade-table {
      border: 1px solid #2b3843;
      border-radius: 5px;
      margin-top: 10px;
      margin-right: 3px;
      font-size: 18px;
      tr {
        &:hover {
          background: #f4f4f5;
          cursor: pointer;
        }
        th {
          background: #2b3843;
          color: #fff !important;
          font-size: 20px;
          padding: 0 5px;
          &:hover {
            cursor: default;
          }
        }
        td {
          padding: 5px 30px;
          .accolade-badge {
            height: 75px;
            width: 75px;
            background-size: cover;
            background-position: center;
          }
        }
      }
      .not-active {
        color: #ddd;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
