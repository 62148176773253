// Quick sizing classes
.icon{
	&-sm{
		width: calc(12px * 1);
	}
	&-md{
		width: calc(12px * 2);
	}
	&-lg{
		width: calc(12px * 3);
	}
}
