@import "../../globals";

*:focus {
  outline: none;
}

// .apply-min {
//   min-width: 355px;
// }

.StudentRow {
  color: darken($disable-color, 65%);
  list-style: none;
  font-size: 14px;
  padding: 20px 10px;
  text-align: left;
}
.students-search-bar {
  margin: -10px auto 15px;
  background-color: #fff;
  border-radius: 8px;
}

/* xs < 768 */
@media screen and (max-width: 767px) {
  .search-container {
    justify-content: center;
    .search-icon {
      font-size: 30px !important;
    }
  }

  .search-input {
    margin-right: 0px;

    input {
      font-size: 30px !important;
      text-align: center;
    }
  }
}
