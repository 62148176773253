@import "../../assets/styles/globals";

.communityDetails {
  background: $dark-navy;
  color: white;
  overflow-y: auto;

  .description {
    font-size: 0.9rem;
    color: $white-navy;
    white-space: pre-wrap;
  }

  .requestInfoButton {
    border: none;
    padding: 0;
    background: transparent;
    color: inherit;
    text-align: left;
    line-height: 1.2rem;
    font-weight: 800;
    margin-bottom: 2rem;

    .questionMark {
      height: 47px;
      width: 47px;
    }
  }

  .actionButton {
    border: none;
    padding: 0;
    background: transparent;
    color: inherit;
    margin-bottom: 2rem;

    display: flex;
    align-items: center;

    .actionIcon {
      fill: white;
      margin-right: 0.75rem;
    }
  }

  .collapsibleBar {
    &:last-child .bar {
      border-bottom: 1px solid $light-navy;
    }

    &.collapsibleBarExpanded .bar {
      border-bottom: 1px solid $light-navy;
    }

    .bar {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px solid $light-navy;
    }

    .barTitle {
      text-align: left;
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
    }
  }

  .coachCollapsibleBar {
    margin: 0;
    border-bottom: 1px solid $primary-navy;

    .coachBar {
      padding: 0.5rem 1rem;
    }

    &:last-child {
      border-bottom: none;
    }

    .coachName {
      text-align: left;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .leadCoach {
      flex-shrink: 0;
      margin-left: 0.5rem;
      color: white;
      font-size: 0.9rem;
      background: $primary-orange;
      padding: 0.25em 0.5em;
      border-radius: 50px;
    }
  }

  .coachDetails {
    font-size: 0.9rem;

    .detailsTitle {
      font-size: inherit;
      font-weight: 800;
      margin-bottom: 0.75rem;
    }

    .coachBio {
      color: $white-navy;
    }

    .socialMediaList {
      list-style: none;

      .socialMedia {
        padding: 0 0.5rem;
      }
    }

    .coachAccolade {
      height: 65px;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .achievements {
    .statistics {
      margin-left: 2.5rem;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .statisticsDescription {
        margin-left: 1.5rem;
        font-weight: 800;

        .number {
          font-size: 1.25rem;
          margin-right: 0.25rem;
        }
      }
    }

    .accolades {
      display: flex;
      flex-direction: column;
      align-items: center;

      .accolade {
        width: 155px;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
