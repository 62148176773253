@import '../../../../assets/styles/globals';

.categoryTitle {
  font-size: 1rem;
  font-weight: 700;
}

.icon {
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;

  path {
    fill: $light-navy;
  }
}
