@import "../../../assets/styles/globals";

.coachProgramsFilterBar {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;

  .programsSearchBar {
    width: 35%;
    background-color: #fff;
    border-radius: 8px;
  }

  .filtersButton {
    height: 48px;
    margin-left: 20px;
    border-radius: 8px;
    border: none;
    background: white;
    padding: 0;
    color: $light-navy;
    font-weight: 700;
  
    &:active {
      background: $off-white;
    }
  
    @media (min-width: 992px) {
      flex-basis: 330px;
    }
  }

  .resetFiltersButton {
    padding: 0.5rem;
    height: 40px;
    background: $red-delete;
    color: white;
    border-radius: 40px;
    border: none;
  
    &:active {
      background: $red-delete;
    }
  }
}

.filtersMenu {
  max-width: none !important;
  max-height: fit-content;
  width: 400px;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .filterTitle {
    font-size: 20px;
    font-weight: 700;
    margin-left: 5px;
  }

  .filterName {
    font-size: 15px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .selectMenuList {
    max-height: 145px;
  }
}

.datePickerInput {
  // Remove button default styling
  background: none;
  border: 1px solid $border-gray;
  border-radius: 10px;
  padding: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: $light-navy;
  width: 170px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
}

@media all and (max-width: 991px) {
  .coachProgramsFilterBar {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media all and (max-width: 768px) {
  .coachProgramsFilterBar {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media all and (max-width: 575px) {
  .coachProgramsFilterBar {
    margin-left: 0px;
    margin-right: 0px;

    .programsSearchBar {
      width: 50%;
    }
  }
}

@media all and (max-width: 390px) {
  .coachProgramsFilterBar {
    margin-left: 0px;
    margin-right: 0px;
  }
}