@import "../../../assets//styles/globals.scss";

#SalesLeadsPage {
  .top-bar {
    margin: 18px 0;
    background: $primary-orange;
    border-radius: 500px;
    padding: 18px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: 3px 3px 3px #ccc;
    .search-container {
      background: #fff;
      width: 75%;
      // display: flex;
      // justify-content: space-between;
      border-top-right-radius: 500px;
      border-bottom-right-radius: 500px;
      form {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
    .filter-box-container {
      width: 75%;
      margin-top: 10px;
      justify-self: center;
      display: flex;
      p {
        margin: 0;
      }
      .filter-box {
        flex: 0 0 33.33%;
      }
      .empty {
        display: flex;
        justify-content: flex-end;
        align-self: flex-start;
        p {
          background: #fff;
          padding: 3px 6px;
          border-radius: 500px;
          color: red;
          font-size: 0.8rem;
          margin-top: 18px;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
            background: #f4f4f5;
          }
        }
      }
      .content {
        border: 2px solid #fff;
        border-radius: 4px;
        margin-right: 6px;
        padding: 9px;
        color: #fff;
        .title {
          font-weight: bold;
        }
        .filter-row {
          display: flex;
          align-items: center;
          padding-left: 4px;
          margin-bottom: 4px;
          &:hover {
            cursor: pointer;
            .checkbox {
              border: 1px solid #ccc;
            }
          }
          p {
            margin-left: 6px;
          }
          .checkbox {
            height: 20px;
            flex: 0 0 20px;
            border: 1px solid #fff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    input {
      padding-left: 5px;
      border: none !important;
      color: inherit !important;
      font-weight: normal;
      margin: 0;
    }
    .search-input {
      flex: 1 1;
    }
    .sub-btn {
      padding: 5px 15px;
      border-top-right-radius: 500px !important;
      border-bottom-right-radius: 500px !important;
      background-color: $light-blue !important;
    }
  }
  .card {
    overflow: hidden;
    .empty-results-box {
      i {
        margin: 18px 0;
        font-size: 65px;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapes;
    margin-top: 20px;

    th {
      border-bottom: 2px solid #afb7bd;
      vertical-align: bottom;
    }
    tbody {
      tr:hover {
        background: #ccc;
        cursor: pointer;
      }
      td {
        padding: 10px 2px;
        border-bottom: 2px solid #d7dbde;
        font-size: 0.9rem;
        .status-pill {
          margin: 0;
          margin-right: 6px;
          padding: 3px 5px;
          border-radius: 500px;
          text-align: center;
          color: #fff;
          font-weight: bold;
        }
        .avatar {
          height: 36px;
          width: 36px;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}
