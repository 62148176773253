@import "../../assets/styles/globals";

#InfoModal {
  .info-modal-close-button {
    background-color: transparent;
    border: none;

    &:focus, &:focus-visible, &:active, &:hover {
      border: none;
      outline: none;
      text-decoration: none;
      background-color: transparent;
    }

    &:hover {
      transform: scale(0.95);
      transition: all 0.2s ease-in-out;
    }

    .info-modal-close-icon {
      font-size: 28px;
      color: $light-navy;
    }
  }

  .info-modal-container {
    text-align: center;
    margin-top: 0px;

    .info-modal-title {
      margin-top: 10px;
      margin-bottom: 0 !important;
      font-size: 24px;
      font-weight: 700;
    }
  }

}
