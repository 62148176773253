@import "../../../../assets/styles/globals";

.header {
  z-index: 9 !important;

  .op36Icon {
    width: 36px;
    height: auto;
    margin-left: 45px;
    flex-shrink: 0;

    & > path {
      fill: white;
    }
  }
}
