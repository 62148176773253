@import "../../globals";
$objectPlaceholder: url("../../../images/mock/classPlaceholder.png");

#ClassesDetailPage {
  .classDetailHeader {
    margin-left: -15px;
    margin-right: -15px;
    // margin-top: -5px;
    padding: 25px;
    // background-image: $objectPlaceholder;
    // background-position: center;
    background-color: #5c6973;

    // .overlay{
    // 	padding: 10px;
    // 	background-color: rgba(0,0,0,.8)
    // }
  }
  .tab-container {
    padding-left: 10%;
    padding-right: 10%;
    background: #5c6973 !important;
    border-top: 1px solid #ccc !important;

    .react-tabs__tab-list,
    .react-tabs__tab {
      background: #5c6973 !important;
      bottom: 0;
      border: none;
      color: #fff;
    }
  }
  .lesson-plan-show-container {
    min-height: calc(100vh - 195px);
  }
}

.student-loader-container {
  margin-top: -500px !important;
  margin-bottom: 50px !important;
}

.archive-program-button {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9999;
}

.seg-control-tab-extended{
  margin-top: -15px !important;
  margin-left: -15px !important;
  margin-right: -15px !important;
}
