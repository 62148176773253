@import "../../assets/styles/globals";

#FamilyDashboardHeader {
  height: 200px;
  background-image: linear-gradient(
    to right,
    $light-navy,
    $primary-color
  ) !important;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -30px;

  .family-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex;
    align-items: center;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-top: 30px;
  }
}

.family-activity-card {
  height: 500px;
}

.family-dashboard-card {
  height: 375px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.family-stats-container {
  p {
    text-align: center;
    font-size: 65px;
    font-weight: 550;
  }
  .family-points {
    color: $secondary-color;
  }

  .family-golfers {
    color: $primary-color;
  }

  .family-activities {
    color: $primary-teal;
  }

  .family-activities-today {
    color: $primary-blue;
  }
}
