.collapsibleBar {
  list-style: none;
  margin: 0 -1.5rem;

  .bar {
    width: 100%;
    background: transparent;
    border: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:active {
      background: transparent;
    }

    .expanded {
      scale: -1;
    }
  }

  .collapse {
    width: 100%;
  }
}
