@import "../../../../assets/styles/globals";

.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}

.coach-counter {
  p {
    color: $primary-orange;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
}

.selected-coach-card {
  background-color: $off-white;
  padding: 10px !important;
  border-radius: 8px;
  margin-bottom: 16px;
}

.coach-avatar {
  @include bordered($dark-font-color, 1px);

  text-align: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.coach-name {
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 12px;
}

.delete-icon {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-right: 8px;

  path {
    fill: $light-navy;
  }
}

.create-coach {
  cursor: pointer;

  p {
    font-size: 15px;
    color: $primary-orange;
    font-weight: 700;
    padding: 10px 10px 0px 10px;
  }
}

.line {
  background: $placeholder-gray;
  width: 100%;
  height: 1px;
  margin: 10px 0px;
}

.info-modal-message {
  padding-top: 50px;
  text-align: center;
  color: $light-navy;

  p {
    margin-bottom: 0;
  }
}