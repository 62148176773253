@import "../../../../assets/styles/globals";

.secondaryInformation {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 24px;
    }
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
      padding: 48px;
      padding-top: 24px;
    }

    .titleText {
      color: white;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 32px;
      text-align: left;
    }

    .inputLabel {
      font-size: 16px;
      color: white;
      font-weight: 700;
    }

    .inputSymbol {
      color: white;
    }

    .avatarLabel {
      font-weight: 700;
      cursor: pointer;

      .avatar {
        border: 1px solid white;
      }

      .roundedPlusIcon {
        width: 15px;
        height: auto;
        flex-shrink: 0;
        margin-left: 20px;
        margin-right: 8px;

        path {
          fill: white;
        }
      }

      .avatarText {
        color: white;
        margin-bottom: 0;
      }
    }

    .checkboxSection {
      margin-top: 38px;

      .checkboxWrapper {
        cursor: pointer;
        margin-bottom: 32px;
        width: fit-content;

        .checkboxLabel {
          color: white;
          font-size: 16px;
          margin-bottom: 0;

          @media (min-width: 768px) {
            font-size: 14px;
          }
        }

        .checkboxLink {
          color: $primary-orange;
        }
      }
    }
  }
}
