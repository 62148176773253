@import '../../../../assets/styles/globals.scss';


.modalWrapper {
  height: 480px;
  width: 500px;
}

.modalContent {
  gap: 16px;
  padding: 8px 33px;

  .flagContainer {
    background-color: $secondary-color;
    border-radius: 50px;
    width: 82px;
    height: 82px;
  }

  .title {
    color: $secondary-color;
    font-weight: 700;
    margin: 0;
  }

  .message {
    font-weight: 700;
  }

  .dismissButton {
    align-self: stretch;
  }

  .cancelButton {
    align-self: stretch;
    border-radius: 50px !important;
    background-color: $slate-gray;
  }
}