@import "../../../assets/styles/globals";

#ProgramBuilder {
  min-height: calc(100vh - 100px);
  margin-bottom: 100px;
  background-color: white;

  @media screen and (min-width: 576px) {
    min-height: calc(100vh - 70px);
    margin-bottom: 70px;
  }
}