@import "../../../../assets/styles/globals";

.control {
  height: auto !important;
  min-height: 48px !important;
  border-color: $placeholder-gray !important;
  border-radius: $card-corner-radius !important;

  &:hover {
    border-color: $placeholder-gray;
  }
}

.menu-list {
  max-height: 400px !important;
}

.group {
  padding: 4px 0px 0px 0px !important;

  & > div:nth-child(1) {
    color: $dark-navy;
    font-size: 16px;
    font-weight: 700;
  }

  & > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 12px;
  }
}

.option {
  background-color: $off-white !important;
  border: 1px solid $placeholder-gray !important;
  cursor: pointer !important;
  min-height: auto !important;
  width: max-content !important;
  border-radius: 20px !important;
  padding: 3px 12px !important;
  color: $dark-navy !important;
  font-size: 15px !important;
  margin: 4px 12px 4px 0px !important;
  white-space: nowrap;
}

.value-container {
  padding: 4px 8px !important;
}

.selected-option {
  background-color: $primary-orange !important;
  border: 1px solid $primary-orange !important;
  color: white !important;
  white-space: nowrap;
}

.multi-value {
  background-color: $primary-orange !important;
  border: 1px solid $primary-orange !important;
  border-radius: 20px !important;
  padding: 0px 3px !important;
  margin: 4px !important;
  white-space: nowrap;
}

.multi-value-label {
  color: white !important;
  font-size: 15px !important;
}

.multi-value-remove {
  svg {
    fill: white;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.indicator-container {
  cursor: pointer;

  &:hover > div:nth-child(1) {
    & > svg {
      fill: $light-navy !important;
    }
  }
}
