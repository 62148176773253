@import '../../../../assets/styles/globals.scss';

.homeTab {
  .homeWrapper {
    padding: 16px;

    @media (min-width: 768px) {
      padding: 24px;
    }

    @media (min-width: 1200px) {
      padding: 36px;
    }

    .mobileAppDownload {
      max-width: 550px;
      width: fit-content;
      padding: 60px 32px;
      background-color: white;
      border-radius: 10px;

      .mobileAppJourneyImage {
        height: 300px;
        width: 150px;
        margin-right: 4px;

        @media (min-width: 768px) {
          height: 400px;
          width: 200px;
        }
      }

      .mobileAppInstructions {
        height: 100%;
        margin-top: 16px;

        @media (min-width: 576px) {
          margin-top: 0;
          margin-left: 24px;
        }

        .qrCodeText {
          font-weight: 700;
          margin-bottom: 0;
        }

        .qrCodeWrapper {
          height: 160px;
          width: 160px;
          border: 5px solid $primary-orange;
          border-radius: 10px;
        }

        .appFacilitiesList {
          font-size: 14px;
          color: $light-navy;

          .bulletPoint {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background-color: $primary-orange;
            flex-shrink: 0;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .mobileQRFooter {
    position: fixed;
    bottom: 0;
    padding: 8px 48px;
    background-color: white;

    .qrCodeText {
      font-weight: 700;
      margin-bottom: 0;
    }

    .qrCodeWrapper {
      height: 70px;
      width: 70px;
      border: 3px solid $primary-orange;
      border-radius: 5px;
    }
  }
}
