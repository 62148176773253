@import "../../assets/styles/globals";

#WorkflowProgressBar {
  width: 660px;
  display: flex;
  align-items: center;
  .bar {
    flex: 1 1 33%;
    background: #fff;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    font-size: 0.9rem;
    padding: 4px 0 3px 30px;
  }
  .page1 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    z-index: 10;
  }

  .triangle {
    height: 22.2px;
    flex: 0 0 22.2px;
    // width: 23.5px;
    background: $primary-orange;
    transform: rotate(45deg);
    border-top-right-radius: 4px;
    position: relative;
    left: -10px;
    top: 0.2px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    z-index: 9;
  }
  .page2 {
    position: relative;
    left: -23.5px;
    z-index: 7;
  }
  .triangle2 {
    left: calc(-23.5px - 11px);
    z-index: 8;
  }
  .page3 {
    position: relative;
    left: calc(-23.5px - 6px - 23.5px);
    z-index: 5;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .triangle3 {
    left: calc(-23.4px - 7px - 23.5px - 11px);
    z-index: 6;
    border-top-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .clickable {
    cursor: pointer;
  }
}
