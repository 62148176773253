@import "../../../assets/styles/globals";

.coach-ad-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100vw;
  // height: 100vh;
  width: 100%;
  height: 100%;
  background-color: $light-navy;
  grid-area: main-zone;
  overflow: hidden;

  .coach-ad-img {
    width: 100%;
    height: 100%;
    // max-width: 100vw;
    // max-height: 100vh;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    color: white; // For the alt tag
  }
}
