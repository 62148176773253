@import "../../../assets/styles/globals";

#AdminEducationSectionsForm {
  h4 {
    font-size: 25px;
    font-weight: 550;
  }

  p {
    color: darken($light-gray, 20%);
  }

  .admin-section {
    h1 {
      font-weight: 550;
      font-size: 30px;
      color: $secondary-color;
    }
  }

  .section-divider {
    width: 75%;
    border-bottom: 3px dashed lighten($light-font-color, 10%);
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .add-section-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: transparent !important;
      color: $secondary-color;
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }
}