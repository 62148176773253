#ProgramMaterialsStep {
  padding: 0 15px;

  .material-card-container {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }

    .action {
      cursor: pointer;
    }
  }
}
