@import "../../../assets/styles/globals";

.enrollmentStatusOption {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: darken(white, 10%) !important;
  }
}

.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

