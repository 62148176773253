.validation-modal-close-btn {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;

  &:focus {
    outline: none !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  .validation-modal-close-icon {
    color: black;
  }
}

.validation-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .validation-title-container {
    display: flex;
    flex-direction: column;

    .validation-modal-icon {
      font-size: 75px;
      font-weight: 550;
      margin-left: 0px;
      text-align: center;
    }

    .validation-modal-title {
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 550;
    }
  }

}
