#MilestonePage {
  .division-milestone-header {
    background-color: #5f6f7c;
    color: #eee;
    padding: 8px;
    border-radius: 10px;
  }

  .milestone-container {
    padding: 15px;
    display: flex;
    justify-content: center;
  }

  .milestone-row {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;

    .first-milestone-row,
    .second-milestone-row {
      display: flex;
      // flex-direction: row;
      max-width: 100%;
      align-items: center;
      flex-wrap: none;
      overflow: hidden;
    }
  }

  // .first,
  // .second {
  //   display: inline-block;
  // }

  .milestone-box {
    padding: 0 5px;
  }
  .progress-container {
    height: 8px;
  }
  .progress-box {
    position: relative;
    top: -60px;
  }
  .progress-bar-1,
  .progress-bar-6 {
    background-color: #ff6633;
  }
  .progress-bar-2,
  .progress-bar-7 {
    background-color: #fddb10;
  }
  .progress-bar-3,
  .progress-bar-8 {
    background-color: #d5de27;
  }
  .progress-bar-4,
  .progress-bar-9 {
    background-color: #2baae1;
  }
  .progress-bar-5,
  .progress-bar-10 {
    background-color: #b51e8c;
  }

  .active-milestones:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  .grey-out {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    background-color: rgba(28, 39, 49, 0.8);
  }
}
