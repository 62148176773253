@import "../../../../assets/styles/globals";

#DrillBasicDetail {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  .warning-box {
    border: 2px solid red;
    border-radius: 8px;
    background-color: rgba(255, 0, 0, 0.25);
    padding: 9px;
    margin-top: -9px;
    margin-bottom: 6px;
    p {
      color: darkred;
      font-size: 12px;
      text-align: center;
      margin: 0;
    }
  }
  .page-content-container {
    max-width: 1000px;
    display: flex;
    justify-content: center;
    .page-column {
      flex: 0 0 50%;
      padding: 18px;
      min-width: 450px;
      color: #1d2933;
      .orange-text {
        color: $primary-orange;
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $primary-orange;
        border-color: $primary-orange;
      }
      .custom-switch
        .custom-control-input:checked
        ~ .custom-control-label::after {
        background-color: $primary-orange;
      }
      .input-label {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
      .placeholder-bar {
        background: #f4f4f5;
        border-radius: 8px;
        padding: 9px;
        max-width: 414px;
        p {
          text-align: center;
          color: #c4c4c4;
          margin: 0;
          border-bottom: 1px solid #ccc;
        }
        .subtext {
          font-size: 0.8rem;
          color: #bbb;
          border-bottom: none;
        }
      }
      input,
      .wrap-input {
        border: none !important;
        color: #1d2933 !important;
        padding-left: 0;
        font-weight: normal;
        font-size: 16px;
        border-bottom: 2px solid #5f6f7c !important;
        width: 100%;
      }
      .RichTextEditor__root___2QXK- {
        border: 2px solid #5f6f7c;
      }
      .title-input {
        font-size: 36px;
      }
      .wrap-input {
        padding-top: 6px;
        resize: none;
      }
      .video-box,
      .image-box {
        background: #c4c4c4;
        color: #828282;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px;
        p {
          margin: 0;
        }
        .inner-dashed-box {
          height: 100%;
          width: 100%;
          flex: 0 0 100%;
          border: 2px dashed #828282;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .image-box {
        .inner-dashed-box {
          justify-content: space-around;
          padding: 9px;
          i {
            font-size: 50px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .inner-photo-preview {
          height: 100%;
          width: 100%;
          flex: 0 0 100%;
          padding: 3px;
          // flex-direction: column;
          // border: 2px solid $primary-orange;
          border-radius: 5px;
          display: flex;
          align-items: center;
          // justify-content: center;
          // position: relative;
          .photo-preview {
            height: 100%;
            height: 70px;
            flex: 0 0 18%;
            background-size: cover;
            background-position: center;
            border-radius: 5px;
            margin-right: 2.5%;
          }
          .loader {
            color: #fff;
            background-color: #5f6f7c;
            font-size: 36px;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              cursor: pointer;
            }
          }
          .photo-preview-image {
            position: relative;
            .photo-remove-btn {
              background: #fff;
              border: 1px solid #1d2933;
              height: 20px;
              width: 20px;
              color: #1d2933;
              position: absolute;
              top: 5px;
              right: 5px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              display: none;
              transition: color 0.3s;
              font-size: 14px;
              &:hover {
                transform: scale(1.1);
              }
            }
            &:hover {
              filter: grayscale(100%);
              cursor: pointer;
              .photo-remove-btn {
                display: flex;
              }
            }
          }
        }
      }
      .video-box {
        height: 300px;
        .FileInput {
          display: none;
        }
        .inner-upload-preview {
          height: 100%;
          width: 100%;
          flex: 0 0 100%;
          flex-direction: column;
          border: 2px solid $primary-orange;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .video-name {
            width: 100%;
            padding: 9px 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }

          .clear-button {
            margin-bottom: 5px;
            width: 200px;
            border-radius: 500px;
            border: none;
          }
          .remove-button {
            position: absolute;
            right: 6px;
            top: 6px;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: red;
            border-radius: 50%;
            display: none;
          }
          &:hover {
            .remove-button {
              display: flex;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .preview-placeholder {
            border: 4px solid #828282;
            border-radius: 8px;
            margin-top: 9px;
            width: 90px;
            flex: 0 0 90px;
            background: #f4f4f5;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 50px;
            }
          }
        }
        .inner-dashed-box {
          flex-direction: column;
          &:hover {
            cursor: pointer;
          }
          .play-icon-box {
            border: 4px solid #828282;
            border-radius: 8px;
            margin-top: 9px;
            width: 90px;
            flex: 0 0 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 50px;
            }
          }
        }
        .remove-original {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .equipment-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 75%;
        .check-icon,
        .trash-icon {
          height: 16px;
          flex: 0 0 16px;
          background-size: cover;
          background-position: center;
          margin: 0 4px;
        }
        p {
          margin: 0 4px;
        }
        .trash-icon {
          height: 20px;
          flex: 0 0 20px;
          &:hover {
            filter: saturate(10000%) hue-rotate(180deg);
            cursor: pointer;
          }
        }
        .equipment-input-container {
          flex: 1 1;
        }
      }
      .full-width {
        width: 100%;
      }
      #high-low-state-switcher {
        display: flex;
        border: 2px solid #34ace0;
        border-radius: 8px;
        background: #fff;
        overflow: hidden;
        cursor: pointer;
        div {
          // padding: 9px;
          p {
            margin: 0;
            height: 100%;
            width: 100%;
            padding: 4px 15px;
            color: #34ace0;
          }
        }
      }
      .scoring-section-subquestions {
        input {
          margin-bottom: 8px;
        }
        textarea {
          width: 100%;
          height: 85px;
          border: 2px solid #5f6f7c !important;
          border-radius: 4px !important;
          color: #1d2933 !important;
          font-weight: 400;
          font-size: 1rem;
          padding: 3px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .page-content-container {
      flex-direction: column;
    }
  }
}
