@import "../../../assets/styles/globals";

.mainCard {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  background-color: $dark-navy;
  box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);

  @media (min-width: 768px) {
    height: 80%;
    width: 70%;
    border-radius: 8px;
  }

  @media (min-width: 1200px) {
    height: 80%;
    width: 50%;
    border-radius: 8px;
  }

  .confirmAccount {
    padding-top: 72px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .mainContent {
      flex-grow: 1;
      width: 100%;
      padding: 16px;

      @media (min-width: 768px) {
        padding: 48px;
      }

      .titleText {
        color: white;
        font-size: 36px;
        font-weight: 700;
        margin: 24px 0;
        text-align: left;
      }

      .subtitleText {
        color: white;
        font-size: 24px;
        font-weight: 700;
      }

      .actionLink {
        max-width: 100%;
        width: 320px !important;
        margin-bottom: 72px;
      }
    }
  }
}
