#PreviewEventModal {
  margin: auto !important;
  width: 90% !important;
  height: 90% !important;
  border-radius: 8px !important;
  background: white !important;

  .modal-content {
    height: 100%;
    overflow-x: hidden !important;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;

    .event-content {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}
