@import "../../assets/styles/globals";

#FamilyProgramCard {
  margin: 10px;
  border: 1px solid $light-gray !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .program-info-wrapper {
    margin-left: 20px;

    .program-name {
      font-size: 20px;
      color: $primary-color;
      margin: 0px;
    }

    .program-meeting-times {
      font-size: 14px;
      color: $light-font-color;
      margin: 0px;
    }
  }

  .program-pill {
    position: absolute !important;
    bottom: 10px;
    right: 10px;
    background-color: $primary-color;
    color: $white-font-color;
    font-weight: bold;
    padding: 3px 10px;
    border-radius: 500vh;
    font-size: 10px;
  }
}
