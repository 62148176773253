@import "../../../assets/styles/globals";


#ProgressBarSteps {
  .progress {
    height: 5px;
    background-color: $light-navy;
    position: absolute;
    top: calc(50% - 2.5px);
    z-index: 1;

    .progress-bar {
      background-color: $dark-teal;
    }
  }

  .progress-steps {
    position: relative;
    z-index: 2;

    .progress-step {
      height: 35px;
      width: 35px;
      flex: 0 0 auto;
      background-color: $light-navy;
      border-radius: 50%;
    }
  }
}
