@import '../../../assets/styles/globals.scss';

.coachDashboardHome {
  height: calc(100vh - $page-header-height - 40px);
  display: flex;
  flex-direction: row;

  @media (max-width: 1199px) {
    position: relative;
  }

  .dashboardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-y: auto;
  }

  .dashboardWidgetWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .quickActionsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
}

.quickAction {
  height: 60px;
  width: 160px;
  background-color: white;
  border-radius: 10px;
  margin-right: 40px;
  display: flex;
  align-items: center;

  &:hover {
    .iconWrapper {
      .icon {
        path {
          fill: $primary-orange;
        }

        circle {
          stroke: $primary-orange;
        }

      }
    }
  }

  .iconWrapper {
    border-left: 2px solid $primary-orange;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .titleWrapper {
    height: 40px;
    width: 80px;
    display: flex;
    justify-content: center;
    padding-left: 20px;
  }

  .quickActionTitle {
    color: black;
    font-size: 14px;
    font-weight: 700;
  }
}

.onboardingInProgress {
  height: calc(100vh - $page-header-height);
}

.onboardingAndEventBanner {
  height: calc(100vh - $page-header-height - $event-banner-height);
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .quickAction {
    height: 50px;
    width: 130px;
    margin-right: 30px;

    .iconWrapper {
      height: 30px;
      padding-left: 10px;
    }

    .titleWrapper {
      height: 40px;
      width: 70px;
    }

    .quickActionTitle {
      font-size: 12px;
    }
  }
}