@import "../../assets/styles/globals";

#BreadcrumbsContainer {
  background: none;
  margin-bottom: 0;
  padding: 0px 0px 25px 0px;

  a {
    color: $secondary-color;

    &:hover {
      color: darken($secondary-color, 15%);
    }
  }

  .disabled-breadcrumb {
    pointer-events: none;
    color: $light-font-color;
  }
}
