@import '../../assets/styles/globals';
$top-content-height: 125px;

.events-page {
  position: absolute;
  width: 100%;
  height: calc(100vh - $page-footer-height);
  margin-bottom: $page-footer-height;
  margin-right: -15px;

  .pop-up-wrapper {
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: fixed;
    top: 100px;

    .pop-up {
      width: 200px;
      height: 200px;
      background-color: #fff;
    }
  }

  .events-search-bar {
    width: 98%;
    margin: -10px auto 15px;
    background-color: #fff;
    border-radius: 8px;
  }

  .no-events-placeholder {
    color: $light-navy;
    width: 35%;
    margin: 100px auto;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .placeholder-title {
      margin-top: 1rem;
      font-weight: 800;
      font-size: 20px;
      color: $dark-navy;
    }

    .placeholder-description {
      white-space: pre-wrap;
      justify-content: start;
    }

    @media screen and (max-width: 768px) {
      width: 80%;
    }
  }

  .tabList {
    height: 40px;
    background-color: #fff;
    border-bottom: 2px solid $light-gray !important;

    .tab{
      margin-top: 0px;
      text-transform: none;
      background-color: transparent;
      padding-left: 100px;
      padding-right: 100px;
      font-size: 14px;
      height: 40px;
      font-weight: bold;
      color: black;
    }

    .activeTab{
      border-bottom: 4px solid $secondary-color;
      height: 40px;
      color: $secondary-color;
      background: transparent !important;
    }
  }

  .tabPanel {
    margin-top: 15px;
  }
}

.editIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  width: 100px;
  height: 100px;
  background-color: $primary-orange;
}

.eventsList {
  height: calc(100vh - $page-header-height - $top-content-height);
  overflow-y: scroll;
  overflow-x: hidden;
}

.eventsListPaginated {
  height: calc(100vh - $page-header-height - $top-content-height - $pagination-height) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
