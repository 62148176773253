@import "../../../../assets/styles/globals";

.sendEmail {
  height: 100%;

  .circleLogo {
    width: 105px !important;
    height: auto;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      width: 64px !important;
      height: auto;
    }
  }

  .titleText {
    color: white;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  .subTitleText {
    color: white;
    font-size: 14px;
    margin-bottom: 32px;
    text-align: center;
    max-width: 320px;
  }

  .inputLabel {
    font-size: 16px;
    text-align: left;
    color: white;
    font-weight: 700;
  }

  .inputSymbol {
    color: white;
  }

  .emailInput {
    .resetPasswordButton {
      margin-top: 36px !important;
      margin-bottom: 96px;
    }
  }

  .emailSent {
    .actionLink {
      max-width: 100%;
      width: 320px !important;
      margin-bottom: 96px !important;
    }
  }
}
