@import "../../../assets/styles/globals";

.filtersButton {
  height: 35px;
  border-radius: 8px;
  @include bordered($off-white);
  background: $off-white;
  padding: 0;
  color: black;
  font-weight: 700;
  font-size: 14px;

  &:active {
    background: white;
    border-color: $off-white;
  }

  @media (min-width: 992px) {
    flex-basis: 260px;
  }

  .extraFilter {
    color: $primary-orange;
    margin-left: 5px;
  }
}

.filtersMenu {
  max-width: none !important;
  border-color: none !important;

  .filtersBody {
    display: flex;

    .filtersTitle {
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 20px;

      .closeButton {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: black;

        float: right;
        margin-top: -20px;
      }
    }

    .fixedRange {
      border-right: 2px solid $border-gray;
      padding: 30px 20px;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .rangeButton {
        // Remove button default styling
        background: none;
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: $dark-navy;

        &:hover {
          color: $light-navy;
        }
      }
    }

    .filters {
      padding: 30px 20px;

      .dates {
        display: flex;
        margin-bottom: 20px;
      }

      .dateLabel {
        font-size: 16px;
        color: $light-navy;
        font-weight: 600;
      }

      .datePickerInput {
        // Remove button default styling
        background: none;
        border: 1px solid $border-gray;
        border-radius: 10px;
        padding: 10px;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: $light-navy;
        width: 180px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 20px;
      }

      .buttons {
        display: flex;
        margin-top: 20px;

        .resetFiltersButton {
          padding: 0.5rem;
          background: $red-delete;
          color: white;
          border-radius: 40px;
          border: none;
          font-size: 16px;
          margin-right: 30px;
        
          &:active {
            background: $red-delete;
          }
        }
      }

      .selectMenuList {
        max-height: 90px;
      }
    }
  }
}