@import "../../../../assets/styles/globals";

.primaryInformation {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 24px;
    }
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
      padding: 48px;
      padding-top: 24px;
    }

    .titleText {
      color: white;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 32px;
      text-align: left;
    }

    .inputLabel {
      font-size: 16px;
      color: white;
      font-weight: 700;
    }

    .inputSymbol {
      color: white;
    }
  }
}
