@import '../../globals';

.burgerMenu {
  position: fixed;
  z-index: 999;
  left: 0px;
  height: $page-header-height;
  background-color: $dark-navy;
  width: $expandable-sidebar-width-collapsed;
  border-right: 1px solid $placeholder-gray;
  border-bottom: 1px solid $placeholder-gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

#expandableSideBar {
  position: fixed;
  z-index: 999;
  left: 0px;
  height: 100%;
  background-color: $dark-navy;
  width: $expandable-sidebar-width-collapsed;
  border-right: 1px solid $placeholder-gray;

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $page-header-height;
    width: $expandable-sidebar-width-collapsed;
    margin-bottom: 15px;
    
    // Remove button default styling
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    .op36Logo {
      width: 45px;
      height: 45px;
      fill: white;
    }
  }

  .navigationItems {
    height: calc(100% - 205px);
    overflow-y: auto;
  }

  .arrowWrapper {
    background-color: $secondary-color;
    height: 35px;
    width: 35px;
    border-radius: 12px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 70px;
    bottom: 150px;

    &:hover {
      cursor: pointer;
      background-color: $secondary-color-alt;
    }

    .arrowButton {
      background-position: center;
      background-size: cover;
      height: 20px;
      width: 25px;
      flex-shrink: 0;
      border-style: none;
      transform: rotate(180deg);
      background: url('../../../images/common/icons/arrow-white.svg') no-repeat;
    }
  }

  .legalLinksContainer {
    border-top: 1px solid $placeholder-gray;

    .policyWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
      margin-top: 10px;
  
      .linkText {
        color: $icon-gray;
        font-size: 12px;
  
        &:hover {
          color: $secondary-color;
        }
      }
  
      .textDivider{
        color: $icon-gray;
        font-size: 10px;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  
    .rights {
      color: $icon-gray;
      font-size: 10px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 0px;
    }
  
    .version {
      color: $icon-gray;
      font-size: 11px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  .profileWrapper {
    border-top: 1px solid $placeholder-gray;
    position: absolute;
    height: 120px;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .profileImg {
      border-radius: 50%;
      border: 2px solid $white-font-color;
      height: 60px;
      width: 60px;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      flex-shrink: 0;
    }
  }

  .profileTooltip {
    height: 100px;
    width: 200px;
    font-size: 16px;
    div {
      padding: 5px;
      display: flex;
      a {
        color: white;
        &:hover {
          color: $icon-gray;
        }
      }
      .logOut {
        color: $red-darker;
        &:hover {
          color: $red;
        }
      }
    }
  }
}

// Expanded sidebar
.sideBarExpanded {
  width: $expandable-sidebar-width-expanded !important;
}

.arrowWrapperExpanded {
  left: 260px !important;
  .arrowButtonExpanded {
    transform: none !important;
    height: 22px !important;
  }
}

.expandedNoTooltip {
  display: none !important;
}

.profileImgExpanded {
  margin-left: 25px;
  margin-right: 15px;
}

.logoWrapperExpanded {
  border-bottom: 1px solid $placeholder-gray !important;
  margin-bottom: 0px !important;
  width: $expandable-sidebar-width-expanded !important;
}

.navigationItemsExpanded {
  height: calc(100% - 190px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px !important;
  }
  
  &::-webkit-scrollbar-track {
    border-left: 1px solid $placeholder-gray;
    background-color: $dark-navy;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $light-navy;
    border-left: 2px solid transparent;
    border-right: 1px solid transparent;
    background-clip: content-box;
  }
}

