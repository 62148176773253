@import "../../../assets/styles/globals";
$navigation-size: 65px;

.event-series {
  position: absolute;
  height: calc(100vh - 100px);
  margin-bottom: 100px;
  width: 100%;
  background-color: #fff;

  @media screen and (min-width: 576px) {
    height: calc(100vh - $page-header-height);
    margin-bottom: $page-header-height;
  }

  .contentContainer {
    height: calc(100% - $page-header-height - $navigation-size);
  }
}
