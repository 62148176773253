@import "../../../../assets/styles/globals";

.sidebar-zone {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: $dark-navy;
  // padding: $primary-padding;
  padding: calc($primary-padding / 1px / $ideal-academy-display-width * 100vw);
  grid-area: sidebar-zone;

  h1, p, img {
    color: white;
    text-align: center;
    // margin-bottom: $secondary-padding;
    margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
  }

  h1 {
    // margin-bottom: $primary-padding;
    margin-bottom: calc($primary-padding / 1px / $ideal-academy-display-height * 100vh);
  }
}

.sidebar-zone-comm-icon {
  // width: 150px;
  // height: 150px;
  width: calc(150px / 1px / $ideal-academy-display-width * 100vw);
  height: calc(150px / 1px / $ideal-academy-display-width * 100vw);
  border: 6px solid white;
  border-radius: 100%;
  object-fit: cover;
}

.sidebar-zone-comm-name {
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  font-weight: $bold-font-weight;
}

p.sidebar-zone-date-time {
  // font-size: $heading-2-size;
  // margin-bottom: 72px;
  font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
  margin-bottom: calc(72px / 1px / $ideal-academy-display-height * 100vh);
}

span.sidebar-zone-date-time-clock {
  // font-size: $heading-1-size;
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
}

// .sidebar-zone-weather {
//
//   .sidebar-zone-weather-primary {
//     display: flex;
//
//     img {
//       margin-right: $secondary-padding;
//     }
//
//     p {
//       font-size: $heading-0-size;
//     }
//   }
//
//   p {
//     font-size: $heading-2-size;
//   }
// }

.sidebar-zone-qr-code-wrapper {
  display: flex;
  flex-flow: column;
  background-color: white;
  border-radius: $card-corner-radius;
  // padding: $secondary-padding;
  padding: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  box-shadow: $non-pressable-elem-drop-shadow;
  margin: auto;
  margin-bottom: 0px;

  p {
    // font-size: $heading-2-size;
    font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
    font-weight: $bold-font-weight;
    color: $primary-orange;
  }

  // The QRCode component is an SVG
  svg {
    width: 100%;
    height: auto;
  }
}
