@import "../../globals";
$objectiveBack: url("../../../images/common/icons/objectives/objectiveBack.png");
$golf: url("../../../images/mock/classPlaceholder.png");

.activity-card {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  margin: 0 auto !important;
  width: 100%;
}

.activity-dropdown-container {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  hr {
    width: 100%;
    margin: 5px;
  }

  .activity-dropdown-delete {
    color: $secondary-color !important;
    font-size: 13px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: darken($color: $secondary-color, $amount: 20%);
    }
  }
}

.activity {
  .activity-dropdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .activity-dropdown-icon {
      margin-right: 20px;
      font-size: 11px;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: lighten($color: $primary-color, $amount: 25%);
      }
    }
  }

  .activityObjectivePost {
    // background-image: $golf;
    background-image: linear-gradient(
      to right,
      $light-navy,
      $primary-color
    ) !important;
    // background-size: cover;
    border: 1px solid lightgrey;
    border-radius: 0px;

    p {
      color: white !important;
    }

    .objectiveRibbon {
      margin-top: -20px;
      z-index: 99;
      border: solid #fff 1px;
    }

    .activityObjectiveBadge {
      height: 140px;
      width: 140px;
      margin: 0 auto;
    }
  }

  .fa {
    padding-left: 10px;
  }
  .profilePicture {
    padding-right: 10px;
  }
  .postName {
    .userName {
      padding-top: 5px;
      color: $primary-color;
      font-size: 18px;
      margin-bottom: 0px;
    }
    .activityName {
      color: black;
      margin-bottom: 5px;
      font-size: 12px;
      margin: 0px;
      text-align: center;
      max-width: 275px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 5px;
    }
  }
  .postDescription {
    padding-left: 20px;
    padding-right: 20px;
    color: $dark-font-color;
    padding-bottom: 10px;
    font-size: 13px;

    .post-desc-link {
      color: $primary-orange;
      text-decoration: underline;
      font-weight: $bold-font-weight;

      &:hover {
        color: darken($secondary-color, 15%);
        cursor: pointer;
      }
    }
  }

  .postCreatedAt {
    color: $dark-font-color;
    padding-bottom: 10px;
    margin-top: 10px;
    font-size: 13px;
    position: relative;
    bottom: 0px;
    width: 100%;

    span {
      padding: 10px 0px 10px 15px;
    }
  }

  .postPicture {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
  .showButtons {
    margin-top: 15px;
    padding: 15px 15px 0px 15px;
    font-size: 14px;
    color: $mid-font-color;
    border-top: 1px solid $light-gray;

    .col-xs-6 {
      padding-right: 2px;
      padding-left: 2px;
    }
    .showButton {
      padding: 5px 0px;
      i {
        color: $light-font-color;
        // background-color: $show-buttons-color;
        padding-right: 10px;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: row !important;

        a {
          display: flex;
          justify-content: center !important;
          align-items: center !important;
          flex-direction: row !important;
          width: 100%;
        }
      }

      &:hover {
        cursor: pointer;
      }

      .post-liked-heart {
        color: $secondary-color;
      }

      &:hover {
        background-color: lighten($light-gray, 10%);

        i {
          color: $secondary-color;
        }
      }
    }
  }
  .actionButtons {
    padding-top: 3px;
    .col-xs-6 {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  #commentSection {
    // padding-top: 20px;
  }

  .comment-web-container {
    padding-top: 20px;
    padding-bottom: 25px;
    background-color: transparent;
    border-top: 1px solid $light-gray;
  }
}

.show-comment-button {
  color: $secondary-color !important;
  text-align: left !important;
  margin-left: 50px !important;
  font-size: 14px !important;

  &:hover,
  &:focus {
    text-decoration: none !important;
  }
}

.comments-card {
  overflow: hidden;
}

.scrollable-content {
  border: 1px solid $light-font-color !important;
  padding: 10px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.scrollable-content::-webkit-scrollbar {
  width: 0px !important;
}

.scrollable-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.close-comment-button {
  color: $primary-color !important;
  text-align: right !important;
  margin-left: 50px !important;
  margin-top: 0px !important;
  font-size: 18px !important;
  background-color: transparent !important;
  border: none !important;
}

.activity-image {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(
    to right,
    $light-navy,
    $primary-color
  ) !important;

  img {
    width: 100%;
  }
}

.activity-dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;

  .activity-dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: lightgrey;
    margin: 5px;
  }
}

// Small devices
@media all and (max-width: 575px) {
  .activity-dropdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;

    .activity-dropdown-icon {
      margin-right: 20px;
      font-size: 11px;
      color: $primary-color;

      &:hover {
        cursor: pointer;
        color: lighten($color: $primary-color, $amount: 25%);
      }
    }
  }
}

@media all and (max-width: 390px) {
  .activity-header-wrapper{
    flex-direction: column;
  }
}

// Medium devices && Protrait Tablet
@media all and (max-width: 768px) {
}

// Large devices
@media all and (max-width: 991px) {
}

// Laptop - Desktop devices
@media all and (max-width: 1600px) {
}

// Landscape Tablet
@media all and (max-width: 1199px) {
}
