@import "../../../assets/styles/globals";

.progress-ribbons-container{
  position:relative;
  width: 192px;
  height:4.2em;
  background-color: white;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar-ribbon {
  position: absolute;
  left: 0.07em;
  height:14px;
  width: 100%;
  background-color: #AFB7BD;
}

.progress-bar-fill {
  position: absolute;
  left: 0.07em;
  height: 14px;
  background-color: #afb7bd;
}

.ribbon-01 {
  position: absolute;
  width: 14px;
  height: 25px;
  left: 10px;
  top: 42px;
}

.circle-01 {
  position: absolute;
  width: 2.1em;
  height: 2.1em;
  background-color: white;
  border:0.22em solid #afb7bd;
  border-radius:50%;
  display: flex;
  justify-content:center;
  align-items: center;
  left: 0em;
}

.ribbon-02 {
  position: absolute;
  width: 14px;
  height: 25px;
  left: 3.4em;
  top: 42px;
}

.circle-02 {
  position: absolute;
  width: 2.1em;
  height: 2.1em;
  background-color: white;
  border:0.22em solid #afb7bd;
  border-radius:50%;
  display: flex;
  justify-content:center;
  align-items: center;
  left: 2.8em;
}

.ribbon-03 {
  position: absolute;
  width: 14px;
  height: 25px;
  left: 6.2em;
  top: 42px;
}
.circle-03 {
  position: absolute;
  width: 2.1em;
  height: 2.1em;
  background-color: white;
  border:0.22em solid #afb7bd;
  border-radius:50%;
  display: flex;
  justify-content:center;
  align-items: center;
  left: 5.6em;
}

.text-circle {
  font-size:90%;
  font-weight: 700;
}

.division-level {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 7.8em;
  background-size:contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
