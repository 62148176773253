@import "../../assets/styles/globals";

.calendarCardIcon {
  height: 100px;
  width: 100px;

  path {
    fill: $icon-gray;
  }
}

.title {
  font-weight: 700;
  font-size: 1.2rem;
  color: $light-navy;
}

.content {
  color: $light-navy;
  text-align: center;
}
