@import "../../../../assets/styles/globals";

.statsPanel {
  overflow-y: auto;
  flex-grow: 1;

  .leaderboardWidget {
    width: 63%;
    height: min(100%, 650px);
    max-width: 600px;
    background-color: white;
    overflow: hidden;
    border-radius: 8px;

    .bannerText {
      font-weight: 800;
    }

    .infoCircleIcon {
      cursor: pointer;

      path {
        fill: $light-navy;
      }
    }

    .header {
      flex: 0 0 fit-content;

      .title {
        font-size: 1.2rem;
        font-weight: 800;
        margin-bottom: 0;
      }

      .timePeriodDetails {
        font-size: 0.9rem;
        font-weight: 800;
      }
    }

    .placeholderContainer {
      padding: 1rem 10%;
    }

    .placeholder {
      width: min(450px, 100%);
      margin-top: 15%;
      text-align: center;

      .icon {
        height: 65px;
        width: 65px;
        fill: $light-navy;
      }

      .title {
        font-size: 1.2rem;
        font-weight: 800;
        margin: 0.5rem 0;
      }

      .text {
        color: $light-navy;
      }
    }
  }

  .summarySection {
    width: 37%;
    max-width: 350px;
    height: fit-content;

    .infoSection {
      min-height: 340px;
      background-color: white;
      border-radius: 8px;

      .loaderText {
        font-size: 1.2rem;
        font-weight: 700;
      }

      .icon path {
        fill: $light-navy;
      }

      .placeholderIcon circle {
        fill: $icon-gray;
      }

      .placeholderTitle {
        font-size: 1.2rem;
        font-weight: 800;
      }

      .placeholderText {
        font-size: 0.9rem;
        text-align: center;
        color: $light-navy;

        .link {
          text-decoration: underline;
        }
      }
    }

    .title {
      margin: 0;
      font-size: 1rem;
      font-weight: 800;
    }

    .subtitle {
      font-size: 0.9rem;
      color: $primary-navy
    }

    .orangeHighlight {
      font-size: 0.9rem;
      font-weight: 800;
      color: $primary-orange;
    }

    .topSeparator {
      border-top: 1px dashed $border-lighter-gray;
    }

    .statsItem {
      font-size: 0.9rem;
      font-weight: 700;

      &.smallerFontSize {
        font-size: 0.8rem
      }
    }

    .totalRevenue {
      font-size: 1.5rem;
      color: $primary-orange;
      font-weight: 800;
    }

    .chart {
      height: 120px !important;
      width: 120px !important;

      &.placeholder {
        border-radius: 50%;
        background-color: $icon-gray;
      }
    }

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;

      &.blue {
        background-color: $dark-blue;
      }

      &.orange {
        background-color: $primary-orange;
      }

      &.gray {
        background-color: $dark-gray;
      }
    }
  }
}
