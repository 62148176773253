@import "../../assets/styles/globals";

#ErrorMessage {
  margin: auto;
  margin-top: 10px;
  background-color: lighten($light-red, 35%);
  padding: 8px;
  border: 1px solid $light-red;

  p {
    color: $light-red;
    font-size: 14px;
    margin-bottom: 0px;
    white-space: pre-wrap;
    text-align: center;
  }
}

