@import '../../assets/styles/globals.scss';

.communityCard {
  height: 100%;
  position: relative;
  background: white;
  border-radius: $card-corner-radius;
  border-left: 0px solid $primary-orange;
  transition: border-left 0.2s;
  overflow: hidden;
  color: $dark-navy;

  &:hover {
    cursor: pointer;
    border-left-width: 4px;
    color: $dark-navy;
  }

  .communityHeaderImage {
    padding: 16px;
    height: 80px;

    .accoladeIcon {
      flex-shrink: 0;
    }

    .certifiedText {
      color: white;
      font-size: 12px;
      font-weight: 700;
      margin-left: 8px;
    }
  }

  .communityPhoto {
    position: absolute;
    top: 35px;
    right: 16px;
    width: 90px;
    height: 90px;
    border: 1px solid $dark-navy;
  }

  .communityInfo {
    width: calc(100% - 122px);
    padding: 12px 16px 12px 16px;

    .nameText {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .addressText {
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .communityDetails {
    padding: 0 16px 16px 16px;

    .programsCountText {
      color: $dark-teal;
      font-weight: 700;
    }

    .distanceWrapper {
      .bulletPoint {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: $primary-orange;
        flex-shrink: 0;
        margin-top: 5px;
      }

      .distanceText {
        font-size: 12px;
        font-weight: 700;
        margin-left: 8px;
      }
    }
  }
}
