@import "../../../assets/styles/globals";

.division-journey-progress-bar {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .progress-levels-indicator {
    height: 6px;
    width: auto;
    margin-bottom: 4px;
  }

  .progress-levels-arrow {
    position: absolute;
    height: 30px;
    width: auto;
    left: 72px;
    top: 132px;
    // z-index: 4;
    z-index: 2;
  }

  .journey-progress-bar {
    position: absolute;
    right: 34px;
    top: 126px;
    height: 18px;
    width: 420px;
    background-color: white;
    border: 1px solid $placeholder-gray;
    // z-index: 4;

    .journey-progress-bar-fill {
      height: 100%;
      background-color: $light-navy;
    }
  }

  .progress-levels {
    // need this or progress bar and arrow will be on top when exported
    position: relative;

    display: flex;
    gap: 18px;
    margin-bottom: $secondary-padding;
    align-items: flex-end;
    z-index: 20;

    .progress-level {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $light-navy;

      .progress-level-number {
        font-weight: $bold-font-weight;
        font-size: 12px;
      }

      .progress-level-yds {
        font-size: 12px;
      }

      .progress-level-division-badge {
        height: 64px;
        width: auto;
        margin-top: 2px;
      }

      .progress-level-score {
        font-size: $heading-2-size;
        color: $white-font-color;
        font-weight: $bold-font-weight;
      }

      .progress-level-date {
        font-size: 12px;
        color: $white-font-color;
      }
    }
  }
}
