@import "../../assets/styles/globals";

.seriesSchedule {
  .title {
    color: $primary-navy;
    font-size: 1.2rem;
    font-weight: 800;
  }

  .subtitle {
    color: $light-navy;
  }

  .events {
    border-bottom: 1px solid $placeholder-gray;
  }

  .action {
    .actionIcon path {
      fill: $primary-orange;
    }

    .actionText {
      color: $primary-orange;
      font-weight: 700;
    }

    &:hover {
      .actionIcon path {
        fill: darken($primary-orange, 15%);
      }

      .actionText {
        color: darken($primary-orange, 15%);
      }
    }
  }
}
