@import "../../../../../assets/styles/globals";

#StripeModal {
  #modalXIcon {
    cursor: pointer;
  }

  #stripeIcon {
    circle {
      fill: $primary-purple;
    }

    width: 97px;
    height: 97px;
    margin-bottom: 20px;
  }

  .stripe-title {
    font-size: 18px;
    text-align: center;
    color: $primary-purple;
    font-weight: 700;
  }

  .transaction-details {
    font-size: 18px;
    color: $primary-navy;
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $border-lighter-gray;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .key {
    color: $light-navy;
    margin-bottom: 7px;
    margin-right: 5px;
  }

  .value {
    text-align: right;
    margin-bottom: 7px;
  }
}
