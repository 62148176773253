@import "../../../../assets/styles/globals";

.program-presentation-page {
  position: relative;
  max-height: 100%;

  .details-tab-bar-wrapper {
    background-color: $dark-navy;
    flex: 0 0 40px;
    position: sticky;
    top: 0;

    .details-tab-bar {
      z-index: 1;
      font-size: 0.9rem;
      list-style-type: none;

      @media (min-width: 992px) {
        width: 60%;
        font-size: 1rem;
        box-shadow: none;
      }

      .detail-tab {
        flex: 1;

        .detail-tab-button {
          width: 100%;
          height: 100%;
          position: relative;
          padding: 0;
          background-color: transparent;
          border: 0;

          &:hover {
            color: $primary-orange;
          }

          &:active {
            background-color: lighten($dark-navy, 2%);
          }

          &.current-tab {
            color: $primary-orange;

            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: $primary-orange;
            }
          }
        }
      }
    }
  }

  .actions-card-wrapper {
    position: absolute;
    width: 40%;
    height: 85%;
    right: 0;
    top: 15%;

    &.coachLayout {
      top: 10%;
    }

    .actionsWrapper {
      max-width: 90%;

      .actions-card {
        width: 500px;
        max-width: 100%;
        height: fit-content;
        max-height: 80vh;
      }

      .editLink {
        width: fit-content !important;
        margin-top: 0 !important;

        .editIcon {
          fill: white;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.program-presentation-section {
  flex-grow: 1;
  color: $light-navy;
  overflow-y: auto;

  @media (min-width: 992px) {
    flex: 0 0 calc(58% - 45px);
    width: 60%;
  }
}

.program-presentation-footer {
  width: 100%;
  padding: 0 15px;
  flex: 0 0 120px;
  border-top: 1px solid $light-navy;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.registration-closed-placeholder {
  color: $primary-orange;

  .info-circle-icon {
    height: 28px;
    width: 28px;
    flex-shrink: 0;

    @media (min-width: 992px) {
      height: 22px;
      width: 22px;
    }
  }

  .registration-closed-message {
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.preview-mode {
  height: calc(100% - 200px);

  @media (min-width: 576px) {
    height: calc(100% - 170px);
  }
}
