@import "../../../../assets/styles/globals";

#CoachEmailInfo {
  padding: 1em;
  background-color: $off-white;
  border-radius: 8px;
  display: flex;

  @media (min-width: 768px) {
    max-width: 70%;
  }

  .email-icon {
    height: 40px;
    width: 50px;
    flex-grow: 1;
    fill: $primary-orange;
  }

  .email-text {
    flex-basis: fit-content;
    flex-shrink: 1;
    margin-left: 1em;
    line-height: 1.2em;

    .email-address {
      color: $primary-orange;
      text-decoration: underline;
    }
  }
}
