@import "../../../assets/styles/globals";

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  color: $primary-navy;
  display: flex;
  gap: 16px;
  align-items: center;
}

.container {
  padding: 2px 40px;

  .header {
    min-height: 40px;
    align-items: center;
    padding: 10px 0;
    gap: 20px;

    h4 {
      margin: 0;
    }
  }

  .loading {
    padding: 0 !important;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.2px;
    color: $primary-navy;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: 800 !important;
  }

  .subHeader {
    margin-bottom: 35px;
    color: $primary-navy;

    h5 {
      font-size: 18px;
      font-weight: 800 !important;
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    padding: 2px 80px;
  }
}