@import "../../../assets/styles/globals";

#AddLessonPlanModal {
  width: 100vw;
  height: 100vh;

  .choose-template-modal {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #fff;
    padding: 15px;
    .choose-template-icon-container {
      display: flex;
      justify-content: space-between;
      i {
        font-size: 24px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .create-lesson-plan-options {
      margin-top: 36px;

      .create-lesson-plan-options-all {
        display: flex;
        flex-flow: column;
        align-items: center;

        .start-from-scratch-button {
          height: 80px;
          width: calc((100% - 45px) / 2); // Same width as template-box
          margin-bottom: 36px;
          border-radius: 8px;
          text-align: left;
          font-size: 24px;
          padding: 10px;
          border: 2px solid $primary-orange;
          color: $primary-orange;
          background-color: white;

          &:hover {
            background-color: #f4f4f5;
          }

          .blank-icon {
            border: 4px solid $primary-orange;
            padding: 10px;
            border-radius: 8px;
            margin-right: 10px;
          }
        }
      }
    }

    .template-cat-box-container {
      position: relative;
      display: flex;
      .template-cat {
        flex: 0 0 50%;
        margin: 0;
        text-align: center;
        line-height: 2;
        border-bottom: 2px solid #ccc;
        &:hover {
          cursor: pointer;
        }
      }
      .bottom-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 50%;
        background: $primary-orange;
        border-radius: 500px;
        transition: left 0.5s;
      }
      .right {
        left: 50%;
      }
    }

    .all-template-boxes {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .template-cat-title {
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 18px;
        border-bottom: 2px solid #ccc;
        margin: 0px;
      }
    }

    .template-box {
      overflow-y: auto;
      width: calc((100% - 45px) / 2); // (15px * 2 margins) - (15px middle)
      margin-bottom: 36px;
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        margin-left: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
      }
      .template-view {
        display: flex;
        // margin-top: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 2px solid #ccc;
        background: #fff;
        // border-radius: 5px 5px 0 0;
        &:hover {
          cursor: pointer;
          background: #f4f4f5;
        }
        // .template-image {
        //   margin-left: 5px;
        //   flex: 0 0 80px;
        //   height: 80px;
        //   border-radius: 8px;
        //   margin-right: 10px;
        //   background-color: $primary-orange;
        //   background-size: cover;
        //   background-position: center;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   .placeholder-icon {
        //     flex: 0 0 40px;
        //     height: 40px;
        //     background-size: cover;
        //     background-position: center;
        //   }
        // }
        .template-view-content {
          flex: 1 1;
          overflow: hidden; // so ellipsis works on long titles

          .template-title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            font-size: 18px;
            font-weight: bold;
          }
          // .template-session-box {
          //   display: flex;
          //   .calendar-icon {
          //     flex: 0 0 24px;
          //     height: 24px;
          //     background-size: cover;
          //     background-position: center;
          //     margin-right: 6px;
          //   }
          //   .session-count-text {
          //     color: $primary-orange;
          //     span {
          //       font-size: 18px;
          //       font-weight: bold;
          //     }
          //   }
          // }
        }
      }
    }

    .view-another-template-button-wrapper {
      width: 50%;

      .view-another-template-button {
        margin-bottom: 36px;
      }
    }

    .lp-template-preview-content {
      display: flex;
      justify-content: center;
    }

    .responsive-player-wrapper {
      width: 50%;
      height: 0;
      position: relative;
      padding-top: calc(56.25% / 2); /* Player ratio: 100 / (1280 / 720) */
      // Divide by 2 since it only occupies 50% of the container width, I guess

      margin-right: 18px;
      border-radius: 8px;
      overflow: hidden;

      .lp-template-react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .template-about-text {
      width: 50%;

      .template-about-heading {
        display: flex;
        margin-bottom: 18px;
      }

      .template-image {
        margin: 0px;
        margin-right: 10px;
      }
    }

    .template-image {
      margin-left: 5px;
      flex: 0 0 80px;
      height: 80px;
      border-radius: 8px;
      margin-right: 10px;
      background-color: $primary-orange;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .placeholder-icon {
        flex: 0 0 40px;
        height: 40px;
        background-size: cover;
        background-position: center;
      }
    }

    .template-name {
      font-size: 24px;
      font-weight: bold;
    }

    .template-session-box {
      display: flex;
      .calendar-icon {
        flex: 0 0 24px;
        height: 24px;
        background-size: cover;
        background-position: center;
        margin-right: 6px;
      }
      .session-count-text {
        color: $primary-orange;
        margin: 0px;
        span {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .template-note-box {
      .notes-placeholder {
        color: #bbb;
      }
    }
    .tag-title {
      margin-right: 6px;
      font-weight: bold;
      font-size: 16px;
    }
    .template-tag-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        background: #5f6f7c;
        color: #fff;
        margin: 4px;
        border-radius: 500px;
        line-height: 1;
        padding: 2px 8px 4px 8px;
      }
      .tag-placeholder {
        color: #bbb;
      }

      margin-bottom: 18px;
    }

    .select-lp-template-button {
      margin-bottom: 36px;
    }

    .template-confirm-button {
      display: block;
      margin: 18px auto 0 auto;
      width: 100%;
      background: $primary-orange;
      border: none;
      border-radius: 500px;
      color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &:hover {
        position: relative;
        bottom: -2px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

@media (max-width: 768px) {
  #AddLessonPlanModal {
    .choose-template-modal {
      .create-lesson-plan-options {
        .create-lesson-plan-options-all {
          .start-from-scratch-button {
            width: 100%;
          }
        }
      }

      .view-another-template-button-wrapper {
        width: 100%;
      }

      .lp-template-preview-content {
        flex-flow: column;
      }

      .responsive-player-wrapper {
        width: 100%;
        padding-top: 56.25%;
        margin-left: 0px;
        margin-bottom: 36px;
      }

      .template-about-text {
        width: 100%;
      }
    }

    .all-template-boxes {
      flex-flow: column;

      .template-box {
        width: 100%;
      }
    }
  }
}
