@import "../../../assets/styles/globals";

#CommunityStage {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;

  p {
    color: $primary-color !important;
    margin: 0px;
  }

  button {
    background-color: transparent;
    border: none;
    color: $light-red;
    padding: 0px;
    margin: 0px;
  }
}
