@import "../../../globals";

.text-input {
  .field {
    input {
      width: 100%;
      background: $input-background-color;
    }
    input:focus {
      outline: none;
      border-style: solid;
    }
  }
}

.view-edit-form {
  input {
    background-color: transparent;
    border-color: transparent;
    transition: 0.5s;
    &:focus {
      background-color: $light-font-color;
      color: $dark-font-color !important;
      border-width: 1px;
    }
  }
  &:hover {
    // background-color: rgba(255,255,255, .5);
    // color: $dark-font-color !important;
    cursor: pointer;
    input {
      background-color: $light-font-color;
      color: $dark-font-color !important;
      border-width: 1px;
      border-radius: 5px;
      transition: 0.5s;
    }
  }
  &:after {
    content: "\F044";
    font: normal normal normal 14px/1 FontAwesome;
    font-family: "FontAwesome";
    font-family: "FontAwesome";
    margin-left: 10px;
    opacity: 0;
  }
  &:hover:after {
    // color:
    opacity: 1;
  }
}

#border-input * input,
input.input-border {
  border-left-width: 1px;
  border-left: 1px solid #ccc;
}

.input-border * input,
#input-border * input {
  border-radius: 3px;
  border-left-width: 1px;
  border-left-style: solid;
  border-color: rgba(0, 0, 0, 0.15);
}

.noBorderInput {
  border: none;
}

.selection-drop {
  margin-left: 93%;
  margin-top: 10px;
}

.formBreakSection {
  * .title {
    margin-bottom: 0px;
    padding: 10px 0px;
  }
}

.select-box {
  background-color: $white-font-color;
  border: $borderBase;
  padding: 10px;
  select {
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    // background-image: none;
    // -webkit-appearance: none;
  }
}

.custom-checkbox {
  margin-bottom: 5px;
  padding-left: 1rem;
  &.checked {
    span {
      span {
        color: inherit;
      }
    }
  }

  [type="checkbox"] {
    display: none;
  }

  [type="checkbox"] + span {
    display: block;
    font-weight: normal;
  }

  /* unchecked style */
  [type="checkbox"] + span:before {
    content: "\F10C";
    display: inline-block;
    width: 21px;
    height: 21px;

    font-family: "FontAwesome";
    vertical-align: middle;
    border-radius: 50%;
    border: 0;
    margin-right: 3px;
    margin-bottom: 4px;
    line-height: 1.3;
  }
}
.custom-checkbox.checked > span:before {
  content: "\F111";
  font-family: "FontAwesome";
  color: #fff;
}
