@import "../../../../assets/styles/globals";

.childSocialSettings {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 48px;
    }
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
      padding: 48px;
    }

    .socialFeaturesWrapper {
      margin-bottom: 48px;
      margin-top: 16px;

      .shieldCheckMark {
        flex-shrink: 0;
        margin-right: 16px;

        & > path {
          fill: white;
        }
      }

      .socialFeaturesTitle {
        font-size: 20px;
        font-weight: 700;
        color: white;
        margin-right: 16px;
        margin-bottom: 0;

        @media (min-width: 768px) {
          margin-right: 32px;
        }
      }

      .QuestionMarkCircle {
        flex-shrink: 0;
        cursor: pointer;
        width: 24px;
        height: auto;

        & > circle {
          stroke: white;
        }

        & > path {
          fill: white;
        }
      }
    }

    .toggleWrapper {
      :global(.react-toggle) {
        height: min-content;
      }

      :global(.react-toggle .react-toggle-track) {
        background-color: $placeholder-gray !important;
        height: 22px !important;
        width: 38px !important;
      }

      :global(.react-toggle .react-toggle-thumb) {
        border-color: $placeholder-gray;
        height: 20px;
        width: 20px;
      }

      :global(.react-toggle:active .react-toggle-thumb) {
        box-shadow: none;
      }

      :global(.react-toggle:hover .react-toggle-track) {
        background-color: $primary-navy !important;
      }

      :global(.react-toggle--checked .react-toggle-track) {
        background-color: $primary-orange !important;
      }

      :global(.react-toggle--checked .react-toggle-thumb) {
        border-color: $primary-orange;
        left: 17px;
      }

      :global(.react-toggle--checked:hover .react-toggle-track) {
        background-color: $primary-orange !important;
      }

      .toggleLabel {
        color: white;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3;
        margin-left: 16px;
        margin-bottom: 0;
      }

      .toggleSubtitle {
        color: white;
        font-size: 14px;
        line-height: 1.3;
        margin-top: 16px;
      }
    }

    .checkboxSection {
      margin-top: 48px;
      margin-bottom: 32px;

      .checkboxWrapper {
        cursor: pointer;
        margin-bottom: 32px;
        width: fit-content;

        .checkboxLabel {
          color: white;
          font-size: 16px;
          margin-bottom: 0;

          @media (min-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }

    .titleText {
      color: white;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
      text-align: left;
    }
  }
}
