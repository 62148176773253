@import "../../../../assets/styles/globals";

.comm-leaderboard-card {
  background-color: white;
  border-radius: $card-corner-radius;
  box-shadow: $non-pressable-elem-drop-shadow;
  display: flex;
  align-items: center;
  // padding: $secondary-padding;
  // margin-bottom: $secondary-padding;
  padding: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
}

.comm-leaderboard-card-ordinal {
  background-color: $light-navy;
  color: white;
  border-radius: 50%;
  width: 2.916vw; // 2.916% of 1920px width
  height: 2.916vw; // 2.916% of 1920px width
  // margin-right: $secondary-padding;
  margin-right: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-weight: $bold-font-weight;
    // font-size: 1.25vw; // 1.25% of 1920px width
    font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
  }
}

.comm-leaderboard-card-pic {
  width: 3.54vw; // 3.54% of 1920px width
  height: 3.54vw; // 3.54% of 1920px width
  border-radius: 50%;
  border: 4px solid $primary-navy;
  object-fit: cover;
  margin-right: $secondary-padding;
}

.comm-leaderboard-card-name {
  font-weight: $bold-font-weight;
  margin-bottom: 0;
  // font-size: 1.875vw; // 1.875% of 1920px width
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  // flex: 1 0 auto;

  // Need these in case name is too long
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(330px / 1px / $ideal-academy-display-width * 100vw);
}

.comm-leaderboard-card-points {
  // Set margins so card points is aligned to right end of card
  margin: auto;
  margin-right: 0;
  // font-size: 1.875vw; // 1.875% of 1920px width
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
}
