@import '../../globals';

#RanksPage{
 

  * .lockedRank{
      .row{
        background-color: $disable-color;  
      }      
  }
  .tabBar{
    position: fixed;
    // top: 60px;
    width: 100%;
    z-index: 999;
    .col{
      margin: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .activeTab{
      background-color: $objective-tabbar-background-color;
    }
    ul{
      background-color: $primary-color;
      text-align: center;
      height: 42px;
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      li{
        line-height: 42px;
        display: inline;
        color: $secondary-color;
      }
    }
  }

  #objectiveWrapper{
    // margin-top: 5px;
    // padding-right: 4px;
    // padding-left: 4px;
  }
}
