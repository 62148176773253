@import "../../../assets/styles/globals";

#CoachRow {
  width: 100%;
  margin: 0px;
  margin-bottom: 15px;
  border: 1px solid $light-font-color !important;

  .orange-highlight {
    border: 3px solid $primary-orange;
  }

  button {
    background-color: transparent;
    border: none !important;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    margin: 0 auto;
  }

  .coach-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin: 0px;
      margin-left: 8px;
    }

    .coach-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .coach-name {
        color: $primary-color;
        font-size: 18px;
      }
      .cert-preview-icon {
        height: 25px;
      }
      .coach-login-date {
        color: $light-font-color;
        font-size: 14px;
      }
    }
  }

  .coach-score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 5px;

    p {
      margin: 0px;
      font-size: 16px;
      margin-right: 5px;
    }

    .coach-score-light {
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
  }
}
