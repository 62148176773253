@import "../../assets/styles/globals.scss";

#Claps {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 75px;
  height: 50px;

  p {
    color: $light-font-color;
  }

  .clap-wrapper {
    height: 25px;
    width: 25px;
    margin-right: 5px;

    img {
      width: 100%;
    }
  }
}
.clap-popover {
  background-color: $primary-color;
  height: 100%;
  padding: 15px 15px 0px 15px;

  .clap-popover-container {
    p {
      color: $white-font-color;
    }
  }
}
