@import "../../../../assets/styles/globals";

#LessonBasicDetails {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  .outer-container {
    // padding-top: 40px;
    // padding-bottom: 40px;
    flex: 0 0 80%;
    height: 80%;
    display: flex;
  }
  .picture-area {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    .picture-box {
      background: #c4c4c4;
      border-radius: 8px;
      color: #828282;
      height: 350px;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i {
        font-size: 170px;
      }
      .lp-photo-uploader:hover {
        cursor: pointer;
      }
      .photo-preview-container {
        height: 100%;
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .remove-photo-button {
          font-size: 0.8rem;
          text-align: center;
          i {
            font-size: 0.8rem;
          }
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .lp-video-container {
      label {
        color: #1d2a33;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 16px;
        padding-top: 10px;
      }
      #lp-video-input {
        width: 100%;
        // border-radius: 5px !important;
        border: none !important;
        border-bottom: 2px solid #1d2a33 !important;
        // box-shadow: 1px 1px 1px #bbb !important;
        color: #1d2a33 !important;
        font-size: 16px;
        font-weight: normal;
        padding-left: 0;
        margin-bottom: 36px;
      }
    }
  }

  .form-area {
    padding: 10px;
    flex: 0 0 60%;
    // overflow-y: auto;
    .form-box {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      label {
        color: #1d2a33;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 18px;
        .orange-text {
          color: $primary-orange;
        }
      }
      input,
      textarea {
        width: 100%;
        // border-radius: 5px !important;
        border: none !important;
        border-bottom: 2px solid #1d2a33 !important;
        // box-shadow: 1px 1px 1px #bbb !important;
        color: #1d2a33 !important;
        font-size: 16px;
        font-weight: normal;
        padding-left: 0;
        margin-bottom: 36px;
        // &:focus {
        //   border: 1px solid #1d2a33 !important;
        // }
      }
      #lp-text-tag-input {
        margin-bottom: 3px;
      }
      #lp-name-input {
        margin-bottom: 3px;
      }
      .failed-validation-box {
        margin-bottom: 33px;
        p {
          margin: 0;
          color: $primary-orange;
        }
      }
      textarea {
        padding-left: 6px;
        border: 2px solid #1d2a33 !important;
        border-radius: 4px !important;
      }
      .lp-default-duration-container {
        width: 40%;
      }
      // .lp-text-tag-container {
      //   width: 60%;
      // }
      #lp-notes-input {
        height: 150px;
        resize: none;
      }

      input[type="time"] {
        &::-webkit-clear-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          -ms-appearance: none;
          appearance: none;
          margin: -10px;
        }
        // &::-webkit-datetime-edit-ampm-field {
        //   display: none;
        // }
      }
      .tag-display-box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 36px;
        .tag-bubble {
          margin: 0;
          margin-right: 5px;
          margin-bottom: 3px;
          padding: 0 8px 3px 8px;
          background: #5f6f7c;
          color: #fff;
          border-radius: 500px;
          span {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .outer-container {
      flex-wrap: wrap;
    }
    .picture-area {
      flex: 0 0 100%;
    }
    .form-area {
      flex: 0 0 100%;
    }
  }
  @media screen and (max-width: 650px) {
    .lp-default-duration-container {
      width: 80%;
    }
    .lp-text-tag-container {
      width: 100%;
    }
  }
}
