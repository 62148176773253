@import "../../../../assets/styles/globals";

.aboutYou {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 24px;
    }
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
      padding: 48px;
      padding-top: 24px;
    }

    .titleText {
      color: white;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 32px;
      text-align: left;
    }

    .accountSegments {
      color: white;

      .categoryWrapper {
        .categoryCard {
          cursor: pointer;
          height: 100%;
          background-color: $primary-navy;
          border: 1px solid $primary-navy;
          border-radius: 8px;
          margin-bottom: 16px;
          padding: 16px;

          &:hover {
            background-color: $light-navy;
            border: 1px solid white;
          }

          .categoryTitle {
            font-weight: 700;
            margin-bottom: 10px;
          }

          .categoryDetails {
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }

      .individualIcon, .professionalIcon, .familyIcon {
        width: 40px;
        height: auto;
        fill: white;
        flex-shrink: 0;
        margin-right: 24px;

        path {
          fill: white;
        }
      }
    }
  }
}
