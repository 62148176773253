@import "../../../assets/styles/globals";

#DraggableResourceCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $light-font-color !important;
  margin-bottom: 15px;
  width: 100%;
  padding: 10px;

  i {
    font-size: 25px;
    margin-right: 25px;
  }

  input {
    width: 100%;
  }
}
