@import "../../../assets/styles/globals";

.select-wrapper {
  .control {
    height: 40px;
    font-size: 1rem;
    cursor: pointer;
    border-color: $placeholder-gray;
    border-radius: $card-corner-radius;
    background: none;
    box-shadow: none;
    padding: 0;
    outline: 0;

    &:hover {
      border-color: $placeholder-gray;
    }

    &.control-error {
      border-color: $input-error-color;
    }

    @media (min-width: 768px) {
      font-size: 0.9rem;
    }
  }

  .value-container {
    cursor: pointer;
    padding: 0 10px;
    white-space: nowrap;
  }

  .single-value {
    margin: 0;
    color: $dark-navy;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    background-color: inherit;
    color: $placeholder-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  .input-wrapper {
    margin: 0;
    padding: 0;

    input {
      border: none !important;
      color: $dark-navy !important;
    }
  }

  .indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  .menu {
    z-index: 999999;
    overflow: hidden;
    border: solid 1px $placeholder-gray;
    box-shadow: 0 2px 5px $light-gray;
  }

  .no-options-message {
    font-size: 1rem;
    color: $placeholder-gray;

    @media (min-width: 768px) {
      font-size: 0.9rem;
    }
  }

  .option {
    min-height: 40px;
    max-height: fit-content;
    width: 100%;
    font-size: 1rem;
    color: $dark-navy;
    cursor: pointer;
    background-color: white;

    &:hover {
      background-color: $disable-color;
    }

    &.option-disabled {
      cursor: not-allowed;
      color: $placeholder-gray;

      &:hover {
        background-color: white;
      }
    }

    @media (min-width: 768px) {
      font-size: 0.9rem;
    }
  }
}
