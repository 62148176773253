@import '../../../assets/styles/globals.scss';

.coachDashboardStats {
  width: 25%;
  border-right: 1px solid $placeholder-gray;
  height: 100%;
  background-color: white;

  .statsHeader {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    position: sticky;

    .iconWrapper {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    .icon{
      color: $secondary-color;

      &:hover {
        color: $secondary-color-alt;
      }
    }

    .statsTitle {
      color: $dark-navy;
      font-weight: 700;
    }
  
    .arrowWrapper {
      float: right;
      background-color: $secondary-color;
      height: 30px;
      width: 30px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &:hover {
        cursor: pointer;
        background-color: $secondary-color-alt;
      }
  
      .arrowButton {
        background-position: center;
        background-size: cover;
        height: 22px;
        width: 26px;
        flex-shrink: 0;
        border-style: none;
        background: url('../../../assets/images/common/icons/arrow-white.svg') no-repeat;
      }
    }

    .filterButtons {
      height: min-content;
      font-size: 16px;
      border-radius: 100px;
      border: 2px solid $dark-navy;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      white-space: nowrap;
      width: 50%;
      margin-left: 10px;
  
      .filterButton {
        border: none;
        width: 50%;
        height: 30px;
        border-radius: 30px;
      }
  
      .filterSelected {
        color: white;
        background-color: $dark-navy;
      }
  
      .filterUnselected {
        color: $dark-navy;
        background-color: white;
      }
    }
  
  }

  .statsHeaderCollapsed {
    justify-content: center;
  }

  
  .statsItemsContainer {
    height: calc(100% - 96px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 30px;
  }

  .statsItem {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    align-content: center;
    margin-top: 30px;

    .levelIcon {
      width: 50px;
      height: 50px;
    }

    .statsCount{
      text-align: center;
      margin: auto;
      font-size: 26px;
      font-weight: 600;
    }

    .level {
      font-weight: 600;
    }

    .yards {
      color: $light-navy;
    }
  }
}

.statsCollapsed {
  width: 5% !important;
  border-right: none !important;
  background-color: white;
}

.statsPopoverContainer {

  .statsPopoverHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .statsPopoverHeader {
      margin-bottom: 0px;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-left: 10px;
      color: $light-gray;
      font-size: 13px;
    }

    .statsPopoverHeaderCount {
      margin-bottom: 0px;
      margin-right: 10px;
      color: $light-gray;
      font-size: 13px;
    }
  }
  
  .statsPopoverGolferWrapper {
    max-height: 300px;
    overflow-y: scroll;
    width: 270px;
  }
}

.popoverGolferCard {
  background-color: white;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
  justify-content: space-between;
  align-items: center;

  .popoverGolferWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    .popoverGolfer {
      cursor: pointer;
    }
  }

  .popoverGolferName {
    font-size: 14px;
  }

  .popoverAttemptsWrapper {
    .popoverAttempts {
      margin-bottom: 0px;
      margin-right: 10px;
      font-size: 19px;
      font-weight: 550;
      color: $secondary-color;
    }
  }
}