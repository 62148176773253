@import "../../../../assets/styles/globals.scss";

.communityFeed {
  flex-basis: 28%;
  padding: 20px;
  border-right: 1px solid $placeholder-gray;
  height: 100%;
  position: relative;

  &:hover {
    .arrowWrapper {
      opacity: 1;
    }
  }

  .arrowWrapper {
    position: absolute;
    bottom: 500px;
    background-color: $secondary-color;
    height: 30px;
    width: 30px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -15px;
    opacity: 0;

    .arrowButton {
      background-position: center;
      background-size: cover;
      height: 22px;
      width: 26px;
      flex-shrink: 0;
      border-style: none;
      background: url("../../../../assets/images/common/icons/arrow-white.svg")
        no-repeat;
    }
  }

  @media (min-width: 1400px) {
    flex-basis: 500px;
  }

  &.collapsed {
    flex-basis: 5%;
    border-right: none;
    background-color: white;
  }

  .iconWrapper {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .icon {
    color: $secondary-color;

    &:hover {
      color: $secondary-color-alt;
    }
  }

  .feedHeader {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    position: sticky;

    .feedTitle {
      color: $dark-navy;
      font-weight: 800;
    }
  }

  .feedHeaderCollapsed {
    justify-content: center;
  }

  .feedContainer {
    height: calc(100% - 125px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 1rem;
    scrollbar-width: none;

    .loader {
      padding-top: 0 !important;
    }

    .feedContainer::-webkit-scrollbar {
      display: none;
    }

    .noActivities {
      height: calc(100% - 125px);
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: hidden;

      .text {
        font-size: 0.9rem;
      }

      .placeholderText {
        text-align: center;
        color: $primary-navy;
      }

      .placeholderTitle {
        font-weight: 800;
      }
    }

    .loadingSection {
      gap: 2rem;
    }

    .switchButton {
      width: 100%;
      height: fit-content;
      border-radius: 100px;
      border: 2px solid $primary-orange;
      color: $primary-orange;
      font-size: 0.9rem;
      padding: 0;
      background-color: transparent;

      .switchOption {
        width: 50%;
        border: none;
        padding: 0.25em 0;
        border-radius: 30px;
        user-select: none;

        &.switchSelected {
          color: white;
          background-color: $primary-orange;
        }
      }
    }
  }
}
