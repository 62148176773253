@import "../../../assets/styles/globals";

.progressBar {
  position: relative;
  height: 38px;
  width: 100%;

  .backgroundLine {
    width: 100%;
    height: 7px;

    &.firstStep {
      background: linear-gradient(to right, $primary-orange 22px, $primary-navy 22px);

      @media (min-width: 768px) {
        background: linear-gradient(to right, $primary-orange 68px, $primary-navy 68px);
      }
    }

    &.otherStep {
      background: linear-gradient(to right, $primary-orange 50%, $primary-navy 50%);
    }

    &.lastStep {
      background: linear-gradient(to left, $primary-navy 22px, $primary-orange 22px);

      @media (min-width: 768px) {
        background: linear-gradient(to left, $primary-navy 68px, $primary-orange 68px);
      }
    }
  }

  .outerCircle {
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: $primary-orange;

    .innerCircle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $primary-orange;
      border: 3px solid $dark-navy;
      color: white;
      text-align: center;
    }

    &.firstStep {
      left: 16px;

      @media (min-width: 768px) {
        left: 48px;
      }
    }

    &.otherStep {
      left: 0;
      right: 0;
      margin: auto;
    }

    &.lastStep {
      right: 16px;

      @media (min-width: 768px) {
        right: 48px;
      }
    }
  }
}

.labelWrapper {
  width: 100%;
  margin-bottom: 0;

  .stepLabel {
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 700;

    width: 70px;

    @media (min-width: 768px) {
      width: 134px;
    }
  }

  &.firstStep {
    text-align: left;
  }

  &.otherStep {
    text-align: center;
  }

  &.lastStep {
    text-align: right;
  }
}
