@import "../../assets/styles/globals";

#Toast {
  padding: 15px;
}

#ToastContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
  padding: 15px;

  p {
    text-align: center;
  }

  button {
    background-color: transparent !important;
    border: 1px solid $white-font-color !important;
    color: $white-font-color !important;
    border-radius: 500vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.25) !important;
      background-color: rgba(255, 255, 255, 0.25) !important;
    }
  }
}
