@import "../../assets/styles/globals.scss";
#ReportsThumbCard {
  background: #fff;
  display: flex;
  border-radius: 8px;
  margin-right: 36px;
  margin-bottom: 36px;
  padding: 18px;
  flex: 0 0 46%;
  // align-items: center;
  .thumb {
    height: 170px;
    flex: 0 0 220px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    margin-right: 18px;
  }
  .title {
    margin: 0;
    margin-bottom: 9px;
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 700px;
  }
  .summary {
    margin: 0;
    font-size: 16px;
    line-height: 1.2em;
    color: $light-navy;
  }
  button {
    margin-top: 9px;
  }
}

@media screen and (max-width: 986px) {
  #ReportsThumbCard {
    flex: 0 0 100%;
  }
}
