#CurriculumDetailModal {
  .grey-out {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: #37434e;
    opacity: 0.8;
    z-index: 10;
  }
  .curriculum-detail-modal-content {
    position: fixed;
    height: 90vh;
    top: 5vh;
    width: 80vw;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 921px;
    max-height: 820px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    overflow: hidden;
    z-index: 15;
    display: flex;
    .modal-preview-bar {
      width: 50%;
      max-width: 423px;
      background: #f4f4f5;
      display: flex;
      flex-direction: column;
      .top-photo {
        width: 100%;
        flex: 0 0 170px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        .subject-icon {
          height: 100px;
          flex: 0 0 100px;
          border-radius: 50%;
          border: 2px solid #5b5b5b;
          background-color: #c4c4c4;
          background-position: center;
          background-size: cover;
        }
      }
      .title-bar {
        background: #36434e;
        color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        .subject-title {
          font-size: 36px;
          padding-left: 18px;
          margin-bottom: 10px;
        }
        .subject-subtitle {
          font-size: 16px;
          padding-left: 18px;
        }
      }
      .objective-list-view {
        flex: 1 1;
        width: 100%;
        padding: 18px 36px;
        overflow-y: auto;
        .objective-preview-bar {
          display: flex;
          align-items: center;
          padding: 10px;
          background: #fff;
          color: #5f6f7c;
          border-radius: 8px;
          margin-bottom: 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          &:hover {
            cursor: pointer;
          }
          .subject-icon-detail {
            height: 48px;
            flex: 0 0 48px;
            border-radius: 50%;
            margin-right: 10px;
            background-position: center;
            background-size: cover;
          }
          .objective-preview-text {
            flex: 1 1;
            p {
              margin: 0;
            }
            .objective-preview-title {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .fa-angle-right {
            font-size: 28px;
          }
        }
        .selected-objective {
          background: #5f6f7c;
          color: #fff;
        }
      }
    }
    .object-view-area {
      flex: 1 1;
      padding: 18px 36px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .exit-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 36px;
        i {
          font-size: 24px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .objective-video-container {
        border-radius: 8px;
        margin-bottom: 18px;
      }
      .objective-view-title-area-container {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .objective-icon-detail {
          height: 70px;
          flex: 0 0 70px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          margin-right: 18px;
        }
        .objective-view-title-area {
          flex: 1 1;
          p {
            margin: 0;
          }
          .objective-detail-title {
            font-size: 24px;
            font-weight: bold;
          }
          .objective-detail-subtitle {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .objective-resources-container {
        flex: 1 1;
        overflow-y: auto;
        max-height: 300px;
        min-height: 150px;
        .objective-resources-box {
          background: #f4f4f5;
          border-radius: 8px;
          padding: 18px;
          .resource-section-title {
            font-weight: bold;
          }
          .no-resource-placeholder {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: #ccc;
            height: 69px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            p {
              margin: 0;
              text-align: center;
            }
          }
          .resource-button {
            cursor: pointer;
            background-color: #ff6633;
            color: #fff;
          }
        }
      }
    }
  }
  .hasSideNav {
    width: calc(90vw - 200px);
    left: 200px;
    top: 72px;
  }
}
