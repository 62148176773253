@import '../../../assets/styles/globals.scss';

.event-modal {
  .title {
    font-size: 24px;
    font-weight: 800;
  }

  .label-text {
    font-weight: 800;
  }

  .hl {
    position: static;
    display: inline-block;
    background: $placeholder-gray;
    width: 100%;
    height: 1px;
    margin: 15px;
  }
}