@import '../../globals';

.sideBarItemExpanded{
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 14px;

  .iconWrapper {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
  }

  .icon {
    fill: $icon-gray;
  }

  .iconSelected {
    fill: white;
  }

  i {
    color: $icon-gray;
  }

  .title {
    padding: 20px;
    color: $icon-gray;
    font-weight: bold;

    a {
      color: $icon-gray;
      font-weight: bold;

      &:hover {
        color: white;
      }
    }
  }

  .selectedTitle {
    color : white;

    a {
      color : white;
    }
  }
}

.section {
  font-size: 14px;

  .sectionLink {
    padding-bottom: 20px;
    margin-left: 60px;

    a {
      color: $icon-gray;
      
      &:hover {
        color: white;
      }
    }
  }

  .selectedSectionLink {
    a {
      color: white;
    }
  }
}