@import "../../../../assets/styles/globals";

.schedule-builder-session-card {
  background-color: #fff;
  color: $light-navy;
  margin-top: -2px;
  padding: 6px;
  border-top: 1px solid $placeholder-gray;

  @media screen and (min-width: 400px) {
    padding: 18px;
  }

  p {
    margin-bottom: 0;
  }

  i {
    color: $placeholder-gray;
    font-size: 20px;
  }

  .icon-wrapper{
    min-width: 18px;
    margin-right: 7px;
    text-align: center;
  }

  .date-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    width: 76px;
    padding-right: 18px;
    border-right: 2px solid $placeholder-gray;

    .missing-date {
      font-size: 20px;
      font-weight: 700;
      color: $input-error-color;
    }

    .day-number {
      font-weight: 700;
      font-size: 36px;
      color: $dark-navy;
    }

    .month-name {
      font-weight: 700;
      color: $dark-navy;
    }
  }

  .session-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 16px;
    flex-grow: 1;

    .title {
      color: $dark-navy;
      font-weight: 700;
      font-size: 18px;
    }

    .edit-icon, .delete-icon {
      cursor: pointer;
    }

    .time-range {
      color: $primary-orange;
      font-weight: 700;
    }

    .description {
      white-space: pre-wrap;
    }
  }

  .error-container {
    i {
      color: $input-error-color;
    }
  }
}
