@import "../../assets/styles/globals";

.display-active {
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
}

.display-inactive {
  height: calc(100vh - 70px); // Subtract height of navbar
}

#ProShopDisplay {
  display: flex;
  justify-content: center;
  background-color: $off-white;
  // overflow-y: auto;

  #pro-shop-grid {
    display: grid;
    grid-template-areas:
      "sidebar-zone main-zone"
      "sidebar-zone footer-zone";
    grid-template-rows: 1fr auto;
    grid-template-columns: 16.875% auto;
    width: 100vw;
    height: 100vh;
    background-color: $dark-navy;
  }

  .slideshow-controls-bar {
    background-color: white;
    width: 100vw;
    padding: $secondary-padding;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
  }

  .controls-hide {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }

  .controls-show {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out;
  }

  #stop-slideshow-btn {
    min-width: 50vw;
  }
}

.needTwoModal {
  width: 400px;
  background-color: white;
  padding: $primary-padding;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $card-corner-radius;
  box-shadow: $modal-drop-shadow;
  p {
    text-align: center;
  }
}
