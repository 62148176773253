#objective-form {
  label {
    margin-top: 0.5rem;
  }
  .name-input-container {
    padding-right: 0;
  }
  .points-input-container {
    padding-left: 0;
  }
  #save-objective-btn {
  }
}