@import "../../../assets/styles/globals";

#DrillPreviewCard {
  background: #fff;
  // height: 85px;
  min-height: 85px;
  // max-width: 480px;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 9px;
  margin-bottom: 9px;
  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  .grey-out {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    background: #5f6f7c;
    opacity: 0.7;
    .task-nav-buttons-container {
      flex: 0 0;
      align-self: center;
      i {
        font-size: 2rem;
        padding-left: 5px;
        &:hover {
          cursor: pointer;
          color: #33d9b2;
        }
      }
    }
    .task-play-button-container {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 3rem;
        &:hover {
          color: $primary-orange;
          cursor: pointer;
        }
      }
      .archived-text {
        margin: 0;
        text-align: center;
        padding-left: 10px;
        color: #f8e4e4;
      }
    }
    .task-delete-container {
      flex: 0 0;
      display: flex;
      align-self: flex-start;
      .fa-trash-o {
        margin-right: 5px;
        margin-top: 5px;
        padding: 5px;
        font-size: 2rem;
        &:hover {
          cursor: pointer;
          color: red;
        }
      }
    }
  }
  .drill-card-photo {
    flex: 0 0 85px;
    height: 85px;
    background-position: center;
    background-size: cover;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  // .archived-photo {
  //   filter: grayscale(100%);
  // }
  .drill-card-info-container {
    padding-left: 10px;
    flex: 1 1;
    max-width: calc(100% - 136px);
    p {
      margin: 0;
    }
    .drill-card-title {
      font-size: 16px;
      font-weight: bold;
      color: #1d2933;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .drill-card-description {
      font-size: 14px;
      color: #5f6f7c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .drill-card-tag-container {
      display: flex;
      flex-wrap: wrap;
      .drill-card-tag-bubble {
        padding: 5px 10px;
        background: #5f6f7c;
        color: #fff;
        border-radius: 500px;
        margin-right: 6px;
        margin-bottom: 1px;
        font-size: 13px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .more-tags-indicator {
        color: #5f6f7c;
        text-decoration: underline;
      }
    }
  }
  .fa-play-circle-o {
    flex: 0 0;
    font-size: 3rem;
    color: #5f6f7c;
    margin-right: 10px;
  }

  @media screen and (max-width: 1000px) {
    .drill-card-tag-container {
      display: none !important;
    }
  }
}
