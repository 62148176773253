
  button{
    cursor: pointer;
    @include border-radius(0px);
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white-font-color;
    &:hover{
      cursor: pointer;
    }
  }

  button:active{
    background-color: $secondary-color-alt;
    border-color: $secondary-color-alt;
  }

  a.btn{
    @include border-radius(0px);
  }

  a.btn, button.btn{
  	&-primary{
  		background-color: $secondary-color;
    	border-color: $secondary-color;
    	color: $white-font-color;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: $secondary-color-alt !important;
        border-color: $secondary-color-alt !important;
        box-shadow: none !important;
    	}
  	}

    &-dark{
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white-font-color;

      &:hover, &:active,  &:visited, &:focus, &:focus-visible {
        background-color: lighten($primary-color, 20%) !important;
        border-color: lighten($primary-color, 20%) !important;
        color: $white-font-color !important;
        box-shadow: none !important;
      }
    }

    &-white{
      background-color: $white-font-color;
      border-color: $secondary-color;
      color: $secondary-color;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: lighten($primary-color, 20%) !important;
        border-color: lighten($primary-color, 20%) !important;
        box-shadow: none !important;
      }
    }

    &-default{
      background-color: $white-font-color;
      border-color: $mid-gray;
      color: $mid-gray;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: lighten($light-gray, 20%) !important;
        border-color: lighten($mid-gray, 20%) !important;
        box-shadow: none !important;
      }
    }

    &-gray{
      background-color: darken($light-gray, 10%);
      border-color: $mid-gray;
      color: $mid-gray;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: $light-gray !important;
        border-color: lighten($mid-gray, 5%) !important;
        box-shadow: none !important;
      }
    }

    &-red {
      background-color: $red;
    	border-color: $red;
    	color: $white-font-color;

      &:hover, &:active, &:visited, &:focus, &:focus-visible {
        background-color: $red-darker !important;
        border-color: $red-darker !important;
        box-shadow: none !important;
    	}
    }
  }

  a.rounded{
    @include border-radius(1.5em);
  }


.list-group-item.active {
   color: $white-font-color;
   background-color: $secondary-color;
   border-color: $secondary-color;
}
