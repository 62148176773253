@import "../../../assets/styles/globals";

// Don't use #HowToUseAppModal here - react-modal won't add it to the modal
// for some reason

.how-to-use-app-wrapper {
  // max-width: 612px; // 8.5 inches
  max-width: 850px;
  // width: 60%;
  margin: $primary-padding auto;
  text-align: center;

  h2 {
    font-size: $heading-2-size;
    margin-bottom: $secondary-padding;
  }

  button {
    width: 100%;
    margin-bottom: $primary-padding;
  }

  #how-to-use-app-page-scroll-container {
    width: 100%;
    overflow: auto;
  }

  #how-to-use-app-page {
    // max-height: 792px; // 11 inches
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 129.411%;
    overflow: hidden;
    border: 1px solid $dark-navy;

    #how-to-use-app-page-content {
      position: absolute;
      left: 0;
      top: 0;

      .how-to-use-app-title, .how-to-use-app-steps {
        padding: $secondary-padding;
      }

      .how-to-use-app-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $primary-navy;
        color: white;

        .how-to-use-app-title-logo {
          height: 24px;
          width: auto;
        }

        h3 {
          margin: 0px;
          font-size: $heading-3-size;
          font-weight: $bold-font-weight;
        }
      }

      .how-to-use-app-steps {
        p {
          color: $light-navy;
        }

        h4 {
          color: $primary-orange;
          font-size: $heading-2-size;
          font-weight: $bold-font-weight;
          margin: 0px;
        }

        .how-to-use-app-part-1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $secondary-padding;

          i {
            color: $primary-orange;
            font-size: $heading-1-size;
            opacity: 0.5;
          }

          .how-to-use-app-step-1, .how-to-use-app-step-2 {
            .how-to-use-app-url {
              margin-bottom: 10px;

              a {
                color: $primary-orange;
                text-decoration: underline;

                &:hover {
                  color: darken($primary-orange, 15%);
                }
              }
            }

            .how-to-use-app-qr-phone {
              margin: 0 auto;
              margin-top: $secondary-padding;
              margin-bottom: 10px;
              height: 250px;
              width: 264px; // 200px phone width + 64px half QR code width

              display: grid;
              grid-template-columns: 64px 64px 1fr;

              svg { // QR code
                border: 2px solid $primary-orange;
                border-radius: $card-corner-radius;
                box-shadow: $pressable-elem-drop-shadow;
                grid-column: 1 / span 2;
                grid-row: 1;
                align-self: end;
              }

              .how-to-use-app-qr-mockup { // phone image
                max-height: 250px;
                max-width: 200px;
                grid-column: 2;
                grid-row: 1;
              }
            }
          }
        }

        .how-to-use-app-part-2 {
          h5 {
            color: $dark-navy;
            font-size: $heading-4-size;
            font-weight: $bold-font-weight;
            margin-top: 10px;
            margin-bottom: $secondary-padding;
          }

          .how-to-use-app-feature-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-image:
              linear-gradient(to right, rgba(255, 255, 255, 0) 25%, #FFFFFF 50%),
              url('./golf-actions-bubbles-bg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            .how-to-use-app-feature-graphic {
              width: 50%;
              height: auto;
              object-fit: contain;
            }

            .how-to-use-app-feature-text {
              text-align: left;
              margin-left: $secondary-padding;

              h6 {
                color: $primary-orange;
                font-size: $heading-4-size;
                font-weight: bold;
                margin-bottom: 4px;
              }

              p {
                font-size: 12px;
                margin-bottom: $secondary-padding;
              }

              #desc4 {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .how-to-use-app-wrapper {
    #how-to-use-app-page-scroll-container {
      border: 1px solid $dark-navy;
    }

    #how-to-use-app-page {
      // display: none;
      border: none;
      width: 850px;
      height: 1100px;
    }
  }
}
