@import "../../assets/styles/globals.scss";

.material-card {
  background: white;
  border: 1px solid $border-darker-gray;
  border-radius: 8px;
  padding: 18px;
  line-height: 1.2;

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }

  .material-info {
    .photo {
      height: 130px;
      width: 130px;
      margin: auto 0;
      border-radius: 8px;

      @media (max-width: 576px) {
        height: 75px;
        width: 75px;
        margin: 0;
      }
    }

    .text-section {
      margin-left: 17px;
      padding: 1.5% 0;

      @media (max-width: 576px) {
        padding: 0;
      }

      .title {
        margin-bottom: 10px;
        font-size: 1.3em;

        @media (max-width: 576px) {
          flex-grow: 1;
          display: flex;
          align-items: center;
          height: 75px;
          margin-top: 0;
        }

        .name {
          font-weight: 800;
          font-size: inherit;
        }

        .price {
          font-weight: 800;
          color: $primary-orange;
          font-size: inherit;
        }
      }

      .description {
        @media (max-width: 576px) {
          margin-left: -92px;
          width: calc(100% + 92px);
        }
      }
    }
  }

  .actions {
    margin-top: 20px;

    .select-action {
      max-width: 70%;
      cursor: pointer;
    }

    .learn-more-action {
      font-weight: 700;
      color: $primary-orange;
      padding: 0;
      background-color: transparent;
      border: 0;
    }
  }
}
