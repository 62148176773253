@import '../../../assets/styles/globals.scss';

.CoachDashboardSupport {
  margin-top: 30px;
  width: 400px;
  margin-left: 45px;
  margin-right: 60px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;

  .title {
    font-weight: 600;
  }

  .profileImg {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
  }

  .repName {
    font-weight: 600;
  }

  .bioTooltip {
    background-color: white !important;
    opacity: 1 !important;
    border-color: $icon-gray !important;
    border-radius: 10px !important;
    color: $light-navy;
    width: 350px;
    font-size: 16px;

    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
  .repBio {
    font-size: 14px;
    color: $primary-orange;
    font-weight: 600;
    cursor: pointer;
  }

  .supportBtn {
    width: 300px;
    margin-bottom: 20px;
  }

  .supportActionLink {
    color: black;
    font-size: 16px;
    width: 400px;
    border-top: 1px solid $placeholder-gray;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
      fill: $placeholder-gray;
      height: 20px;
      width: 13px;
      margin-right: 30px;
    }

    &:hover {
      background-color: $background-color;
    }
  }
  
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .CoachDashboardSupport {
    width: 330px;
    margin-right: 0px;

    .supportActionLink {
      width: 330px;
      font-size: 14px;
      padding-left: 50px;
    }
  }
}