@import "../../../assets/styles/globals";

#AdminUsersPage {
  .users-table-header {
    p {
      color: $light-font-color;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }

  .users-search-bar {
    width: 98%;
    margin: -10px auto 15px;
    background-color: #fff;
    border-radius: 8px;
  }
}
