@import '../../../assets/styles/globals.scss';

.pairingGroup {
  background-color: #fff;
  display: flex;
  flex-wrap:wrap;
  border-top: 1px solid $light-gray-accent;
  border-bottom: 1px solid $light-gray-accent;
  max-width: 100%;
  width: 100%;

  .pairingGroupInfo {
    background-color: $off-white;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 95%;
    min-height: 33px;
    margin-left: 5%;

    .flagIcon {
      fill: $dark-gray;
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    .pairingGroupOrder {
      font-weight: 800;
    }

    .pairingGroupTime {
      font-weight: 700;

      .timeIcon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        fill: $light-navy;
      }
    }
  }

  .studentCard {
    min-width: 256px;
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 10px;
    margin-top: 10px;

    .studentData {
      margin-left: -10px;
      text-align: left;
    }
    .studentLevel {
      font-size: 25px;
    }

    .studentName {
      font-weight: 700;
    }

    p {
      color: $light-navy;
      margin-bottom: 0 !important;
    }
  }
}
