@import "../../../../assets/styles/globals";

#SimpleEducationLesson {
  width: 100%;

  p {
    margin: 0px;
  }

  .lesson-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    margin: 0px;

    &:hover {
      background-color: lighten($slate-gray, 5%);
    }
  }

  .lesson-complete-button {
    display: flex;
    justify-content: flex-end;

    &:hover {
      color: lighten($slate-gray, 20%);
    }
  }

  .selected-lesson {
    background-color: lighten($slate-gray, 10%) !important;
  }

  .roundedCheckboxComplete:checked,
  .roundedCheckboxComplete:checked:hover {
    &[readonly] {
      background-color: $dark-teal !important;
    }
  }

  .roundedCheckbox,
  .roundedCheckbox:hover {
    &[readonly] {
      background-color: $dark-gray !important;
      border-color: $dark-gray !important;
    }
  }

  .checkBoxCheckMark {
    left: 4px;
    top: -3.5px;
  }

  .noCheckMark {
    display: none;
  }
}