@import "../../../../assets/styles/globals.scss";

:root {
  --onboardingStep-title-size: #{$heading-1-size};
  --onboardingStep-title-desc-size: #{$heading-4-size};
}

.QuestionStep.OnboardingStep {
  display: flex;
  flex-flow: column;
  align-items: center;

  img {
    margin-bottom: 10px;
    width: 72px;
    height: auto;
  }

  h2 {
    color: $primary-orange;
    font-size: var(--onboardingStep-title-size);
    font-weight: $bold-font-weight;
  }

  h3 {
    color: $light-navy;
    font-size: var(--onboardingStep-title-desc-size);
    margin-bottom: $secondary-padding;
  }

  .question-step-header-bar {
    height: 10px;
    border-radius: 999px;
    background-color: $light-navy;
    margin-bottom: $secondary-padding;
  }

  .question-step-header-bar-fill {
    height: 10px;
    border-radius: 999px;
    background-color: $primary-orange;
    transition: width .2s ease-in-out; // So the bar slides
  }

  .onboarding-question-container {
    width: 100%;
  }

  .question-step-dropdown {
    text-align: left;
    display: flex;
    flex-flow: column;
    width: 100%;
  }

  .question-step-dropdown-other-input {
    border: 0px !important;
    border-bottom: 2px solid $light-navy !important;

    font-size: $heading-3-size;
    font-weight: 400;
    color: $dark-navy !important;
    opacity: 1;
    text-align: center;

    margin: $secondary-padding;
    padding: 0px !important;

    max-width: 100%; // To avoid overflow on mobile devices

    &::placeholder {
      color: $light-navy !important;
      opacity: .5;
    }
  }

  // Color of 'placeholder'/default value
  .question-step-dropdown.dropdown-unselected {
    // $lignt-navy with 50% opacity
    // (can't use opacity prop because we only want the text to be light)
    color: rgba(95, 111, 124, .5) !important;
  }

  .question-step-button {
    margin: $secondary-padding 0px;
  }

  .multi-select-desc {
    color: $light-navy;
    font-size: 14px;
  }

  .autocomplete-dropdown-container {
    border-radius: 0px 0px $card-corner-radius $card-corner-radius;
    margin-top: -#{$secondary-padding};
    width: 100%;
    overflow-x: hidden;
    border: 1px solid $light-navy;
    border-top: none;
    font-size: $heading-3-size;

    .suggestion-item, .suggestion-item--active {
      border-radius: 0px;
      padding: 10px 10px;
      cursor: pointer;
    }

    .suggestion-item {
      background-color: white;
    }

    .suggestion-item--active {
      background-color: #afb7be; // $light-navy at 50%
    }
  }

  .autocomplete-dropdown-container:empty {
    border: none;
  }
}

@media (max-width: 425px) { // phone (large)
  :root {
    --onboardingStep-title-size: #{$heading-2-size};
  }
}

@media (max-width: 320px) { // phone (small)
  :root {
    --onboardingStep-title-size: #{$heading-3-size};
    --onboardingStep-title-desc-size: 14px;
  }
}
