.pageSlider-leave {
  transform: translate3d(0, 0, 0);
}

.pageSlider-leave.pageSlider-leave-active {
  transform: translate3d(100%, 0, 0);
  transition: all 600ms;
}

.pageSlider-enter {
  transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active {
  transform: translate3d(0, 0, 0);
  transition: all 600ms;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}


// crossfade classes are for ProShopDisplay CSSTransition
// Not an actual crossfade yet, but we'll work on it (maybe use TransitionGroup)
.crossfade-enter {
  opacity: 0;
}
.crossfade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease;
}
.crossfade-exit {
  opacity: 1;
}
.crossfade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease;
}

// Registration transition classes for CoachRegistrationFlow
.regStep-enter {
  opacity: 0;
}
.regStep-enter-active {
  opacity: 1;
  transition: opacity 200ms ease;
}
.regStep-exit {
  opacity: 1;
}
.regStep-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;
}

.regSubStep-enter {
  opacity: 0;
  transform: translateX(-500%);
}
.regSubStep-enter-active {
  opacity: 1;
  transition: all 1000ms ease;
  transform: translateX(0%);
}
.regSubStep-exit {
  opacity: 1;
  transform: translateX(500%);
}
.regSubStep-exit-active {
  opacity: 0;
  transition: all 1000ms ease;
  transform: translateX(0%);
}
