@import "../../../assets/styles/globals";

.placeholder {
  max-width: 400px;
  margin: 100px auto;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 64px;
    height: 64px;
    fill: $light-navy;
  }

  .title {
    text-align: center;
    margin-top: 1rem;
    font-weight: 800;
    font-size: 20px;
    color: $dark-navy;
  }

  .description {
    white-space: pre-wrap;
    text-align: center;
    color: $light-navy;
  }
}
