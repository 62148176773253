@import "../../globals";

.commentTextSubmit {
  font-size: 22px;
  color: $white-font-color !important;
}
#commentForm {
  #commentFormBox {
    z-index: 9999;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    border-top: 2px solid $comments-form-border;
    background-color: $input-background-color;
    min-height: 60px;
    .fa {
      // width: 100%;
      text-align: center;
      font-size: 24px;
      padding-top: 7px;
    }
    .commentTextInput {
      border: none;
      border-left-width: 0px;
    }
    .commentTextInput:focus {
      border-left-width: 0px;
    }
    .commentTextSubmit {
      font-size: 22px;
      color: $white-font-color !important;
    }
  }

  #commentFormWeb {
    width: 100%;
    background-color: $input-background-color;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .input-wrapper {
      margin: 0 auto;
    }
    .fa {
      // width: 100%;
      display: none;
      text-align: center;
      font-size: 24px;
      padding-top: 7px;
    }
    .commentTextInput {
      border: none;
      border-left-width: 0px;
      width: 100%;
    }
    .commentTextInput:focus {
      border-left-width: 0px;
    }
    .commentTextSubmit {
      font-size: 18px;
      margin-top: 6px;
      padding: 8px 30px;
      color: white;
    }
  }
}
