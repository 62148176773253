#CurriculumReportPrint {
  .round-image {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .golfer-title {
    color: #fff;
  }
  .data-area {
    border: 1px solid black;
    padding-bottom: 5px;
  }
  .data-title {
    border-bottom: 1px solid black;
    font-size: 0.7rem;
  }

  .player-card {
    break-inside: avoid;
  }

  table {
    width: 100%;
    th {
      font-size: 0.8rem;
      color: #fff !important;
      background: #2b3843;
    }
    tr {
      width: 100%;
    }
    th,
    td {
      width: 6.6%;
      border: 1px solid black;
      h5 {
        margin-bottom: 0;
      }
    }
    .player-data {
      width: 13%;
      color: #fff;
      padding: 3px;
      .name-container {
        width: 100%;
        text-align: center;
        max-width: 180px;
        margin-right: auto;
        margin-left: auto;
        h5 {
          overflow: hidden;
        }
      }
    }
    td {
      p {
        margin: 0 5px;
      }
    }
  }

  @media print {
    .no-print {
      display: none !important;
    }
  }
}
