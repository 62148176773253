@import "../../assets/styles/globals";

#ConfirmationModal2 {
  #modalXIcon {
    cursor: pointer;
  }

  .confirmation-modal-container {
    margin-top: 0px;

    i {
      color: $placeholder-gray;
      font-size: 60px;
    }

    .confirmation-modal-title {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 700;
    }

    .confirmation-modal-message {
      word-break: break-word;
    }

    .confirmation-modal-final-message {
      font-weight: 700;
      word-break: break-word;
    }
  }
}
