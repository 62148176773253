@import "../../assets/styles/globals";

#AdminEducationLesson {
  margin: 10px 0px;
  padding: 10px;
  border-radius: 8px;
  background-color: lighten($light-gray, 10%);

  input,
  textarea {
    background-color: white !important;
  }
}
