@import "../../../assets/styles/globals";

#CloseButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px 15px 15px;

  button {
    background-color: transparent;
    border: none;

    i {
      color: $primary-color;
    }
  }
}
