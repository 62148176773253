@import "../../assets/styles/globals";

.messageCard {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 500px;
  height: 80px;
  border: 1px solid $border-darker-gray;
  background-color: #fff;
  border-radius: 8px;

  i {
    font-size: 39px;
    color: $primary-orange;
  }

  .continueEditingButton {
    width: 180px;
    margin-left: 30px;
  }
}