@import "../../../../assets/styles/globals";

.config-item-image-array {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $secondary-padding 0 0;
}

.config-item-error-msg {
  // margin-left: 189px;
}

.config-item-selected-image-container {
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  background-color: $off-white;
  border-radius: $card-corner-radius;
  padding: 10px;
  justify-content: space-between;
  // max-width: 439px; // 133px images with 10px spacing
  // flex: 0 0 439px;
  margin-right: 10px;

  .config-item-selected-image-wrapper {
    background-color: $light-navy;
    border-radius: $card-corner-radius;
    // transition: all 0.2s ease-in-out;
    box-shadow: $non-pressable-elem-drop-shadow;
    overflow: hidden;
    margin: 0 5px;
    .config-item-selected-image-thumbnail {
      // These dimensions are from reducing 1596px x 954px
      width: 133px;
      height: 79.5px;
      object-fit: contain;
    }

    &:hover {
      transform: scale(1.05);
      // transition: all 0.2s ease-in-out;
      cursor: grab;
      box-shadow: $pressable-elem-drop-shadow;
    }

    &:active {
      cursor: grabbing;
    }
  }
}
