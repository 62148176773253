@import "../../../../assets/styles/globals";

#SummaryDropDown {
  .summary-dropdown-header {
    height: 35px;

    @media (min-width: 768px) {
      height: 50px;
      background-color: $primary-navy;
    }

    .summary-dropdown-icon {
      height: 0.8em;
    }
  }

  .summary-dropdown-section {
    background-color: $dark-navy;

    @media (min-width: 768px) {
      background-color: $primary-navy;
    }
  }
}
