#LessonPlanActionModal {
  .grey-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(25, 36, 45, 0.8);
    z-index: 900;
  }
  .lesson-plan-action-modal-content {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    color: #36434e;
    width: 600px;
    padding: 10px 20px 35px 20px;
    border-radius: 8px;
    z-index: 901;
    .fa-times {
      font-size: 1.4rem;
      &:hover {
        cursor: pointer;
      }
    }
    .lesson-plan-action-modal-title {
      text-align: center;
      font-size: 24px;
      font-weight: 550;
      margin-bottom: 5px;
    }
    .lesson-plan-action-modal-subtitle {
      font-size: 18px;
    }
    .lesson-plan-action-modal-links-container {
      display: flex;
      justify-content: space-between;
      .lesson-plan-action-modal-link-button {
        height: 130px;
        flex-basis: 150px;
        flex-grow: 0;
        flex-shrink: 0;
        background: #36434e;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // margin: 0 8px;
        padding: 10px;
        .fa {
          margin-bottom: 5px;
          font-size: 4rem;
        }
        .lesson-plan-action-modal-link-icon {
          flex: 0 0 64px;
          width: 55px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: 5px;
        }
        &:hover {
          cursor: pointer;
        }
        .lesson-plan-action-modal-link-label {
          margin: 0;
          line-height: 1;
          text-align: center;
        }
      }
    }
  }
}
