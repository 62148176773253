@import '../../../assets//styles/globals.scss';

.rosterLabels {
  display: flex;
  width: 95%;
  margin-left: 15px;

  .labelWrapper {
    width: 158px;
    height: 59px;
    display: flex;
    align-items: center;
    background-color: #fff;
    font-size: 14px;
    font-weight: 800;
    border-radius: 8px;
    margin: 20px 10px;
    padding: 5px 10px 5px 0;

    .hl {
      height: 39px;
      width: 2px;
      margin-right: 10px;
    }

    .textWrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .p {
        margin-bottom: 0 !important;
      }

      .eventToday {
        color: $primary-orange;
      }
    }
  }

  .golfersLabel {
    color: $dark-navy;
    font-size: 20px;

    .iconWrapper {
      border: 2px solid $dark-navy;

      i {
        color: $dark-navy;
      }
    }

    .hl {
      background-color: $dark-navy;
    }
  }

  .attendingLabel {
    color: $dark-teal;

    .iconWrapper {
      border: 2px solid $dark-teal;

      i {
        color: $dark-teal;
      }
    }

    .hl {
      background-color: $dark-teal;
    }
  }

  .notAttendingLabel {
    color: $primary-orange;

    .iconWrapper {
      border: 2px solid $primary-orange;

      i {
        color: $primary-orange;
      }
    }

    .hl {
      background-color: $primary-orange;
    }
  }

  .noResponseLabel {
    color: $light-navy;

    .iconWrapper {
      border: 2px solid $light-navy;

      i {
        color: $light-navy;
      }
    }

    .hl {
      background-color: $light-navy;
    }
  }

  .rsvpLabel {
    color: $dark-navy;
    flex-grow: 1;

    .iconWrapper {
      background-color: $primary-navy;
    }

    .hl {
      background-color: $dark-navy;
    }
  }

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    border-radius: 500px;

    i {
      font-size: 13px;
      color: #fff;
    }
  }
}