@import '../../../../assets/styles/globals.scss';

.widgetContainer {
  display: flex;
  flex-direction: column;
  width: max(400px, 32%);
  background-color: $background-navy;
  color: white;

  .upperPart {
    display: flex;
    flex-direction: column;
    padding: 30px 25px;
    flex: 1;
  }

  .stepsWrapper {
    position: relative;
    flex-grow: 1;
    overflow: auto;
    padding: 24px 0;
    mask-image: linear-gradient(to bottom, transparent 0%, black 32px, black calc(100% - 32px), transparent 100%);
  }


  .stepsContainer {
    position: absolute;
    width: 100%;
  }

  .title {
    margin-bottom: 21px;
  }

  .secondaryText {
    color: $light-gray-text;
    font-size: 14px;
  }

  .dismissText {
    text-align: left;
    background-color: inherit;
    border: none;
    padding: 0;
    font-size: 14px;
    text-decoration: underline;
  }

  .captionText {
    font-size: 12px;
  }

  .stepHeader {
    border: none;
    text-align: left;
  }

  .stepHeader:active {
    background: initial;
  }

  .onboardingStep {
    margin-bottom: 20px;
    background-color: $slate-gray;
    border: 1px solid $border-slate !important;
    box-shadow: none;
    interpolate-size: allow-keywords
  }

  .stepCollapsed {
    height: 0;
    overflow: hidden;
  }

  .stepExpanded {
    height: auto;
  }

  .stepTransition {
    transition: height 0.2s ease-in;
  }

  .onboardingItem:not(:last-child) {
    margin-bottom: 30px;
  }

  .onboardingItemTitle {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    background: inherit;
    border: none;
  }

  .onboardingItemTitle:hover {
    text-decoration: underline;
  }

  .buttonStep {
    background: inherit;
    border: none;
    font-size: 16px;
    gap: 15px;
  }

  .progressBar {
    background-color: $dark-teal;
  }

  .progressWrapper {
    margin-bottom: 32px;
  }

  .progressContainer {
    width: 70%;
    background-color: $dark-gray;
    border-radius: 11px;
  }

  .totalSteps {
    font-size: 12px;
    white-space: nowrap;
  }

  .stepsComplete {
    font-size: 16px;
  }

  .stepArrowCollapsed {
    transform: translate(-4px) rotate(90deg);
    color: $icon-gray;
  }

  .roundedCheckboxComplete:checked,
  .roundedCheckboxComplete:checked:hover {
    &[readonly] {
      background-color: $dark-teal !important;
    }
  }

  .unlockFeedButton {
    background-color: $dark-teal;
    border-radius: 20px;
    border: none;
    padding: 8px;
    width: 100%;
    font-weight: 700;
    margin-top: 30px;
  }

  .unlockFeedButton:hover {
    background-color: $darker-teal;
  }

  .lowerPart {
    padding: 22px 30px;
    background-color: $primary-navy;
    gap: 16px;
  }

  .lockIcon {
    width: 44px !important;
    height: 56px !important;

    path {
      fill: #B4B9BC !important;
    }
  }
}