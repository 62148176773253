@import "../../../assets/styles/globals";

.header {
  color: white;
  padding-top: 2rem;

  .backButton {
    background: none;
    padding: 0;
    border: none;
  }

  .title {
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 800;
  }

  .dates {
    font-weight: 700;
  }

  .meetingTimes {
    font-weight: 700;
    font-size: 0.9rem;
  }

  .golfersCount {
    color: white;
  }
}
