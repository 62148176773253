@import '../../../../../assets/styles/globals.scss';

.enrolledFamilyView {
  width: 100%;

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .enrollmentsList {
    .enrollmentsWrapper {
      background-color: white;
      border-radius: $card-corner-radius;
      padding: 16px;

      .completedSwitchWrapper {
        .completedSwitchButton {
          width: 100%;
          max-width: 250px;
          height: 48px;
          border-radius: 100px;
          border: 2px solid $primary-orange;
          color: $primary-orange;
          font-size: 0.9rem;
          padding: 0;
          background-color: white;
          margin-bottom: 8px;

          @media (min-width: 254px) {
            height: 32px
          }

          .switchOption {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            width: 50%;
            height: 100%;
            padding: 0 4px;
            border-radius: 30px;
            user-select: none;

            &.switchSelected {
              color: white;
              background-color: $primary-orange;
            }
          }
        }
      }

      .golferIcon {
        width: 50px;
        height: auto;
        fill: $light-navy;
        flex-shrink: 0;

        path {
          fill: $light-navy;
        }
      }

      .enrollmentsTable {
        tr {
          border-bottom: 1px solid $placeholder-gray !important;

          &:first-child {
            border-top: 1px solid $placeholder-gray !important;
          }
        }

        .golferInfo {
          min-width: 140px;

          .avatarWrapper {
            cursor: pointer;
            position: relative;

            .divisionWrapper {
              position: absolute;
              top: 1px;
              right: -29px;
            }

            .golferAvatar {
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }
          }

          .golferName {
            color: $dark-navy;
            font-weight: 700;
            margin-bottom: 0;
          }

          .golferIdentification {
            color: $light-navy;
            font-size: 14px;
            margin-bottom: 0;
          }
        }

        .programInfo {
          min-width: 200px;

          .programName {
            font-weight: 700;
          }

          .communityWrapper {
            .communityPhoto {
              border: 1px solid $dark-navy;
              width: 25px !important;
              height: 25px !important;
              margin-right: 6px;
            }
          }

          .communityName {
            color: $light-navy;
            font-size: 14px;
          }
        }

        .packageInfo {
          min-width: 110px;

          .packageAmount {
            font-weight: 700;
          }

          .paymentMethod {
            font-size: 14px;
            font-weight: 700;

            .paymentText {
              white-space: nowrap;
            }

            .offlinePayment {
              color: $primary-orange;

              .moneyCheckDollarIcon {
                flex-shrink: 0;
                height: 16px;
                width: auto;
              }
            }

            .onlinePayment {
              color: $primary-purple !important;

              .stripeIcon {
                height: 18px;
                width: 18px;
                flex-shrink: 0;

                circle {
                  fill: $primary-purple;
                }
              }
            }
          }
        }
      }
    }
  }
}
