@import "../../../../assets/styles/globals";

.dark-navy-border {
  @include bordered($dark-navy, 2px);
}

.newFamilyMemberModal {
  max-height: 880px !important;

  label {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 800;

    &.accountType {
      font-size: 0.9rem;
      font-weight: 700;
    }

    &.uploadedAvatar {
      font-weight: 700;
      cursor: pointer;
    }
  }

  .telInput {
    :global(.flag-dropdown) {
      background-color: transparent !important;
      border: none !important;
    }

    :global(.selected-flag) {
      margin-top: 4px;
    }
  }

  p.accountType {
    height: 2.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 0.9rem;
    color: $placeholder-gray;
  }

  .userAvatarTitle {
    height: 70px;
    aspect-ratio: 1;

    @media (min-width: 576px) {
      height: 60px;
    }
  }

  .newUserModalTitle {
    font-weight: bold;
    margin: 0;
  }

  .switchButton {
    width: 100%;
    height: fit-content;
    border-radius: 100px;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    font-size: 0.9rem;
    padding: 0;
    background-color: transparent;

    .switchOption {
      width: 50%;
      border: none;
      padding: 0.5em 0;
      border-radius: 30px;
      user-select: none;

      &.switchSelected {
        color: white;
        background-color: $primary-orange;
      }
    }
  }

  .requiredFieldsInfo {
    font-size: 0.9rem;
    color: $input-error-color;
  }

  .proxyConsent {
    cursor: pointer;
  }
}
