@import '../../globals';
$clouds: url('../../../images/common/empty/clouds.png');
$hill: url('../../../images/common/empty/hill.png');
$hill-web: url('../../../images/common/empty/hill-web.png');

#profileActivities{
    overflow-x: hidden !important;
    width: 100%;
}    

#activityFeedPage{	
  overflow-x: hidden;
  background-color: $activity-feed-backround;


  width: 100%;
  overflow-y: scroll;
  

  #activityWrapper {
    // height: 100%;
  }
  
  .refresh-view {
    height: 100%;
  }
  
  // USED FOR RATCHET HEADERS
  .container-fluid{
    padding-left: 0px;
    padding-right: 0px;
  }
}



.sky{
    background-color: #e1ebf7;
    background-image: $clouds;  
    background-repeat: repeat-x;
    animation-name: stars;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse; 
    &-web{
      // width: 90vw;
      margin-left: 0;
    }
  .empty-putt{
    background-image: $hill;
    background-size: 100% auto; 
    background-repeat: no-repeat;
    background-position: bottom center;
    min-height: 67vh;
    &-web{
      background-image: $hill-web;
      background-size: 100% auto; 
      background-repeat: no-repeat;
      background-position: bottom center;
      min-height: 100vh;
    }
  }


}


/* Animations */
@-webkit-keyframes stars {
  0%  {background-position: 0% 50%;
      
    }
  100% {background-position: 100% 50%;   ;
      
    }
}


/* Animations */
@keyframes stars {
  0%  {background-position: 0% 50%;
      
    }
  100% {background-position: 100% 50%;   ;
      
    }
}
