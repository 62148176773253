@import "../../../../assets/styles/globals";

#FamilyMemberSelection {
  .dark-navy-border {
    @include bordered($dark-navy, 2px);
  }

  .family-member-card {
    margin-bottom: 1em;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .family-member-name {
      font-weight: 700;
      margin-left: 0.5rem;
      margin-right: 0.75em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .family-member-enrollment-status {
      font-size: 0.85em;
      color: white;
      padding: 0.25em 0.5em;
      max-width: fit-content;
      border-radius: 8px;
      white-space: nowrap;

      &.request-sent {
        background: $light-navy;
      }

      &.enrolled {
        background: $primary-orange;
      }
    }
  }
}

#familyMembersSelectionFooter {
  .footer-text {
    font-size: 0.9em;
  }
}
