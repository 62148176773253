@import "../../../assets/styles/globals";

.popper {
  box-shadow: none !important;
  margin-top: 5px !important;
  width: 175px;
}

.action {
  color: $light-navy;
  background: transparent !important;
  border: none;
  font-size: 15px;
  padding: 0.3rem 0.6rem 0;
  text-align: left;

  &.red {
    color: $red-delete;
  }

  &:hover {
    color: $primary-orange;

    &.red {
      color: darken($red-delete, 15%);
    }
  }
}
