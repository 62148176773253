@import "../../../assets/styles/globals";

.emptyProgramsList {
  margin-top: 10%;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 10px;
  }

  .subtitle {
    color: $light-navy;
  }

  .icon {
    fill: $light-navy;
    height: 64px;
    width: 64px;
  }
}
