@import "../../../assets/styles/globals.scss";

.modal {
  margin-top: 70px !important;
  margin-left: 10rem !important;
  width: 85% !important;
  max-width: 1800px !important;
  padding: 0 !important;

  .container {
    display: flex;
    flex-direction: row;
    max-height: 90vh;

    .leftPanel {
      display: flex;
      width: 70%;

      .imageContainer {
        width: 100%;
      }
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 30%;
      padding-right: 20px;
      padding-left: 20px;

      .commentSection {
        flex-grow: 1;
        border: 2px solid $light-gray;
        overflow-y: scroll;
        padding-right: 17px;
        border-radius: 10px;
        margin: 10px;
        padding: 10px;
      }

      .commentsPlaceholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        font-weight: 800;
        align-items: center;
        height: 100%;
        font-size: 20px;
        color: $light-navy;
      }
      .postCommentWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .commentsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;

        .comments {
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background-color: transparent !important;
          border: none;

          .commentsText {
            font-size: 13px;
            color: $light-navy;
          }
        }

        .comments:hover {
          background-color: $light-gray !important;
        }

        .readBy {
          display: flex;
          align-items: center;
          gap: 5px;
          border-radius: 8px;
          background-color: transparent !important;
          border: none;

          .readByText {
            font-size: 13px;
            color: $dark-teal;
          }
        }

        .readBy:hover {
          background-color: $light-gray !important;
        }
      }

      .closeButtonContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        .closeButton {
          background-color: transparent !important;
          border: none !important;
        }
      }

      .captionContainer {
        .caption {
          font-size: 13px;
          margin-left: 20px;
          color: $light-navy;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
