@import "../../assets/styles/globals";

#LargeStudentCardContainer {
  margin-bottom: 15px;

  .card {
    transition: all 0.2s ease-in-out;
    padding: 10px;
    height: 100px;
    &:hover {
      //   background-color: $light-gray;
      cursor: pointer;
      transform: scale(0.95);
    }
  }

  .student-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $light-font-color;
    padding: 1px;

    .student-info-wrapper {
      margin-left: 10px;
      max-width: 160px;

      .student-name {
        font-size: 16px;
        color: $primary-color;
      }

      .student-username {
        font-size: 14px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.apply-min {
  min-width: 355px;
}

.student-admin {
  .student-name {
    font-size: 18px;
    color: $primary-color !important;
  }
}

.student-division-indicator {
  height: 65px;
  overflow: hidden;
}

.student-icon-wrapper {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-admin-icon-wrapper {
  position: absolute;
  right: 90px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .round-image-wrapper {
//   transform: scale(0.8);
// }

.student-admin-pill {
  // position: absolute;
  // right: 5px;
  // top: 5px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-teal;
  border-radius: 500vh;
  padding: 1px 7px;
  width: 90px;
  .student-admin-text {
    color: $white-font-color;
    margin: 0px;
    font-size: 11px;
  }
}
