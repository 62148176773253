@import "../../../assets/styles/globals";

#SessionBuilder {
  max-width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 36px;
  padding-bottom: 36px;
  .title-bar {
    border-bottom: 2px solid #5f6f7c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-left: 36px;
    margin-right: 36px;
    .session-title {
      flex: 1 1;
      font-size: 24px;
      font-weight: 550;
      color: #1d2933 !important;
      padding: 0;
      padding-left: 5px;
      border-radius: 5px !important;
      border: 1px solid #fff !important;
      &:hover {
        border: 1px solid #ddd !important;
      }
      &:focus {
        border: 1px solid #ddd !important;
      }
    }
    .note-icon {
      flex: 0 0 24px;
      margin-left: 10px;
      height: 24px;
      background-size: cover;
      background-position: center;
      margin-bottom: 16px;
      &:hover {
        cursor: pointer;
      }
    }
    .note-present {
      background-color: $primary-orange;
      border-top-right-radius: 12px;
    }
  }
  .set-duration-container {
    display: flex;
    align-items: center;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 18px;
    .set-duration-input-label {
      font-weight: 550;
      font-size: 18px;
      margin-bottom: 0;
      margin-right: 5px;
    }
    input {
      // border-radius: 5px !important;
      border: none !important;
      border-bottom: 2px solid #1d2a33 !important;
      // box-shadow: 1px 1px 1px #bbb !important;
      color: #1d2a33 !important;
      font-size: 16px;
      font-weight: normal;
      padding-left: 0;
      margin-bottom: 0;
      text-align: center;
    }
  }
  .sticky {
    box-shadow: inset 0 7px 5px -4px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 61px;
    width: calc(50% - 125px);
    background: #fff;
    z-index: 5;
  }
  .pad {
    padding-top: 63px;
  }
  .duration-info-container {
    display: flex;
    justify-content: space-between;
    margin-left: 36px;
    margin-right: 36px;
    p {
      margin: 0;
      font-size: 16px;
      // font-weight: bold;
    }
    .duration-ratio {
      color: $primary-orange;
    }
    .over-time {
      font-weight: bold;
      color: red;
    }
  }
  .duration-bar-container {
    padding-left: 36px;
    padding-right: 36px;
    .duration-bar-outline {
      // margin-left: 36px;
      // margin-right: 36px;
      height: 18px;
      border: 2px solid #36434e;
      border-radius: 500px;
      position: relative;
      z-index: 2;
    }
    .duration-bar-fill {
      // margin-left: 36px;
      // margin-right: 36px;
      height: 18px;
      max-width: 100%;
      position: relative;
      z-index: 3;
      top: -18px;
      background: $primary-orange;
      border-top-left-radius: 500px;
      border-bottom-left-radius: 500px;
      transition: width 1s;
    }
  }
  .zero-block-placeholder {
    margin-right: 36px;
    margin-left: 36px;
    background: #f4f4f5;
    border-radius: 8px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    .zero-block-placeholder-text {
      color: #acacac;
      text-align: center;
      margin: 0;
    }
  }
  .add-block-container {
    margin-left: 36px;
    margin-right: 36px;
    background: #36434e;
    height: 54px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-block-button {
      color: #fff;
      margin: 0;
      font-size: 24px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .grey-out1 {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: #37434e;
    opacity: 0.8;
    z-index: 999;
  }
  .session-notes-modal {
    position: fixed;
    z-index: 1000;
    top: 100px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 580px;
    // height: 390px;
    background: #fff;
    border-radius: 20px;
    padding: 18px 36px 36px 36px;
    .session-notes-icon-container {
      display: flex;
      justify-content: flex-end;
      font-size: 2rem;
      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
    p {
      margin: 0;
    }
    .session-notes-title {
      text-align: center;
      font-size: 36px;
    }
    .session-notes-subtitle {
      text-align: center;
      font-size: 24px;
      color: #ddd;
    }
    textarea {
      width: 100%;
      resize: none;
      height: 200px;
      border: 3px solid #5f6f7c !important;
      color: inherit !important;
      font-weight: 400;
      font-size: 16px;
      overflow-y: auto;
    }
    .session-notes-ok-button-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .session-notes-ok-button {
        flex: 0 0 80%;
        background: $primary-orange;
        border: none;
        border-radius: 500px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #fff;
        &:hover {
          position: relative;
          bottom: -2px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .sticky {
      width: calc(50% - 75px - 15px);
    }
    .pad {
      padding-top: 87px;
    }
  }
}
