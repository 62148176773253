@import "../../assets/styles/globals";

#BaseModal {
  // We need to set a value different than 0 for height and width,
  // otherwise some native functions won't work
  // (like using tab between inputs)
  height: 1px;
  width: 1px;

  .clickable-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    // Don't need color and opacity here since it's built into react-modal overlay
    // background: #37434e;
    // opacity: 0.8;
    z-index: 999;
  }

  .modal-content-container {
    position: fixed;
    top: 100px;
    max-height: calc(100vh - 115px) !important;
    overflow-y: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    color: #36434e;
    max-width: 80vw;
    padding: $primary-padding;
    padding-top: 18px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.75);
    border-radius: $card-corner-radius;
    z-index: 1000;
    .top-close-bar {
      display: flex;
      justify-content: flex-end;
      i {
        font-size: $heading-1-size;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .backable-close-bar {
      display: flex;
      justify-content: space-between;
      i {
        font-size: $heading-1-size;
        &:hover {
          cursor: pointer;
        }
      }
    }
    // &::-webkit-scrollbar {
    //   width: 8px !important;
    // }
    // &::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.5);
    //   background-color: #fff;
    //   border-radius: 10px;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 10px;
    //   background-color: rgba(255, 255, 255, 0.5);
    //   -webkit-box-shadow: inset 0 0 6px rgba(104, 104, 104, 0.7);
    // }
  }

  ////////////////////////////////////////////////////////////
  // Classes for "Choose type" modal
  ////////////////////////////////////////////////////////////
  .new-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: $heading-1-size;
      font-weight: $bold-font-weight;
      margin-bottom: $primary-padding;
    }

    .picker-container {
      display: flex;
      width: 100%;
      background: $off-white;
      border-radius: $card-corner-radius;
      // padding: $secondary-padding;
      justify-content: space-between;

      .picker-card {
        // flex: 0 0 calc(50% - 5px);
        background: #fff;
        display: flex;
        flex-flow: column;
        box-shadow: $pressable-elem-drop-shadow;
        padding: $secondary-padding;
        margin: $secondary-padding;
        border-radius: $card-corner-radius;
        align-items: center;

        .picker-card-icon {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-bottom: $secondary-padding;

          img {
            filter: brightness(0) invert(1);
            width: 64px;
            height: 64px;
          }
        }

        h2 {
          font-size: $heading-2-size;
          font-weight: $bold-font-weight;
          margin-bottom: $secondary-padding;
        }

        ul {
          padding-left: $secondary-padding;
        }
      }

      #picker-card-activity {
        margin-right: 9px;

        .picker-card-icon {
          background-color: $primary-orange;
        }
      }

      #picker-card-drill {
        margin-left: 9px;

        .picker-card-icon {
          background-color: $primary-blue;
        }
      }
    }

    .subtitle-bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: $field-margin;

      p {
        font-size: $heading-3-size;
        font-weight: $bold-font-weight;
        margin: 0;
      }
      span {
        color: $primary-orange;
      }
      .small-grey-text {
        font-size: 0.9rem;
        font-weight: 400;
        color: $light-navy;
      }
    }
    .centered-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $secondary-padding;
      i {
        font-size: 50px;
      }
    }
    .even-container {
      width: 100%;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      // margin-top: 9px;
      margin-bottom: $primary-padding;
      .icon-container {
        width: 12%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .skill-icon {
          width: 5vw;
          flex: 0 0 5vw;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-color: $light-navy;
          box-shadow: $pressable-elem-drop-shadow;
          transition: all 0.2s ease-in-out;
          margin-bottom: 10px;

          &:hover {
            cursor: pointer;
            transform: scale(0.95);
            transition: all 0.2s ease-in-out;
          }
        }

        .skill-icon-selected {
          transform: scale(0.95);
          box-shadow: $non-pressable-elem-drop-shadow;
        }

        p {
          // font-size: 0.8rem;
          text-align: center;
          margin: 0;
        }
      }
    }
    .full-length-btn {
      width: 100%;
      background: $primary-orange;
      border: none;
      border-radius: 500px;
      box-shadow: $pressable-elem-drop-shadow;
      color: #fff;
      opacity: 1;
      transition: opacity 0.3s;
      &:hover {
        position: relative;
        bottom: -2px;
        box-shadow: $non-pressable-elem-drop-shadow;
      }
      &:disabled {
        opacity: 0.5;
        box-shadow: none;
        &:hover {
          position: static;
          cursor: not-allowed;
        }
      }
    }
  }
}
