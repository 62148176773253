@import '../../../../../assets/styles/globals.scss';

.schedule {
  margin-top: 30px;
  max-width: 420px;

  .scheduleTitle {
    display: flex;
    justify-content: space-between;

    .scheduleTitleText {
      font-weight: 600;
    }
  }

  .infoTooltip {
    background-color: white !important;
    opacity: 1 !important;
    border-color: $icon-gray !important;
    border-radius: 10px !important;
    color: $light-navy;
    width: 350px;
    font-size: 16px;

    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }

  .noActivePrograms {
    background-color: white;
    border-radius: 15px;
    border-left: 4px solid $primary-orange;
    display: flex;
    align-items: center;
    padding-right: 30px;

    .content {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 50px;
    }

    .title {
      font-weight: 700;
      font-size: 16px;
    }

    .text {
      color: $light-navy;
      font-size: 14px;
    }

    .arrow {
      fill: $placeholder-gray;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
}
