@import "../../../../assets/styles/globals";

.live-program-builder-modal-content {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

#LiveModal {
  #modalXIcon {
    cursor: pointer;
  }

  #mainIcon {
    margin-top: 16px;
  }

  #openEyeIcon {
    path {
      fill: #36434E;
    }
  }

  #closedEyeIcon {
    path {
      fill: #36434E;
    }
  }

  .title {
    color: $primary-orange;
    padding: 0px 32px;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    color: $primary-orange;
    font-size: 28px;
    font-weight: 700;
  }

  .message {
    word-break: break-word;
    margin-bottom: 6px;
  }

  .link-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $primary-orange;

    .program-page {
      color: $dark-navy;
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 700;
    }

    .link-program-page {
      text-decoration: underline;
    }
  }

  .copy-link-button {
    white-space: nowrap;
    padding-left: 22px;
    padding-right: 22px;
  }
}
