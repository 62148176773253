@import "../../../assets/styles/globals";

#PaymentsLockedPage {
  width: 40%;
  height: calc(100vh - 70px);
  margin-top: -30px !important;
  padding: 0 15px;
  margin: auto;
  text-align: center;

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  #packageIcon {
    width: 72px;
    height: 72px;
    fill: $placeholder-gray;
  }

  .stripe-required-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 18px;
  }

  .stripe-required-instructions {
    color: $light-navy;
  }

  .communitySettingsLink {
    width: 65% !important;
  }
}
