@import "../../../assets/styles/globals";

#CommunityDetailCard {
  background-color: lighten($light-gray, 10%);
  border: 1px solid $light-font-color;
  border-radius: 8px;
  padding: 0px 15px 15px 15px;

  label {
    margin-top: 15px;
  }

  select {
    background-color: $white-font-color !important;
  }

  input {
    background-color: $white-font-color !important;
  }

  .btn-link {
    font-size: 12px;
  }

  .create-stage-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .flag-overview-wrapper {
    .flag-text-wrapper {
      .resolved-flags {
        color: $primary-teal;
      }
      .unresolved-flags {
        color: $light-red;
      }
    }
  }

  .community-save-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
  .progress-bar {
    background-color: $primary-teal;
  }
  .season-date-wrapper {
    p {
      color: $primary-color !important;
      font-size: 12px;
    }

    .date-picker {
      background-color: $white-font-color !important;
    }
  }
}

.react-datepicker__tether-element {
  left: -50px !important;
}
