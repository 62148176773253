@import '../../../assets/styles/globals.scss';

.placeholderWrapper {
  width: 100%;
  height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;

  .placeholderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin: auto;
  }

  .title {
    font-weight: 800;
  }

  .description {
    text-align: center;
    color: $light-navy;
  }

  .logScoresButton {
    width: 150px;
  }
}