@import "../../../assets/styles/globals";

#BlockBuilder {
  max-width: 100%;
  &:hover {
    background: rgba(95, 111, 124, 0.25);
  }
  display: flex;
  margin-bottom: 18px;
  .block-move-container {
    flex: 0 0 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5f6f7c;
    .block-move-box {
      display: flex;
      flex-direction: column;
      i {
        font-size: 24px;
        &:hover {
          cursor: pointer;
          color: $primary-orange;
        }
      }
    }
  }
  .delete-block-container {
    flex: 0 0 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5f6f7c;
    i {
      font-size: 24px;
      &:hover {
        cursor: pointer;
        color: $primary-orange;
      }
    }
  }
  .block-builder-content {
    flex: 1 1;
    max-width: calc(100% - 72px);
    background: #36434e;
    color: #fff;
    border-radius: 8px;
    padding: 18px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    .block-builder-title-bar {
      border-bottom: 2px solid #bdbdbd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-bottom: 18px;
      .block-title {
        font-size: 24px;
        font-weight: 400;
        color: #fff !important;
        padding: 0;
        padding-left: 5px;
        border-radius: 5px !important;
        border: 1px solid #36434e !important;
        &:hover {
          border: 1px solid #ddd !important;
        }
        &:focus {
          border: 1px solid #ddd !important;
        }
      }
      .fa {
        font-size: 1.5rem;
        transform: rotate(0deg);
        transition: transform 0.3s;
        &:hover {
          cursor: pointer;
        }
      }
      .rotated {
        transform: rotate(180deg);
      }
    }
    .closed-block-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      .duration-pill,
      .subtext {
        margin: 0;
        line-height: 1;
      }
      .duration-pill {
        margin: 5px 0;
        background: $primary-orange;
        padding: 2px 8px 3px 8px;
        border-radius: 500px;
      }
      .subtext {
        font-size: 14px;
        color: #ccc;
      }
    }
    .block-duration-box {
      display: flex;
      align-items: center;
      margin-top: 18px;
      p {
        margin-right: 5px;
        margin-bottom: 0;
      }
      .block-duration-input {
        border: none !important;
        border-bottom: 2px solid #fff !important;
        // box-shadow: 1px 1px 1px #bbb !important;
        color: #fff !important;
        font-size: 16px;
        font-weight: normal;
        padding-left: 0;
        margin-bottom: 0;
        text-align: center;
      }
    }
    .block-description-container {
      display: flex;
      textarea {
        width: 100%;
        height: 76px;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        font-size: 15px;
        font-weight: 400;
        color: #37434e !important;
        resize: none;
        overflow-y: auto;
      }
      .add-block-description-button {
        color: #bdbdbd;
        margin-top: 10px;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .active-tasks-container {
      max-width: 100%;
    }
    .task-drop-box {
      color: #bdbdbd;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      border-radius: 5px;
      border: 2px dashed #bdbdbd;
      padding: 5px;
      p {
        margin: 0;
        text-align: center;
      }
    }
    .drop-hover {
      border-color: $primary-orange;
      background: #0e7cac;
    }
  }
}
