@import '../../../../assets/styles/globals';

.details {
  flex-basis: fit-content;
}

.icon {
  width: 12px;
  fill: $icon-gray;
}

.package {
  font-size: 0.9rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
