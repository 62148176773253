@import "../../../assets/styles/globals";

.socialSettingsModalContent {
  height: min-content !important;
  max-width: 450px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  .shieldCheckMark {
    width: 42px;
    height: auto;
    flex-shrink: 0;

    & > path {
      fill: $primary-navy;
    }
  }

  .title {
    color: $dark-navy;
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
  }

  .message {
    color: $dark-gray;
    font-size: 14px;
    text-align: center;
    word-break: break-word;
    margin-bottom: 6px;
    white-space: pre-wrap;
  }
}
