@import '../../../assets/styles/globals';

.studentCard {
  width: 258px;
  height: 82px;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;

  .studentData {
    width: 258px;
    height: 82px;
  }

  .studentLevel {
    font-size: 25px;
  }

  .studentTitle {
    color: $dark-navy;
    font-size: 14px;
    font-weight: 700;
    margin-left: -15px;
  }

  .studentInfo {
    color: $light-navy;
    margin-left: -15px;
    margin-top: -4px;
    font-size: 12px;
    font-weight: 700;
  }

  .studentStatus {
    margin-left: -15px;
    font-size: 14px;
  }

  p {
    margin-bottom: 0 !important;
  }

  .removeStudentButton {
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    position: relative;
    margin-right: 0px;
    fill: #fff;
    display: none;
  }

  .dragIcon {
    margin-right: 8px;
    display: none;
  }

  &:hover {
    background-color: $light-gray-accent;
  }

  @media (max-width: 1570px) {
    padding: 5px;
    width: 208px;
    height: 66px;

    .studentData {
      width: 208px;
      height: 66px;
    }

    .studentLevel {
      font-size: 20px;
    }

    .studentTitle, .studentStatus {
      font-size: 12px;
    }

    .studentInfo {
      font-size: 10px;
    }
  }
}

.dragged {
  &:hover {
    .removeStudentButton {
      display: block;
      cursor: default;
    }

    .dragIcon {
      display: block;
    }
  }
}

.grab {
  cursor: grab;

  .dragIcon {
    display: block;
  }
}

.grabbing {
  // .dragIcon {
  //   display: block;
  // }
  opacity: 0.5;
}
