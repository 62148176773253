@import "../../../assets/styles/globals";

#CommunityRepDetail {
  position: relative;
  h2 {
    color: $primary-color;
    font-size: 30px;
    font-weight: 550;
    margin: 0px;
  }
  .title-bar {
    p {
      margin: 0 6px;
    }
    .title-line {
      height: 2px;
      background: #fff;
      flex: 1 1;
    }
  }

  .login-button {
    margin-top: 6px;
    min-width: 135px;
  }

  .community-photo {
    height: 55px;
    flex: 0 0 55px;
    margin-right: 6px;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .metric-box {
    margin: 9px 0 0 0;
    flex: 0 0 49%;
    p {
      margin: 0;
      font-size: 14px;
      text-align: center;
    }
    .metric-box-number {
      font-size: 36px;
      font-weight: bold;
    }
  }

  .status-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 9px;
    border-radius: 500px;
    p {
      margin: 0;
      font-size: 14px;
      // font-weight: bold;
    }
  }
  .last-login-container {
    font-size: 14px;
    margin: 0;
    .last-login-number {
      font-size: 24px;
      margin-right: 6px;
      font-weight: bold;
    }
  }

  .community-avatar-wrapper {
    width: 200px;
    height: 200px;
    background-color: $light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    margin: auto;

    img {
      object-fit: contain;
      width: 100%;
      height: inherit;
    }
  }
  .community-details-text {
    color: $primary-color;
    margin-bottom: 15px;
    // margin-top: 25px;
    font-weight: 550;
  }

  .community-data-container {
    p {
      color: $light-font-color;
    }

    .community-address {
      color: $primary-color;
      font-size: 20px;
      margin: 0px;
    }

    .admin-action-button {
      width: min-content !important;
      white-space: nowrap;
      padding-left: 18px;
      padding-right: 18px;
    }

    .community-representative {
      margin: 0px;
      color: $primary-color;
      font-size: 18px;

      span {
        color: $secondary-color;
        font-weight: 550;
      }
    }

    .community-details-text {
      color: $primary-color;
      margin-bottom: 10px;
      // margin-top: 25px;
      font-weight: 550;
    }

    .section-switcher {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $light-gray;
      padding-top: 30px;

      button {
        background-color: transparent;
        border: none;
        color: $light-font-color !important;
        padding-bottom: 10px;
        margin-right: 25px;
      }

      .active-section {
        color: $secondary-color !important;
        border-bottom: 2px solid $secondary-color;
      }

      .show-form-btn {
        color: $white-font-color !important;
        padding-bottom: 6px;
        position: relative;
        bottom: 10px;
        background-color: $primary-orange;
      }
    }

    .license-banner {
      margin-top: 6px;
      margin-right: 10px;
      padding: 4px 9px;
      border-radius: 500px;
      width: min-content;
      white-space: nowrap;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .license-text {
      margin-bottom: 0;
      color: white;
    }

    .application-fee {
      font-size: 15px;
      margin-top: 6px;
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  .upsell-products-card {
    background-color: #f4f4f5;
    padding: 15px 15px 15px 15px;
    border-radius: 8px;
    border: 1px solid $primary-orange;
  }

  .lead-preview-card {
    background-color: #f4f4f5;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid $primary-orange;
    table {
      tr {
        .count-pill-container {
          padding-left: 5px;
        }
        td {
          p {
            margin-bottom: 2px;
            width: 60px;
            border-radius: 500px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }

  .features-card {
    background-color: #f4f4f5;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid $primary-orange;
  }

  .coach-section {
    padding-top: 15px;
  }

  .contact-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .rating-loader-icon {
    font-size: 40px;
    color: $light-font-color;
  }

  .section-container {
    height: 500px;
    overflow-y: scroll;
  }

  .community-flag-icon {
    font-size: 50px;
    color: $light-red;
  }

  .grey-out {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(43, 56, 67, 0.85);
    z-index: 99;
  }
  .override-date-modal {
    position: fixed;
    height: 300px;
    width: 500px;
    top: 100px;
    left: 0;
    right: 0;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    background: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #2b3843;
    border-radius: 35px;
    p {
      text-align: center;
      font-size: 1.5rem;
      margin: 0;
    }
    .datepicker-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .add-date-prompt {
        font-size: 14px;
      }
      input {
        text-align: center;
        border-radius: 8px !important;
        &:focus {
          border-bottom: 1px solid #ddd !important;
        }
        &:hover {
          cursor: pointer;
          background: #eee !important;
        }
      }
    }
    .remove-override-button {
      border-radius: 500px;
      border: 2px solid $light-red;
      background: $light-red;
      padding: 5px 10px;
      &:hover {
        color: $light-red;
        background: transparent;
      }
    }
    .button-box {
      button {
        width: 120px;
        border-radius: 500px;
        padding: 5px;
      }
      .nevermind {
        margin-right: 20px;
        border: 2px solid #79d1f7;
        background: #79d1f7;
        &:hover {
          color: #79d1f7;
          background: transparent;
        }
      }
      .add-override-button {
        border: 2px solid #33d9b2;
        background: #33d9b2;
        &:hover {
          color: #33d9b2;
          background: transparent;
        }
      }
    }
  }
  .flag-text-wrapper {
    background: #f4f4f5;
    border: 1px solid #12a482;
    padding: 10px 15px 0 15px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    .resolved-flags {
      color: $primary-teal;
      margin-bottom: 0;
      padding-left: 10px;
    }
    .unresolved-flags {
      color: $light-red;
      padding-left: 10px;
    }
  }
  .card-toggler {
    .card-toggler-icon {
      color: $primary-orange;
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .map-filters {
    display: flex;
    flex-direction: column;
    background: #f4f4f5;
    border: 1px solid $primary-orange;
    border-radius: 8px;
    padding: 10px;
    .checkbox-container {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 7px;
      margin-left: 7px;
      margin-bottom: 4px;
      .filter-category-text {
        margin: 0;
        width: 100%;
        text-decoration: underline;
        color: #000;
        text-decoration-color: red;
        font-weight: 550;
      }
      .check-box {
        height: 20px;
        flex-basis: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 5px;
        border: 2px solid #f68c22;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        .fa {
          font-size: 0.7rem;
          color: #f68c22;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .box-checked {
        background: radial-gradient(#fff, #79d1f7, #f68c22);
      }
      .checkbox-label {
        font-size: 0.9rem;
        color: #2b3843;
        white-space: nowrap;
        margin: 0;
      }
    }
  }
}
