@import '../../../assets/styles/globals.scss';

.scheduleSessionCard {
  background-color: white;
  border-left: 4px solid $dark-teal;
  border-radius: 15px;
  padding: 10px 20px;
  margin-bottom: 20px;
  transition: border-left 0.2s;

  &:hover {
    border-left: 10px solid $dark-teal;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .notificationButton {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;

    .notificationIcon {
      height: 20px;
      width: 20px;
      fill: $icon-gray;
    }

    .badge {
      background-color: $secondary-color;
      border-radius: 50%;
      height: 7px;
      width: 7px;
      margin-left: -8px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    width: 90%;
  }

  .subtitle{
    color: $light-navy;
    font-weight: 700;
  }

  .text {
    color: $light-navy;
    padding-left: 10px;
  }

  .textStudents {
    color: $light-navy;
    font-size: 18px;
  }

  .faIcon {
    color: $light-navy;
  }
  
  .icon {
    fill: $light-navy;
    height: 20px;
    margin-right: 5px;
  }

  .enrollments{
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
  }

  .studentsProfiles{
    display: flex;
    align-items: center;
    padding-left: 15px;

    .moreProfiles {
      color: $light-navy;
      margin-left: 10px;
    }
  }
  
  .profileImg {
    border-radius: 50%;
    border: 1px solid $icon-gray;
    height: 40px;
    width: 40px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
    margin-left: -10px;
  }
}