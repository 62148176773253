@import '../../../../assets/styles/globals.scss';

.accountTab {
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media (min-width: 1200px) {
    padding: 36px;
  }

  .accountInformation {
    background-color: white;
    border-radius: $card-corner-radius;
    padding: 16px 20px;
    width: 100%;

    @media (min-width: 992px) {
      width: 80%;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .subtitle {
      font-size: 14px;
    }

    .text {
      font-weight: 700;
    }

    .familyPoints {
      font-size: 20px;
    }

    .favoriteIcon {
      flex-shrink: 0;
    }

    .editIcon {
      width: 16px;
      flex-shrink: 0;
      fill: $dark-navy;
    }
  }

  .familyMembersList {
    width: 100%;

    @media (min-width: 992px) {
      width: 80%;
    }

    .familyMemberCard {
      cursor: pointer;
      width: 100%;
      height: 100%;
      color: $dark-navy;
      background-color: white;
      margin-bottom: 0;
      border-left: 0px solid $primary-orange;
      transition: border-left 0.2s;

      &:hover {
        cursor: pointer;
        border-left-width: 4px;
      }
    }

    .newFamilyMemberCard {
      min-height: 74px;
      padding: 12px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-color: white;
      border-left: 0px solid $primary-orange;
      transition: border-left 0.2s;

      &:hover {
        cursor: pointer;
        border-left-width: 4px;
      }

      .circlePlusIcon {
        flex-shrink: 0;
      }

      .addMemberText {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}
