@import "../../../../assets/styles/globals";

.newMemberAboutData {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .header {
    padding-top: 16px;

    @media (min-width: 768px) {
      padding-top: 48px;
    }
  }

  .mainContent {
    flex-grow: 1;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
      padding: 48px;
    }

    .familySwitchWrapper {
      .familySwitchButton {
        width: 100%;
        max-width: 250px;
        height: 48px;
        border-radius: 100px;
        border: 2px solid $primary-orange;
        color: $primary-orange;
        font-size: 0.9rem;
        padding: 0;
        background-color: white;
        margin-bottom: 8px;

        @media (min-width: 254px) {
          height: 32px
        }

        .switchOption {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 100%;
          padding: 0 4px;
          border-radius: 30px;
          user-select: none;

          &.switchSelected {
            color: white;
            background-color: $primary-orange;
          }
        }
      }

      .familySwitchText {
        color: white;
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    .titleText {
      color: white;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
      text-align: left;
    }

    .subtitleText {
      color: white;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 24px;
    }

    .inputSection {
      color: white;
      font-size: 18px;
      font-weight: 700;
      margin: 16px 0;
      text-align: left;
    }

    .inputLabel {
      font-size: 16px;
      color: white;
      font-weight: 700;
    }

    .inputSymbol {
      color: white;
    }
  }
}
