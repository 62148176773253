@import "../../../assets/styles/globals";

#BlockContentPicker {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
  .block-content-picker-top-container {
    padding: 33px 72px 0 72px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
    .block-content-database-picker {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      position: relative;
      border: 2.5px solid #36434e;
      border-radius: 20px;
      height: 49px;
      overflow: hidden;
      &:hover {
        cursor: pointer;
      }
      .database-picker-title {
        flex: 1 1;
        text-align: center;
        background: transparent;
        z-index: 2;
        color: #36434e;
        transition: color 0.6s;
      }
      .moving-pill {
        position: absolute;
        height: 100%;
        width: 50%;
        right: 50%;
        background: #36434e;
        border-radius: 15px;
        transition: right 0.6s;
        z-index: 1;
      }
      .right {
        right: 0;
      }
    }
    .block-content-task-switcher {
      margin-bottom: 36px;
      display: flex;
      .task-picker-box {
        flex: 1 1;
        p {
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
        .picker-underline {
          height: 6px;
          background: #fff;
          border-radius: 500px;
        }
        .active {
          background: #36434e;
        }
      }
    }
    .block-task-search-container {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      .block-task-search-inner-container {
        flex: 1 1;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #c4c4c4;
        .fa-search {
          color: #c4c4c4;
          font-size: 1.3rem;
        }
        input {
          border: none !important;
          color: #1d2933 !important;
          flex: 1 1;
        }
      }
      .block-task-filter-icon {
        flex: 0 0 30px;
        height: 30px;
        margin-left: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        &:hover {
          cursor: pointer;
        }
        .filter-count {
          position: absolute;
          top: -10px;
          right: -10px;
          background: $primary-orange;
          color: #fff;
          margin: 0;
          line-height: 1;
          font-size: 13px;
          padding: 4px 6px;
          border-radius: 500px;
        }
      }
    }
  }
  .loader-container {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
  }
  .placeholder-task-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
    // height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #bbb;
    padding: 20px;
    i {
      font-size: 48px;
    }
    p {
      text-align: center;
    }
  }
  .block-content-container {
    position: relative;
    z-index: 1;
    background: #f4f4f5;
    flex: 1 1;
    max-width: 100%;
    overflow-y: auto;
    padding: 18px 50px;
  }

  @media screen and (max-width: 1280px) {
    .block-content-picker-top-container {
      padding: 33px 52px 0 52px;
    }
    .block-content-container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media screen and (max-width: 1100px) {
    .block-content-container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
