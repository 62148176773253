@import "../../../../assets/styles/globals";

#PackageSelection {
  .package-card-option {
    cursor: pointer;
    margin-bottom: 1em;
    margin-right: 20%;

    @media (max-width: 575px) {
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
