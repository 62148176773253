@import '../../globals';


// Migrate this to the commeon .scss
.padding-base{
	padding:10px;

	.stat-badges-wrap {
		display: flex;
		align-items: center;
	}
}

.spacer{
	&-sm{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	&-md{
		margin-top: 20px;
		margin-bottom: 20px;
	}
	&-lg{
		margin-top: 30px;
		margin-bottom: 30px;
	}
}


#profileStats{

	#myChart {
		margin-top: 10%;
	}

	.badge {
		color: inherit;
	}
}
// Can be removed.
.profileHeader{
	// background-color:#222;
	background: url('../../../images/mock/objectivePlaceholder.png');
	background-size: cover;
}

.placeholder{
	background-color: $mid-font-color;
	&-light{
		background-color: lighten($mid-font-color, 30%);
	}
	&-dark{
		background-color: darken($mid-font-color, 30%);
	}

}


.stat{
	&-division-score{
		@include border-radius(30px);
		height: 23px;
		width: 23px;

	}
	&-level{
	@include border-radius(30px);
	height: 35px;
	width: 35px;
		p{
			padding-top: 5px;
		}
	}
	&-yard{
		margin: 6px 0px 0px 0px;
	}
	&-score{
		margin-right: 10px;
	}
	&-chart{
		&-title{
			margin-top: 100px;
    		margin-bottom: -100px;
    	}
	}

}


// to Add to the global





.profileWrapper {
	padding: 10px;
}

// @each $statColor in 1, 2, 3, 4, 5, 6, 7 {
//   .stat-color-#{$statColor} {
//     color: $stat-color-#{$statColor} ;
//   }
// }



// This needs to be optimized. the @each loop above does not work.
// Needs to resemble the code block above.

.stat-color-0-bg{
	color: inherit;
}


.custom-checkbox {
	&.stat-color-1-text {
		color: $stat-color-1;
	}
	&.stat-color-2-text {
		color: $stat-color-2;
	}
	&.stat-color-3-text {
		color: $stat-color-3;
	}
	&.stat-color-4-text {
		color: $stat-color-4;
	}
	&.stat-color-5-text {
		color: $stat-color-5;
	}
	&.stat-color-6-text {
		color: $stat-color-6;
	}
	&.stat-color-7-text {
		color: $stat-color-7;
	}

	span {

		span {
			color: #000;
		}
	}
}

.stat-yard {
	margin-left: 6px;
}

// This has the same problem. Needs to have a @each loop. Needs to debug -Tim
.stat-badge-1:not(.checked) {
	.badge-pill{
		// color: inherit;
	}
}
.stat-badge-1.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-1;
	}
}


.stat-badge-2:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-2.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-2;
	}
}

.stat-badge-3:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-3.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-3;
	}
}

.stat-badge-4:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-4.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-4;
	}
}


.stat-badge-5:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-5.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-5;
	}
}


.stat-badge-5:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-5.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-5;
	}
}


.stat-badge-6:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-6.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-6;
	}
}


.stat-badge-7:not(.checked) {
	.badge-pill{
		color: inherit;
	}
}
.stat-badge-7.checked {
	* {
		color: $white-font-color
	}
	.badge-pill{
		background-color: $stat-color-7;
	}
}


// $stat-color-1: #8ACD00;
// $stat-color-2: #5FA000;
// $stat-color-3: #2D6800;
// $stat-color-4: #F2A800;
// $stat-color-5: #F24539;
// $stat-color-6: #4E8EE9;
// $stat-color-7: #B7FE00;

// Circle Chart .CSS
