@import "../../../assets/styles/globals";

#ProgramMarketplaceContainer {
  margin-top: 50px;

  .available-programs-container {
    margin-bottom: 20px;
    height: 700px;
  }

  .template-loader-container {
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switchButton {
    width: 30%;
    height: fit-content;
    border-radius: 100px;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    font-size: 0.9rem;
    padding: 0;
    background-color: transparent;

    .switchOption {
      width: 50%;
      border: none;
      padding: 0.25em 0;
      border-radius: 30px;
      user-select: none;

      &.switchSelected {
        color: white;
        background-color: $primary-orange;
      }
    }
  }
}

@media all and (max-width: 1312px) {
  #ProgramMarketplaceContainer {
    .available-programs-container {
      margin-bottom: 20px;
      height: 500px;
      overflow-y: scroll;
    }

    .template-loader-container {
      height: 500px;
    }

    .switchButton {
      font-size: 0.7rem;
    }
  }
}