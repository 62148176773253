@import "../../assets/styles/globals";
$top-content-height: 125px;

.seriesList {
  height: calc(100vh - $page-header-height - $top-content-height);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 98%;
  margin: auto;
}

.seriesListPaginated {
  height: calc(100vh - $page-header-height - $top-content-height - $pagination-height);
  overflow-y: scroll;
  overflow-x: hidden;
}

.seriesActions {
  padding-bottom: 10px;
  padding-top: 5px;

  p,
  a {
    color: $light-navy;
    cursor: pointer;
    font-size: 15px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: #ff6633;
    }
  }
}
