@import "../../assets/styles/globals";

.community-settings-page {
  margin: 0 -15px;
  background: white;
  height: 100vh;

  .tabs {
    height: calc(100% - 70px);

    .selected-tab {
      border-bottom: 4px solid $primary-orange;
      height: 40px;
      color: $primary-orange;
    }

    .selected-tab-panel {
      display: block;
      height: calc(100% - 40px);
    }

    .tab-list {
      padding: 0;
      margin: 0;
      height: 40px;
      border-bottom: 1px solid $light-gray;

      .tab {
        height: 100%;
        display: inline-flex;
        align-items: center;
        margin-top: 0;
        padding: 0 100px;
        font-size: 0.9rem;
        font-weight: bold;
        list-style: none;
        cursor: pointer;
      }
    }
  }
}
