@import "../../../assets/styles/globals";

.modal-footer-container {
  width: 100%;
  border-top: 1px solid $border-lighter-gray;
  position: sticky;
  bottom: 0;
  background-color: white;

  .footer-buttons-container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    justify-content: center;
  }

  .btn {
    min-width: 180px;
  }
}
