$header-size: 70px;
$title-font-size: 30px;
$icon-size: $title-font-size;
$top-padding: calc(($header-size - $title-font-size) / 2);

@import "../../../assets/styles/globals";

.d-block {
  display: !important;
}

.page-header {
  & > .row {
    // background-image: url('./page-header-bg.png');
    // background-position: center;
    // background-size: cover;
    background-color: transparent;
    height: $header-size;
    border-bottom: 1px solid $light-gray;

    // & > .col-12 {
    //   -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
    //   -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
    //   box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
    //   // padding-top: $top-padding;
    // }
  }
  img {
    float: right;
    max-width: 80px;
    width: 100%;
  }
  h1 {
    float: left;
    color: $light-font-color;
    padding-left: 10px;
    font-size: 18px;
  }
  h3 {
    // position: absolute;
    // left: 86px;
    color: $primary-color;
    // top: 47px;
  }
  button {
    margin-top: 0;
  }
  .header-button-container {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    .question-button {
      font-size: 22px;
    }

    #buildProgramButton {
      svg path {
        stroke: white;
      }
    }
  }
}

.header-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 20px;
  height: 40px;
  top: 8px;
  width: 40px;

  .title-shifted {
    margin-left: -45px;
  }
}

.header-title {
  // position: absolute;
  // left: 75px;
  margin: 0px;
}

.header-icon {
  position: relative;
  bottom: 3px;
  left: -20px;
  width: 100%;
  padding: 20px;
}

.fa-header-icon {
  font-size: 25px !important;
  color: $primary-color !important;
}

.btn-family {
  background-color: white;
  border-color: white !important;
  color: #f68a22 !important;

  &:focus {
    background-color: white;
  }

  &:hover {
    color: #f68a22 !important;
    background-color: #f1f1f1;
    border-color: #e9e9e9 !important;
  }
}

.view-community-link {
  padding: 0 15px;

  #outsideLinkIcon {
    flex-shrink: 0;
  }

  .view-community-label {
    padding-left: 10px;
    color: $primary-orange;
    font-weight: 700;
  }
}

@media (max-width: 761px) {
  .page-header {
    button {
      margin-top: 0;
      font-size: 12px;
      float: right;
    }
  }
}

@media (max-width: 991px) {
  .page-header {
    .header-title {
      top: 10px;
    }

    button {
      margin-top: 0;
      font-size: 12px;
      float: right;
    }
  }
}

.program-popover-style {
  width: 175px !important;
  box-shadow: none !important;
  margin-top: 5px !important;
}

.build-program-actions {
  padding-bottom: 10px;
  padding-top: 5px;

  p, a {
    color: $light-navy;
    cursor: pointer;
    font-size: 15px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: #ff6633;
    }
  }
}
