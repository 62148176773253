@import "../../../assets/styles/globals";

#ProgramRegistrationPage {
  color: $dark-navy;
  font-size: inherit;

  .summary-panel-wrapper {
    height: auto;
    position: sticky;
    top: 0;
    background-color: $dark-navy;
    color: white;
    z-index: 1;

    @media (min-width: 992px) {
      height: 100%;
      position: static;
    }
  }

  .registration-step-wrapper {
    padding: 8% 8% 1rem;

    @media (max-width: 575px), (max-height: 575px) {
      padding: 1rem 0.5rem;
    }
  }

  .step-label {
    margin-bottom: 1.25em;
    font-weight: 700;
  }
}

.program-registration-spinner {
  color: $primary-orange;
}
