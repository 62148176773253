@import "../../../assets/styles/globals";

.reportsTab {
  width: 80%;
  margin: 1rem auto;
  background-color: white;
  border-radius: 8px;

  .leftPanel {
    flex-basis: 40%;

    .title {
      font-weight: 800;
    }

    .subtitle {
      font-size: 1.5rem;
      font-weight: 800;
    }

    .firstText {
      font-size: 1.2rem;
      color: $light-navy;
      font-weight: 800;
    }

    .secondText {
      font-size: 1.2rem;
      color: $light-navy;
    }
  }

  .rightPanel {
    width: 60%;
    background: $off-white;
    border-radius: 8px;

    .image {
      width: 522px;
      height: 288px;
    }
  }
}
