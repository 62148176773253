#ScoreNamePopover {
  position: absolute;
  border: 1px solid #afb7bd;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #fff;
  width: calc(100% + 4px);
  left: -2px;
  top: 10px;
  overflow: hidden;
  .selection-line {
    padding: 10px;
    .check-container {
      width: 35px;
      i {
        font-size: 25px;
        color: #34ace0;
      }
    }
    &:hover {
      background: #f4f4f5;
    }
  }
}
