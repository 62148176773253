@import "../../../assets/styles/globals";

#FeatureCard {
  margin-right: 7px;
  margin-left: 7px;
  margin-bottom: 4px;

  .check-box {
    height: 20px;
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 5px;
    border: 2px solid #f68c22;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    .fa {
      font-size: 0.7rem;
      color: #f68c22;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .check-box.disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  .checkbox-label {
    font-size: 0.9rem;
    color: #2B3843 !important;
    white-space: nowrap;
    margin: 0;
  }

  .feature-card-tooltip {
    max-width: 250px;
  }

  #infoCircleIcon {
    margin-left: 10px;
  }
}
