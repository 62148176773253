@import "../../../assets/styles/globals";

#PricingTab {
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .community-settings-main-row {
    margin: 27px 21px 0px 21px;

    .community-pricing-packages {
      width: 100%;

      .community-pricing-packages-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .community-pricing-packages-description {
        color: $light-navy;
      }
    }

    .packages-filter-buttons {
      height: min-content;
      font-size: 16px;
      border-radius: 100px;
      border: 2px solid $light-navy;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      white-space: nowrap;

      .packages-filter-button {
        border: none;
        padding: 6px 20px;
        border-radius: 30px;
      }

      .packages-filter-selected {
        color: white;
        background-color: $light-navy;
      }

      .packages-filter-unselected {
        color: $light-navy;
        background-color: white;
      }
    }

    .package-add-wrapper {
      margin-left: 30px;
      white-space: nowrap;
      width: min-content;

      .package-add-button {
        background-color: #36434e;
        white-space: nowrap;
        border-radius: 25px;
        border: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 260px;
        padding: 8px 0;
        color: $off-white;
        font-size: 16px;
      }

      #roundedPlusIcon {
        flex-shrink: 0;
        margin-right: 18px;
      }
    }
  }

  @media (max-width: 900px) {
    .community-settings-main-row {
      flex-wrap: wrap;
    }

    .table-wrapper {
      margin-top: 21px;
    }
  }

  @media (max-width: 586px) {
    .community-settings-main-row {
      .packages-filter-buttons {
        flex-basis: 100%;

        .packages-filter-button {
          flex-basis: 100%;
        }
      }

      .package-add-wrapper {
        flex-basis: 100%;
        margin-top: 18px;
        margin-left: 0 !important;

        .package-add-button {
          width: 100%;
        }
      }
    }
  }
}

.popover-style {
  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25) !important;
}

.packages-actions {
  padding-bottom: 10px;
  padding-top: 5px;

  .package-action {
    cursor: pointer;
    font-size: 16px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: #ff6633;
    }
  }
}
