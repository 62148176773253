@import "../../../../assets/styles/globals";

#ColumnPackageSelect {
  height: 44px;

  .table-tooltip {
    max-width: 350px;
    min-width: min-content;
    word-break: break-word;
    font-size: 12px;
    border: 2px solid $placeholder-gray !important;
    border-radius: 8px;
    opacity: 1;

    @media screen and (max-width: 767px) {
      width: 250px;
    }
  }

  .package-select {

    .package-selected-value {
      height: 45px;
      overflow: hidden;

      p {
        margin-bottom: 0;
      }
    }
  }
}

#ColumnPackageSelect.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.package-option {
  .table-tooltip {
    max-width: 350px;
    min-width: min-content;
    word-break: break-word;
    font-size: 12px;
    border: 2px solid $placeholder-gray !important;
    border-radius: 8px;
    opacity: 1;

    @media screen and (max-width: 767px) {
      width: 250px;
    }
  }

  padding: 8px;
  cursor: pointer;

  .price-label {
    padding-left: 3px;
    margin-bottom: 0;
  }

  &:hover {
    background-color: darken(white, 10%) !important;
  }
}