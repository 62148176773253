@import "../../../assets/styles/globals";

.signUpPage {
  position: fixed;
  background-image: url("../../../assets/images/auth/sign-up-background-mobile.jpg");
  background-color: $primary-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    background-image: url("../../../assets/images/auth/sign-up-background-desktop.jpg");
  }

  .currentStep {
    height: 100%;
    width: 100%;

    .mainCard {
      height: 100%;
      width: 100%;
      border-radius: 0px;
      background-color: $dark-navy;
      box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);

      @media (min-width: 768px) {
        height: 80%;
        width: 70%;
        border-radius: 8px;
      }

      @media (min-width: 1200px) {
        height: 80%;
        width: 50%;
        border-radius: 8px;
      }
    }

    .sideCard {
      width: 100%;
      height: 100%;
      bottom: 0;
      position: absolute;
      background: linear-gradient(to top, rgba(29, 41, 51, 1) 25%, rgba(0, 0, 0, 0) 100%);

      @media (min-width: 768px) {
        width: 40%;
        height: 100%;
        top: 0;
        position: relative;
        background: none;
        text-align: center;
        display: initial;
        background-color: $dark-navy;
        box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.35);
      }
    }
  }
}
