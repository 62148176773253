@import "../../../assets/styles/globals.scss";
$roster-top-conent-height: 139px;

.enrollmentsList{
  max-height: calc(100vh - $page-header-height - $page-footer-height - $roster-top-conent-height);
  overflow-x: hidden;
  background-color: #fff;
  margin-bottom: -4px;

  table {
    margin-bottom: 0;
  }

  .paired {
    color: $dark-teal;
  }

  .notPaired {
    color: $red-darker;
  }
}

.noStudentsPlaceholder {
  color: $light-navy;
  width: 35%;
  margin: 100px auto;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .placeholderTitle {
    margin-top: 1rem;
    font-weight: 800;
    font-size: 20px;
    color: $dark-navy;
  }

  .placeholderDescription {
    white-space: pre-wrap;
    justify-content: start;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
}

.deleteButton {
  cursor: pointer;
}

.messageIcon {
  cursor: pointer;
  fill: $light-gray;

  &:hover {
    fill: $slate-gray;
  }
}

.studentActions {
  padding-bottom: 10px;
  padding-top: 5px;

  p, a {
    color: $light-navy;
    cursor: pointer;
    font-size: 15px;
    padding: 5px 10px 0px 10px;
    margin: 0;

    &:hover {
      color: $primary-orange;
    }
  }
}
