@import '../../../../assets/styles/globals.scss';

.userActions {
  .notificationSection {
    .notificationButton {
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 48px;

      .icon {
        height: 20px;
        width: 20px;
        fill: white
      }

      .label {
        color: white;
        font-weight: 500;
        padding-left: 10px;
        margin-bottom: 0px !important;
      }

      .badge {
        background-color: $secondary-color;
        border-radius: 50%;
        height: 7px;
        width: 7px;
        margin-bottom: 15px;
        margin-left: -5px;
      }
    }
  }

  .avatarSection {
    margin-right: 8px;
    cursor: pointer;

    .avatarWrapper {
      width: 46px !important;
      height: 46px !important;
      margin-right: 12px;
      border: 1px solid white;
    }

    .dropDownArrowIcon {
      width: 16px !important;
      height: 10px !important;
    }
  }

  .tooltipWrapper {
    padding: 8px 12px;
    font-size: 14px;
    background-color: $dark-navy !important;
    border-color: $dark-gray !important;
    border-radius: 8px !important;
    opacity: 1 !important;

    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }

  .userTooltip {
    width: 121px;

    a {
      padding: 3px 0;
      color: white;

      &:hover {
        color: $icon-gray;
      }
    }

    .logOut {
      padding: 3px 0;
      margin-bottom: 4px;
      color: $primary-orange;

      &:hover {
        color: $red;
      }
    }

    .termsAndPolicies {
      font-size: 0px;
      color: $border-darker-gray;
      border-top: 1px solid rgba(175, 183, 189, .5);

      .separator {
        font-size: 9px;
        padding-top: 3px;
      }

      .footerTitle {
        font-size: 9px;
        padding-bottom: 0;
        margin-bottom: 0;

        &:hover {
          color: $icon-gray;
        }
      }

      .footerSubtitle {
        font-size: 8px;
      }
    }
  }
}
