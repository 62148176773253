@import "../../assets/styles/globals";

.messageCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  width: 100%;
  height: 45px;

  i {
    font-size: 26px;
    color: #fff;
  }

  span {
    font-weight: 800;
    color: #fff;
  }

  .continueEditingButton {
    cursor: pointer;
  }
}