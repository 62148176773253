@import "../../../assets/styles/globals";

.action {
  background-color: transparent !important;
  border: none;
  color: $primary-orange;
  font-weight: 700;

  &:hover {
    color: darken($primary-orange, 15%);

    .icon {
      fill: darken($primary-orange, 15%);

      path {
        fill: darken($primary-orange, 15%);
      }
    }
  }
}

.icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  fill: $primary-orange;

  path {
    fill: $primary-orange;
  }
}
