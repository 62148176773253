@import "../../../../assets/styles/globals";

.event-schedule {
  height: 100%;
  padding-bottom: 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;

  .title {
    font-size: 20px;
    font-weight: 800;
    color: $dark-navy;
  }

  .events {
    border-bottom: 1px solid $placeholder-gray;
  }
}