@import "../../../../assets/styles/globals";

#PaymentMethodSelect {
  .payment-method-select-tooltip {
    max-width: 250px;
  }

  .checkbox-select-wrapper {
    margin-bottom: 10px;
    width: fit-content;

    .checkbox-label {
      font-size: 14px;
    }

    .disabled {
      opacity: 0.65;
      pointer-events: none;
    }

    #infoCircleIcon {
      margin-left: 10px;
    }
  }
}