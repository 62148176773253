@import "../../../../assets/styles/globals";

.categoryOption {
  cursor: pointer;

  &:hover {
    background-color: darken(white, 10%) !important;
  }
}

.categoryValue {
  cursor: pointer;

  & > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.name {
  color: $dark-navy;
  font-size: 1.25rem;
  margin-bottom: 0;
  font-weight: 700;
}

.description {
  color: $dark-navy;
  font-size: 0.9rem;
  font-size: 14px;
  margin-bottom: 0;
}

.color {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.createCategory {
  cursor: pointer;

  p {
    font-size: 15px;
    color: $primary-orange;
    font-weight: 700;
    padding: 10px 10px 0px 10px;
  }
}
