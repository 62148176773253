@import "../../../assets/styles/globals";

#ChallengeDetailCard {
  width: 50%;
  // height: 300px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #777;
  border-radius: 8px;
  overflow: hidden;
  background: #5f6f7c;
  box-shadow: 3px 3px 3px #ccc;
  position: relative;
  .icon-nav {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    font-size: 1.3rem;
    background: #fff;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      background: #f4f4f5;
    }
  }
  .delete-view {
    color: red;
  }
  .edit-view {
    color: #34ace0;
  }
  .challenge-show-pic {
    width: 300px;
    height: 250px;
    background: #f4f4f5;
    background-size: cover;
    background-position: center;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .placeholder-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #34ace0;
      i {
        font-size: 50px;
      }
    }
  }
  .challenge-show-content-container {
    background: #fff;
    p {
      margin: 0;
    }
    .challenge-show-name-section {
      display: flex;
      align-items: center;
      padding: 5px;
      .challenge-show-icon {
        // background: red;
        height: 70px;
        flex-basis: 70px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 10px;
        border-radius: 50%;
        background: #ccc;
        background-size: cover;
        background-position: center;
      }
      .challenge-show-name {
        font-size: 2rem;
        font-weight: 550;
      }
      .challenge-show-dates {
        font-size: 1rem;
        color: #999;
      }
      .challenge-show-points {
        margin-left: auto;
        color: #6677de;
        font-size: 1.5rem;
      }
    }
    .challenge-show-desc-section {
      padding: 0 25px 15px 25px;
      color: $primary-orange;
    }
    .submit-button-container {
      display: flex;
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .challenge-react-player {
    // width: 90%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 2px #ccc;
    margin-bottom: 10px;
  }
  .delete-flow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .secret-password-input {
      border-radius: 5px !important;
      &:focus {
        border-bottom: 1px solid red !important;
      }
    }
    .delete-trigger {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 150px;
      border: 2px solid red;
      background: #fff;
      color: red;
      border-radius: 500px;
      font-weight: 550;
      &:hover {
        background: red;
        color: #fff;
      }
    }
  }
}
