@import "../../../assets/styles/globals";

#AdminSupportActivityTable {
  .clear-all-filters {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .react-datepicker-popper {
    z-index: 3; // table header has z-index 2
  }
  .report-filter-section {
    .filter-title {
      .toggle-icon {
        color: $primary-orange;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .filter-content-container {
    background: #86eed5;
    padding: 5px 10px;
    border: 1px solid #12a482;
    border-radius: 8px;
    .filter-box-container {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .filter-box {
        height: 20px;
        flex-basis: 20px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 2px solid #0e7cac;
        border-radius: 5px;
        background: #79d1f7;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        .fa {
          color: #243497;
        }
      }
      .filter-label {
        margin: 0;
        font-size: 0.85rem;
      }
    }
  }
}
