@import "../../assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  .coachHeader {
    padding: 20px;
  }

  button {
    all: unset;
    position: absolute;
    right: 10px;
    top: 10px;

    svg {
      stroke-width: 1px;
    }

    &:active {
      background-color: transparent;
    }
  }

  .imageContainer {
    flex-grow: 1;
  }
  .commentsContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 50px;
    border-top: 1px solid $light-gray;

    .comments {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 10px;
      border-radius: 8px;
      background-color: transparent !important;
      border: none;

      .commentsText {
        font-size: 13px;
        color: $light-navy;
      }
    }

    .comments:hover {
      background-color: $light-gray !important;
    }

    .readBy {
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 8px;
      padding: 10px;

      .readByText {
        font-size: 13px;
        color: $dark-teal;
      }
    }

    .readBy:hover {
      background-color: $light-gray;
    }
  }

  .caption {
    overflow: hidden;
    font-size: 13px;
    margin: 10px 20px 10px 20px;
  }

  .fromNow {
    color: $light-navy;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 13px;
  }
}
