@import '../../globals';

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

#objectiveDetailsBackground {
  background: url('../../../images/mock/StockPhoto.png');
  background-size: cover;
  background-position: center;
  // background-attachment: fixed;
  height: 280px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
}

#ObjectiveDetailsPage {
  position: relative;
  height: 100%;

  #objectiveDetailsWrapper{
    padding-top: 30px;
    position: relative;
    top: 115px;
    padding-bottom: 80px;
    // margin-top: 115px;
    // overflow: hidden;
    background-color:$objective-details-background-color;
    .objectivePoints{
      width: 50%;
      position: absolute;
      color: $secondary-color;
      i{
        position: absolute;
        font-size: 28px;
        top: 8px;
        left: 12px;
      }
      h5{
        position: absolute;
        display: inline;
        font-size: 32px;
        left: 44px;
        top: 10px;
        span{
          position: relative;
          font-size: 22px;
          display: inline;
          left: 8px;
          top: -8px;
        }
      }
      .objectivePercentComplete{
        .objectiveRankBadge {
          top: -40px;
          left: calc(100% - 65px);
          position: absolute;
          width: 69px;
          height: 69px;
        }
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .objectiveTitle{
      padding-top: 70px;
      h5{
        font-size: 26px;
        max-width: 90%;
        margin: 0 auto;
        text-align: center;
      }
    }

    .socialButtonContainer{
      width:100%;
      .socialButton{
        float:right;
        width:33%;
        background-color: $secondary-color;
        border: none;
        color: $white-font-color;
        padding: 5px 14px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;

      }
    }
    .objectiveDescription{
      padding-top: 20px;
      p{
        max-width: 95%;
        margin: 0 auto;
        font-size: 14px;
      }
    }
    .howToSection{
      padding-top: 25px;
      p{
        max-width: 95%;
        margin: 0 auto;
        font-size: 14px;
      }
    }
    .videoSection {
      padding-top: 25px;
      .vimeo {
        height: 240px;
        position: relative;
        .vimeo-image,
        .vimeo-image:after,
        .vimeo-embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto !important;
        }
        .vimeo iframe {
          border: 0;
        }
        .vimeo-image {
          // background-position: center;
          // background-size: cover;
          background-position: center center;
          background-size: 100% auto;
          background-repeat: no-repeat;
          .vimeo-play-button, .vimeo-loading {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
          .vimeo-play-button {
            z-index: 2;
            position: absolute;
            padding: 0;
            width: 70px;
            border: 0;
            background: none;

            border-radius: inherit;
            background-clip: inherit;
            background-color: inherit;
            border-color: inherit;
            color: inherit;
            cursor: inherit;
            margin-top: inherit;

            svg {
              fill: white;
            }
          }
        }
        .vimeo-loading {
          z-index: 4;
          position: absolute;
          width: 32px;
          height: 32px;
        }
        .vimeo-loading svg {
          fill: #000;
          transform-origin: 50% 50%;
          -webkit-animation: spinner 0.8s infinite linear;
          animation: spinner 0.8s infinite linear;
        }
        .vimeo-embed iframe {
          width: 100%;
          height: 100%;
        }
        @-moz-keyframes spinner {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spinner {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        @-o-keyframes spinner {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        @keyframes spinner {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      .embeddedVideo{
        padding-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        img{
          display: block;
          width: 95%;
          margin: 0 auto;
        }
      }
    }

    .media-player-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 640px;
      margin-bottom: 24px;
    }

    .media-player {
      width: 100%;
      max-width: 640px;
      position: relative;

      // hide native controls
      //video::-webkit-media-controls {
        //display: none !important;
      //}
    }

    .media-player-element {
      max-width: 100%;
      height: 0;
      padding-bottom: 56.25%; // 16:9
      position: relative;
      overflow: hidden;
      video,
      iframe,
      object,
      embed {
        display: block;
        width: 100%;
        height: 300%;
        border: 0;
        position: absolute;
        top: -100%;
        left: 0;
        //pointer-events: none;
      }
    }
    .linksSection{
      padding-top: 25px;
      .link{
        .linkImage{
          background: url('../../../images/mock/StockPhoto.png');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          img{
            height: 85px;
          }
        }
        .linkText{
          h4{
            font-size: 12px;
          }
          p{
            font-size: 10px;
            max-height: 2em;
          }

        }

      }
    }
  }
  .bottom-border {
    border-bottom: 1px solid $light-gray;
  }
}

.VimeoVideo {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.VimeoVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.VimeoVideo iframe, .VimeoVideo object, .VimeoVideo embed {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
}
