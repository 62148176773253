@import "../../../../assets/styles/globals.scss";

#ResultStep {
  width: 100%;

  .result-step-image {
    height: auto;
    width: 100%;
    max-width: 500px;
    border-radius: $card-corner-radius;
    border: 2px solid $dark-navy;
  }

  .result-step-title {
    color: $dark-navy;
    font-size: $heading-2-size;
    font-weight: $bold-font-weight;
    margin: $secondary-padding 0px;
  }

  .result-step-desc {
    color: $dark-navy;
  }

  .result-step-button-group {
    display: flex;
    flex-flow: column;
    align-items: center;

    .result-step-link:hover {
      color: $primary-orange;
    }

    .result-step-button {
      margin: $secondary-padding 0px;

      &:hover {
        color: white;
      }
    }
  }
}
