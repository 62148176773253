#PlaceholderLPCard {
  flex: 0 0 400px;
  width: 400px;
  height: 90px;
  background: #ccc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  p {
    text-align: center;
    margin: 0;
    color: #fff;
  }
}
