@import "../../assets/styles/globals";

#ActivityCard {
  .activity-card-container {
    width: 100%;
  }
  .activity-header-wrapper {
    margin: 15px;

    .activity-info-wrapper {
      width: 70%;
      .activity-name-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        h4 {
          padding: 0px;
        }

        .coach-pill-wrapper {
          margin-left: 10px;
          background-color: $secondary-color;
          border-radius: 500vh;
          padding: 2px 10px;

          p {
            margin-bottom: 0px;
            color: $white-font-color;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.activity-picture-wrapper {
  padding-bottom: 30px;
  width: 100%;
}

.activity-overview-wrapper {
  border-radius: 500vh;
  padding: 2px 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 12px;
  }
}

.activity-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 400px;
  cursor: pointer;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: inherit;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: inherit;
  }

  .activity-score-overlay-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0px 15px 15px 15px;

    .activity-score-overlay-wrapper {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 8px;
      padding: 25px;

      .activity-data {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h5 {
          font-weight: 550;
          margin: 0px;
          font-size: 35px;
        }

        p {
          color: primary-color;
          margin: 0px;
          font-size: 14px;
        }
      }

      .activity-score {
        h5 {
          color: $secondary-color;
        }
      }

      .activity-greens {
        h5 {
          color: $primary-teal;
        }
      }

      .activity-putts {
        h5 {
          color: $light-blue;
        }
      }
    }
  }
}

.score-overlay {
  height: 143px !important;
  background-color: #5f6f7c;
}

.activity-clap-container {
  display: flex;
  align-items: center;
  flex-direction: row;

  .activity-clap-wrapper {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }
}

.activity-icon-wrapper {
  height: 30px;
  width: 30px;
  border-radius: 50%;

  img {
    width: 100%;
    padding: 5px;
  }
}

// Small devices
@media all and (max-width: 575px) {
  .activity-icon-wrapper {
    position: absolute;
    right: 15px;
    height: 30px;
    width: 30px;
    border-radius: 50%;

    img {
      width: 100%;
      padding: 5px;
    }
  }

  .activity-image-wrapper {
    height: 300px;
  }

  .showButton {
    font-size: 10px;
  }
}

// Medium devices && Protrait Tablet
@media all and (max-width: 768px) {
}

// Large devices
@media all and (max-width: 991px) {
}

// Laptop - Desktop devices
@media all and (max-width: 1600px) {
}

// Landscape Tablet
@media all and (max-width: 1199px) {
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .showButton {
    font-size: 12px;
  }

  .activity-image-wrapper {
    height: 300px;
  }

}
