@import "../../../assets/styles/globals";

.progress-bar-container{
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  .progress-bar-left {
    width: 100%;
    background-color: $light-navy;
    border-radius: 36px;
    height: 10px;

    .progress-bar-filled {
      height: 100%;
      border-radius: 36px;
    }
  }

  .progress-bar-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $secondary-padding;
    margin-top: 10px;

    .progress-bar-qty {
      font-size: $heading-3-size;
    }

    .completed {
      font-size: 14px;
    }

    .left {
      font-size: 14px;
      color: $light-navy;
    }
  }
}
