@import "../../assets/styles/globals";

.programCard {
  height: 170px;
  background: white;
  border: none;
  border-left: 4px solid;
  border-radius: 15px;
  padding: 1.2rem 1rem;
  transition: border-left 0.2s;
  color: $dark-navy;

  @media (min-width: 992px) {
    height: 100px;
  }

  &:hover {
    color: $dark-navy;
    border-left-width: 10px;

    @media (min-width: 992px) {
      .action {
        .angle {
          display: none;
        }

        .actionText {
          display: initial;
        }
      }
    }
  }

  .name {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 800;

    @media (min-width: 992px) {
      font-size: 1.2rem;
    }
  }

  .subtitle {
    color: $light-navy;
    line-height: 1rem;
    font-size: 0.9rem;

    @media (min-width: 992px) {
      flex-basis: 33%;
      font-size: 1rem;
    }

    .subtitleIcon {
      fill: $light-navy;
      height: 1rem !important;
      width: auto;

      @media (min-width: 992px) {
        height: 1.2rem;
      }
    }

    .spotsLeft {
      font-weight: 800;
      font-size: 0.9rem;

      @media (min-width: 992px) {
        font-size: 1rem;
      }
    }
  }

  .action {
    flex-basis: 5%;
    @media (min-width: 992px) {
      flex-basis: 15%;
    }

    .angle {
      font-size: 1.5rem;

      @media (min-width: 992px) {
        margin-right: 20px;
      }
    }

    .actionText {
      display: none;
      color: white;
      border-radius: 20px;
      padding: 0.5rem 0.8rem;
      background: $primary-orange;

      &.grayActionText {
        background: $light-navy;
      }
    }
  }
}
