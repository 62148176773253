@import '../../../../assets/styles/globals';

#PaymentMethodSelection {
  .payment-option-card {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    cursor: pointer;

    .payment-method-info {
      font-weight: 700;
      margin-left: 1.2em;
    }
  }

  #cashPaymentInfo {
    width: 340px;
    word-break: break-word;
    text-align: center;
    font-size: 14px;
    border: 2px solid $placeholder-gray !important;
    border-radius: 8px;
    opacity: 1;

    @media screen and (max-width: 767px) {
      width: 200px;
    }
  }

  #infoCircleIcon {
    height: 1.25em;
    width: 1.25em;
    margin-left: 1em;
  }
}
