@import '../../../assets/styles/globals.scss';

.coachDashboardOp36 {
  height: calc(100vh - $page-header-height - 40px);
  display: flex;

  .dashboardContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow-y: hidden;
  }

  .communityLeaderboard {
    width: 50%;
    height: 560px;
    margin-top: 20px;
    margin-left: 40px;
    background-color: white;
    border-radius: 10px;

    @media (min-width: 1400px) {
      height: 730px;
    }

    .title {
      font-size: 1.2rem;
      font-weight: 700;
      margin: 0;
    }

    .leaderboardWrapper {
      flex-grow: 1;
    }
  }
}

.allTimeTotals {
  width: 30%;
  height: max-content;
  padding-left: 40px;
  padding-top: 20px;
  background-color: white;
  border-radius: 10px;
  margin-left: 40px;
  margin-top: 20px;

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .allTimeTotalsItem {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .infoWrapper {
      margin-left: 20px;

      .info {
        font-size: 26px;
        font-weight: 700;
      }

      .itemTitle {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  .coachDashboardOp36 {
    .allTimeTotalsItem {
      margin-top: 20px;
      margin-bottom: 20px;

      .infoWrapper {

        .info {
          font-size: 20px;
          font-weight: 700;
        }

        .itemTitle {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .coachDashboardOp36 {

    .allTimeTotalsItem {
      margin-top: 15px;
      margin-bottom: 10px;

      .infoWrapper {

        .info {
          font-size: 18px;
        }

        .itemTitle {
          font-size: 12px;
        }
      }
    }
  }
}
