

.skylight-dialog{
	width: 98% !important ;
	height: auto !important ;
	position: fixed !important ;
	top: 40% !important ;
	left: 1% !important ;
	margin-left: -25% !important;
	border-radius: 4px !important ;
	z-index: 999 !important ;
	padding: 50px 15px !important ;
	box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 40px, rgba(0, 0, 0, 0.28) 0px 0px 40px !important ;
	min-width: 300px !important;
	border-style: solid !important ;
	display: block !important ;
	background: #fff !important ;
	margin-top: inherit !important;
	margin-left: inherit !important;
}

.ios-notification{
	&-header{
			background-color: 	rgba(220, 220, 220, .9);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-top-left-radius:15px;
			border-top-right-radius: 15px;
			padding:10px;
			align-items: center;
			&-title{
				text-transform: uppercase;
				font-family: "Helvetica", Arial, sans-serif;
				color: #3C424B;
				font-weight: bold;
				margin-bottom: 0px;
				margin-top: 0px;
				font-size: 18px;
			}
			&-time{
				font-family: "Helvetica", Arial, sans-serif;
				color: #3C424B;
				margin-bottom: 0px;
				margin-top: 0px;
				font-size: 12px;

			}
	}
	border-radius: 15px;
	background-color: 	rgba(200, 200, 200, .8)
}


@media only screen and (min-width : 992px) {
	.skylight-dialog{
		max-width: 50% !important ;
		left: 25% !important ;
	}
}
