@import "../../../assets/styles/globals";

.page-footer-container {
  width: 100%;
  height: 60px;
  bottom: 0px;
  z-index: 999;
  background-color: #fff;
  border-top: 1px solid $light-gray;

  .footer-buttons-container {
    padding-right: 75px;
  }

  .btn {
    min-width: 150px;
    background-color: $primary-orange;
    border-radius: 500px !important;

    transition: all 0.2s ease-in-out;

    &:focus {
      outline:none;
      box-shadow: none;
    }

    &:hover {
      transform: scale(0.9);
    }
  }
}
