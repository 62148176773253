@import "../../assets/styles/globals";

.modal {
  max-height: 860px !important;
  background: $primary-navy !important;
}

.checkIcon {
  font-size: 100px !important;
  color: #12a482;
}

.successTitle {
  font-size: 36px;
  color: #12a482;
}

.communityContactForm {
  $user-color: #fff;
  position: relative;
  width: 100%;
  background: $primary-navy;
  color: $off-white;

  label, .reqFormFamilyCount {
    display: block;
    font-size: 14px;
    font-weight: 550;
    margin-bottom: 3px;
    margin-top: 15px;
    color: $off-white;
    cursor: default;

    p {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  .reqFormTitle {
    font-size: 1.9vw;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (max-width: 830px) {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }

  .familyCountBox {
    display: flex;
    justify-content: space-evenly;
    margin: 8px 0;

    .radioContainer {
      background: transparent;
      display: block;
      padding: 0;
      border: none;

      .radioOuter {
        height: 20px;
        width: 20px;
        border: 2px solid #f4f4f5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .radioInner {
          height: 12px;
          flex-basis: 12px;
          flex-grow: 0;
          flex-shrink: 0;
          background: #f4f4f5;
          border-radius: 50%;
        }
      }

      .radioLabel {
        text-align: center;
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }

  .checkboxArea {
    display: flex;
    width: 100%;
    margin-top: 10px;

    .checkboxContainer {
      width: 50%;

      .checkBoxTitle {
        font-weight: 550;
        font-size: 14px;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      .checkRow {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .checkBox {
          background: transparent;
          padding: 0;
          height: 20px;
          flex-basis: 20px;
          flex-grow: 0;
          flex-shrink: 0;
          border: 2px solid #f4f4f5;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
          cursor: pointer;
        }

        .checkLabel {
          font-size: 13px;
          margin: 0 5px;
        }
      }
    }
  }

  input {
    width: 100%;
    border: none !important;
    border-bottom: 2px solid $off-white !important;
    border-radius: 5px !important;
    font-size: 15px;
    padding: 4px 10px;
    font-weight: 450;
    background-color: white !important;

    &::-webkit-input-placeholder {
      color: $placeholder-gray !important;
    }

    &::-moz-placeholder {
      color: $placeholder-gray !important;
    }

    &::-ms-placeholder {
      color: $placeholder-gray !important;
    }

    &::placeholder {
      color: $placeholder-gray !important;
    }
  }

  textarea {
    width: 100%;
    height: 75px;
    resize: none;
    overflow-y: auto;
    font-size: 15px;
    font-weight: 450;
    border-radius: 5px !important;
    padding: 2px 10px;
  }

  .invalidEmailNote {
    color: #ec8291;
    text-align: center;
    border: 2px solid #ec8291;
    padding: 5px;
    margin-top: 1rem;
    background: #fff;
  }

  .reqFormSubmit {
    display: block;
    width: fit-content;
    margin: 30px auto 5px;
    font-size: 19px;
    font-weight: bold;
    padding: 12px 60px;
  }

  .redText {
    color: #ec8391;
  }

  .redLine {
    border-bottom: 2px solid #ec8391 !important;
  }

  .redBorder {
    border: 2px solid #ec8391 !important;
  }
}
