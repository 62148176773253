@import "../assets/styles/globals";

.welcomePage {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(29, 41, 51, 1);

  .backgroundPanel {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (min-width: 768px) {
      width: 60%;
    }

    .backgroundVideo {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .actionPanel {
    width: 100%;
    height: 60%;
    bottom: 0;
    position: absolute;
    background: linear-gradient(to top, rgba(29, 41, 51, 1) 25%, rgba(0, 0, 0, 0) 100%);

    @media (min-width: 768px) {
      width: 40%;
      height: 100%;
      top: 0;
      position: relative;
      background: none;
      text-align: center;
      display: initial;
    }

    .op36Logo {
      max-width: 100%;
      width: 320px !important;
      height: auto;
    }

    .logoText {
      max-width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: white;
    }

    .actionLink {
      max-width: 100%;
      width: 320px !important;
    }
  }
}
