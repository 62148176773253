@import "../../../../assets/styles/globals";

#FlagRow {
  border-bottom: 1px solid $light-gray;
  padding: 20px;

  .note-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;

    .note-info-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      h4,
      p {
        margin: 0px;
      }

      h4 {
        font-size: 20px;
        font-weight: 550;
        color: $primary-color;

        span {
          color: $secondary-color;
        }
      }

      .representative-action-type {
        color: $white-font-color;
        background-color: $primary-color;
        border-radius: 500vh;
        margin-left: 10px;
        padding: 3px 10px;
      }

      .contact-made {
        margin-left: 5px;
        padding: 3px 10px;
        border-radius: 500vh;
        color: $white-font-color;
      }
    }
    .note-date-container {
      p {
        margin: 0px;
      }
      .note-action-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        button {
          background-color: transparent;
          border: none;
          margin-left: 15px;
        }
        .note-edit {
          color: $secondary-color;

          &:hover {
            color: darken($secondary-color, 25%);
          }
        }

        .note-delete {
          color: $light-red;

          &:hover {
            color: darken($light-red, 25%);
          }
        }
      }
    }
  }

  .note-container {
    display: flex;
    align-items: center;
    flex-direction: "row";

    p {
      margin: 0px;

      span {
        color: $primary-color !important;
        font-weight: 550;
        margin-right: 10px;
      }
    }

    .resolved-pill {
      color: $white-font-color;
      border-radius: 500vh;
      padding: 3px 15px;
    }

    .flag-type-pill {
      color: $white-font-color;
      border-radius: 500vh;
      padding: 3px 15px;
      background-color: $primary-color;
      margin-left: 10px;
    }
  }
}
