#AdminTagIndex {
  .fa-pencil-square {
    &:hover {
      cursor: pointer;
      color: lightblue;
    }
  }
  .fa-times-circle {
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
  .fa-check-circle {
    &:hover {
      cursor: pointer;
      color: lightgreen;
    }
  }
  .fa-ban {
    &:hover {
      cursor: pointer;
      color: palevioletred;
    }
  }
  input {
    padding-left: 5px;
    border-radius: 4px !important;
    font-weight: 400;
    font-size: 1rem;
    width: 97%;
    &:focus {
      border-bottom: 1px solid #ddd !important;
    }
  }
}
