@import "../../../assets/styles/globals";

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 28.8px;
  color: $primary-navy;
}

.container {
  padding: 2px 40px;

  label {
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    color: $dark-navy;
  }
}

.accountInputContainer {
  padding: 5px 15px !important;

  input {
    color: $light-navy !important;
  }
}

.extraMargin {
  margin-bottom: 76px;
}

@media (min-width: 1400px) {
  .container {
    padding: 2px 80px;
  }
}