@import "../../../assets/styles/globals";

#DrillActivityFilterModal {
  .reset-filter {
    float: right;
    margin-top: 10px;
    color: $primary-blue;
    // font-weight: bold;
    font-size: 18px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  h1 {
    font-size: $heading-1-size;
    font-weight: $bold-font-weight;
    text-align: center;
    margin-bottom: $primary-padding;
    line-height: 1;
  }

  h2 {
    font-size: $heading-2-size;
    margin-bottom: $secondary-padding;
    line-height: 1;
  }

  .drill-activity-filters {
    // display: grid;
    // grid-template-areas:
    //   "type skill"
    //   "visibility skill"
    //   "tag tag";
    display: flex;
    flex-flow: column;

    .drill-activity-type-filters,
    .drill-activity-visibility-filters,
    .drill-activity-skill-filters,
    .drill-activity-tag-filters {
      margin-bottom: $secondary-padding;
    }

    // .drill-activity-type-filters {
    //   grid-area: type;
    // }

    // .drill-activity-visibility-filters {
    //   grid-area: visibility;
    // }

    // .drill-activity-skill-filters {
    //   grid-area: skill;
    // }

    // .drill-activity-tag-filters {
    //   grid-area: tag;
    // }

    .filter-radio-container {
      display: flex;
      margin-bottom: 10px;
      margin-right: 10px;

      .filter-radio-outer {
        border: 2px solid $light-navy;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .filter-radio-inner {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $primary-orange;
        }
      }

      .filter-radio-label {
        margin: 0px;
        margin-left: 6px;
      }
    }

    .filter-radio-outer.radio-selected,
    .filter-radio-outer:hover {
      border: 2px solid $primary-orange;
    }
  }

  .skill-container {
    flex: 0 0 12.5%;
    display: flex;
    flex-direction: column;
    img {
      height: 70px;
      width: 70px;
      margin-left: auto;
      margin-right: auto;
      opacity: 1;
      &:hover {
        cursor: pointer;
      }
    }
    .dim {
      filter: grayscale(100%);
      opacity: 0.7;
    }
    p {
      text-align: center;
      margin: 0;
      line-height: 1.1;
    }
  }

  .filter-checkbox-container {
    display: flex;
    margin-bottom: 10px;

    .filter-checkbox {
      border: 2px solid $primary-orange;
      background-color: white;
      width: 24px;
      height: 24px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .filter-checkbox.checkbox-checked {
      background-color: $primary-orange;

      i {
        color: white;
      }
    }

    .filter-checkbox:hover,
    .filter-checkbox.checkbox-checked:hover {
      cursor: pointer;
      filter: brightness(85%);
    }

    .filter-checkbox-label {
      margin: 0px;
      margin-left: 6px;
    }
  }

  .filter-clickable-tag {
    background-color: $light-navy;
    color: white;
    padding: 6px;
    margin-right: 6px;
    margin-bottom: 3px;
    border-radius: 50px;
    line-height: 1;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
  }

  .tag-checked,
  .filter-clickable-tag:hover {
    background-color: $primary-orange;
    cursor: pointer;
  }

  .filter-modal-footer {
    display: flex;
    margin-top: $secondary-padding;

    .btn-rectangular-main-negative {
      margin-right: $secondary-padding;
    }

    .btn-rectangular-main-positive {
      margin-left: $secondary-padding;
    }
  }
}
