@import "../../../assets/styles/globals";

.landing-page-tab {
  height: calc(100% - 80px);
  width: 100%;
  overflow-y: auto;
}

.settings-form {
  width: 700px;
  margin: 0 auto;

  .link {
    width: fit-content;
    text-decoration: underline;
  }

  .label-description {
    font-weight: 400;
    font-size: 0.9rem;
    color: $light-navy;
  }

  .left-button {
    flex-basis: 35%;
  }

  .right-button {
    flex-basis: fit-content;
  }


  .placeholder {
    height: 100px;
    font-weight: 700;
    color: $light-navy;
    background-color: $off-white;
    border-top: 2px solid $border-darker-gray;
  }
}

.footer {
  background-color: white;
  position: sticky;
  bottom: 0;
  height: 80px;
  border-top: 1px solid $border-darker-gray;

  .save-button {
    width: fit-content !important;

    &:disabled {
      opacity: 1;
      background: $light-navy !important;
    }
  }
}

.settings-form, .course-category-modal {
  label {
    font-size: 1rem;
    font-weight: 800;
  }
}

.confirmation-modal-title {
  font-size: 2rem;
  font-weight: bold;
  color: $primary-orange;
  margin-bottom: 1rem;
}

.course-category-modal {
  height: 740px;

  &.with-delete-button {
    height: 780px;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 800;
    padding-bottom: 1rem;
  }

  .required {
    color: $input-error-color;
  }

  .delete-button {
    color: $input-error-color;
    background: transparent;
    outline: none;
    border: none;
  }
}
