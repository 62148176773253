@import '../../../assets/styles/globals.scss';

.activeTabPanel {
  flex-grow: 1;
  flex-basis: 0;
  display: block !important;
  overflow-y: auto;
}

.tabsListWrapper {
  border-bottom: 1px solid $light-gray;
  flex-basis: 40px;

  .tabList {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: transparent;
    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar {
      height: 0;
      background: transparent;
    }

    .tab {
      height: 100%;
      text-wrap: nowrap;
      margin: 0;
      padding: 0 0.75rem;
      font-size: 0.9rem;
      font-weight: bold;
      list-style: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-bottom: 4px solid transparent;

      &:hover {
        color: $primary-orange;
      }
    }

    .activeTab {
      border-bottom-color: $primary-orange;
      color: $secondary-color;
    }
  }

  .exportButton {
    background-color: $off-white;
    font-size: 0.9rem;
    font-weight: 700;
    color: $dark-navy;
    border-radius: 15px;
    border: none;
    height: fit-content;

    &:disabled {
      color: $dark-navy;
    }

    .exportIcon {
      margin-left: 5px;
    }
  }
}
