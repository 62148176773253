@import "../../assets/styles/globals";

.filtersButton {
  height: 35px;
  border-radius: 8px;
  @include bordered($border-lighter-gray);
  background: white;
  padding: 0;
  color: $light-navy;
  font-weight: 700;

  &:active {
    background: white;
    border-color: $border-gray;
  }

  @media (min-width: 992px) {
    flex-basis: 330px;
  }
}

.resetFiltersButton {
  padding: 0.5rem;
  height: 35px;
  background: $red-delete;
  color: white;
  border-radius: 40px;
  border: none;

  &:active {
    background: $red-delete;
  }
}

.filtersMenu {
  max-width: none !important;
  width: 330px;

  .filterName {
    font-size: 1rem;
  }

  .switchButton {
    width: 100%;
    height: fit-content;
    border-radius: 100px;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    font-size: 0.9rem;
    padding: 0;
    background-color: transparent;

    .switchOption {
      width: 50%;
      border: none;
      padding: 0.25em 0;
      border-radius: 30px;
      user-select: none;

      &.switchSelected {
        color: white;
        background-color: $primary-orange;
      }
    }
  }

  .selectMenuList {
    max-height: 145px;
  }
}
