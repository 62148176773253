@import "../../../../assets/styles/globals";

.division-records-card {
  background-color: white;
  border-radius: $card-corner-radius;
  box-shadow: $non-pressable-elem-drop-shadow;
  display: flex;
  flex-flow: row;
  // width: 85vw;
  width: 100%;
  // max-width: 1700px;
  // margin-bottom: $secondary-padding;
  margin-bottom: calc($secondary-padding / 1px / $ideal-academy-display-height * 100vh);
  line-height: 1;
}

.division-records-numbers {
  // padding: $secondary-padding;
  padding: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  display: flex;
  flex-flow: row;
  align-items: center;
}

.division-records-badge {
  // width: 90.5px;
  width: 4.7vw; // 4.7% of 1920px width
  // margin-right: $secondary-padding;
  margin-right: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
}

.divisions-records-div-name {
  background-color: $light-navy;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 0;
  text-align: center;
  // width: 221px;
  // height: 104px;
  width: 11.5vw; // 11.5% of 1920px width
  height: 5vw; // 5% of 1920px width
  border-radius: $card-corner-radius;
  // line-height: 29px;
  line-height: 1.2;
  // margin-right: $secondary-padding;
  margin-right: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
}

.divisions-records-div-name > p {
  // Remove the margin from the text so it'll be centered in the box
  margin: 0;
  // font-size: 24px;
  // font-size: 1.25vw; // 1.25% of 1920px width
  font-size: calc($heading-2-size / 1px / $ideal-academy-display-width * 100vw);
  font-weight: $bold-font-weight;
}

.division-records-score-info {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  // width: 140px;
  width: calc(140px / 1px / $ideal-academy-display-width * 100vw);
}

.division-records-score-info > p {
  margin: 0;
}

.division-records-score {
  // font-size: 72px;
  font-size: calc(72px / 1px / $ideal-academy-display-width * 100vw);
  font-weight: $bold-font-weight;
  // line-height: 1.2;
  line-height: 1.1;
  color: $light-navy;
}

.division-records-date {
  // font-size: 18px;
  font-size: calc($heading-3-size / 1px / $ideal-academy-display-width * 100vw);
  color: $light-navy;
}

.division-records-student {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.division-records-student {
  background-color: $light-navy;
  border-radius: 0 $card-corner-radius $card-corner-radius 0;
  display: flex;
  align-items: center;
  // padding: $secondary-padding;
  padding: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
}

.division-records-student-pic {
  // width: 104px;
  // height: 104px;
  width: calc(96px / 1px / $ideal-academy-display-width * 100vw);
  height: calc(96px / 1px / $ideal-academy-display-width * 100vw);
  border: 4px solid white;
  // margin-right: $secondary-padding;
  margin-right: calc($secondary-padding / 1px / $ideal-academy-display-width * 100vw);
  border-radius: 100%;
  object-fit: cover;
}

.division-records-student-name {
  // font-size: 96px;
  font-size: calc(96px / 1px / $ideal-academy-display-width * 100vw);
  font-weight: $bold-font-weight;
  color: white;
  margin: 0;

  max-width: calc(850px / 1px / $ideal-academy-display-width * 100vw);
}

.division-not-attempted-msg {
  color: white;
  // font-size: 36px;
  font-size: calc($heading-1-size / 1px / $ideal-academy-display-width * 100vw);
  margin: 0;
  width: 100%;
  text-align: center;

  max-width: calc(964px / 1px / $ideal-academy-display-width * 100vw);
}

.division-records-student-name, .division-not-attempted-msg {
  // Need these in case name is too long
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
