@import "../../../../assets/styles/globals";

.config-item {
  display: flex;
  margin-bottom: $secondary-padding;
  // max-width: 50%;
  width: 100%;
}

.config-item-preview-img {
  height: 79.5px;
  width: 133px;
  object-fit: cover;
  flex: 0 0 auto;
  // min-height: 90px;
  background-color: #c4c4c4;
  border-radius: $card-corner-radius;
  margin: 0 $secondary-padding;
}

.config-item-label {
  font-weight: $bold-font-weight;
  font-size: $heading-3-size;
}

.config-item-desc {
  color: $light-navy;
}
