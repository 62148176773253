@import '../../../../assets/styles/globals.scss';

.loadingAnimation {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: lighten($slate-gray, 10%);
  }

  100% {
    background-color: lighten($slate-gray, 4%);
  }
}

.container {
  position: relative;
  height: 100%;
  padding: 30px 25px;
}

.skeletonTitle {
  border-radius: 4px;
  width: 60%;
  height: 28px;
  margin-bottom: 5px;
}

.skeletonSubTitle {
  border-radius: 4px;
  width: 40%;
  height: 28px;
  margin-bottom: 20px;
}

.skeletonCaption {
  height: 12px;
  width: 90%;
  margin-bottom: 5px;
  border-radius: 4px;
}

.skeletonBar {
  border-radius: 4px;
  width: 70%;
  height: 20px;
  margin-top: 20px;
}

.skeletonBox {
  margin-top: 50px;
  width: 100%;
  height: 45vh;
  border-radius: 4px;
}

.skeletonFooter {
  position: absolute;
  width: 90%;
  height: 60px;
  bottom: 20px;
  border-radius: 4px;
}