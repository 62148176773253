#LargeMilestoneIcon {
  .hexagon {
    position: relative;
    width: 120px;
    height: 69.28px;
    border-radius: 3px;
    top: -43px;
  }

  .hexagon-before,
  .hexagon-after {
    position: relative;
    z-index: 1;
    width: 84.85px;
    height: 84.85px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    left: 18.25px;
  }

  .hexagon-before {
    top: 0;
    border-top-right-radius: 7px;
  }

  .hexagon-after {
    top: -85.75px;
    border-bottom-left-radius: 7px;
  }

  // ==========================================
  .inner-hex {
    position: relative;
    z-index: 3;
    width: 86px;
    height: 49.65px;
    background-color: transparent;
    left: 8px;
    top: 9.815px;
  }

  .inner-hex-before,
  .inner-hex-after {
    position: relative;
    z-index: 4;
    width: 60.81px;
    height: 60.81px;
    // -webkit-transform: scaleY(0.5774) rotate(-45deg);
    // -ms-transform: scaleY(0.5774) rotate(-45deg);
    // transform: scaleY(0.5774) rotate(-45deg);
    background-color: transparent;
  }

  .inner-hex-before {
    top: 10px;
    left: 0;
  }

  .inner-hex-after {
    top: -2px;
    left: 11px;
  }
  img {
    width: 55px;
    position: relative;
    top: -3px;
  }

  .left-division-box,
  .right-division-box {
    position: absolute;
    padding: 1px;
    top: 13px;
    font-size: 0.9rem;
  }

  .left-division-box {
    left: -6px;
  }
  .right-division-box {
    right: -6px;
  }
}
