@import "../../../assets/styles/globals";

#CommunityStatCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .stat-circle {
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    -webkit-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);
    box-shadow: 8px 10px 10px -10px rgba(0, 0, 0, 0.3);

    .stat-number {
      color: $white-font-color !important;
      margin: 0px;
      font-size: 30px;
      font-weight: 550;
    }
  }

  .stat-name {
    font-size: 12px;
    margin-top: 10px;
  }
}
