@import "../../assets/styles/globals";

#EnrollmentCard {
  margin-bottom: 15px;
  border: 1px solid $light-gray !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .enrollment-info-wrapper {
    margin-left: 20px;

    .enrollment-name {
      font-size: 20px;
      color: $primary-color;
      margin: 0px;
    }

    .enrollment-course {
      font-size: 17px;
      color: $light-font-color;
      margin: 0px;
    }

    .enrollment-meeting-times {
      font-size: 14px;
      color: $light-font-color;
      margin: 0px;
    }

    .enrollment-pill {
      position: absolute !important;
      top: 10px;
      right: 10px;
    }
  }
}
