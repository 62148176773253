@import "../../assets/styles/globals.scss";

.mcElement {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px; // Ensure width is 24px if parent display != flex
  height: 24px;
  flex: 24px 0 0; // Ensure width is 24px if parent display = flex
  flex-shrink: 0;
  box-sizing: border-box;

  // Override with props passed into mcElement
  border: 2px solid $primary-orange;
  background-color: white;
  .fa-check {
    color: white;
  }

  .radio-button-fill {
    background-color: $primary-orange;
    border-radius: 100%;
    width: 14px;
    height: 14px;
  }

  &:hover {
    filter: brightness(85%);
  }
}

.mcElement.disabled {
  cursor: not-allowed;
  pointer-events: none;

  &:hover {
    filter: none;
  }
}

.mcElement.radio {
  border-radius: 100%;
}

.mcElement.checkbox {
  border-radius: 5px;
}

.mcElement.checkbox.selected-checkbox {
  background-color: $primary-orange;
}
