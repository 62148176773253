@import '../../globals';
#FamiliesStudents {
  .tabList {
    margin-left: -15px;
    margin-right: -15px;
    height: 40px;
    background-color: transparent;
    border-bottom: 2px solid $light-gray !important;

    .tab{
      margin-top: 0px;
      text-transform: none;
      background-color: transparent;
      padding-left: 100px;
      padding-right: 100px;
      font-size: 14px;
      height: 40px;
      font-weight: bold;
      color: black;
    }

    .activeTab{
      border-bottom: 4px solid $secondary-color;
      height: 40px;
      color: $secondary-color;
      background: transparent !important;
    }
  }

  .tabPanel {
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
  }
}