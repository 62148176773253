@import "../../../assets/styles/globals";

#PlayerJourneyReport {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  // justify-content: space-between;
  div {
    background-repeat: no-repeat;
  }
  .impact-top-bar {
    background: $dark-navy;
    margin-left: -15px;
    margin-right: -15px;
    padding: 9px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    p {
      margin: 0;
    }
    i {
      font-size: 36px;
      margin-right: 9px;
    }
    .fa-arrow-left:hover {
      cursor: pointer;
      color: $primary-orange;
    }
    .top-bar-title-container {
      flex: 1 1;
    }
    .impact-video-trigger-container {
      color: $primary-orange;
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      i {
        font-size: 18px;
        margin-right: 4px;
      }
      &:hover {
        cursor: pointer;
        p {
          text-decoration: underline;
        }
      }
    }
  }
  .player-journey-main {
    background: #fff;
    flex: 1 1;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 63px;
    h4 {
      text-align: center;
      margin-top: 36px;
    }
    p {
      margin-bottom: 0;
    }
    .bold {
      font-weight: bold;
    }
    .student-placeholder-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: $light-navy;
      p {
        margin-bottom: 4px;
        text-align: center;
        max-width: 350px;
      }
      .placeholder-icon {
        flex: 0 0 70px;
        width: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .student-note-container {
      width: 696px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 36px;
      margin-bottom: 18px;
    }
    .clickable {
      padding: 0 6px;
      &:hover {
        cursor: pointer;
        border-radius: 8px;
        background: #ddd;
      }
    }
    .orange-text {
      color: $primary-orange;
    }
    .student-table-head {
      margin-top: 18px;
      background: #f4f4f5;
      border: 2px solid #5f6f7c;
      display: flex;
      padding: 10px 18px;
      color: $light-navy;
      .head-text-title {
        font-weight: bold;
      }
      .head-text {
        font-size: 14px;
      }
    }
    .student-row {
      min-height: 84px;
      display: flex;
      padding: 0 18px;
      border-left: 2px solid #5f6f7c;
      border-right: 2px solid #5f6f7c;
      border-bottom: 2px solid #5f6f7c;
      .row-left {
        display: flex;
        align-items: center;
        overflow: hidden;
        border-right: 2px solid #afb7bd;
        .player-icon {
          height: 48px;
          flex: 0 0 48px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
          border: 2px solid #36434e;
          margin-right: 10px;
        }
        .row-name {
          font-weight: bold;
        }
        .row-username {
          font-size: 12px;
          color: $light-navy;
          white-space: nowrap;
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .row-right {
        display: flex;
        padding: 9px;
        padding-top: 6px;
        padding-right: 0;
        overflow: hidden;
        textarea {
          height: 100% !important;
          resize: none;
          border: none !important;
          // overflow: hidden;
          flex: 1 1;
          font-weight: 400;
          color: $light-navy !important;
          font-size: 16px;
          line-height: 1.1;
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar {
            background-color: transparent;
            width: 4px;
            margin-left: 10px;
          }
          &::-webkit-scrollbar-thumb {
            // background-color: red;
            // background-image: linear-gradient(#f68a22, #7dc1f5, #adfa96);
            background-color: #ddd;
            border-radius: 10px;
          }
        }
        .word-count {
          margin-left: 9px;
          font-size: 14px;
          color: #afb7bd;
        }
      }
    }
    .left-table {
      width: 220px;
    }
    .right-table {
      flex: 1 1;
    }
  }
  .impact-report-bottom-bar {
    background: $light-navy;
    margin-right: -15px;
    margin-left: -15px;
    padding: 18px 25% 9px 25%;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
textarea.player-journey-general-note-input {
  width: 100%;
  resize: none;
  color: $light-navy !important;
  font-weight: normal;
  font-size: 16px;
  height: 150px;
  border: 2px solid $light-navy !important;
  border-radius: 8px !important;
  padding: 9px;
}
