@import "../../../../assets/styles/globals";

#Marketing {
  width: 60%;
  margin: auto;
  padding: 24px;

  @media (max-width: 1199px) {
    width: 80%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: $dark-navy;
    margin-bottom: 8px;
  }

  label {
    color: $dark-navy !important;
    font-size: 16px;
    font-weight: 700;
  }

  .media-card-container {
    height: 170px;
    cursor: pointer;
    border: 1px solid $placeholder-gray;
    border-radius: 10px;
    overflow: hidden;

    & > div {
      height: 100%;
    }
  }

  .header-image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .link-video-preview {
    border-radius: 10px;

    iframe {
      width: 100%;
      height: 170px;
    }
  }

  .remove-media {
    cursor: pointer;
    font-size: 14px;
    color: $primary-orange;
  }

  .character-counter {
    color: $light-navy;
    font-size: 14px;
  }

  .description-details {
    font-size: 15px;

    .description-examples-link {
      color: $primary-orange;
      text-decoration: underline;
    }
  }

  .tags-details {
    font-size: 15px;
  }
}