@import "../../../assets/styles/globals";

#BlockShow {
  background: #36434e;
  border-radius: 8px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 15px 18px;
  margin-bottom: 18px;
  // max-width: 500px;
  .blockshow-topbar {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      line-height: 1;
    }
    .duration-pill {
      background: $primary-orange;
      padding: 2px 8px 3px 8px;
      border-radius: 500px;
      margin-right: 10px;
    }
    .blockshow-block-name {
      flex: 1 1;
      font-size: 24px;
      font-weight: bold;
    }
    .fa {
      font-size: 1.5rem;
      transform: rotate(0deg);
      transition: transform 0.3s;
      &:hover {
        cursor: pointer;
      }
    }
    .rotated {
      transform: rotate(180deg);
    }
  }
  .blockshow-description {
    white-space: pre-wrap;
    margin: 10px 0;
  }
  .tasks-container {
    margin-top: 10px;
  }
}
