@import "../../../../../assets/styles/globals";

.approve-enrollment-modal-content {
  height: min-content !important;
  max-width: 550px !important;
  margin: auto !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
  background: white !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

#ApproveEnrollmentModal {
  #modalXIcon {
    cursor: pointer;
  }

  #completedIcon {
    margin-bottom: 8px;
    width: 82px;
    height: 82px;
  }

  .title {
    color: #12A482;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  .sub-title {
    text-align: center;
  }

  .coachMessageWrapper {
    width: 95%;
  }

  .coachMessageInputContainer {
    height: 150px;
  }
}
