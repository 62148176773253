@import "../../../assets/styles/globals.scss";

#LogScores {
  height: 100%;

  .table-container {
    height: 100%;

    table {
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 1;
    }
  }

  thead {
    height: 47px;
  }

  input {
    color: $light-navy !important;
  }

  th {
    padding-bottom: 0.25rem;
    color: $light-navy !important;
    font-size: 14px;
    font-weight: 700px;
  }

  td {
    vertical-align: middle;
    align-items: center;
    border-bottom: 1px solid $border-lighter-gray;
    border-top: 0px;
  }

  .pairing-group-header {
    width: 100%;
    height: 55px;
    border-top: 2px solid $border-lighter-gray;
  }

  .pairing-group-info {
    background-color: $off-white;
    border-radius: 6px;
    margin: 10px 0 10px 20px;
    width: 275px;
    height: 33px;

    .flag-icon {
      fill: $dark-gray;
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    .pairing-group-order {
      font-weight: 800;
    }

    .pairing-group-time {
      font-weight: 700;

      .time-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        fill: $light-navy;
      }
    }
  }

  .students-table-row {
    .milestones-col {
      color: $dark-teal;
      font-size: 14px;
      font-weight: 800;
    }
  }

  .centered-text {
    text-align: center;
  }

  .col-input {
    width: 78px;
    border-radius: 10px !important;
  }

  .golfer-header {
    padding-left: 50px;
    color: $primary-orange !important;
  }

  .golfer-data {
    padding-left: 50px;
  }

  .trash-icon {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }

  .add-golfer-button {
    width: 150px;
    .btn {
      margin-top: 0 !important;
      margin-bottom: 1rem;
    }
  }

  .no-students-placeholder {
    color: $light-navy;
    width: 25%;
    margin: 200px auto 0px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .placeholder-title {
      margin-top: 1rem;
      font-weight: 800;
      font-size: 20px;
      color: $dark-navy;
    }

    @media screen and (max-width: 768px) {
      width: 80%;
    }
  }
}