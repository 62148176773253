@import "../../../../assets/styles/globals";

.modal {
  width: 80% !important;
  max-width: 1400px !important;
  height: 85% !important;
  max-height: 1000px !important;
  padding: 1.5rem 2rem !important;

  .placeholder {
    max-width: 300px;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      width: 64px;
      height: 64px;
      fill: $light-navy;
    }

    .title {
      font-weight: 800;
      font-size: 1.2rem;
    }

    .description {
      color: $light-navy;
      text-align: center;
    }
  }

  .modalContentWrapper {
    height: calc(100% - $modal-header-height);

    .dualPanelSelector {
      flex: 1;
      min-height: 0;
    }

    .button {
      width: 200px !important;
    }
  }
}
