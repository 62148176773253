@import "../../../assets/styles/globals";

#ResourceSectionForm {
  .resource-draggable-container {
    width: 100%;
    border-top: 1px dashed $light-gray;
    margin-top: 50px;
    padding: 30px;

    h3 {
      font-weight: 550;
      font-size: 22px;
      margin-bottom: 30px;
    }

    div:first-child {
      width: 100%;
    }
  }
}
