@import "../../../assets/styles/globals";

#ResourceSectionsPage {
  .recent-resource-activity-container {
    display: flex;
    flex-direction: column;
    border-left: 1px solid $light-gray;

    h3 {
      font-size: 20px;
      color: $primary-color;
      text-align: left;
    }
  }

  .resource-search-bar {
    margin: -10px auto 15px;
    background-color: #fff;
    border-radius: 8px;
  }

  .resource-folder-box {
    display: flex;
    align-items: center;
    color: #fff;
    background: $dark-navy;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    p {
      margin: 0;
      font-size: 14px;
    }
    .box-title {
      font-size: 18px;
    }
    div:nth-child(2) {
      margin: 0 9px;
    }
    i {
      font-size: 96px;
    }
    .folder-box {
      position: relative;
      .community-logo {
        position: absolute;
        right: 0;
        left: 0;
        top: calc(50% - 20px);
        // bottom: 0;
        margin-left: auto;
        margin-right: auto;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid $dark-navy;
      }
    }
    .btn {
      color: #fff;
      background: $primary-orange;
      border-radius: 4px !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
