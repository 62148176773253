@import "../../../../assets/styles/globals.scss";

:root {
  --startingStep-intro-heading-size: #{$heading-3-size};
  --startingStep-logo-display: none;
}

#StartingStep {
  width: 100%;

  svg { // logo for mobile version
    height: var(--regFlowHeader-logo-height);
    width: auto;
    margin: 0px auto;
    margin-bottom: $secondary-padding;
    display: var(--startingStep-logo-display);
  }

  .starting-step-heading {
    font-size: $heading-3-size;
    font-weight: $bold-font-weight;
  }

  #starting-step-heading-2 {
    margin-bottom: $secondary-padding;
  }

  img {
    height: auto;
    width: 100%;
    max-width: 500px;
    margin-bottom: $secondary-padding;
  }

  .starting-step-subtitle {
    font-size: 14px;
  }

  .starting-step-bullets {
    display: inline-block; // So it's centered based on its dynamic width
  }

  .starting-step-feature-list {
    text-align: left;
    padding-left: $secondary-padding;
  }

  .starting-step-checkboxes {
    display: flex;
    flex-flow: column;
    text-align: left;
  }

  .starting-step-check-label {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: $light-navy;
    // cursor: pointer;
    // margin: 9px 0px;
    margin-bottom: $secondary-padding;

    .mcElement {
      margin-right: 10px;
    }

    p {
      margin: 0;
    }
  }

  .starting-step-button {
    // margin-top: 9px;
    margin: $secondary-padding 0px;
  }

  .starting-step-sign-in {
    font-size: 14px;
  }
}

@media (max-width: 425px) { // phone (large)
  :root {
    --startingStep-logo-display: block;
  }
}

@media (max-width: 320px) { // phone (small)
  :root {
    --startingStep-intro-heading-size: #{$heading-4-size};
  }
}
