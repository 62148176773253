@import "../../../assets/styles/globals";

#CurriculumPreviewCard {
  height: 155px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 9px;
  margin-bottom: 9px;
  overflow: hidden;
  position: relative;
  .grey-out {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    // align-items: flex-start;
    // justify-content: flex-end;
    background: #5f6f7c;
    opacity: 0.7;
    .task-nav-buttons-container {
      flex: 0 0;
      align-self: center;
      i {
        font-size: 2rem;
        padding-left: 5px;
        &:hover {
          cursor: pointer;
          color: #33d9b2;
        }
      }
    }
    .task-play-button-container {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 3rem;
        &:hover {
          color: $primary-orange;
          cursor: pointer;
        }
      }
    }
    .task-delete-container {
      flex: 0 0;
      display: flex;
      align-self: flex-start;
      .fa-trash-o {
        margin-right: 5px;
        margin-top: 5px;
        padding: 5px;
        font-size: 2rem;
        &:hover {
          cursor: pointer;
          color: red;
        }
      }
    }
  }
  .curriculum-preview-photo-area {
    height: 92px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    .curriculum-preview-icon {
      height: 70px;
      flex: 0 0 70px;
      background: #c4c4c4;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      border: 2px solid #5b5b5b;
    }
  }
  .curriculum-preview-titlebar {
    background: #36434e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    height: 33px;
    padding: 0 10px;
    p {
      margin: 0;
    }
    .curriculum-preview-label {
      background: $primary-orange;
      border-radius: 500px;
      font-size: 0.9rem;
      padding: 0 10px;
    }
  }
  .curriculum-preview-footer {
    padding: 0 10px;
    height: 30px;
    display: flex;
    align-items: center;
    background: #fff;
    p {
      margin-bottom: 0;
      font-size: 0.9rem;
      color: #5f6f7c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
