@import "../../../assets/styles/globals";

#GoalCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;

  .goal-card-icon-wrapper {
    i {
      font-size: 45px;
      color: $secondary-color;
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .goal-card-info-wrapper {
    width: 100%;

    h4 {
      color: $primary-color;
      font-size: 20px;
    }

    p {
      margin-bottom: 0px;
      color: $light-font-color;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
