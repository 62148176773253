@import "../../../../assets/styles/globals";

#CommunityFlagsForm {
  padding: 20px;
  border: 1px solid $light-font-color;
  border-radius: 8px;
  margin: 15px;

  .rep-form-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h4 {
      font-size: 20px;
      font-weight: 550;
    }
  }
}
