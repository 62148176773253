@import '../globals';

.iOSscroll{
  // border: solid;
  z-index: 99;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  &.container{
    position: absolute;
  }
}



header.globalHeader{
  h1.title{
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: $white-font-color;
    margin-top: 15px;
  }
}
header.bar-nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $primary-color;
  min-height: 55px;
  height: 85px;
  z-index: 100;
  .backLink{
    color: $white-font-color;
    .fa{
      font-size: 26px;
    }
  }
  .backLinkHeader{
    line-height: 60px;
  }
  .submitTextWrapper{
    position: absolute;
    top: 0px;
    right: 20px;
    color: $white-font-color;
    .submitText{
      line-height: 60px;
    }
  }
}

.submitText > a{
      color: white;
      padding-right: 12px;
}

.submitText > a:active{
      color: white;
}

.backlink-arrow{
  margin-top: 11px;
}
.componentBodyInner {
  margin-top: 0px;
  height: calc(100% - 63px);
  &-noFooter{
      height: calc(100% - 0px);
  }
}


.componentBody {
  // @extend .iOSscroll;
  margin-top: 85px;
  height: calc(100% - 123px) ;
  width: 100%;
  &-noFooter{
    @extend .componentBody;
    height: calc(100% - 60px) ;
  }
  &-formBottom{
    @extend .componentBody;
    height: calc(100% - 170px);
  }

  * .componentBody{
    margin-top: 0px;
    height: inherit;
  }
}
.componentBody.container-fluid{
  position: absolute;
}
.transparentHeader{
  header.bar-nav{
    position: absolute;
    background-color: $transparent;
    color: black;
    h1.title{
      // color: black;
    }
  }
}

.header-container {
  padding-top: 30px;
}
