@import "../../../assets/styles/globals";

#ActivityModal {
  height: 100%;
  .row:first-child {
    height: 100%;
    margin-right: -15px;

    .activity-image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $light-gray;
      cursor: initial;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: inherit;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: inherit;
      }

      .no-media-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        i {
          font-size: 100px;
          color: $light-font-color;
        }

        h2 {
          color: $light-font-color;
          text-transform: uppercase;
          font-size: 25px;
        }
      }

      .activity-score-overlay-container {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        padding: 10px 15px 0px 15px;

        .activity-score-overlay-wrapper {
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 8px;
          padding: 25px;

          .activity-data {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h5 {
              font-weight: 550;
              margin: 0px;
              font-size: 50px;
            }

            p {
              color: primary-color;
              margin: 0px;
              font-size: 14px;
            }
          }

          .activity-score {
            h5 {
              color: $secondary-color;
            }
          }

          .activity-greens {
            h5 {
              color: $primary-teal;
            }
          }

          .activity-putts {
            h5 {
              color: $light-red;
            }
          }
        }
      }
    }

    .activity-modal-left-container {
      height: 100%;
    }

    .activity-modal-right-container {
      height: 100%;
      .activity-modal-user-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;

        .activity-info-container {
          h4 {
            font-size: 20px;
            margin-bottom: 0px;
          }

          p {
            color: $light-font-color;
            font-size: 14px;
            margin-bottom: 0px;
          }

          .activity-date {
            font-size: 12px;
            color: $primary-color;
          }
        }
      }
      .activity-caption {
        max-height: 25%;
        flex-shrink: 0;
        overflow-y: auto;
        color: $primary-color;
        font-size: 14px;
        margin-top: 30px;
      }

      .claps-comments-wrapper {
        flex-basis: fit-content;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid $light-gray;
        padding: 0 15px;

        p {
          color: $light-font-color;
          margin: 0px;
        }
      }

      .comments-section {
        flex-grow: 1;
        overflow-y: scroll;

        #comment-web-wrapper {
          margin: 0px !important;
          // height: 75px;
          height: auto;
        }
      }

      .post-comment-wrapper {
        flex-basis: 50px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: lighten($light-gray, 10%);
        border-top: 1px solid $light-font-color;
        padding: 10px;

        .comment-user-wrapper {
          width: 20%;
          display: flex;
          justify-content: center;
          flex-direction: row;
        }

        .smaller-round-image-wrapper {
          margin-right: 15px;
          border: 2px solid $primary-color;
        }

        .comment-action-container {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 80%;

          .comment-input-wrapper {
            width: 90%;
          }

          .comment-post-wrapper {
            width: 10%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 575px) {
}

// Medium devices && Protrait Tablet
@media all and (max-width: 768px) {
  .activity-mobile-view {
    height: 100%;
    overflow-y: scroll;

    .activity-modal-user-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .avatar-wrapper {
        margin-right: 10px;
      }
    }

    .activity-date {
      color: $light-gray;
      font-size: 12px;
      margin: 0px;
    }
    .activity-caption {
      font-size: 14px;
      margin-top: 10px;
    }

    .activity-info-container {
      .userName {
        font-size: 14px;
        margin: 0px;
      }

      .activityName {
        font-size: 12px;
        margin: 0px;
      }
    }

    .claps-comments-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 7px;
      p {
        margin: 0px;
        font-size: 12px;
      }

      #Claps {
        p {
          margin: 0px;
        }
      }
    }

    .post-comment-wrapper {
      position: absolute;
      bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 1px solid $light-gray;
      padding-top: 15px;
      margin: auto;
      right: 25px;
      left: 25px;

      .comment-user-wrapper {
        margin-right: 7px;
      }

      .comment-action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

// Large devices
@media all and (max-width: 991px) {
  .activity-mobile-view {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 100px;

    .activity-modal-user-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .avatar-wrapper {
        margin-right: 10px;
      }
    }

    .activity-date {
      color: $light-gray;
      font-size: 12px;
      margin: 0px;
    }
    .activity-caption {
      font-size: 14px;
      margin-top: 10px;
    }

    .activity-info-container {
      .userName {
        font-size: 14px;
        margin: 0px;
      }

      .activityName {
        font-size: 12px;
        margin: 0px;
      }
    }

    .claps-comments-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 7px;
      p {
        margin: 0px;
        font-size: 12px;
      }

      #Claps {
        p {
          margin: 0px;
        }
      }
    }

    .post-comment-wrapper {
      position: absolute;
      bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $light-gray;
      padding-top: 15px;
      margin: auto;
      right: 25px;
      left: 25px;
      bottom: 0px;
      padding-bottom: 50px;
      background-color: lighten($light-gray, 10%);

      .comment-user-wrapper {
        margin-right: 7px;
      }

      .comment-action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

// Laptop - Desktop devices
@media all and (max-width: 1600px) {
}

// Landscape Tablet
@media all and (max-width: 1199px) {
}
