@import "../../../assets/styles/globals";

.stepButton {
  width: 320px !important;
  max-width: 100%;
  position: relative;
  box-shadow: none !important;
  border: none;

  &.orange {
    margin-top: auto;
    background-color: $primary-orange !important;

    &:focus, &:active {
      background-color: $primary-orange !important;
    }

    &:disabled {
      cursor: not-allowed;

      &:hover {
        background-color: $primary-orange !important;
      }
    }
  }

  &.navy {
    margin-top: 16px;
    background-color: $primary-navy !important;

    &:hover, &:focus, &:active {
      background-color: $primary-navy !important;
    }
  }

  &:focus, &:active {
    outline: none;
    border: none;
  }
}
