@import '../../../../../assets/styles/globals.scss';

.requestLocationCard {
  min-height: 170px;
  background: white;
  border-radius: $card-corner-radius;
  padding: 24px 32px;

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .subtitle {
    color: $light-navy;
    font-size: 14px;
  }

  .greyOutModalBackground {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(43, 56, 67, 0.85);
    // z-index: 1000009; // google maps sets their map to z-index 1,000,000
  }
}
