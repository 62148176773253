#ChallengeDestroy {
  padding: 15px;
  .deniedModal {
    position: fixed;
    background: rgba(29, 41, 51, 0.8);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    font-size: 1000px;
    z-index: 999;
    background-image: url(https://meme.xyz/uploads/posts/t/l-21807-wrong.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
