@import "../../../../assets/styles/globals";

.changePassword {
  height: 100%;

  .circleLogo {
    width: 105px !important;
    height: auto;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      width: 64px !important;
      height: auto;
    }
  }

  .passwordReset {
    .titleText {
      color: white;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 16px;
      text-align: center;
      max-width: 320px;
    }

    .actionLink {
      max-width: 100%;
      width: 320px !important;
      margin-bottom: 96px !important;
    }
  }

  .passwordInput {
    .titleText {
      color: white;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 16px;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }

    .inputLabel {
      font-size: 16px;
      text-align: left;
      color: white;
      font-weight: 700;
    }

    .inputSymbol {
      color: white;
    }

    .resetPasswordButton {
      margin-top: 36px !important;
      margin-bottom: 16px;
    }

    .footer {
      width: 100%;
      margin-top: 16px;
      padding: 0 16px;
      text-align: center;
      font-size: 14px;

      .logInLink {
        font-weight: 700;
        margin-left: 4px;
      }
    }
  }
}
