@import "../../../assets/styles/globals";

.studentDashboardPage {
  .tabs {
    .tabList {
      position: sticky;
      top: 70px;
      z-index: 1;
      padding: 0px;
      margin-bottom: 0px;
      height: 40px;
      background-color: white;
      border-bottom: 2px solid $light-gray !important;
      white-space: nowrap;

      .tab {
        padding: 6px 16px 0 16px;
        margin-top: 0px;
        text-transform: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
        color: black;

        @media (min-width: 576px) {
          padding: 6px 48px 0 48px;
        }

        @media (min-width: 768px) {
          padding: 6px 60px 0 60px;
        }
      }

      .activeTab{
        border-bottom: 4px solid $secondary-color;
        height: 40px;
        color: $secondary-color;
        background: transparent !important;
      }
    }
  }
}
