@import "../../../assets/styles/globals.scss";

.date-picker-wrapper {
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
    }
  }

  .react-datepicker-popper > div > .react-datepicker {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 576px) {
      flex-wrap: nowrap;
    }
  }
}
