@import "../../../assets/styles/globals.scss";

.wrapper {
  position: relative;

  [type="radio"] {
    position: relative;
    appearance: none;
    width: 18px;
    height: 18px;
    outline: none;
    border: 2px solid $dark-gray !important;
    border-radius: 100px !important;
    background-color: inherit;
    cursor: pointer;

    &:checked+.radioDot {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      background-color: $dark-teal !important;
      border-radius: 100px !important;

      &.orange {
        background-color: $primary-orange !important;
      }
    }

    &:hover {
      border-color: #49535D !important;
      background-color: #202930 !important;

      &.orange {
        border-color: $primary-orange !important;
      }
    }
  }

  [type="checkbox"] {
    position: relative;
    appearance: none;
    width: 24px;
    height: 24px;
    outline: none;
    border: 2px solid $dark-gray !important;
    border-radius: 5px !important;
    background-color: inherit;
    cursor: pointer;

    &.orange {
      border-color: $primary-orange !important;
    }

    &[readonly] {
      border-radius: 100px !important;
      width: 19px !important;
      height: 19px !important;
    }

    &[disabled] {
      cursor: not-allowed;
      border-color: $placeholder-gray !important;
      background-color: $light-gray !important;
    }

    &:active {
      border: none;
    }

    &:hover {
      border-color: #49535D !important;
      background-color: darken(white, 15%) !important;

      &.orange {
        border-color: $primary-orange !important;
      }

      &[disabled] {
        border-color: $placeholder-gray !important;
        background-color: $light-gray !important;
      }
    }

    &:checked {
      background-color: $dark-teal !important;
      border: none !important;

      &.orange {
        background-color: $primary-orange !important;
      }

      &[readonly] {
        background-color: $dark-gray !important;
      }

      &:hover {
        background-color: darken($dark-teal, 15%) !important;

        &.orange {
          background-color: darken($primary-orange, 15%) !important;
        }

        &[readonly] {
          background-color: $dark-gray !important;
        }
      }
    }
  }

  .checkMark {
    pointer-events: none;
    position: absolute;
    top: -1px;
    left: 5px;
    width: 14px;
    height: 14px;

    svg {
      color: $slate-gray;
    }

    &.disabled {
      color: $placeholder-gray !important;

      svg {
        color: $placeholder-gray !important;
      }
    }

    &.orange {
      svg {
        color: $primary-orange;
      }

      &.checked {
        svg {
          color: white;
        }
      }
    }
  }

  .checkMarkReadOnly {
    width: 11px;
    height: 11px;
    left: 4px;
    top: -1.5px;

    svg {
      color: white;
    }
  }
}
